import React from 'react'
import PageBlock, {
  BlockData,
} from 'components/StoryPlayer/PageBlock'
import { Stack } from '@mui/material'

type Props = {
  blocks: BlockData[]
}
export default function HelperBlocks({ blocks }: Props) {
  if (!blocks?.length) {
    return null
  }
  return (
    <Stack sx={{ gap: 1, mb: 2, mt: -0.5 }}>
      {blocks?.map((tooltip, index) => (
        <PageBlock key={index} {...tooltip} />
      ))}
    </Stack>
  )
}

import React from 'react'
import {
  EnhancedDialog,
  EnhancedDialogTitle,
} from 'components/EnhancedDialog'
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
} from '@mui/material'
import MarkdownContent from 'components/MarkdownContent'
import useInterpolate from 'hooks/useInterpolate'
import { StoryPlayerContext } from './StoryPlayerContext'
import { useRouter } from 'next/router'

type Action = {
  label: string
  url: string
  target?: '_self' | '_blank'
}

export type ConsentCompleteDialogSettings = {
  title: string
  actions: Action[]
  content: string
}

export type ConsentCompleteDialogProps =
  ConsentCompleteDialogSettings & {
    open: boolean
    onClose: () => void
  }

export default function ConsentCompleteDialog(
  props: ConsentCompleteDialogProps,
): JSX.Element {
  const router = useRouter()
  const interpolate = useInterpolate(StoryPlayerContext)
  const actions: Action[] = props?.actions?.map((action) => ({
    ...action,
    label: interpolate(action.label),
    url: interpolate(action.url),
  }))

  return (
    <EnhancedDialog
      open={props?.open}
      onClose={props?.onClose}
      fullWidth
      maxWidth="sm"
      scroll="paper"
      disableCloseOnBackdropClick
    >
      <EnhancedDialogTitle onClose={props?.onClose}>
        {interpolate(props?.title)}
      </EnhancedDialogTitle>
      <Divider />
      <DialogContent>
        <MarkdownContent value={interpolate(props?.content)} />
      </DialogContent>
      {actions && (
        <>
          <Divider />
          <DialogActions>
            {actions.map(
              ({ label, url, target = '_self' }, index) => (
                <Button
                  key={`button.${index}.${label}.${url}`}
                  variant="contained"
                  onClick={() => {
                    if (target !== '_blank') {
                      // Avoid dialog close for external links.
                      props?.onClose()
                    }
                    if (url) {
                      if (target === '_self') {
                        // Handle internal links.
                        router.push(url)
                      } else {
                        // Handle external links.
                        window.open(url, target)
                      }
                    }
                  }}
                >
                  {label}
                </Button>
              ),
            )}
          </DialogActions>
        </>
      )}
    </EnhancedDialog>
  )
}

// @refresh reset
import React, { useEffect, useState } from 'react'
import { Stack, lighten, useTheme } from '@mui/material'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  XAxis,
  YAxis,
} from 'recharts'
import { omit } from 'lodash'
import { BaseBlockData } from 'components/StoryPlayer/PageBlock'

export type ChartBlockData = BaseBlockData & {
  type: 'chart'
  data: unknown[]
  options?: {
    legend?: boolean
  }
  width?: number
  height?: number
}

export type ChartBlockProps = Omit<ChartBlockData, 'type'>

export default function ChartBlock(props: ChartBlockProps) {
  const {
    data = [],
    width = 500,
    height = 300,
    disableMotion,
    options,
    ...stackProps
  } = props
  const [dataKeys, setDataKeys] = useState<string[]>([])
  const { palette } = useTheme()

  useEffect(() => {
    setDataKeys(
      Object.keys(data?.[0] ?? {}).filter((key) => key !== 'label'),
    )
  }, [data])

  return (
    <Stack
      {...omit(stackProps, 'type')}
      className={['ChartBlock', props.className]
        .filter(Boolean)
        .join(' ')}
      sx={{
        '& .recharts-wrapper, & .recharts-wrapper > svg': {
          aspectRatio: `${width} / ${height}`,
          height: 'auto !important',
          maxWidth: 1,
          width: 'auto !important',
        },
        alignItems: 'center',
        justifyContent: 'center',
        p: 1,
        position: 'relative',
        ...props.sx,
      }}
    >
      <BarChart width={width} height={height} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="label" />
        <YAxis />
        {options?.legend && <Legend />}
        {dataKeys.map((dataKey) => (
          <Bar
            key={`chartBlock.bar.${dataKey}`}
            dataKey={dataKey}
            fill={lighten(palette.primary.light, 0.166)}
            isAnimationActive={!disableMotion}
            animationBegin={500}
          />
        ))}
      </BarChart>
    </Stack>
  )
}

import React from 'react'
import {
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
} from '@mui/material'

const ErrorTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip
      {...props}
      title={props.title ?? ''}
      classes={{ popper: className }}
    />
  ),
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.error.main,
  },
}))

export default ErrorTooltip

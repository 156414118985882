import React from 'react'
import { Box, BoxProps } from '@mui/material'

export type OutlinedTextProps = BoxProps & {
  content: string
  fontSize?: number
  color?: string
  strokeColor?: string
  strokeWidth?: number
}

export default function OutlinedText(props: OutlinedTextProps) {
  const {
    content,
    fontSize = 42,
    color = '#fff',
    strokeColor = '#000',
    strokeWidth = 8,
    ...boxProps
  } = props

  return (
    <Box
      {...boxProps}
      sx={{
        fontFamily: 'inherit',
        fontSize: fontSize,
        fontWeight: 'bold',
        position: 'relative',
        ...boxProps.sx,
      }}
    >
      <Box
        sx={{
          WebkitTextStrokeColor: strokeColor,
          WebkitTextStrokeWidth: strokeWidth,
          color,
          position: 'absolute',
          zIndex: -1,
        }}
      >
        {content}
      </Box>
      <Box sx={{ color }}>{content}</Box>
    </Box>
  )
}

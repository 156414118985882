import React from 'react'
import {
  Alert,
  CircularProgress,
  FormControl,
  Stack,
  ToggleButton,
  darken,
  lighten,
} from '@mui/material'
import {
  Field,
  FieldProps,
} from 'components/StoryPlayer/pages/PageForm'
import { MotionTrail } from 'components/MotionComponents'
import StoryFieldLabel from 'components/StoryFieldLabel'
import HelperBlocks from './HelperBlocks'
import { isSingleEmoji } from 'utils/TextUtil'
import {
  SelectOptionsQueryConfig,
  useSelectOptionsQuery,
} from 'hooks/useSelectOptionsQuery'

const MAX_COLUMNS = 5

export type ButtonField = Omit<Field, 'hiddenSubmit'> & {
  type: 'button'
  columns?: 1 | 2 | 3 | 4 | 5
  disableSubmit?: boolean
  selectionHidden?: boolean
} & (
    | {
        // Options hardcoded in the story with 'options'.
        options: { label: string; value: string | number }[]
        optionsQuery?: never
      }
    | {
        // Dynamic options via supported queries with 'optionsQuery'.
        options?: never
        optionsQuery: SelectOptionsQueryConfig
      }
  )

type StoryButtonFieldProps = ButtonField &
  FieldProps & {
    disabled?: boolean
  }

export default function StoryButtonField(
  props: StoryButtonFieldProps,
) {
  const {
    label,
    optionsQuery,
    value,
    disabled,
    disableSubmit,
    helperBlocks,
    selectionHidden,
    columns = 1,
    required,
    disableRequiredAsterisk,
  } = props
  const { options: fetchedOptions, loading } =
    useSelectOptionsQuery(optionsQuery)
  const options = optionsQuery
    ? (fetchedOptions ?? [])
    : props.options
  const emptyOptions = !options.length && !loading

  return (
    <FormControl
      fullWidth
      error={!!props.fieldState.error}
      sx={{ gap: 1 }}
    >
      <StoryFieldLabel
        label={label}
        required={required && !disableRequiredAsterisk}
      />
      <HelperBlocks blocks={helperBlocks} />
      {loading && (
        <Stack
          sx={{
            alignItems: 'center',
            height: 1,
            justifyContent: 'center',
            left: 0,
            position: 'fixed',
            top: 0,
            width: 1,
            zIndex: 1,
          }}
        >
          <CircularProgress color="primary" size={32} />
        </Stack>
      )}
      {emptyOptions && (
        <Alert severity="error">No options available.</Alert>
      )}
      <Stack
        sx={{
          '& > div': {
            flex: 1,
            minWidth: `calc(${100 / Math.min(MAX_COLUMNS, columns)}% - 8px)`,
          },
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: 1,
        }}
      >
        <MotionTrail
          id={props.name}
          items={options.map((option, index) => (
            <ToggleButton
              key={`${props.name}.${option.value}.${index}`}
              name={props.name}
              value={option.value}
              fullWidth
              size="large"
              type={disableSubmit ? 'button' : 'submit'}
              onBlur={props.onBlur}
              onChange={() => {
                props.onChange(options[index].value)
              }}
              selected={!selectionHidden && value === option.value}
              disabled={disabled}
              sx={{
                '&.Mui-selected': {
                  '&.Mui-disabled': {
                    bgcolor: ({ palette }) =>
                      lighten(palette.secondary.main, 0.25),
                  },
                  '&:hover': {
                    bgcolor: ({ palette }) =>
                      darken(palette.secondary.main, 0.1),
                  },
                  bgcolor: 'secondary.main',
                  color: 'secondary.contrastText',
                },
                '@media (any-hover: hover)': {
                  '&:hover': {
                    bgcolor: ({ palette }) =>
                      darken(palette.background.default, 0.05),
                  },
                },
                bgcolor: ({ palette }) => palette.background.default,
                color: 'text.primary',
                fontSize: isSingleEmoji(option.label) ? '3em' : null,
                lineHeight: 1.4,
              }}
            >
              {option.label}
            </ToggleButton>
          ))}
        />
      </Stack>
    </FormControl>
  )
}

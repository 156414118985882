import React, { ReactNode } from 'react'
import { Button, Menu, MenuItem } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

export type UnitSelectMenuProps<T> = {
  value: T
  anchorEl: HTMLElement | null
  onAnchorElChange: (el: HTMLElement | null) => void
  onChange: (value: T) => void
  options: T[]
  renderLabel?: (value: T) => ReactNode
}

export default function UnitSelectMenu<T>(
  props: UnitSelectMenuProps<T>,
) {
  const {
    anchorEl,
    onAnchorElChange,
    onChange,
    value,
    options,
    renderLabel = (value) => String(value),
  } = props
  return (
    <>
      <Button
        onClick={({ currentTarget }) =>
          onAnchorElChange(currentTarget)
        }
        endIcon={
          <ArrowDropDownIcon fontSize="inherit" color="inherit" />
        }
        sx={{
          color: 'text.primary',
          fontSize: 'inherit',
          fontWeight: 400,
          minWidth: 0,
        }}
      >
        {renderLabel(value)}
      </Button>
      <Menu
        id="unit-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => onAnchorElChange(null)}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        sx={{
          '& .MuiMenuItem-root': {
            minWidth: 72,
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={`unit-${option}`}
            selected={value === option}
            onClick={() => {
              onAnchorElChange(null)
              onChange(option)
            }}
          >
            {String(option)}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

import { visit } from 'unist-util-visit'
import { Node } from 'unist'
import { formatDateTime } from '__shared__/utils/DateUtil'

export function stripMarkdownSyntax(input: string) {
  return input
    .replace(/[*_]{1,2}(.+?:?)[*_]{1,2}/g, '$1')
    .replace(/#{1,6} (.+)/g, '$1')
}

export function decodeEntities(input: string) {
  const textarea = document.createElement('textarea')
  textarea.innerHTML = input
  return textarea.value
}

type VisitNode = Node & {
  name?: string
  value: string
  attributes?: Record<string, unknown>
  children?: VisitNode[]
}

export function commonRemarkDirectives() {
  return (tree) => {
    visit<VisitNode>(tree, (node) => {
      const { type } = node

      if (['containerDirective', 'leafDirective'].includes(type)) {
        node.data = {
          ...node.data,
          hName: node.name,
          hProperties: node.attributes,
        }

        // Ability to format datetime using data-format="datetime".
        // ::div[{{createdAt}}]{data-format="datetime"}
        if (node.attributes['data-format'] === 'datetime') {
          node.children[0].value = formatDateTime(
            node.children[0].value,
            true,
            'M/d/y p',
          )
        }
      }
    })
  }
}

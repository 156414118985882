import { get, isPlainObject } from 'lodash'

export function getObjectKeys(data: unknown) {
  if (!isPlainObject(data)) {
    return []
  }
  return Object.entries(data)
    .map(([key, value]) => {
      return isPlainObject(value)
        ? getObjectKeys(value).map((path) => `${key}.${path}`)
        : key
    })
    .flat()
}

export function interpolateData(
  content: string,
  data: Record<string, unknown>,
) {
  data = isPlainObject(data) ? data : {}
  const keys = getObjectKeys(data)
  return keys.reduce(
    (acc, variable) =>
      acc.replace(
        new RegExp(`{{${variable}}}`, 'g'),
        String(get(data, variable) ?? ''),
      ),
    content,
  )
}

export function interpolateDefaultValue<T>(
  defaultValue: string,
  data: Record<string, unknown>,
) {
  data = isPlainObject(data) ? data : {}
  const variableRegExp = new RegExp(`^{{(.+?)}}$`)
  const name = defaultValue.match(variableRegExp)?.[1]
  return variableRegExp.test(defaultValue)
    ? ((get(data, name) as T) ?? '')
    : (defaultValue as unknown as T)
}

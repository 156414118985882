import React, {
  CSSProperties,
  MouseEventHandler,
  ReactNode,
  useLayoutEffect,
  useRef,
} from 'react'
import { Box, BoxProps, Stack, SxProps, Theme } from '@mui/material'
import {
  animated,
  useSpring,
  useSpringValue,
  useTrail,
} from 'react-spring'
import { omit } from 'lodash'

const AnimatedBox = animated(Box)

export type MotionElementProps = {
  component?: BoxProps['component']
  componentType?: 'box' | 'stack'
  children: ReactNode
  sx?: SxProps<Theme>
  className?: string
  onClick?: MouseEventHandler<HTMLDivElement>
  from?: CSSProperties & { x?: number; y?: number }
  to?: CSSProperties & { x?: number; y?: number }
  disableMotion?: boolean
  reverse?: boolean
}

export function MotionElement(props: MotionElementProps) {
  const {
    disableMotion,
    children,
    componentType,
    reverse,
    className,
    ...boxProps
  } = props
  const from = { opacity: 0, ...props.from }
  const to = { opacity: 1, ...props.to }
  const springProps = useSpring({
    delay: 10,
    from,
    reverse,
    to,
  })
  const Component =
    componentType === 'box' ? animated(Box) : animated(Stack)

  return (
    <Component
      {...omit(boxProps, 'from', 'to')}
      className={[className, reverse && 'MotionElement-reverse']
        .filter(Boolean)
        .join(' ')}
      style={!disableMotion ? springProps : null}
    >
      {children}
    </Component>
  )
}

export type MotionCollapseProps = MotionElementProps & {
  in: boolean
}

export function MotionCollapse(props: MotionCollapseProps) {
  const container = useRef<HTMLDivElement>(null)
  const height = useSpringValue(0)
  const springProps = useSpring({
    from: { height: 0 },
    height,
  })
  const { disableMotion, children, ...boxProps } = props

  useLayoutEffect(() => {
    height.set(props.in ? container.current.offsetHeight : 0)
  }, [props.in, height])

  return (
    <AnimatedBox
      {...omit(boxProps, 'in')}
      style={!disableMotion ? springProps : null}
    >
      <Box ref={container}>{children}</Box>
    </AnimatedBox>
  )
}

export type MotionTrailProps = {
  items: ReactNode[]
  from?: CSSProperties
  to?: CSSProperties
  delay?: number
  disableMotion?: boolean
  id: string
  itemComponent?: BoxProps['component']
  sx?: SxProps<Theme>
}

export function MotionTrail(props: MotionTrailProps) {
  const { items, delay = 0, disableMotion, id, sx } = props
  const from = { opacity: 0, y: 2, ...props.from }
  const to = { opacity: 1, y: 0, ...props.to }
  const [trails] = useTrail(
    items.length,
    () => ({
      config: {
        friction: 8,
        mass: 0.25,
        tension: 60,
      },
      delay,
      from,
      to,
    }),
    [items.length, delay, from, to],
  )

  return (
    <>
      {trails.map((springProps, index) => (
        <AnimatedBox
          key={`trail.${id}.${index}`}
          style={!disableMotion ? springProps : null}
          component={props.itemComponent}
          sx={sx}
        >
          {items[index]}
        </AnimatedBox>
      ))}
    </>
  )
}

import SpeedDial from '@mui/material/SpeedDial'
import SpeedDialAction from '@mui/material/SpeedDialAction'
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded'
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { useMemo, useRef, useState } from 'react'
import { alpha } from '@mui/material'
import useKiosk from 'hooks/useKiosk'
import { useRouter } from 'next/router'

export default function KioskSpeedDial() {
  const router = useRouter()
  const kiosk = useKiosk()
  const [open, setOpen] = useState(false)
  const hidden = useMemo(
    // Hide on kiosk page but display on logged in patient pages.
    () => !(kiosk.enabled && !router.asPath.match(/^\/kiosk\/$/)),
    [kiosk.enabled, router.asPath],
  )
  const closeTimeout = useRef<number>(-1)
  const handleOpen = () => {
    window.clearTimeout(closeTimeout.current)
    setOpen(true)
  }
  const handleClose = () => {
    // Add delay to avoid blur/close triggering before onClick (possible MUI bug?).
    window.clearTimeout(closeTimeout.current)
    closeTimeout.current = window.setTimeout(
      () => setOpen(false),
      200,
    )
  }

  return (
    <SpeedDial
      ariaLabel="Kiosk Menu"
      sx={{
        '& .MuiFab-root': {
          '&:hover': {
            bgcolor: ({ palette }) => alpha(palette.grey['700'], 0.2),
          },
          bgcolor: ({ palette }) => alpha(palette.grey['900'], 0.2),
          boxShadow: 1,
          color: 'rgba(255,255,255,0.87)',
        },
        '& .MuiSpeedDialAction-staticTooltip': {
          whiteSpace: 'nowrap',
        },
        '& .MuiSpeedDialAction-staticTooltipLabel': {
          bgcolor: ({ palette }) => alpha(palette.grey['900'], 0.2),
          color: 'rgba(255,255,255,0.87)',
          whiteSpace: 'nowrap',
        },
        bottom: 0,
        m: 3,
        position: 'fixed',
        right: 0,
        zIndex: 1302,
      }}
      FabProps={{ size: 'medium' }}
      icon={<SettingsRoundedIcon />}
      openIcon={<CloseRoundedIcon />}
      hidden={hidden}
      onOpen={handleOpen}
      onClose={handleClose}
      open={open}
    >
      <SpeedDialAction
        icon={<ExitToAppRoundedIcon />}
        tooltipTitle="End Session"
        tooltipOpen
        onClick={kiosk.sessionEnd}
      />
    </SpeedDial>
  )
}

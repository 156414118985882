import { Texture } from 'components/DynamicBackground'

// eslint-disable-next-line import/no-anonymous-default-export
const texture: Texture = {
  data: (fill: string) => `
<svg width="20px" height="20px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g fill="${fill}">
      <circle cx="3" cy="3" r="3"></circle>
      <circle cx="13" cy="13" r="3"></circle>
    </g>
  </g>
</svg>`,
  direction: ['x', 'y', 'xy'],
  height: 20,
  scale: 0.5,
  width: 20,
}
export default texture

import { useAuth, useLoginActions } from '@frontegg/react'
import useKiosk from 'hooks/useKiosk'
import { throttle } from 'lodash'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import {
  useDebounce,
  useDocumentEventListener,
  useIntervalWhen,
} from 'rooks'
import RootStore from 'stores/RootStore'

const INACTIVITY_DIALOG_THRESHOLD = 60000 * 5 // 5 min

export default function useAutoLogout(
  // Duration in ms before user is logged out automatically.
  activityExpiration: number,
  disabled?: boolean,
) {
  const { logout: fronteggLogout } = useLoginActions()
  const { isAuthenticated } = useAuth()
  const router = useRouter()
  const kiosk = useKiosk()
  const getLastActivity = useCallback((): number | null => {
    let lastActivity: string | number = RootStore.get('lastActivity')

    if (lastActivity) {
      lastActivity = parseInt(lastActivity, 10)
    }
    if (!lastActivity) {
      return null
    }

    return lastActivity as number
  }, [])
  const isInactive = useCallback(() => {
    const lastActivity = getLastActivity()

    // No value means no recorded activity in this case.
    if (!lastActivity) {
      return false
    }

    const timeSinceLastActivity = Date.now() - lastActivity

    return timeSinceLastActivity > activityExpiration
  }, [activityExpiration, getLastActivity])
  const shouldOpenInactiveDialog = useCallback(() => {
    const lastActivity = getLastActivity()

    // No value means no recorded activity in this case.
    if (!lastActivity) {
      return false
    }

    const timeSinceLastActivity = Date.now() - lastActivity
    const isSessionActive =
      timeSinceLastActivity <= activityExpiration
    const timeUntilActivityExpiration =
      activityExpiration - timeSinceLastActivity
    const isInactiveDialogThresholdReached =
      timeUntilActivityExpiration <= INACTIVITY_DIALOG_THRESHOLD

    return isSessionActive && isInactiveDialogThresholdReached
  }, [activityExpiration, getLastActivity])
  const [isTrackingActivity, setIsTrackingActivity] = useState(false)
  const logout = useCallback(() => {
    setIsTrackingActivity(false)
    setTimeout(() => {
      abandonScheduledLogout()
      fronteggLogout()
    })
  }, [fronteggLogout])
  const [isSessionInactive, setIsSessionInactive] = useState(false)
  const [isSessionExpired, setIsSessionExpired] = useState(false)
  const scheduleLogout = useCallback(
    (reason: string) => {
      if (isSessionExpired) {
        // Otherwise toggling a pending logout multiple times may cause re-renders.
        return
      }

      console.warn(`Schedule logout: ${reason}`)
      setIsTrackingActivity(false)
      setIsSessionInactive(false)
      setIsSessionExpired(true)
      if (!disabled) {
        RootStore.set('scheduledLogout', 'true')
      }
    },
    [disabled, isSessionExpired],
  )
  const [
    isActivityMonitoringStarted,
    setIsActivityMonitoringStarted,
  ] = useState(false)
  const inactiveCheck = useCallback(() => {
    // Inactivity checks may happen:
    // - while there's no user authenticated
    // - before a starting activity event will be processed
    // - when a logout dialog is being shown.
    if (
      !isAuthenticated ||
      !isActivityMonitoringStarted ||
      isSessionExpired
    ) {
      return
    }

    if (isInactive()) {
      scheduleLogout('inactive check')
    } else if (shouldOpenInactiveDialog()) {
      setIsSessionInactive(true)
    }
  }, [
    isAuthenticated,
    isActivityMonitoringStarted,
    isSessionExpired,
    isInactive,
    shouldOpenInactiveDialog,
    scheduleLogout,
  ])
  const throttledHandleActivity = throttle((e?: Event) => {
    e?.stopImmediatePropagation()
    if (disabled || !isTrackingActivity) {
      return
    }

    // Store last activity for syncing browsers tabs with the app open and reloading the app.
    RootStore.set('lastActivity', `${Date.now()}`)
    setIsActivityMonitoringStarted(true)
  }, 1000)
  const handleActivity = useCallback(throttledHandleActivity, [
    throttledHandleActivity,
  ])
  const isOnFronteggUrl =
    window.location.pathname.startsWith('/account/') ||
    window.location.pathname.startsWith('/login')

  // On app mount, check if session is valid based on inactivity.
  useEffect(() => {
    if (!disabled && isInactive()) {
      scheduleLogout('inactive check on app mount')
      console.warn('Logout on app mount due to activity expiration')
      logout()
      return
    }

    setIsTrackingActivity(true)
    handleActivity()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Make sure to clear a last activity on the next tick because
  // we have event listeners also relying on isLoggingOut.
  useEffect(() => {
    if (!isTrackingActivity) {
      RootStore.remove('lastActivity')
    }
  }, [isTrackingActivity])

  useDocumentEventListener('keydown', handleActivity)
  useDocumentEventListener('mousemove', handleActivity)
  useDocumentEventListener('touchstart', handleActivity)

  // Check inactivity status every 5s when auto-logout is enabled.
  useIntervalWhen(inactiveCheck, 5000, !disabled, !disabled)

  // Handle Redirect is a callback function that accepts
  // a params object with the following properties:
  // - isSessionExpired: boolean
  // - isAuthenticated: boolean
  // - isOnFronteggUrl: boolean
  // However it also depends on the following variables:
  // - router
  // - logout
  // - kiosk
  // in order to be redefined
  const handleRedirectFn = useCallback(
    (params: {
      isSessionExpired: boolean
      isAuthenticated: boolean
      isOnFronteggUrl: boolean
    }) => {
      if (params.isSessionExpired) {
        // Otherwise toggling a pending logout will take effect immediately.
        console.warn('Session expired')
        return
      }

      // Navigate to redirect url for deep linking.
      if (params.isAuthenticated) {
        const redirectUrl = RootStore.get('redirectAfterLogin')

        if (redirectUrl) {
          RootStore.remove('redirectAfterLogin')
          console.warn(`Redirecting to ${redirectUrl}`)
          router.replace(redirectUrl)
          return
        }
      }

      // Save previous route and redirect to login if not authenticated.
      if (!params.isAuthenticated) {
        // Remove possibly stale values to avoid another double login issue
        // because we're already logged out.
        abandonScheduledLogout()

        // Redirect to kiosk url if enabled.
        if (kiosk.enabled) {
          // In the unlike scenario of a race condition
          // where the user is already on the kiosk url
          // and they're auto-logged out, we don't want to redirect.
          if (window.location.pathname === kiosk.url) {
            console.warn(
              `Already on ${kiosk.url}. No need to redirect`,
            )
            return
          }
          console.warn(`Redirecting after logout: ${kiosk.url}`)
          router.replace(kiosk.url).then(() => {
            // Clear session for new kiosk login.
            kiosk.resetStoryId()
          })
          return
        }

        if (!params.isOnFronteggUrl) {
          const redirectUrl =
            window.location.pathname +
            window.location.search +
            window.location.hash

          RootStore.set('redirectAfterLogin', redirectUrl)
          console.warn(`Scheduled redirect: ${redirectUrl}`)
          router.replace(`/login`)
        }
        return
      }

      // Check for scheduled logout (possibly from other tabs).
      if (
        params.isAuthenticated &&
        RootStore.get('scheduledLogout')
      ) {
        console.warn('Performing scheduled logout')
        logout()
        return
      }
    },
    [router, logout, kiosk],
  )

  // Handle redirect is wrapped with debounce to avoid
  // multiple redirects in a short period of time.
  const handleRedirect = useDebounce(handleRedirectFn, 350)

  // Redirect for auth and non-auth sessions.
  useEffect(
    () => {
      if (!disabled) {
        handleRedirect({
          isAuthenticated,
          isOnFronteggUrl,
          isSessionExpired,
        })
      }
    },
    // A lot of occasions to re-render this effect
    // However, the handleRedirect is wrapped with debounce
    [
      isAuthenticated,
      router,
      logout,
      disabled,
      isSessionExpired,
      isOnFronteggUrl,
      kiosk,
      handleRedirect,
    ],
  )

  return {
    logout,
    scheduleLogout,
    sessionIsExpired: isSessionExpired,
    sessionIsInactive: isSessionInactive,
    setSessionIsInactive: setIsSessionInactive,
  }
}

export function abandonScheduledLogout() {
  const wasLogoutScheduled = RootStore.get('scheduledLogout')
  RootStore.remove('scheduledLogout')
  RootStore.remove('lastActivity')
  if (wasLogoutScheduled) {
    console.warn('Abandoned scheduled logout')
  }
}

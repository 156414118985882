import React, { MouseEvent, useContext } from 'react'
import IconButton from '@mui/material/IconButton'
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import {
  TextColor,
  pwaStatusBarHeight,
} from 'components/NestMuiTheme'
import {
  Box,
  Collapse,
  Fade,
  Grow,
  Stack,
  Typography,
  alpha,
  useTheme,
} from '@mui/material'
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded'
import { mixins } from 'components/NestMuiTheme'
import { useDebouncedValue, usePreviousDifferent } from 'rooks'
import { StoryPlayerContext } from 'components/StoryPlayerContext'
import StoryAudioButton, {
  AudioToggleButton,
} from 'components/StoryAudioButton'
import StoryProgressBar from 'components/StoryProgressBar'
import { StoryPageData } from 'components/StoryPlayer/StoryPage'
import { findLongestPathFromPages } from 'components/StoryPlayerUtil'
import { isLightColor } from 'utils/ColorUtil'

type StoryPlayerHeaderProps = {
  title?: string
  category?: string
  audioSrc?: string
  pageId?: string
  history: string[]
  pages: StoryPageData[]
  hasVideoMuteButton?: boolean
  hasAudioButton?: boolean
  onBack: () => void
  onClose: (e: MouseEvent) => void
  onOptionsClick?: () => void
  backgroundColor: string
  hidden?: boolean
  hiddenTitle?: boolean
  disableBack?: boolean
  disableProgress?: boolean
  disableClose?: boolean
}

export default function StoryPlayerHeader(
  props: StoryPlayerHeaderProps,
): JSX.Element {
  const { storyPlayerMuted, setStoryPlayerMuted } = useContext(
    StoryPlayerContext,
  )
  const { palette } = useTheme()
  const {
    hidden,
    disableClose,
    disableProgress,
    history,
    audioSrc,
    hasAudioButton,
    hasVideoMuteButton,
    pageId,
    backgroundColor = palette.background.default,
    pages = [],
  } = props
  const prevTitle = usePreviousDifferent(props.title)
  const progress = pages.length ? history.length - 1 : 0
  const progressTotal = Math.max(
    1,
    // Current progress + longest path from current page.
    progress + findLongestPathFromPages(pages, pageId),
  )
  const page = pages.find((p) => p.id === pageId)
  const isDarkControls =
    page?.theme?.controlsDarkMode ?? isLightColor(backgroundColor)
  const controlsColor = isDarkControls
    ? TextColor.light.primary
    : TextColor.dark.primary
  const disableScrollHint = page?.disableScrollHint
  const [hasAudioButtonDebounced] = useDebouncedValue(
    hasAudioButton,
    300,
  )
  const [hasVideoMuteButtonDebounced] = useDebouncedValue(
    hasVideoMuteButton,
    300,
  )

  return (
    <Stack
      component="header"
      sx={{
        '& .MuiButtonBase-root': {
          pointerEvents: 'auto',
        },
        '@media (hover: none) and (pointer: coarse)': {
          userSelect: 'none',
        },
        '@media all and (display-mode: standalone)': {
          top: pwaStatusBarHeight,
        },
        background:
          disableProgress || disableScrollHint
            ? null
            : `linear-gradient(${backgroundColor} -10%, ${alpha(
                backgroundColor,
                0,
              )} 66%)`,
        justifyContent: 'space-between',
        opacity: hidden ? 0 : 1,
        pb: 2,
        pointerEvents: 'none',
        position: 'relative',
        transition: 'opacity .3s ease 0s',
        transitionDelay: hidden ? '.3s' : '0s',
        zIndex: 1,
      }}
    >
      <Fade in={!disableProgress}>
        <Stack sx={{ mt: 2, mx: 2 }}>
          <StoryProgressBar
            value={(progress / progressTotal) * 100}
            color={controlsColor}
          />
        </Stack>
      </Fade>
      <Stack
        direction="row"
        gap={1}
        sx={{
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          pl: 1,
          pr: 1,
          pt: 1,
        }}
      >
        <Stack
          direction="row"
          sx={{
            alignItems: 'center',
            height: 40,
            overflow: 'hidden',
            width: 1,
          }}
        >
          <Box sx={{ width: 46 }}>
            <Fade in={!props.disableBack}>
              <IconButton onClick={props.onBack} sx={{ p: 0.5 }}>
                <ChevronLeftRoundedIcon
                  htmlColor={controlsColor}
                  sx={{ fontSize: 32 }}
                />
              </IconButton>
            </Fade>
          </Box>
          <Stack
            sx={{
              color: controlsColor,
              overflow: 'hidden',
              width: 1,
            }}
          >
            <Fade in={!!props.title && !props.hiddenTitle}>
              <Typography
                variant="body1"
                sx={[
                  mixins.textOverflowEllipsis,
                  {
                    fontSize: '0.875rem',
                    fontWeight: 500,
                    lineHeight: 1.4,
                    textAlign: 'center',
                  },
                ]}
              >
                {props.title ?? prevTitle}
              </Typography>
            </Fade>
          </Stack>
        </Stack>
        <Stack
          sx={{
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
          }}
        >
          <Stack direction="row">
            {!!props.onOptionsClick && (
              <IconButton
                onClick={props.onOptionsClick}
                sx={{ display: 'none' }}
              >
                <MoreHorizRoundedIcon htmlColor={controlsColor} />
              </IconButton>
            )}
            {!disableClose && (
              <IconButton onClick={props.onClose} sx={{ p: 0.5 }}>
                <CloseRoundedIcon
                  htmlColor={controlsColor}
                  sx={{ fontSize: 32 }}
                />
              </IconButton>
            )}
          </Stack>
          <Collapse in={hasAudioButton}>
            <Grow
              in={hasAudioButton ? hasAudioButtonDebounced : false}
            >
              <Stack sx={{ mt: 1 }}>
                <StoryAudioButton
                  color={controlsColor}
                  audioSrc={audioSrc}
                  onClick={() =>
                    setStoryPlayerMuted(!storyPlayerMuted)
                  }
                />
              </Stack>
            </Grow>
          </Collapse>
          <Grow
            in={
              hasAudioButton && !hasVideoMuteButton
                ? false
                : hasVideoMuteButtonDebounced
            }
          >
            <Stack sx={{ mt: 1 }}>
              <AudioToggleButton
                variant="mute-toggle"
                enabled={!storyPlayerMuted}
                onClick={() => setStoryPlayerMuted(!storyPlayerMuted)}
                sx={{ color: controlsColor }}
              />
            </Stack>
          </Grow>
        </Stack>
      </Stack>
    </Stack>
  )
}

import React, { useRef, useState } from 'react'
import { Box, Fade, useTheme } from '@mui/material'
import useInterpolate from 'hooks/useInterpolate'
import { StoryPlayerContext } from 'components/StoryPlayerContext'
import { omit } from 'lodash'
import { ReactSVG } from 'react-svg'
import chroma from 'chroma-js'
import { BaseBlockData } from 'components/StoryPlayer/PageBlock'

export type SvgBlockData = BaseBlockData & {
  type: 'svg'
  src: string
  width?: number
  height?: number
  fullBleed?: boolean
}

export type SvgBlockProps = Omit<SvgBlockData, 'type'>

export default function SvgBlock(props: SvgBlockProps) {
  const {
    disableMotion,
    src,
    width = 200,
    height = 200,
    fullBleed,
    ...boxProps
  } = props
  const [timeout, setTimeout] = useState(0)
  const { palette } = useTheme()
  const startTime = useRef(new Date().getTime())
  const interpolate = useInterpolate(StoryPlayerContext)
  const [loaded, setLoaded] = useState(false)
  return (
    <Fade in={loaded} timeout={timeout}>
      <Box
        {...omit(boxProps, 'type')}
        className={[
          'SvgBlock',
          props.className,
          fullBleed && 'fullBleed',
        ]
          .filter(Boolean)
          .join(' ')}
        sx={{
          '& div, & svg': {
            height: 1,
            width: 1,
          },
          display: 'block',
          height: height,
          maxWidth: 1,
          mx: 'auto',
          objectFit: 'contain',
          width: width,
          ...props.sx,
        }}
      >
        <ReactSVG
          src={interpolate(src)}
          afterInjection={() => {
            const loadTime = new Date().getTime() - startTime.current
            setTimeout(!disableMotion && loadTime > 100 ? 300 : 0)
            setLoaded(true)
          }}
          wrapper="div"
          beforeInjection={(svg) => {
            svg
              .querySelectorAll('g, path, circle, ellipse, rect')
              .forEach((path) => {
                // Replace single theme colors.
                if (path.getAttribute('fill') === '#888888') {
                  const color = chroma(palette.secondary.main)
                    .set('hsl.l', 0.5)
                    .set('hsl.s', '*.50')
                    .css()
                  path.setAttribute('fill', color)
                }

                // Replace duotone colors.
                if (path.getAttribute('fill') === '#121212') {
                  const color = chroma(palette.secondary.main)
                    .set('hsl.l', 0.33)
                    .set('hsl.s', '*.50')
                    .css()
                  path.setAttribute('fill', color)
                }
                if (path.getAttribute('stroke') === '#121212') {
                  const color = chroma(palette.secondary.main)
                    .set('hsl.l', 0.33)
                    .set('hsl.s', '*.50')
                    .css()
                  path.setAttribute('stroke', color)
                }
                if (
                  path.getAttribute('fill')?.toLowerCase() ===
                  '#d9d9d9'
                ) {
                  const color = chroma(palette.secondary.main)
                    .set('hsl.l', 0.66)
                    .set('hsl.s', '*0.50')
                    .css()
                  path.setAttribute('fill', color)
                }
                if (
                  path.getAttribute('fill')?.toLowerCase() ===
                  '#ededed'
                ) {
                  const color = chroma(palette.secondary.main)
                    .set('hsl.l', 0.93)
                    .set('hsl.s', '*0.50')
                    .css()
                  path.setAttribute('fill', color)
                }
              })
          }}
        />
      </Box>
    </Fade>
  )
}

import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  Button,
  CircularProgress,
  Grow,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded'
import PauseRoundedIcon from '@mui/icons-material/PauseRounded'
import StoryProgressBar from 'components/StoryProgressBar'
import { useStoryPlayerProgress } from 'hooks/useStoryPlayerProgress'
import { Box } from '@mui/system'
import {
  BasePageProps,
  PageData,
} from 'components/StoryPlayer/StoryPage'
import { StoryPlayerContext } from 'components/StoryPlayerContext'
import PageFooter from 'components/StoryPlayer/PageFooter'
import { clamp } from 'lodash'
import ActionButtonBlock from 'components/blocks/ActionButtonBlock'
import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded'
import { useDebouncedValue } from 'rooks'
import {
  BlockData,
  BlocksAlign,
} from 'components/StoryPlayer/PageBlock'
import OverlayBlocksContainer from 'components/blocks/OverlayBlocksContainer'

export interface PageVideoData extends PageData {
  blocks?: BlockData[]
  blocksAlign?: BlocksAlign
  blocksVariant?: 'contained' | 'transparent'
  type: 'video'
  fillScreen?: boolean
  noControls?: boolean
  loop?: boolean
  duration?: number
  src: string
}

export type PageVideoProps = Pick<
  BasePageProps,
  | 'disableMotion'
  | 'enableActionIconButton'
  | 'submitLabel'
  | 'onNext'
> &
  Pick<
    PageVideoData,
    | 'blocks'
    | 'blocksAlign'
    | 'blocksVariant'
    | 'fillScreen'
    | 'noControls'
    | 'src'
    | 'duration'
    | 'loop'
  > & {
    canPlay: boolean
    hiddenControls?: boolean
  }

function formatSecondsToString(seconds = 0) {
  return new Date(seconds * 1000).toISOString().substr(15, 4)
}

export default function PageVideo(
  props: PageVideoProps,
): JSX.Element {
  const {
    duration,
    loop,
    blocks = [],
    blocksAlign = 'center',
    blocksVariant = 'contained',
    onNext,
    hiddenControls,
    enableActionIconButton,
    canPlay,
    submitLabel = 'Next',
    src,
    noControls,
    disableMotion,
  } = props
  const { storyPlayerMuted } = useContext(StoryPlayerContext)
  const [hiddenVideoControls, setHiddenVideoControls] = useState(true)
  const [loaded, setLoaded] = useState(false)
  const videoRef = useRef<HTMLVideoElement>(null)
  const getProgressValue = useCallback(
    (elapsedTime: number) => {
      const video = videoRef.current
      if (!video) {
        return
      }
      if (duration) {
        return Math.min(elapsedTime / duration, 1)
      } else if (video && !loop) {
        const elapsedTime = video.currentTime ?? 0
        const duration = video.duration ?? 1
        return Math.min(elapsedTime / duration, 1)
      }
      return 0
    },
    [duration, loop],
  )
  const [playing, setPlaying] = useState(false)
  const [videoProgress, setVideoProgress] = useState(0)
  const { resetElapsedTime } = useStoryPlayerProgress({
    duration,
    getValue: getProgressValue,
    onNext,
    playing: playing && canPlay,
  })
  const handleProgress = useCallback(() => {
    const video = videoRef.current
    if (!video) {
      return
    }
    const currentTime = video.paused
      ? video.currentTime
      : video.currentTime + 1
    const progress = clamp(currentTime / video.duration, 0, 1)
    setVideoProgress(progress)
  }, [])
  const progressIntervalRef = useRef<number | null>(null)
  const clearProgressInterval = useCallback(() => {
    clearInterval(progressIntervalRef.current)
  }, [])
  const startProgressInterval = useCallback(() => {
    if (videoRef.current?.paused) {
      return
    }
    clearProgressInterval()
    handleProgress()
    progressIntervalRef.current = window.setInterval(
      handleProgress,
      1000,
    )
  }, [clearProgressInterval, handleProgress])
  const handlePlayToggle = () => {
    const video = videoRef.current
    if (!video) {
      return
    }
    clearProgressInterval()
    if (video.paused) {
      video.play()
      startProgressInterval()
    } else {
      video.pause()
      handleProgress()
    }
  }
  const [videoEnded, immediatelyUpdateVideoEndedValue] =
    useDebouncedValue(!loop && videoProgress === 1, 500)
  const progressBarRef = useRef<HTMLDivElement>(null)
  const jumpToProgress = useCallback(
    // Reset the video progress without progress bar animation.
    (progress: number) => {
      const video = videoRef.current
      const progressBarEl =
        progressBarRef.current?.querySelector<HTMLElement>(
          '.MuiLinearProgress-bar',
        )
      if (!video || !progressBarEl) {
        return
      }
      // Manually updated the progress without transition.
      progressBarEl.style.transitionDuration = '0s'
      clearProgressInterval()
      setTimeout(() => {
        progressBarEl.style.transitionDuration = '1s'
        startProgressInterval()
      }, 50)

      // Seek video to time offset.
      video.currentTime = progress * (video.duration || 0)
      setVideoProgress(progress)
    },
    [clearProgressInterval, startProgressInterval],
  )

  useEffect(() => {
    // Auto play the video visibility or src changes.
    if (src) {
      immediatelyUpdateVideoEndedValue(false)
    }
    return () => {
      resetElapsedTime()
    }
  }, [resetElapsedTime, src, immediatelyUpdateVideoEndedValue])

  useEffect(() => {
    // Reset progress when video source changes.
    jumpToProgress(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src])

  useEffect(() => {
    // Clear interval on unmount.
    return () => clearProgressInterval()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Stack
      sx={{
        '& video': {
          objectFit: props.fillScreen ? 'cover' : null,
        },
        backgroundColor: '#000',
        flexGrow: 1,
        justifyContent: 'flex-end',
      }}
      onClick={() => {
        setHiddenVideoControls(!loop && !hiddenVideoControls)
      }}
    >
      <Grow in={!loaded}>
        <Stack
          sx={{
            alignItems: 'center',
            color: 'white',
            height: 1,
            justifyContent: 'center',
            left: 0,
            position: 'fixed',
            top: 0,
            width: 1,
            zIndex: 1,
          }}
        >
          <CircularProgress color="inherit" />
        </Stack>
      </Grow>
      <Grow in={videoEnded}>
        <Stack
          sx={{
            alignItems: 'center',
            color: 'white',
            height: 1,
            justifyContent: 'center',
            left: 0,
            position: 'fixed',
            top: 0,
            width: 1,
            zIndex: 1,
          }}
        >
          <Button
            variant="outlined"
            color="inherit"
            startIcon={<ReplayRoundedIcon />}
            size="large"
            onClick={(e) => {
              // Prevent toggling visibility of player controls.
              e.stopPropagation()
              const video = videoRef.current
              if (!video) {
                return
              }

              // Seek to beginning and play.
              video.currentTime = 0
              video.play()

              immediatelyUpdateVideoEndedValue(false)
              setHiddenVideoControls(true)
            }}
          >
            Replay
          </Button>
        </Stack>
      </Grow>
      <OverlayBlocksContainer
        in={loaded}
        blocks={blocks}
        blocksAlign={blocksAlign}
        variant={blocksVariant}
        disableMotion={disableMotion}
      />
      <Box
        ref={videoRef}
        component="video"
        src={src}
        autoPlay
        playsInline
        loop={loop}
        muted={storyPlayerMuted}
        onPlay={() => {
          setPlaying(true)
          startProgressInterval()
        }}
        onPause={() => setPlaying(false)}
        onLoadedData={() => {
          setLoaded(true)
          // Initialize after load and autoplay.
          if (!videoRef.current?.paused) {
            setPlaying(true)
            startProgressInterval()
          }
        }}
        width="100%"
        height="100%"
        sx={{
          left: 0,
          overflow: 'hidden',
          position: 'fixed',
          top: 0,
        }}
      />
      <PageFooter
        sx={{ position: 'fixed' }}
        disableScrollHint={
          noControls || (hiddenVideoControls && !videoEnded)
        }
      >
        {!noControls && loaded && (
          <Stack
            direction="row"
            sx={{
              alignItems: 'center',
              mx: -3,
              opacity:
                (hiddenControls || hiddenVideoControls) && !videoEnded
                  ? 0
                  : 1,
              pointerEvents:
                (hiddenControls || hiddenVideoControls) && !videoEnded
                  ? 'none'
                  : 'auto',
              pt: 2,
              px: 2,
              transition: 'opacity .3s ease 0s',
              transitionDelay: hiddenControls ? '.3s' : '0s',
              zIndex: 1,
            }}
            onClick={(e) => {
              // Prevent toggling visibility of player controls.
              e.stopPropagation()
            }}
          >
            <IconButton onClick={handlePlayToggle} disableRipple>
              {playing && canPlay ? (
                <PauseRoundedIcon htmlColor="white" />
              ) : (
                <PlayArrowRoundedIcon htmlColor="white" />
              )}
            </IconButton>
            <Box
              sx={{
                WebkitTapHighlightColor: 'transparent',
                backgroundColor: 'transparent',
                border: 0,
                cursor: 'pointer',
                flexGrow: 1,
                mx: 1,
                px: 0,
                py: 2,
              }}
              component="button"
              onClick={(e) => {
                const video = videoRef.current
                if (!video) {
                  return
                }
                const { currentTarget, clientX } = e
                const width = currentTarget.clientWidth
                let offsetLeft = currentTarget.offsetLeft ?? 0
                let parent = currentTarget.offsetParent as HTMLElement

                // Calculate progress bar offset.
                while (parent) {
                  offsetLeft = offsetLeft + (parent?.offsetLeft ?? 0)
                  parent = parent?.parentElement as HTMLElement
                }

                // Calculate progress value based on the click position.
                const x = clientX - offsetLeft
                const progress = x / width
                jumpToProgress(progress)
              }}
            >
              <StoryProgressBar
                ref={progressBarRef}
                value={videoProgress * 100}
                color="white"
                sx={{
                  '& .MuiLinearProgress-bar': {
                    // Smooth transition of the progress bar at 1s intervals.
                    transitionDuration: '1s',
                  },
                }}
              />
            </Box>
            <Typography
              variant="caption"
              color="white"
              sx={{
                pl: 2,
                width: 48,
              }}
            >
              {formatSecondsToString(videoRef.current?.currentTime)}
            </Typography>
          </Stack>
        )}
        <ActionButtonBlock
          variant={enableActionIconButton ? 'icon' : 'label'}
          label={submitLabel}
          onClick={(e) => {
            // Prevent toggling visibility of player controls.
            e.stopPropagation()
            props.onNext()
          }}
        />
      </PageFooter>
    </Stack>
  )
}

import React from 'react'
import { ErrorBoundary } from '@sentry/nextjs'
import ForbiddenErrorBoundary from 'components/boundaries/ForbiddenErrorBoundary'
import CustomErrorPage from 'components/boundaries/CustomErrorPage'

export type ErrorBoundariesProps = {
  children: React.ReactNode
}

export default function ErrorBoundaries({
  children,
}: ErrorBoundariesProps) {
  return (
    <ErrorBoundary fallback={<CustomErrorPage type="error" />}>
      <ForbiddenErrorBoundary>{children}</ForbiddenErrorBoundary>
    </ErrorBoundary>
  )
}

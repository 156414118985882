import useHashParams from 'hooks/useHashParams'
import { useCallback, useState } from 'react'

export const getConsentParamsFromPath = (url = '') => {
  const matches = url?.match?.(/#\/consent\/?([\w-]+)\/?(completed)?/)
  return {
    completed: !!matches?.[2],
    consentId: matches?.[1],
  }
}

export default function useConsentHashParams({
  disabled,
}: {
  disabled?: boolean
}) {
  const [params, setParams] = useState({
    completed: false,
    consentId: '',
  })
  const onHashChange = useCallback(() => {
    const { consentId, completed } = getConsentParamsFromPath(
      window.location.hash,
    )
    setParams({ completed, consentId })
  }, [])

  useHashParams({ disabled, onHashChange })

  return params
}

import React from 'react'
import { Box, CircularProgress, Stack } from '@mui/material'
import {
  Field,
  FieldProps,
} from 'components/StoryPlayer/pages/PageForm'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormHelperText from '@mui/material/FormHelperText'
import Checkbox from '@mui/material/Checkbox'
import Chip from '@mui/material/Chip'
import MarkdownContent from 'components/MarkdownContent'
import StoryFieldLabel from 'components/StoryFieldLabel'
import { playerMaxHeight } from 'components/StoryPlayer'
import { isArray, isNil } from 'lodash'
import HelperBlocks from './HelperBlocks'
import {
  SelectOptionsQueryConfig,
  useSelectOptionsQuery,
} from 'hooks/useSelectOptionsQuery'

const defaultPlaceholder = 'Select option'

export type SelectField = Field & {
  type: 'select'
  helperText?: string
  multiple?: boolean
  variant?: 'default' | 'chip'
  placeholder?: string
} & (
    | {
        // Options hardcoded in the story with 'options'.
        options: { label: string; value: string | number }[]
        optionsQuery?: never
      }
    | {
        // Dynamic options via supported queries with 'optionsQuery'.
        options?: never
        optionsQuery: SelectOptionsQueryConfig
      }
  )

export type StorySelectFieldProps = SelectField &
  FieldProps & {
    autoFocus?: boolean
    disabled?: boolean
  }

export default function StorySelectField(
  props: StorySelectFieldProps,
) {
  const {
    fieldState,
    multiple,
    label,
    required,
    helperBlocks,
    disableRequiredAsterisk,
    optionsQuery,
    inputRef,
  } = props
  const { error } = fieldState
  const placeholder = props.placeholder ?? defaultPlaceholder
  const { options: fetchedOptions, loading } =
    useSelectOptionsQuery(optionsQuery)
  const options = optionsQuery
    ? (fetchedOptions ?? [])
    : props.options
  const emptyOptions = !options.length && !loading

  return (
    <FormControl fullWidth error={!!error}>
      <StoryFieldLabel
        label={label}
        required={required && !disableRequiredAsterisk}
      />
      <HelperBlocks blocks={helperBlocks} />
      <Select
        inputRef={inputRef}
        onBlur={props.onBlur}
        onChange={props.onChange}
        value={
          (multiple
            ? // Using `multiple` will enforce array values and reset to empty.
              isArray(props.value)
              ? options.length
                ? props.value.filter((item) =>
                    // Filter out values not included in "options".
                    options.some(({ value }) => value === item),
                  )
                : []
              : []
            : options.length
              ? (props.value ?? '')
              : '') as string
        }
        displayEmpty={!!placeholder}
        autoFocus={props.autoFocus}
        disabled={props.disabled || loading || !options.length}
        multiple={multiple}
        fullWidth
        MenuProps={{
          color: 'secondary',
          sx: {
            '& .MuiMenuItem-root': {
              '&.Mui-selected': {
                '&:hover': {
                  bgcolor: 'secondary.dark',
                  color: 'secondary.contrastText',
                },
                bgcolor: 'secondary.main',
                color: 'secondary.contrastText',
              },
              whiteSpace: 'normal',
            },
            maxHeight: playerMaxHeight,
          },
        }}
        sx={{
          '& .MuiSelect-select': {
            '& .MuiBox-root > *': {
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            },
            '& p:first-of-type': { mt: 0 },
            '& p:last-of-type': { mb: 0 },
            '&:has(.MuiChip-root)': {
              // Fix height discrepancies when Chips are displayed.
              my: '-0.28rem',
            },
          },
          '& > .MuiSvgIcon-root': {
            visibility: loading ? 'hidden' : 'visible',
          },
        }}
        required={required}
        error={!!error}
        renderValue={(value) => {
          if (emptyOptions) {
            return 'No options available'
          }
          if (
            isNil(value) ||
            value === '' ||
            (multiple && !(value as unknown as unknown[])?.length)
          ) {
            return placeholder
          }

          if (multiple) {
            return (
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 0.5,
                }}
              >
                {[value]
                  .flat()
                  .map((value) =>
                    options.find((o) => o.value === value),
                  )
                  .filter(Boolean)
                  .map((option) => (
                    <Chip key={option.value} label={option.label} />
                  ))}
              </Box>
            )
          }

          const label = options.find(
            (option) => option.value === value,
          )?.label

          return <MarkdownContent value={label} />
        }}
        endAdornment={
          loading && (
            <Stack>
              <CircularProgress color="primary" size={20} />
            </Stack>
          )
        }
      >
        {options.map((option, index) => (
          <MenuItem
            key={`item-${option.value}-${index}`}
            value={option.value}
          >
            {multiple && (
              <Checkbox
                sx={{
                  '&.MuiCheckbox-root.Mui-checked': ({
                    palette: { getContrastText, secondary },
                  }) => ({
                    // Determine light/dark icon color based on background color.
                    color: getContrastText(secondary.main),
                  }),
                }}
                checked={
                  isArray(props.value)
                    ? props.value.includes(option.value)
                    : false
                }
              />
            )}
            <MarkdownContent value={option.label} />
          </MenuItem>
        ))}
      </Select>
      {(error?.message || props.helperText) && (
        <FormHelperText>
          {error ? error.message : props.helperText}
        </FormHelperText>
      )}
    </FormControl>
  )
}

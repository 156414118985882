import { JSONSchema7 } from 'json-schema'
import Ajv, { ErrorObject } from 'ajv'
import { merge } from 'lodash'
import { jsonSchemaDefaults } from 'utils/JsonSchemaDefaults'
import { useMemo } from 'react'
import betterAjvErrors from 'better-ajv-errors'

const ajv = new Ajv()

export default function useStorySettings(
  schemaProperties: JSONSchema7['properties'],
  data: Record<string, unknown>,
) {
  const result = useMemo((): {
    errorMessage: string
    errors: ErrorObject<string, Record<string, unknown>, unknown>[]
    storySettings: Record<string, unknown>
    valid: boolean | null
  } => {
    if (!schemaProperties) {
      return {
        errorMessage: null,
        errors: [],
        storySettings: null,
        valid: true,
      }
    }
    const storySettingsSchema: JSONSchema7 = {
      properties: schemaProperties,
      required: Object.keys(schemaProperties ?? {}),
      type: 'object',
    }
    const validateJsonSchema = ajv.compile(storySettingsSchema)
    const storySettings = merge(
      // Merge the default values from schema with the data.
      jsonSchemaDefaults(storySettingsSchema),
      data,
    )
    const valid = validateJsonSchema(storySettings)
    const errors = validateJsonSchema.errors
    const errorMessage = betterAjvErrors(
      storySettingsSchema,
      storySettings,
      errors,
      { indent: 2 },
    )

    return { errorMessage, errors, storySettings, valid }
  }, [schemaProperties, data])

  return result
}

import { TodoListItem } from 'components/TodoList'
import { TimelineEntryProps } from 'components/TimelineEntry'

const patientData: {
  todos: TodoListItem[]
  timeline: TimelineEntryProps[]
} = {
  timeline: [
    {
      dateLabel: 'Recommended in 3 months',
      icon: {
        type: 'appointment-future',
      },
      //subtitle: 'Dana-Farber',
      subtitle: 'UIC',
      title: 'Clinical Breast Exam',
    },
    {
      active: true,
      button: {
        label: 'Update appointment',
      },
      dateLabel: 'Sep 22, 2022 10:00 AM',
      headerTitle: 'Your Next Step',
      icon: {
        type: 'appointment',
      },
      //subtitle: 'Dana-Farber',
      subtitle: 'UIC',
      title: 'Mammogram',
    },
    {
      description: `You've reduced your risk by 50%.`,
      icon: {
        type: 'thumb-up',
      },
      img: {
        src: '/img/timeline-thumbs-up.svg',
      },
      title: 'Great job! Keep it up!',
    },
    {
      dateLabel: 'Jun 4, 2022',
      icon: {
        type: 'success',
      },
      //subtitle: 'Dana-Farber',
      subtitle: 'UIC',
      title: 'Clinical Breast Exam',
    },
    {
      dateLabel: 'Mar 16, 2022',
      icon: {
        type: 'success',
      },
      //subtitle: 'Dana-Farber',
      subtitle: 'UIC',
      title: 'Breast MRI',
    },
    {
      dateLabel: 'Nov 24, 2021',
      description: 'There is no change to your care plan.',
      icon: {
        type: 'birthday',
      },
      img: {
        src: '/img/timeline-birthday-1.svg',
      },
      parallaxBackground: [
        {
          multiplier: 0.075,
          src: '/img/timeline-confetti.svg',
        },
        {
          multiplier: 0.125,
          src: '/img/timeline-confetti.svg',
          sx: {
            backgroundPosition: '40vw 20vw',
          },
        },
      ],
      title: 'Happy birthday!',
    },
    {
      dateLabel: 'Oct 9, 2021',
      icon: {
        type: 'success',
      },
      //subtitle: 'Dana-Farber',
      // subtitle: 'UIC',
      title: 'Shared results with family members',
    },
    {
      dateLabel: 'Sep 14, 2021',
      icon: {
        type: 'success',
      },
      //subtitle: 'Dana-Farber',
      subtitle: 'UIC',
      title: 'Mammogram',
    },
    {
      dateLabel: 'Sep 1, 2021',
      icon: {
        type: 'success',
      },
      img: {
        src: '/img/timeline-care-plan-ready.svg',
      },
      title: 'Your care plan is ready for you!',
    },
    {
      dateLabel: 'Sep 1, 2021',
      icon: {
        type: 'success',
      },
      title: 'Genetic Counseling Appointment',
    },
    {
      dateLabel: 'Aug 25, 2021',
      icon: {
        type: 'success',
      },
      title: 'Genetic Test Result',
    },
    {
      icon: {
        type: 'begin',
      },
      subtitle: 'Your journey begins here.',
    },
  ],
  todos: [
    {
      category: 'LEARN',
      description: `You've been assigned this course which covers what your variant is and what it means for your health`,
      hidden: (storyProgress) =>
        storyProgress.isCompleted('results-overview-course-BRCA2'),
      href: '#/story/results-overview-course-BRCA2',
      id: 'learn',
      status: 'ACTIVE',
      title: 'Complete your BRCA2 Results Overview course',
    },
    {
      category: 'APPOINTMENT',
      chip: {
        color: 'warning',
        label: 'Overdue',
      },
      description: `You're due for your annual mammogram. Make an appointment now to stay on track with your care plan.`,
      hidden: (storyProgress) =>
        storyProgress.isCompleted(
          // 'make-mammogram-appointment',
          'make-mammogram-appointment-non-integrated',
        ),
      // href: '#/story/make-mammogram-appointment',
      href: '#/story/make-mammogram-appointment-non-integrated',
      id: 'share',
      status: 'ACTIVE',
      title: 'Schedule your mammogram',
    },
    {
      category: 'SURVEY',
      description: `Your answers will help your care team get an accurate history of your medical concerns and conditions.`,
      hidden: (storyProgress) =>
        storyProgress.isCompleted('annual-questionnaire-BRCA2'),
      href: '#/story/annual-questionnaire-BRCA2',
      id: 'G001',
      status: 'ACTIVE',
      title: 'Complete your annual questionnaire',
    },
  ],
}

export default patientData

import { OrderableTestOptionsQuery } from '__generated__/graphql'
import { StoryPageData } from 'components/StoryPlayer/StoryPage'
import { PageTransformer } from 'components/StoryPlayer/pageTransformer'
import { generateId } from 'utils/SeedUtil'

const exampleOrderableTests: PageTransformer = ({
  returnToPageId,
  data,
}): StoryPageData[] => {
  const seedRoot = 'exampleOrderableTests'
  const options =
    (data as OrderableTestOptionsQuery)?.patientApp
      ?.orderableTestOptions ?? []
  const formPageId = generateId(seedRoot + 'form')
  const followUpPageId = options.length ? formPageId : returnToPageId

  return [
    {
      blocks: [
        {
          content: `Orderable Tests`,
          type: 'text',
          variant: 'h5',
        },
        {
          content: options.length
            ? `### You're eligible for ${options.length} tests.`
            : `### You're not eligible for tests.`,
          type: 'text',
        },
      ],
      defaultConnectionPageId: followUpPageId,
      id: generateId(seedRoot),
      overrideHeaderTitle: 'Orderable Tests',
      theme: {
        backgroundColor: '#fffaf1',
        color: '#e9a907',
        secondaryColor: 'rgba(39, 33, 38, 0.5)',
        variant: 'random',
      },
      type: 'custom',
    },
    {
      defaultConnectionPageId: returnToPageId,
      fields: [
        {
          label: 'Which tests would you like to order?',
          name: 'selectedTests',
          options,
          type: 'checkbox',
        },
      ],
      id: formPageId,
      overrideHeaderTitle: 'Orderable Tests',
      theme: {
        backgroundColor: '#fffaf1',
        color: '#e9a907',
        secondaryColor: 'rgba(39, 33, 38, 0.5)',
        variant: 'random',
      },
      type: 'form',
    },
  ]
}

export default exampleOrderableTests

import { MotionValue } from 'framer-motion'
import { noop } from 'lodash'
import PatientModel from 'models/Patient'
import React from 'react'
import { PaletteMode, Theme } from '@mui/material'
import { StoryData } from 'components/StoryPlayer'
import { FontSize } from 'components/NestMuiTheme'
import {
  PatientAppPatientData,
  TodosQuery,
} from '__generated__/graphql'
import {
  AccountSettings,
  ContentAccountData,
} from '__shared__/constants'
import { LazyQueryExecFunction } from '@apollo/client'

export type FeatureFlags = Pick<
  AccountSettings,
  | 'demoAccount'
  | 'enablePatientOnboardingAlwaysOnStart'
  | 'patientAppCarePlan'
  | 'patientAppCareTeam'
  | 'onboardingStoryId'
  | 'clinicalSupportEmail'
> & {
  customLearnAccounts: ContentAccountData[]
}

export type AppContextProps = {
  contentAccounts: ContentAccountData[]
  documentTitle: string
  featureFlags: FeatureFlags
  fetchTodos: LazyQueryExecFunction<TodosQuery, unknown>
  fontSize: number
  hasBackNavigation: boolean
  patient: PatientModel
  patientData: PatientAppPatientData[]
  patientLoading: boolean
  ready: boolean
  refetchPatient: () => void
  scrollY: MotionValue
  setDocumentTitle: (title: string) => void
  setFontSize: (fontSize: number) => void
  setHasBackNavigation: (value: boolean) => void
  setTheme: (theme: Theme) => void
  setThemeMode: (mode: PaletteMode) => void
  stories: StoryData[]
  theme: Theme
  themeMode: PaletteMode
  todos: TodosQuery['patientApp']['todos']
  todosLoading: boolean
}

export const AppContext = React.createContext<AppContextProps>({
  contentAccounts: [],
  documentTitle: '',
  featureFlags: {
    clinicalSupportEmail: '',
    customLearnAccounts: [],
    demoAccount: false,
    enablePatientOnboardingAlwaysOnStart: false,
    patientAppCarePlan: true,
  },
  fetchTodos: null,
  fontSize: FontSize.Default,
  hasBackNavigation: false,
  patient: null as PatientModel,
  patientData: [],
  patientLoading: false,
  ready: false,
  refetchPatient: noop,
  scrollY: new MotionValue(),
  setDocumentTitle: noop,
  setFontSize: noop,
  setHasBackNavigation: noop,
  setTheme: noop,
  setThemeMode: noop,
  stories: [] as StoryData[],
  theme: null,
  themeMode: 'light' as PaletteMode,
  todos: [],
  todosLoading: false,
})

import React, { ReactNode } from 'react'
import { Alert, AlertTitle, Snackbar } from '@mui/material'

type ErrorSnackbarProps = {
  open: boolean
  title?: ReactNode
  children: ReactNode
  onClose: () => void
}

export default function ErrorSnackbar(props: ErrorSnackbarProps) {
  return (
    <Snackbar
      data-testid="ErrorSnackbar"
      open={props.open}
      onClose={props.onClose}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom',
      }}
      ClickAwayListenerProps={{
        mouseEvent: false,
        touchEvent: false,
      }}
      sx={{
        '& .MuiAlert-message': {
          minWidth: 0,
        },
        maxWidth: 'calc(100% - 48px)',
      }}
    >
      <Alert
        onClose={props.onClose}
        severity="error"
        sx={{ width: 1 }}
      >
        {props.title && <AlertTitle>{props.title}</AlertTitle>}
        {props.children}
      </Alert>
    </Snackbar>
  )
}

import React from 'react'
import LinearProgress, {
  LinearProgressProps,
} from '@mui/material/LinearProgress'
import { useTheme } from '@mui/material'

export type StoryProgressBarProps = {
  value: number
  color?: string
  sx?: LinearProgressProps['sx']
}

const StoryProgressBar = React.forwardRef(function StoryProgressBar(
  props: StoryProgressBarProps,
  ref,
): JSX.Element {
  const { palette } = useTheme()
  const { value, color = palette.text.primary, sx } = props

  return (
    <LinearProgress
      ref={ref}
      variant="determinate"
      color="inherit"
      value={value}
      sx={{
        borderRadius: 2,
        color,
        height: 2,
        opacity: 0.5,
        ...sx,
      }}
    />
  )
})

export default StoryProgressBar

import React, {
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import {
  AccordionProps,
  Box,
  Button,
  Chip,
  ChipProps,
  ClickAwayListener,
  Grow,
  IconButton,
  Paper,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { AppContext } from 'components/AppContext'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import BasicAccordion from 'components/BasicAccordion'
import AppIcon from 'components/AppIcon'
import GuidedTour, { Step } from 'components/GuidedTour'
import WaitingImage from 'assets/images/waiting.svg'
import { CareStepCategory, PatientCareStep } from 'models/Patient'
import { times } from 'lodash'
import ResponsiveContainer from 'components/ResponsiveContainer'
import StepTypeIcon from 'components/StepTypeIcon'
import CareStepModel from 'models/CareStep'
import { mixins } from 'components/NestMuiTheme'
import { StepPriority } from 'models/GuidelineStatement'
import Link from 'next/link'
import useDocumentTitle from 'hooks/useDocumentTitle'
import {
  TodoCompleteActionType,
  useTodoCompleteActionMutation,
} from '__generated__/graphql'

const tourGuideSteps: Step[] = [
  {
    content:
      'Your **Care Plan** is made of up of steps that are important to disease screening or prevention.\n\nWhen you click on a step, it will expand with more details.',
    placement: 'center',
    target: 'body',
  },
]

export default function MyCarePlanPage() {
  useDocumentTitle('Care Plan')
  const { patient, refetchPatient, patientLoading } =
    useContext(AppContext)
  const [todoCompleteActionMutation] = useTodoCompleteActionMutation()
  const carePlans = patient?.carePlans || []
  const carePlanSteps: {
    [CareStepCategory.MEDICAL_CARE]: PatientCareStep[]
    [CareStepCategory.SELF_CARE]: PatientCareStep[]
    [CareStepCategory.OPTIONS_TO_CONSIDER]: PatientCareStep[]
  } = carePlans
    .map((carePlan) => carePlan.steps)
    .flat()
    .map(
      (carePlanStep): PatientCareStep => ({
        ...carePlanStep,
        areaOfConcern: carePlanStep.areaOfConcern,
        priority: carePlanStep.data?.priority,
        subtitle: new CareStepModel(carePlanStep).modifiersLabel,
        title: carePlanStep.name,
      }),
    )
    .reduce(
      (acc, step) => {
        if (step.priority === StepPriority.CONSIDERATION) {
          acc[CareStepCategory.OPTIONS_TO_CONSIDER].push(step)
        } else if (step.category === CareStepCategory.MEDICAL_CARE) {
          acc[CareStepCategory.MEDICAL_CARE].push(step)
        } else if (step.category === CareStepCategory.SELF_CARE) {
          acc[CareStepCategory.SELF_CARE].push(step)
        }
        return acc
      },
      {
        [CareStepCategory.MEDICAL_CARE]: [],
        [CareStepCategory.OPTIONS_TO_CONSIDER]: [],
        [CareStepCategory.SELF_CARE]: [],
      },
    )

  // Only run this effect when the component mounts
  useEffect(() => {
    todoCompleteActionMutation({
      variables: {
        todoCompleteActionInput: {
          completeActionData: {},
          completeActionType: TodoCompleteActionType.REVIEW_CARE_PLAN,
        },
      },
    })
  }, [todoCompleteActionMutation])

  useEffect(() => {
    refetchPatient?.()
  }, [refetchPatient])

  if (!carePlans.length && !patientLoading) {
    return (
      <Grow in>
        <Paper
          sx={{
            mx: 2,
            px: 3,
            py: 4,
          }}
          elevation={0}
        >
          <Stack
            sx={{
              justifyContent: 'center',
              mb: 5,
              textAlign: 'center',
            }}
          >
            <Stack sx={{ alignItems: 'center', height: 120, mb: -1 }}>
              <WaitingImage />
            </Stack>
            <Typography
              variant="h4"
              color="text.primary"
              gutterBottom
              sx={{ width: 1 }}
            >
              In Progress
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Your care team is working on your care plan. Please
              check back later.
            </Typography>
          </Stack>
        </Paper>
      </Grow>
    )
  }

  return (
    <>
      <GuidedTour id="care-plan" steps={tourGuideSteps} open />
      <Typography
        variant="h4"
        sx={{
          bgcolor: 'background.default',
          color: 'text.secondary',
          position: 'relative',
          px: 3,
          py: 2,
          zIndex: 1100,
        }}
      >
        Steps
      </Typography>
      <Stack gap={3}>
        {patientLoading && (
          <ResponsiveContainer
            header={
              <Skeleton
                width={80}
                sx={{ mx: 0.75, my: 1.5, opacity: 0.5 }}
              />
            }
          >
            <Paper
              sx={{
                borderRadius: { sm: 1, xs: 0 },
                opacity: 0.6,
              }}
            >
              {times(4, (index) => (
                <Stack
                  key={`stepSkeleton.${index}`}
                  direction="row"
                  sx={{
                    '&:last-of-type': {
                      borderBottomWidth: 0,
                    },
                    alignItems: 'center',
                    borderBottomColor: 'divider',
                    borderBottomStyle: 'solid',
                    borderBottomWidth: 1,
                    display: 'flex',
                    gap: 2,
                    height: 64,
                    opacity: 0.6,
                    px: 3,
                  }}
                >
                  <Skeleton
                    variant="circular"
                    width={40}
                    height={40}
                    sx={{ flexShrink: 0 }}
                  />
                  <Skeleton width="200px" />
                </Stack>
              ))}
            </Paper>
          </ResponsiveContainer>
        )}
        {!patientLoading && (
          <>
            <StepCategoryGroup
              title="Medical Care"
              steps={carePlanSteps?.['MEDICAL_CARE']}
              tooltip="These steps are performed by a provider and usually need to be scheduled."
            />
            <StepCategoryGroup
              title="Self Care"
              steps={carePlanSteps?.['SELF_CARE']}
              tooltip="These steps are things you do yourself."
            />
            <StepCategoryGroup
              title="Options to consider"
              steps={carePlanSteps?.['OPTIONS_TO_CONSIDER']}
              tooltip="You should discuss these steps with your provider so you can come to shared decisions about whether or not to do them."
            />
          </>
        )}
      </Stack>
    </>
  )
}

type StepCategoryGroupProps = {
  title: ReactNode
  steps: PatientCareStep[]
  tooltip: ReactNode
}

function StepCategoryGroup(props: StepCategoryGroupProps) {
  const { title, steps, tooltip } = props
  const [tooltipOpen, setTooltipOpen] = useState(false)

  if (!steps.length) {
    return null
  }

  return (
    <Stack>
      <ResponsiveContainer
        header={
          <Stack
            sx={{
              ...mixins.bgBlur,
              position: {
                sm: 'static',
                xs: 'sticky',
              },
              top: 64,
              zIndex: 1100,
            }}
          >
            <Stack
              direction="row"
              sx={{
                alignItems: 'center',
                justifyContent: 'space-between',
                px: {
                  sm: 1,
                  xs: 3,
                },
                py: 1,
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  color: 'text.primary',
                  fontWeight: 500,
                  lineHeight: 1,
                  m: 0,
                }}
              >
                {title}
              </Typography>
              <ClickAwayListener
                onClickAway={() => setTooltipOpen(false)}
              >
                <div>
                  <Tooltip
                    title={tooltip || ''}
                    open={tooltipOpen}
                    disableFocusListener
                    placement="bottom-end"
                    arrow
                  >
                    <IconButton
                      size="small"
                      sx={{
                        mx: {
                          xs: -0.5,
                        },
                      }}
                      onClick={() => setTooltipOpen(!tooltipOpen)}
                    >
                      <AppIcon.Question color="secondary" />
                    </IconButton>
                  </Tooltip>
                </div>
              </ClickAwayListener>
            </Stack>
          </Stack>
        }
      >
        {!steps?.length && (
          <Paper
            sx={{
              alignItems: 'center',
              borderRadius: { sm: 1, xs: 0 },
              display: 'flex',
              height: 64,
              px: 3,
            }}
          >
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ fontStyle: 'italic' }}
            >
              None
            </Typography>
          </Paper>
        )}
        <Stack
          sx={{
            borderRadius: {
              sm: 1,
              xs: 0,
            },
          }}
        >
          {steps?.map((step, index) => (
            <StepAccordion key={`step.${index}`} step={step} />
          ))}
        </Stack>
      </ResponsiveContainer>
    </Stack>
  )
}

type StepAccordionProps = {
  step: PatientCareStep
  sx?: AccordionProps['sx']
}

export function StepAccordion(props: StepAccordionProps) {
  const { step, sx } = props
  return (
    <BasicAccordion sx={sx}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          minHeight: 56,
          px: 3,
        }}
      >
        <Stack
          direction="row"
          sx={{ alignItems: 'center', gap: 2, pr: 1, width: 1 }}
        >
          <StepTypeIcon variant={step.type} />
          <Stack sx={{ flexGrow: 1 }}>
            <Typography variant="body1">{step.title}</Typography>
            {step.subtitle && (
              <Typography variant="body2" color="text.secondary">
                {step.subtitle}
              </Typography>
            )}
          </Stack>
          <AreaOfConcernChip
            label={step.areaOfConcern}
            sx={{ display: { md: 'inherit', xs: 'none' } }}
          />
        </Stack>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          '& p:first-of-type': { mt: 0 },
          '& p:last-of-type': { mb: 0 },
          alignItems: 'flex-start',
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          pb: 3,
          px: {
            md: 10,
            xs: 3,
          },
          typography: 'body2',
        }}
      >
        <AreaOfConcernChip
          label={step.areaOfConcern}
          sx={{ display: { sm: 'none' } }}
        />
        <Box sx={{ whiteSpace: 'pre-wrap' }}>
          {step.patientDescription}
        </Box>
        <Stack sx={{ alignItems: 'flex-start', gap: 0.5 }}>
          {(step.metadata?.careStepStories ?? []).map(
            (item, index) => (
              <Button
                key={`story.${step.id}.${index}`}
                startIcon={<AppIcon.Play />}
                LinkComponent={Link}
                href={`#/story/${item.slug}`}
              >
                {item.label ?? 'Learn more'}
              </Button>
            ),
          )}
        </Stack>
      </AccordionDetails>
    </BasicAccordion>
  )
}

function AreaOfConcernChip(props: {
  label: ReactNode
  sx?: ChipProps['sx']
}) {
  const { label, sx } = props
  if (!label) {
    return null
  }
  return (
    <Chip variant="outlined" size="smaller" label={label} sx={sx} />
  )
}

import React, { MouseEvent, useEffect, useState } from 'react'
import { Stack } from '@mui/material'
import {
  BasePageProps,
  PageData,
} from 'components/StoryPlayer/StoryPage'
import {
  MotionElement,
  MotionTrail,
} from 'components/MotionComponents'
import { useStoryPlayerProgress } from 'hooks/useStoryPlayerProgress'
import AnimationBlock, {
  AnimationBlockProps,
} from 'components/blocks/AnimationBlock'
import ImageBlock, {
  ImageBlockProps,
} from 'components/blocks/ImageBlock'
import ChartBlock, {
  ChartBlockProps,
} from 'components/blocks/ChartBlock'
import ButtonBlock from 'components/blocks/ButtonBlock'
import ObjectBlock, {
  ObjectBlockProps,
} from 'components/blocks/ObjectBlock'
import SvgBlock, { SvgBlockProps } from 'components/blocks/SvgBlock'
import PageFooter from 'components/StoryPlayer/PageFooter'
import ActionButtonBlock from 'components/blocks/ActionButtonBlock'
import BlocksContainer from 'components/blocks/BlocksContainer'
import PageBlock, {
  BlockData,
} from 'components/StoryPlayer/PageBlock'
import { useRouter } from 'next/router'

export interface PageCustomData extends PageData {
  type: 'custom'
  hero?:
    | (ImageBlockProps & { type: 'image' })
    | (SvgBlockProps & { type: 'svg' })
    | (ObjectBlockProps & { type: 'object' })
    | (AnimationBlockProps & { type: 'animation' })
    | (ChartBlockProps & { type: 'chart' })
  blocks: BlockData[]
  duration?: number
  hiddenSubmit?: boolean
  submitUrl?: string
}

export type PageCustomProps = Pick<
  BasePageProps,
  | 'onNext'
  | 'disableMotion'
  | 'enableClose'
  | 'submitLabel'
  | 'enableActionIconButton'
  | 'sx'
> & {
  id: string
  hero?: PageCustomData['hero']
  blocks: BlockData[]
  duration?: number
  canPlay: boolean
  onBack: (e: MouseEvent) => void
  onClose: () => void
  hiddenSubmit?: boolean
  submitUrl?: PageCustomData['submitUrl']
}

export default function PageCustom(
  props: PageCustomProps,
): JSX.Element {
  const {
    blocks = [],
    hero,
    duration,
    onNext,
    onClose,
    canPlay,
    disableMotion,
    enableClose,
    hiddenSubmit,
    submitLabel,
    submitUrl,
    enableActionIconButton,
    sx,
  } = props
  const [loading, setLoading] = useState(false)
  const router = useRouter()
  const { resetProgress, resetElapsedTime } = useStoryPlayerProgress({
    duration,
    onNext,
    playing: canPlay,
  })

  useEffect(() => {
    setLoading(false)
  }, [props.id])

  useEffect(() => {
    resetProgress()
    return () => resetElapsedTime()
  }, [duration, resetProgress, resetElapsedTime])

  return (
    <>
      <Stack
        sx={{ containerType: 'size', flexGrow: 1, overflowY: 'auto' }}
      >
        {hero && (
          <MotionElement
            key={`hero.${props.id}`}
            className="hero"
            sx={{
              '& img': { height: 1 },
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              height: '40cqh',
              justifyContent: 'center',
              mb: 3,
              pt: hero?.type === 'image' ? 0 : 8,
            }}
          >
            {hero?.type === 'image' && (
              <ImageBlock
                disableMotion={disableMotion}
                {...hero}
                fullBleed
              />
            )}
            {hero?.type === 'svg' && (
              <SvgBlock disableMotion={disableMotion} {...hero} />
            )}
            {hero?.type === 'object' && (
              <ObjectBlock disableMotion={disableMotion} {...hero} />
            )}
            {hero?.type === 'animation' && (
              <AnimationBlock
                disableMotion={disableMotion}
                {...hero}
              />
            )}
            {hero?.type === 'chart' && (
              <ChartBlock disableMotion={disableMotion} {...hero} />
            )}
          </MotionElement>
        )}
        <BlocksContainer
          sx={{
            justifyContent: hero ? 'flex-start' : 'center',
            pb: 14, // Allow space for the footer.
            pt: hero ? 0 : 14, // Allow space for the header.
            ...sx,
          }}
        >
          <MotionTrail
            key={`trail.${props.id}`}
            id={`trail.${props.id}`}
            disableMotion={disableMotion}
            items={blocks.map((block, index) => (
              <PageBlock
                key={`block.${props.id}.${index}`}
                data-id={`block.${props.id}.${index}`}
                disableMotion={disableMotion}
                {...block}
              />
            ))}
          />
        </BlocksContainer>
      </Stack>
      <PageFooter>
        <ActionButtonBlock
          variant={enableActionIconButton ? 'icon' : 'label'}
          label={submitLabel}
          loading={loading}
          hidden={hiddenSubmit}
          component={submitUrl ? 'a' : undefined}
          href={submitUrl}
          onClick={async (e) => {
            setLoading(true)
            e.preventDefault()
            // Prevent onClick on link behavior.
            if (submitUrl) {
              await onNext()
              setLoading(false)
              router.push(submitUrl)
              return
            }
            await onNext()
          }}
        />
        {enableClose && (
          <ButtonBlock
            key="story-close"
            type="button"
            variant="text"
            color="secondary"
            size="large"
            fullWidth
            component={submitUrl ? 'a' : undefined}
            href={submitUrl}
            onClick={onClose}
          >
            Done
          </ButtonBlock>
        )}
      </PageFooter>
    </>
  )
}

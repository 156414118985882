import React from 'react'
import { Stack, darken } from '@mui/material'
import useInterpolate from 'hooks/useInterpolate'
import { StoryPlayerContext } from 'components/StoryPlayerContext'
import { omit } from 'lodash'
import MarkdownContent from 'components/MarkdownContent'
import { BaseBlockData } from 'components/StoryPlayer/PageBlock'
import InfoRoundedIcon from '@mui/icons-material/InfoRounded'

export const InfoIcon = InfoRoundedIcon

export type InfoBannerBlockData = BaseBlockData & {
  type: 'info-banner'
  content: string
}

export type InfoBannerBlockProps = Omit<InfoBannerBlockData, 'type'>

export default function InfoBannerBlock(props: InfoBannerBlockProps) {
  const { content, sx, ...typographyProps } = props
  const interpolate = useInterpolate(StoryPlayerContext)

  return (
    <Stack
      className={['InfoBannerBlock', props.className]
        .filter(Boolean)
        .join(' ')}
      sx={{
        alignItems: 'center',
        bgcolor: ({ palette }) => palette.background.default,
        borderColor: ({ palette }) =>
          darken(palette.background.default, 0.075),
        borderStyle: 'solid',
        borderWidth: 1,
        flexDirection: 'row',
        gap: 1.5,
        my: 1,
        p: 2,
        ...sx,
      }}
    >
      <Stack
        {...omit(typographyProps, 'disableMotion')}
        sx={{
          '& .MuiSvgIcon-root': { minHeight: 30 },
          '& p:first-of-type': { mt: 0 },
          '& p:last-of-type': { mb: 0 },
          '& strong,b': { fontWeight: 600 },
          color: 'text.primary',
          flex: 1,
          flexDirection: 'row',
          flexWrap: 'wrap',
          typography: 'body2',
          ...props.sx,
        }}
      >
        <MarkdownContent value={interpolate(content)} />
      </Stack>
      <InfoIcon sx={{ color: 'text.secondary' }} />
    </Stack>
  )
}

import { Texture } from 'components/DynamicBackground'

// eslint-disable-next-line import/no-anonymous-default-export
const texture: Texture = {
  data: (fill: string) => `
<svg width="6px" height="6px" viewBox="0 0 6 6" xmlns="http://www.w3.org/2000/svg">
  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g fill="${fill}">
      <polygon points="5 0 6 0 0 6 0 5"></polygon>
      <polygon points="6 5 6 6 5 6"></polygon>
    </g>
  </g>
</svg>`,
  direction: ['x', 'y', 'xy'],
  height: 6,
  width: 6,
}
export default texture

import React, { useContext } from 'react'
import { Box, Stack, StackProps, alpha } from '@mui/material'
import { StoryPlayerContext } from 'components/StoryPlayerContext'
import { omit } from 'lodash'

export type PageFooterProps = StackProps & {
  disableScrollHint?: boolean
}

export default function PageFooter(
  props: PageFooterProps,
): JSX.Element {
  const { page } = useContext(StoryPlayerContext)
  const disableScrollHint =
    props.disableScrollHint || page?.disableScrollHint

  return (
    <Stack
      component="footer"
      {...omit(props, 'disableScrollHint')}
      sx={{
        '& > *': {
          // Allow nested elements to receive events.
          pointerEvents: 'auto',
        },
        '@media all and (display-mode: standalone)': {
          pb: 6,
        },
        bottom: 0,
        gap: 1,
        pb: 3,
        // Allow events to pass thru such as scroll and click events.
        pointerEvents: 'none',
        position: 'fixed',
        px: 3,
        width: 1,
        zIndex: 1,
        ...props.sx,
      }}
    >
      <Box
        sx={{
          '&:after': {
            background: ({ palette }) =>
              `linear-gradient(${alpha(
                palette.background.default,
                0,
              )} 0%,${palette.background.default} 110%)`,
            borderBottomLeftRadius: 12,
            borderBottomRightRadius: 12,
            bottom: 0,
            content: '""',
            display: 'block',
            height: 'calc(100% + 64px)',
            left: 0,
            minHeight: 96,
            pointerEvents: 'none',
            position: 'absolute',
            right: 0,
            zIndex: -1,
          },
          opacity: disableScrollHint ? 0 : 1,
          transition: 'opacity .3s ease 0s',
        }}
      />
      {props.children}
    </Stack>
  )
}

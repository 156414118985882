import React, { SVGAttributes } from 'react'
import * as textures from 'assets/images/textures'
import seedrandom from 'seedrandom'

export type Texture = {
  data: (fill: string) => string
  width: number
  height: number
  direction: ('x' | 'y' | 'xy')[]
  scale?: number
}

function toBase64(str) {
  return `data:image/svg+xml;base64,` + window.btoa(str)
}

const width = 540
const height = 960
const numOfTextures = Object.keys(textures).length
export const TextureColors = [
  '#b096af',
  '#e75732',
  '#67b0b7',
  '#ef8e76',
  '#e78432',
  '#7c949a',
]

function getSvgAttributes(
  animationDirection: Texture['direction'][0],
  reverse?: boolean,
): {
  to: SVGAttributes<SVGElement>['to']
  from: SVGAttributes<SVGElement>['from']
} {
  const w = animationDirection?.match('x') ? -width : 0
  const h = animationDirection?.match('y') ? -width : 0
  const positions = ['0,0', `${w},${h}`]
  return {
    from: positions[reverse ? 1 : 0],
    to: positions[reverse ? 0 : 1],
  }
}

function getSeed(id: string) {
  let val = id
  return () => {
    val = String(seedrandom(val)())
    return seedrandom(val)()
  }
}

function getConfig(
  id: string,
  variant: DynamicBackgroundProps['variant'],
  texture: Texture,
) {
  const seed = getSeed(id)

  texture ??=
    Object.values(textures)[Math.floor(seed() * numOfTextures)]

  return {
    animation: getSvgAttributes(
      texture.direction[
        Math.floor(seed() * texture.direction.length)
      ],
      seed() > 0.5,
    ),
    texture,
    textureColor:
      TextureColors[Math.floor(seed() * TextureColors.length)],
    variantIndex:
      variant === 'random'
        ? Math.floor(seed() * BackgroundVariants.length)
        : variant,
  }
}

export type DynamicBackgroundProps = {
  id: string
  variant?: 'random' | 0 | 1 | 2 | 3 | 4
  texture: Texture
  textureColor?: string
  textureAnimated?: boolean
  backgroundColor: string
}

export default function DynamicBackground(
  props: DynamicBackgroundProps,
) {
  const { variant = 'random', textureAnimated = true } = props
  const { texture, variantIndex, animation, ...config } = getConfig(
    props.id,
    variant,
    props.texture,
  )
  const {
    width: textureWidth,
    height: textureHeight,
    scale = 1,
  } = texture
  const textureColor = props.textureColor ?? config.textureColor
  const dataUrl = toBase64(texture.data(textureColor))

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      preserveAspectRatio="xMidYMid slice"
      opacity={0.2}
    >
      <defs>
        <pattern
          id="texturePattern"
          patternUnits="userSpaceOnUse"
          width={textureWidth * scale}
          height={textureHeight * scale}
        >
          <use
            href="#textureImage"
            transform={`scale(${scale} ${scale})`}
          />
        </pattern>
        <clipPath id="backgroundCanvas">
          <rect width={width} height={height} fill="black" />
        </clipPath>
        <clipPath id="clipLayer">
          {BackgroundVariants[variantIndex]}
        </clipPath>
        <image
          id="textureImage"
          href={dataUrl}
          width={textureWidth}
          height={textureHeight}
        />
      </defs>
      <g clipPath="url(#backgroundCanvas)">
        <g clipPath="url(#clipLayer)">
          <rect
            width={width * 2}
            height={height * 2}
            fill="url(#texturePattern)"
          >
            {textureAnimated && (
              <animateTransform
                attributeName="transform"
                type="translate"
                from={animation.from}
                to={animation.to}
                dur="200s"
                repeatCount="indefinite"
              />
            )}
          </rect>
        </g>
      </g>
    </svg>
  )
}

const BackgroundVariants = [
  <>
    <path
      d="M311 507C440.235 507 545 402.235 545 273C545 143.765 440.235 39 311 39C181.765 39 77 143.765 77 273C77 402.235 181.765 507 311 507Z"
      fill="black"
    />
    <path
      d="M507 966C538.48 966 564 940.48 564 909C564 877.52 538.48 852 507 852C475.52 852 450 877.52 450 909C450 940.48 475.52 966 507 966Z"
      fill="black"
    />
    <path
      d="M23 1080C122.964 1080 204 998.964 204 899C204 799.036 122.964 718 23 718C-76.9635 718 -158 799.036 -158 899C-158 998.964 -76.9635 1080 23 1080Z"
      fill="black"
    />
  </>,
  <>
    <path
      d="M419.019 485.182C318.298 473.625 209.518 486.308 122.503 541.641C77.1347 570.467 41.3898 609.642 15.433 656.59C-3.919 691.584 -32.866 728.956 -43.7855 767.482C-52.9311 800.078 -46.9889 841.629 -41.6833 874.51C-30.3625 947.349 -0.14624 1017.76 48.3447 1073.66C137.705 1176.51 276.058 1217.49 402.407 1163.63C565.482 1094.31 689.012 918.261 669.438 737.361C654.807 601.348 553.628 500.214 419.019 485.182Z"
      fill="black"
    />
  </>,
  <>
    <path
      d="M152 843C281.235 843 386 738.235 386 609C386 479.765 281.235 375 152 375C22.7654 375 -82 479.765 -82 609C-82 738.235 22.7654 843 152 843Z"
      fill="black"
    />
    <path
      d="M235 60C266.48 60 292 34.4802 292 3C292 -28.4802 266.48 -54 235 -54C203.52 -54 178 -28.4802 178 3C178 34.4802 203.52 60 235 60Z"
      fill="black"
    />
    <path
      d="M491 1081C565.558 1081 626 1020.56 626 946C626 871.442 565.558 811 491 811C416.442 811 356 871.442 356 946C356 1020.56 416.442 1081 491 1081Z"
      fill="black"
    />
  </>,
  <>
    <path
      d="M34 702C163.235 702 268 597.235 268 468C268 338.765 163.235 234 34 234C-95.2346 234 -200 338.765 -200 468C-200 597.235 -95.2346 702 34 702Z"
      fill="black"
    />
    <path
      d="M473 928C504.48 928 530 902.48 530 871C530 839.52 504.48 814 473 814C441.52 814 416 839.52 416 871C416 902.48 441.52 928 473 928Z"
      fill="black"
    />
    <path
      d="M500 140C552.467 140 595 97.4671 595 45C595 -7.46705 552.467 -50 500 -50C447.533 -50 405 -7.46705 405 45C405 97.4671 447.533 140 500 140Z"
      fill="black"
    />
  </>,
  <>
    <path
      d="M30 1090C181.326 1090 304 967.326 304 816C304 664.674 181.326 542 30 542C-121.326 542 -244 664.674 -244 816C-244 967.326 -121.326 1090 30 1090Z"
      fill="black"
    />
    <path
      d="M517 467C554.003 467 584 437.003 584 400C584 362.997 554.003 333 517 333C479.997 333 450 362.997 450 400C450 437.003 479.997 467 517 467Z"
      fill="black"
    />
  </>,
  <>
    <path
      d="M122 375.49C251.235 375.49 356 270.725 356 141.49C356 12.2556 251.235 -92.5098 122 -92.5098C-7.23463 -92.5098 -112 12.2556 -112 141.49C-112 270.725 -7.23463 375.49 122 375.49Z"
      fill="black"
    />
    <path
      d="M491 375.49C522.48 375.49 548 349.97 548 318.49C548 287.01 522.48 261.49 491 261.49C459.52 261.49 434 287.01 434 318.49C434 349.97 459.52 375.49 491 375.49Z"
      fill="black"
    />
    <path
      d="M256 1147.91C354.307 1147.91 434 1068.21 434 969.906C434 871.6 354.307 791.906 256 791.906C157.693 791.906 78 871.6 78 969.906C78 1068.21 157.693 1147.91 256 1147.91Z"
      fill="black"
    />
  </>,
]

import React, { useCallback, useEffect, useState } from 'react'
import { CircularProgress } from '@mui/material'
import { useRaf } from 'rooks'

export type DeterminateCircularProgressProps = {
  enabled: boolean
  getProgress: () => number
  enableTransition?: boolean
  size?: number
  thickness?: number
}

export default function DeterminateCircularProgress(
  props: DeterminateCircularProgressProps,
): JSX.Element {
  const {
    enabled,
    getProgress,
    size = 40,
    thickness = 2,
    enableTransition = false,
  } = props
  const [progress, setProgress] = useState(0)
  const handleProgress = useCallback(() => {
    setProgress(getProgress())
  }, [getProgress])
  const resetProgress = () => {
    setProgress(0)
  }

  useEffect(() => {
    if (!enabled) {
      resetProgress()
    }
    return () => setProgress(0)
  }, [enabled])

  useRaf(handleProgress, enabled)

  return (
    <CircularProgress
      variant="determinate"
      value={progress * 100}
      sx={{
        '& .MuiCircularProgress-circle': {
          // Disable transition during playback.
          transition: enableTransition ? undefined : 'none',
        },
        left: 0,
        position: 'absolute',
        top: 0,
      }}
      size={size}
      thickness={thickness}
    />
  )
}

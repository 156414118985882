import { Stack, StackProps } from '@mui/material'
import React, { ReactNode } from 'react'

export type ResponsiveContainerProps = StackProps & {
  header?: ReactNode
}

export default function ResponsiveContainer(
  props: ResponsiveContainerProps,
) {
  const { header, ...stackProps } = props
  return (
    <Stack
      {...stackProps}
      sx={{
        '& .MuiAccordion-root:first-of-type': {
          borderTopLeftRadius: {
            sm: 6,
            xs: 0,
          },
          borderTopRightRadius: {
            sm: 6,
            xs: 0,
          },
        },
        '& .MuiAccordion-root:last-of-type': {
          borderBottomLeftRadius: {
            sm: 6,
            xs: 0,
          },
          borderBottomRightRadius: {
            sm: 6,
            xs: 0,
          },
        },
        mx: {
          sm: 2,
          xs: 0,
        },
        ...stackProps.sx,
      }}
    >
      {header}
      {props.children}
    </Stack>
  )
}

import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  Operation,
  gql as graphQl,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { ReactNode } from 'react'
import { NetworkError } from '@apollo/client/errors'
import { RetryLink } from '@apollo/client/link/retry'
import { useAuth } from '@frontegg/react'
import { GraphQLErrorCode } from 'types/Global'
import packageInfo from '../../package.json'
import { recordGraphQL } from './TrackerContext'
import ClientErrorSnackbar from 'components/ClientErrorSnackbar'
import { GraphQLFormattedError } from 'graphql/error'

export const gql = graphQl
export { useQuery } from '@apollo/client'
export { useLazyQuery } from '@apollo/client'
export { useMutation } from '@apollo/client'

export type ClientError = {
  graphQLErrors: readonly GraphQLFormattedError[]
  networkError: NetworkError
  operation: Operation
}

// Custom abort controller to silence "The user aborted a request" errors.
// https://github.com/apollographql/apollo-client/issues/6769
const abortController = new AbortController()

export function MainApolloProvider(props: { children: ReactNode }) {
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false)
  const [error, setError] = useState<ClientError>(null)
  const { user, isAuthenticated } = useAuth()
  const link = useMemo(() => {
    const trackerApolloLink = new ApolloLink((operation, forward) => {
      return forward(operation).map((result) => {
        const operationDefinition = operation.query.definitions[0]
        return recordGraphQL?.(
          operationDefinition.kind === 'OperationDefinition'
            ? operationDefinition.operation
            : 'unknown?',
          operation.operationName,
          operation.variables,
          result,
        )
      })
    })
    const httpLink = new HttpLink({
      fetchOptions: {
        mode: 'cors',
        signal: abortController.signal,
      },
      headers: isAuthenticated
        ? { Authorization: `Bearer ${user?.accessToken}` }
        : undefined,
      uri: process.env.NEXT_PUBLIC_APOLLO_CLIENT_URI,
    })
    const retryLink = new RetryLink({
      attempts: {
        max: 1, // Default: 5, retries flakey network requests.
      },
    })
    const errorLink = onError(({ ...rest }) => {
      const { graphQLErrors, networkError, operation } = rest
      // If only not found errors are present do not open the snackbar. Each page should handle 404s separately.
      const isResourceNotFound =
        graphQLErrors &&
        graphQLErrors.filter(
          (error) =>
            error.extensions.code !== GraphQLErrorCode.NOT_FOUND,
        ).length < 1
      if (!isResourceNotFound) {
        setError({ graphQLErrors, networkError, operation })
        setErrorSnackbarOpen(true)
      }
    })
    return ApolloLink.from(
      [
        recordGraphQL && trackerApolloLink,
        errorLink,
        retryLink,
        httpLink,
      ].filter(Boolean),
    )
  }, [isAuthenticated, user?.accessToken])
  const client = useMemo(() => {
    return new ApolloClient({
      cache: new InMemoryCache({ addTypename: false }),
      defaultOptions: {
        // From the comments on the following issue, it appears that in order to avoid hitting the cache,
        // All of the mutate,query,watchQuery properties need to be configured
        // https://github.com/apollographql/apollo-client/issues/2555#issuecomment-449871538
        mutate: { errorPolicy: 'all' },
        query: { errorPolicy: 'all', fetchPolicy: 'network-only' },
        watchQuery: {
          errorPolicy: 'all',
          fetchPolicy: 'network-only',
        },
      },
      link,
      name: packageInfo.name,
      version: packageInfo.version,
    })
    // Ensure a single instance of the client is created using no dependencies.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handleErrorSnackbarClose = useCallback(
    () => setErrorSnackbarOpen(false),
    [],
  )

  // Update apollo client link when user access token changes.
  useEffect(() => {
    client.setLink(link)
  }, [client, link])

  return (
    <ApolloProvider client={client}>
      {props.children}
      <ClientErrorSnackbar
        open={errorSnackbarOpen}
        onClose={handleErrorSnackbarClose}
        error={error}
      />
    </ApolloProvider>
  )
}

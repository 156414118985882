import React, { useEffect, useState } from 'react'
import { Box, CssBaseline, Stack, Typography } from '@mui/material'
import SupportedBrowsersRegex from 'utils/SupportedBrowsersRegex'

/**
 * Check if browser is 2 versions or older from latest.
 * @returns {boolean} flag for unsupported browser.
 */
export default function useUnsupportedBrowser() {
  const [isUnsupportedBrowser, setIsUnsupportedBrowser] =
    useState(false)

  // Navigator object must be accessed in `useEffect` to work with NextJS.
  useEffect(() => {
    setIsUnsupportedBrowser(
      !SupportedBrowsersRegex.test(navigator?.userAgent),
    )
  }, [])

  return isUnsupportedBrowser
}

export function UnsupportedBrowserMessage() {
  return (
    <>
      <CssBaseline enableColorScheme />
      <Stack
        gap={1}
        sx={{
          alignItems: 'center',
          height: '100vh',
          justifyContent: 'center',
          p: 3,
          textAlign: 'center',
          width: '100vw',
        }}
      >
        <Box
          component="img"
          src="/img/unsupported-browser.svg"
          alt="Unsupported browser"
          width={128}
          height={128}
        />
        <Typography variant="h5">
          Your browser is not currently supported.
        </Typography>
        <Typography variant="body1">
          Please use the latest version of Chrome, Safari, Firefox, or
          Edge.
        </Typography>
      </Stack>
    </>
  )
}

import { Country, State } from 'country-state-city'

const commonlyUsedCountryOptions = [
  {
    category: 'Commonly Used',
    label: 'United States',
    value: 'US',
  },
  {
    category: 'Commonly Used',
    label: 'Canada',
    value: 'CA',
  },
  {
    category: 'Commonly Used',
    label: 'Australia',
    value: 'AU',
  },
  {
    category: 'Commonly Used',
    label: 'Mexico',
    value: 'MX',
  },
]

const groupedCountryOptions = commonlyUsedCountryOptions
  .concat(
    Country.getAllCountries().map(({ name, isoCode }) => ({
      category: 'A-Z',
      label: name,
      value: isoCode,
    })),
  )
  .reduce((acc, option) => {
    if (!acc[option.category]) {
      acc[option.category] = []
    }
    acc[option.category].push(option)
    return acc
  }, {})

export const countryOptions = Object.keys(
  groupedCountryOptions,
).reduce((acc, category) => {
  acc.push({ isCategory: true, label: category })
  acc = acc.concat(
    groupedCountryOptions[category].map((option) => ({
      ...option,
      isCategory: false,
    })),
  )
  return acc
}, [])

export function getStateOptions(country: string) {
  return (
    State.getStatesOfCountry(country ?? '')?.map((option) => ({
      label: option.name,
      value: option.isoCode,
    })) || []
  )
}

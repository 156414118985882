import React, { Ref } from 'react'
import Accordion, { AccordionProps } from '@mui/material/Accordion'

const BasicAccordion = React.forwardRef(function BasicAccordion(
  props: AccordionProps,
  ref,
) {
  return (
    <Accordion
      ref={ref as Ref<HTMLDivElement>}
      variant="outlined"
      disableGutters
      {...props}
      sx={{
        '& .MuiAccordionSummary-root': {
          transition:
            'padding-top 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        },
        '& .MuiAccordionSummary-root.Mui-expanded': {
          pt: 2,
        },
        '& .MuiCollapse-wrapperInner': {
          pb: 2,
        },
        '&.MuiAccordion-root': {
          mx: {
            sm: 0,
            xs: '-1px',
          },
        },
        '&:before, &.Mui-expanded + &.Mui-expanded:before, & + &.Mui-expanded:before':
          {
            display: 'none',
          },
        '&:not(:last-of-type)': {
          borderBottom: 0,
        },
        ...props.sx,
      }}
    >
      {props.children}
    </Accordion>
  )
})

export default BasicAccordion

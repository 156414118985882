import {
  Backdrop,
  Button,
  Paper,
  Stack,
  Typography,
} from '@mui/material'
import MarkdownContent from 'components/MarkdownContent'
import { isString } from 'lodash'
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import Joyride, {
  CallBackProps,
  EVENTS,
  Step,
  Styles,
  TooltipRenderProps,
} from 'react-joyride'
import SettingsStore from 'stores/SettingsStore'

export type { Step } from 'react-joyride'

const RUN_DELAY = 1500

class BodyScroll {
  static enable() {
    document.querySelector('body')?.classList.remove('no-scroll')
  }
  static disable() {
    document.querySelector('body')?.classList.add('no-scroll')
  }
  static setDisabled(value: boolean) {
    if (value) {
      BodyScroll.disable()
    } else {
      BodyScroll.enable()
    }
  }
}

type AppTourGuideProps = {
  id: string
  steps: Step[]
  open: boolean
  onComplete?: () => void
}

export default function GuidedTour(props: AppTourGuideProps) {
  const { onComplete } = props
  const runTimeout = useRef(-1)
  const [backdropOpen, setBackdropOpen] = useState(false)
  const [run, setRun] = useState(false)
  const [stepIndex, setStepIndex] = useState(0)
  const locale = useMemo(
    () => ({
      last: 'Got it!',
      next: 'Got it!',
    }),
    [],
  )
  const styles = useMemo(
    () =>
      ({
        options: {
          zIndex: 90000,
        },
        overlay: {
          // Fix overlay not covering full height on Safari.
          minHeight: '100dvh',
        },
      }) as Styles,
    [],
  )
  const handleJoyrideCallback = useCallback(
    (data: CallBackProps) => {
      const { index, type } = data
      if (type === EVENTS.STEP_AFTER) {
        setStepIndex(index + 1)
      }
      if (type === EVENTS.TOUR_END) {
        setStepIndex(0)
        setRun(false)
        onComplete?.()
        const guidedToursCompleted = SettingsStore.get(
          'settings.guidedToursCompleted',
        )
        SettingsStore.set('settings.guidedToursCompleted', {
          ...guidedToursCompleted,
          [props.id]: true,
        })
        BodyScroll.setDisabled(false)
      }
    },
    [props.id, onComplete],
  )
  const steps = useMemo(
    () =>
      props.steps.map((step) => ({
        ...step,
        // Support markdown content.
        content: isString(step.content) ? (
          <MarkdownContent value={step.content} />
        ) : (
          step.content
        ),
      })),
    [props.steps],
  )

  // Start the tour on mount with a delay.
  useEffect(() => {
    const guidedToursCompleted =
      SettingsStore.get('settings.guidedToursCompleted') ?? {}
    const tourCompleted = !!guidedToursCompleted?.[props.id]

    if (!tourCompleted && props.open) {
      setBackdropOpen(true)
      window.clearTimeout(runTimeout.current)
      runTimeout.current = window.setTimeout(() => {
        setBackdropOpen(false)
        setRun(true)
      }, RUN_DELAY)
    }
    return () => setBackdropOpen(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open])

  useEffect(() => {
    BodyScroll.setDisabled(backdropOpen)
  }, [backdropOpen])

  // Fix guide modal position and arrow issue on Safari.
  useEffect(() => {
    if (run) {
      setTimeout(() => window.dispatchEvent(new Event('resize')), 100)
      setTimeout(
        () => window.dispatchEvent(new Event('resize')),
        1100,
      )
    }
  }, [run])

  if (!props.open) {
    return null
  }

  return (
    <>
      {/* Use additional backdrop to prevent interaction before tour begins */}
      <Backdrop
        open={backdropOpen}
        sx={{ backdropFilter: 'none', zIndex: 90000 }}
        invisible
      />
      <Joyride
        steps={steps}
        continuous
        callback={handleJoyrideCallback}
        stepIndex={stepIndex}
        disableCloseOnEsc
        disableOverlayClose
        hideBackButton
        hideCloseButton
        disableScrollParentFix
        disableScrolling
        run={run}
        spotlightPadding={4}
        locale={locale}
        styles={styles}
        tooltipComponent={Tooltip}
      />
    </>
  )
}

const Tooltip = ({
  continuous,
  step,
  primaryProps,
  tooltipProps,
}: TooltipRenderProps) => (
  <Paper
    {...tooltipProps}
    elevation={4}
    sx={{
      '& li': {
        my: '.25em',
      },
      '& p:first-of-type': { mt: 0 },
      '& p:last-of-type': { mb: 0 },
      '& ul': {
        my: 0,
        paddingInlineStart: '2em',
      },
      bgcolor: 'common.white',
      color: 'rgba(0,0,0,.87)',
      fontSize: '.9375rem',
      m: '-1px',
      p: 2,
      width: 290,
    }}
  >
    {step.title && <Typography variant="h4">{step.title}</Typography>}
    {step.content}
    <Stack direction="row" sx={{ justifyContent: 'flex-end', mt: 2 }}>
      {continuous && (
        <Button
          variant="contained"
          autoFocus
          fullWidth
          {...primaryProps}
          sx={{
            '& .MuiTouchRipple-root': {
              opacity: 0.2,
            },
          }}
        >
          {primaryProps.title}
        </Button>
      )}
    </Stack>
  </Paper>
)

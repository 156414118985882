import React, { useContext, useRef, useState } from 'react'
import {
  Button,
  ButtonProps,
  DialogActions,
  DialogContent,
  IconButton,
  IconButtonProps,
  Stack,
  Typography,
  alpha,
} from '@mui/material'
import TextField from 'components/TextField'
import {
  MotionElement,
  MotionElementProps,
} from 'components/MotionComponents'
import {
  EnhancedDialog,
  EnhancedDialogTitle,
} from 'components/EnhancedDialog'
import useDialog from 'hooks/useDialog'
import { useUpsertStoryRatingMutation } from '__generated__/graphql'
import { StoryPlayerContext } from './StoryPlayerContext'
import { useDebounce } from 'rooks'
import LoadingBackdrop from 'components/LoadingBackdrop'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { omit } from 'lodash'

export type StoryFeedbackProps = {
  disabled?: boolean
}

const to: MotionElementProps['to'] = { y: 0 }
const from: MotionElementProps['from'] = { y: 8 }

export default function StoryFeedback(
  props: StoryFeedbackProps,
): JSX.Element {
  const { disabled } = props
  const { story } = useContext(StoryPlayerContext)
  const container = useRef<HTMLDivElement>(null)
  const [stepIndex, setStepIndex] = useState(0)
  const setStepIndexDebounced = useDebounce(setStepIndex, 500)
  const rating = useRef(0)
  const [commentsDialogProps, { setOpen: setCommentsDialogOpen }] =
    useDialog('commentsDialog')
  const [upsertStoryRating] = useUpsertStoryRatingMutation()
  const handleRatingClick: IconButtonProps['onClick'] = ({
    currentTarget,
  }) => {
    rating.current = parseInt(currentTarget.value)
    setStepIndexDebounced(1)
    upsertStoryRating({
      variables: {
        upsertStoryRatingInput: {
          rating: rating.current,
          storyId: story.id,
        },
      },
    })
  }
  const formContext = useForm<{ comments: string }>()
  const { control, handleSubmit } = formContext
  const handleShareCommentClick: ButtonProps['onClick'] = ({
    currentTarget,
  }) => {
    const value = currentTarget.value
    const openCommentDialog = value === 'yes'
    if (openCommentDialog) {
      setCommentsDialogOpen(openCommentDialog)
    } else {
      setStepIndexDebounced(2)
    }
  }
  const [loading, setLoading] = useState(false)
  if (disabled) return null

  return (
    <>
      <Stack
        ref={container}
        sx={{
          '& .MotionElement-reverse': {
            pointerEvents: 'none',
          },
          '& .MuiToggleButton-root': {
            '&.Mui-selected': {
              bgcolor: 'secondary.main',
              borderColor: 'secondary.main',
              color: 'secondary.contrastText',
            },
            '&.Mui-selected:hover': {
              bgcolor: 'secondary.main',
              borderColor: 'secondary.main',
              color: 'secondary.contrastText',
            },
            minWidth: 64,
            px: 1.5,
            py: 1,
          },
          '& .MuiToggleButtonGroup-root': {
            bgcolor: 'rgba(255,255,255,.9)',
          },
          '& > div': {
            alignItems: 'center',
            position: 'absolute',
            width: 1,
          },
          height: 86,
          mt: 4,
          position: 'relative',
          textAlign: 'center',
          width: 1,
        }}
      >
        <MotionElement
          componentType="stack"
          to={to}
          from={from}
          reverse={stepIndex !== 0}
          sx={{ alignItems: 'start', justifyContent: 'start' }}
        >
          <Typography variant="body2" gutterBottom>
            Was this story helpful?
          </Typography>
          <Stack
            sx={{
              '& .MuiIconButton-root': {
                '& svg': {
                  height: 28,
                  width: 28,
                },
                '&.selected': {
                  bgcolor: ({ palette }) =>
                    alpha(palette.secondary.light, 0.166),
                  color: 'secondary.main',
                  opacity: 0.875,
                },
                '&:hover': {
                  opacity: 1,
                },
                bgcolor: 'grey.200',
                opacity: 0.75,
                p: 2,
              },
              flexDirection: 'row',
              gap: 1,
            }}
          >
            <IconButton
              className={rating.current === 5 ? 'selected' : ''}
              value={5}
              color="inherit"
              size="large"
              onClick={handleRatingClick}
            >
              <ThumbUp />
            </IconButton>
            <IconButton
              className={rating.current === 1 ? 'selected' : ''}
              value={1}
              color="inherit"
              size="large"
              onClick={handleRatingClick}
            >
              <ThumbDown />
            </IconButton>
          </Stack>
        </MotionElement>
        <MotionElement
          componentType="stack"
          to={to}
          from={from}
          reverse={stepIndex !== 1}
        >
          <Typography variant="body2" gutterBottom>
            Do you want to share additional comments?
          </Typography>
          <Stack sx={{ flexDirection: 'row', gap: 1 }}>
            <Button
              variant="outlined"
              color="inherit"
              value="yes"
              onClick={handleShareCommentClick}
            >
              Yes
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              value="no"
              onClick={handleShareCommentClick}
            >
              No
            </Button>
          </Stack>
        </MotionElement>
        <MotionElement
          componentType="stack"
          to={to}
          from={from}
          reverse={stepIndex !== 2}
          sx={{ cursor: 'default', gap: 0.5 }}
        >
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            Thank you for sharing your feedback!
          </Typography>
          <Typography sx={{ fontSize: '2rem' }}>🎉🎉🎉</Typography>
        </MotionElement>
      </Stack>
      <EnhancedDialog
        {...commentsDialogProps}
        disableCloseOnBackdropClick
        sx={{
          mx: 1,
        }}
        component="form"
        onSubmit={handleSubmit(async (formValues) => {
          const { comments } = formValues
          setCommentsDialogOpen(false)
          setStepIndexDebounced(2)
          setLoading(true)
          await upsertStoryRating({
            variables: {
              upsertStoryRatingInput: {
                comments,
                storyId: story.id,
              },
            },
          })
          setLoading(false)
        })}
      >
        <FormProvider {...formContext}>
          <EnhancedDialogTitle
            onClose={() => {
              setCommentsDialogOpen(false)
              setStepIndexDebounced(2)
            }}
          >
            Additional Comments
          </EnhancedDialogTitle>
          <DialogContent sx={{ pb: 1 }}>
            <Controller
              name="comments"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...omit(field, 'ref')}
                  inputRef={field.ref}
                  color="secondary"
                  placeholder="Tell us in words what you liked or didn't like about this story."
                  multiline
                  minRows={4}
                  fullWidth
                  autoFocus
                  disabled={loading}
                  error={!!fieldState.error}
                />
              )}
            ></Controller>
          </DialogContent>
          <DialogActions sx={{ pt: 0, px: 2 }}>
            <Button
              type="submit"
              color="secondary"
              variant="text"
              disabled={loading}
            >
              Submit
            </Button>
          </DialogActions>
        </FormProvider>
        <LoadingBackdrop open={loading} />
      </EnhancedDialog>
    </>
  )
}

function ThumbUp() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24">
      <path
        d="M8 11V19C8 19.2652 7.89464 19.5196 7.70711 19.7071C7.51957 19.8946 7.26522 20 7 20H5C4.73478 20 4.48043 19.8946 4.29289 19.7071C4.10536 19.5196 4 19.2652 4 19V12C4 11.7348 4.10536 11.4804 4.29289 11.2929C4.48043 11.1054 4.73478 11 5 11H8ZM8 11C9.06087 11 10.0783 10.5786 10.8284 9.82843C11.5786 9.07828 12 8.06087 12 7V6C12 5.46957 12.2107 4.96086 12.5858 4.58579C12.9609 4.21071 13.4696 4 14 4C14.5304 4 15.0391 4.21071 15.4142 4.58579C15.7893 4.96086 16 5.46957 16 6V11H19C19.5304 11 20.0391 11.2107 20.4142 11.5858C20.7893 11.9609 21 12.4696 21 13L20 18C19.8562 18.6135 19.5834 19.1402 19.2227 19.501C18.8619 19.8617 18.4328 20.0368 18 20H11C10.2044 20 9.44129 19.6839 8.87868 19.1213C8.31607 18.5587 8 17.7956 8 17"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  )
}

function ThumbDown() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24">
      <path
        d="M16 13.0001V5.00005C16 4.73484 16.1054 4.48048 16.2929 4.29295C16.4804 4.10541 16.7348 4.00005 17 4.00005H19C19.2652 4.00005 19.5196 4.10541 19.7071 4.29295C19.8946 4.48048 20 4.73484 20 5.00005V12.0001C20 12.2653 19.8946 12.5196 19.7071 12.7072C19.5196 12.8947 19.2652 13.0001 19 13.0001H16ZM16 13.0001C14.9391 13.0001 13.9217 13.4215 13.1716 14.1716C12.4214 14.9218 12 15.9392 12 17.0001V18.0001C12 18.5305 11.7893 19.0392 11.4142 19.4143C11.0391 19.7893 10.5304 20.0001 10 20.0001C9.46957 20.0001 8.96086 19.7893 8.58579 19.4143C8.21071 19.0392 8 18.5305 8 18.0001V13.0001H5C4.46957 13.0001 3.96086 12.7893 3.58579 12.4143C3.21071 12.0392 3 11.5305 3 11.0001L4 6.00005C4.14382 5.38658 4.41663 4.85981 4.77735 4.49909C5.13807 4.13837 5.56716 3.96323 6 4.00005H13C13.7956 4.00005 14.5587 4.31613 15.1213 4.87873C15.6839 5.44134 16 6.20441 16 7.00005"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  )
}

import React, { useRef, useState } from 'react'
import {
  Box,
  CircularProgress,
  Fade,
  Typography,
} from '@mui/material'
import useInterpolate from 'hooks/useInterpolate'
import { StoryPlayerContext } from 'components/StoryPlayerContext'
import { omit } from 'lodash'
import { BaseBlockData } from 'components/StoryPlayer/PageBlock'

export type ImageBlockData = BaseBlockData & {
  type: 'image'
  src: string
  alt: string
  width: number
  height: number
  fullBleed?: boolean
  caption?: string
}

export type ImageBlockProps = Omit<ImageBlockData, 'type'>

export default function ImageBlock(props: ImageBlockProps) {
  const { disableMotion, fullBleed, ...boxProps } = props
  const [timeout, setTimeout] = useState(0)
  const startTime = useRef(new Date().getTime())
  const interpolate = useInterpolate(StoryPlayerContext)
  const [loaded, setLoaded] = useState(false)

  return (
    <>
      <Fade in={loaded} timeout={timeout}>
        <Box
          {...omit(boxProps, 'type')}
          ref={(el: HTMLImageElement) => {
            if (el) {
              const { width, height } = {
                height: props.height ?? el.getAttribute('height'),
                width: props.width ?? el.getAttribute('width'),
              }

              el.setAttribute('width', String(width))
              el.setAttribute('height', String(height))
            }
          }}
          className={[
            'ImageBlock',
            props.className,
            fullBleed && 'fullBleed',
          ]
            .filter(Boolean)
            .join(' ')}
          component="img"
          src={interpolate(props.src)}
          onLoad={({ currentTarget }) => {
            const loadTime = new Date().getTime() - startTime.current
            const { width, height } = {
              height: props.height ?? currentTarget.naturalHeight,
              width: props.width ?? currentTarget.naturalWidth,
            }

            setTimeout(!disableMotion && loadTime > 100 ? 300 : 0)
            setLoaded(true)
            currentTarget.setAttribute('width', String(width))
            currentTarget.setAttribute('height', String(height))
          }}
          sx={{
            alignSelf: 'center',
            borderRadius: fullBleed ? 0 : 1,
            display: 'block',
            height: 'auto',
            maxWidth: props.width,
            my: 1,
            objectFit: 'cover',
            objectPosition: 'center',
            width: 1,
            ...props.sx,
          }}
          alt={interpolate(props.alt)}
        />
      </Fade>
      {!loaded && (
        <CircularProgress
          color="inherit"
          size={24}
          sx={{
            left: 'calc(50% - 12px)',
            opacity: 0.33,
            position: 'absolute',
            top: 'calc(50% - 12px)',
          }}
        />
      )}
      <Typography
        component="figcaption"
        variant="caption"
        sx={{
          color: 'text.secondary',
          px: fullBleed ? 3 : 0,
        }}
      >
        {interpolate(props.caption)}
      </Typography>
    </>
  )
}

import React, { WheelEvent, useState } from 'react'
import { InputAdornment } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import OutlinedInput from '@mui/material/OutlinedInput'
import FormHelperText from '@mui/material/FormHelperText'
import StoryFieldLabel from 'components/StoryFieldLabel'
import { NumberField } from 'components/StoryNumberField'
import {
  FieldProps,
  FormValues,
} from 'components/StoryPlayer/pages/PageForm'
import {
  convertCholesterolMgdlToMmol,
  convertCholesterolMmolToMgdl,
} from '__shared__/utils/UnitConversionUtil'
import HelperBlocks from 'components/HelperBlocks'
import { Controller, UseFormReturn } from 'react-hook-form'
import { isUndefined, omit } from 'lodash'
import { isValidNumber, parseNumberString } from 'utils/FormUtil'
import UnitSelectMenu from 'components/UnitSelectMenu'

export enum CholesterolUnit {
  'mg/dL' = 'mg/dL',
  'mmol/L' = 'mmol/L',
}
export type CholesterolValues = {
  'mg/dL': number | ''
  'mmol/L': number | ''
}

export type CholesterolField = Omit<
  NumberField,
  'type' | 'startAdornment' | 'endAdornment'
> & {
  type: 'cholesterol'
  unit?: keyof typeof CholesterolUnit
}

const defaultMaxInMmolL = 31.03
const defaultMax = {
  'mg/dL': Math.ceil(convertCholesterolMmolToMgdl(defaultMaxInMmolL)),
  'mmol/L': Math.ceil(defaultMaxInMmolL),
}

export type StoryCholesterolFieldProps = Omit<FieldProps, 'value'> &
  CholesterolField & {
    value: number | ''
    disabled?: boolean
    formContext: UseFormReturn<FormValues>
  }

export default function StoryCholesterolField(
  props: StoryCholesterolFieldProps,
) {
  const {
    name,
    formContext,
    fieldState,
    label,
    required,
    min = 0,
    max,
    value: valueInMmolL,
    onChange,
    helperBlocks,
    disableRequiredAsterisk,
    inputRef,
  } = props
  const minValue = {
    'mg/dL': Math.floor(convertCholesterolMmolToMgdl(min)),
    'mmol/L': Math.floor(min),
  }
  const maxValue = isUndefined(max)
    ? defaultMax
    : {
        'mg/dL': Math.ceil(convertCholesterolMmolToMgdl(max)),
        'mmol/L': Math.ceil(max),
      }
  const getMaxErrorMessage = (unit: CholesterolField['unit']) => {
    const label = {
      'mg/dL': `${maxValue['mg/dL']} mg/dL`,
      'mmol/L': `${maxValue['mmol/L']} mmol/L`,
    }[unit]
    return `Must be less than ${label}`
  }
  const { control, clearErrors, formState } = formContext
  const [values, setValues] = useState({
    'mg/dL':
      valueInMmolL === ''
        ? valueInMmolL
        : String(convertCholesterolMmolToMgdl(valueInMmolL)),
    'mmol/L': String(valueInMmolL),
  })
  const [unit, setUnit] = useState<CholesterolField['unit']>(
    props.unit ?? 'mg/dL',
  )
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement>(null)
  const placeholder =
    props.placeholder ?? `${minValue[unit]}-${maxValue[unit]}`
  const valueForUnit = values[unit]
  const error = formState.errors.root || fieldState.error
  const helperText = error?.message || props.helperText

  return (
    <FormControl fullWidth error={!!error} sx={{ gap: 1 }}>
      <StoryFieldLabel
        label={label}
        required={required && !disableRequiredAsterisk}
      />
      <HelperBlocks blocks={helperBlocks} />
      <Controller
        name={name}
        control={control}
        defaultValue={
          isValidNumber(values['mmol/L']) ? values['mmol/L'] : ''
        }
        rules={{
          max: {
            message: getMaxErrorMessage(unit),
            value: maxValue['mmol/L'],
          },
          min: minValue[unit],
          required,
        }}
        render={({ field, fieldState }) => (
          <OutlinedInput
            {...omit(field, 'ref')}
            inputRef={inputRef}
            color="secondary"
            inputProps={{
              inputMode: 'numeric',
              onWheel: (e: WheelEvent<HTMLInputElement>) =>
                e.currentTarget.blur(),
            }}
            type="text"
            placeholder={placeholder}
            autoFocus={props.autoFocus}
            onBlur={props.onBlur}
            onChange={(e) => {
              clearErrors()
              const value = parseNumberString(e.target.value)
              const mmolL =
                unit === 'mmol/L'
                  ? parseFloat(value)
                  : convertCholesterolMgdlToMmol(parseFloat(value))
              const mgdL = convertCholesterolMmolToMgdl(mmolL)
              const isValid =
                !!mmolL &&
                isFinite(Number(mmolL)) &&
                !/\.$/.test(String(mmolL))

              setValues({
                'mg/dL': unit === 'mg/dL' ? value : String(mgdL),
                'mmol/L': unit === 'mmol/L' ? value : String(mmolL),
              })
              onChange(isValid ? mmolL : '')
            }}
            value={isValidNumber(valueForUnit) ? valueForUnit : ''}
            fullWidth
            disabled={props.disabled}
            endAdornment={
              <InputAdornment position="end" sx={{ mr: -1.5 }}>
                <UnitSelectMenu<CholesterolField['unit']>
                  value={unit}
                  anchorEl={menuAnchorEl}
                  onAnchorElChange={setMenuAnchorEl}
                  onChange={(value) => {
                    clearErrors()
                    setUnit(value)
                  }}
                  options={Object.values(CholesterolUnit)}
                />
              </InputAdornment>
            }
            error={!!fieldState.error || !!error}
            sx={{
              '& .MuiInputBase-input': {
                textAlign: 'center',
              },
            }}
          />
        )}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

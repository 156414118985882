import { StoryPageData } from 'components/StoryPlayer/StoryPage'
import exampleOrderableTests from './transformers/exampleOrderableTests'
import exampleReviewTodos from './transformers/exampleReviewTodos'
import orderTests from './transformers/orderTests'
import testIntakeQuestions from './transformers/testIntakeQuestions'
import testQualificationQuestions from './transformers/testQualificationQuestions'
import testQualificationSummary from './transformers/testQualificationSummary'
import useInterpolate, {
  InterpolationData,
} from 'hooks/useInterpolate'
import { StoryPlayerContextProps } from 'components/StoryPlayerContext'
import { MutableRefObject } from 'react'

export type PageTransformerName = keyof typeof transformers

export type PageTransformer<TOptions = Record<string, never>> =
  (args: {
    returnToPageId: string
    data: unknown
    options: {
      interpolate: ReturnType<typeof useInterpolate>
      questionnaireResponses: MutableRefObject<
        StoryPlayerContextProps['questionnaireResponses']
      >
      interpolationData: InterpolationData
    } & TOptions
  }) => StoryPageData[]

const transformers = {
  exampleOrderableTests,
  exampleReviewTodos,
  orderTests,
  testIntakeQuestions,
  testQualificationQuestions,
  testQualificationSummary,
}

export default transformers

import React, { WheelEvent, useState } from 'react'
import { InputAdornment, Stack } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import OutlinedInput from '@mui/material/OutlinedInput'
import FormHelperText from '@mui/material/FormHelperText'
import StoryFieldLabel from 'components/StoryFieldLabel'
import { NumberField } from 'components/StoryNumberField'
import {
  FieldProps,
  FormValues,
} from 'components/StoryPlayer/pages/PageForm'
import {
  convertCmToFtIn,
  convertFtInToCm,
} from '__shared__/utils/UnitConversionUtil'
import HelperBlocks from 'components/HelperBlocks'
import { Controller, UseFormReturn } from 'react-hook-form'
import { isUndefined, omit } from 'lodash'
import { isValidNumber, parseNumberString } from 'utils/FormUtil'
import UnitSelectMenu from 'components/UnitSelectMenu'

export enum HeightUnit {
  'ft/in' = 'ft/in',
  'cm' = 'cm',
}
export type HeightValues = {
  cm: number | ''
  feet: number | ''
  inches: number | ''
}

export type HeightField = Omit<
  NumberField,
  'type' | 'startAdornment' | 'endAdornment' | 'placeholder'
> & {
  type: 'height'
  unit?: keyof typeof HeightUnit
}

const defaultMaxInCm = 275
const defaultMax = {
  cm: Math.ceil(defaultMaxInCm),
  ...convertCmToFtIn(defaultMaxInCm),
}

export type StoryHeightFieldProps = Omit<
  FieldProps,
  'ref' | 'value'
> &
  HeightField & {
    value: number | ''
    disabled?: boolean
    formContext: UseFormReturn<FormValues>
  }

export default function StoryHeightField(
  props: StoryHeightFieldProps,
) {
  const {
    name,
    formContext,
    fieldState,
    label,
    required,
    min = 0,
    max,
    value: valueInCm,
    onChange,
    helperBlocks,
    disableRequiredAsterisk,
    inputRef,
  } = props
  const minValue = { cm: Math.floor(min), ...convertCmToFtIn(min) }
  const maxValue = isUndefined(max)
    ? defaultMax
    : { cm: Math.ceil(max), ...convertCmToFtIn(max) }
  const getMaxErrorMessage = (unit: HeightField['unit']) => {
    const label = {
      cm: `${maxValue.cm} cm`,
      'ft/in': `${maxValue.feet} ft ${maxValue.inches} in`,
    }[unit]
    return `Must be less than ${label}`
  }
  const { control, clearErrors, formState } = formContext
  const error = formState.errors.root || fieldState.error
  const [values, setValues] = useState({
    cm: String(valueInCm),
    feet:
      valueInCm === ''
        ? valueInCm
        : String(convertCmToFtIn(valueInCm).feet),
    inches:
      valueInCm === ''
        ? valueInCm
        : String(convertCmToFtIn(valueInCm).inches),
  })
  const [unit, setUnit] = useState<HeightField['unit']>(
    props.unit ?? 'ft/in',
  )
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement>(null)
  const placeholder = `${minValue[unit]}-${maxValue[unit]}`
  const helperText = error?.message || props.helperText

  return (
    <Stack sx={{ gap: 1, width: 1 }}>
      <FormControl fullWidth error={!!error}>
        <StoryFieldLabel
          label={label}
          required={required && !disableRequiredAsterisk}
        />
      </FormControl>
      <HelperBlocks blocks={helperBlocks} />
      <Stack
        direction="row"
        gap={1}
        sx={{
          '& .MuiInputBase-input': {
            textAlign: 'center',
          },
        }}
      >
        {unit === 'ft/in' && (
          <>
            <Controller
              name={name}
              control={control}
              defaultValue={
                isValidNumber(values.feet) ? values.feet : ''
              }
              rules={{
                max: {
                  message: getMaxErrorMessage('ft/in'),
                  value: maxValue.cm,
                },
                min: minValue.cm,
                required,
              }}
              render={({ field, fieldState }) => (
                <OutlinedInput
                  {...omit(field, 'ref')}
                  inputRef={inputRef}
                  color="secondary"
                  inputProps={{
                    inputMode: 'numeric',
                    onWheel: (e: WheelEvent<HTMLInputElement>) =>
                      e.currentTarget.blur(),
                  }}
                  type="text"
                  placeholder="–"
                  autoFocus={props.autoFocus}
                  onBlur={props.onBlur}
                  onChange={(e) => {
                    clearErrors()
                    // Extract single digit.
                    e.target.value =
                      e.target.value.match(/\d/)?.[0] || ''
                    const valueAsNumber = parseInt(e.target.value)
                    const cm = convertFtInToCm(
                      valueAsNumber,
                      parseInt(values.inches) || 0,
                    )
                    const isValid = !!cm && isFinite(cm)
                    const feet = isValid ? valueAsNumber : ''

                    setValues({
                      cm: String(cm),
                      feet: String(feet),
                      inches: values.inches,
                    })
                    onChange(isValid ? cm : '')
                  }}
                  value={
                    isValidNumber(values.feet) ? values.feet : ''
                  }
                  fullWidth
                  disabled={props.disabled}
                  endAdornment={
                    <InputAdornment position="end" sx={{ mr: -1.5 }}>
                      <UnitSelectMenu<HeightField['unit']>
                        value={unit}
                        anchorEl={menuAnchorEl}
                        onAnchorElChange={setMenuAnchorEl}
                        onChange={(value) => {
                          clearErrors()
                          setUnit(value)
                        }}
                        options={Object.values(HeightUnit)}
                        renderLabel={(unit) =>
                          unit === 'ft/in' ? 'ft' : unit
                        }
                      />
                    </InputAdornment>
                  }
                  required={required}
                  error={!!fieldState.error || !!error}
                />
              )}
            />
            <Controller
              name={name}
              control={control}
              defaultValue={
                isValidNumber(values.inches) ? values.inches : ''
              }
              rules={{
                max: {
                  message: getMaxErrorMessage('ft/in'),
                  value: maxValue.cm,
                },
                min: minValue.cm,
                required,
              }}
              render={({ field, fieldState }) => (
                <OutlinedInput
                  {...omit(field, 'ref')}
                  inputRef={field.ref}
                  color="secondary"
                  inputProps={{
                    inputMode: 'numeric',
                    onWheel: (e: WheelEvent<HTMLInputElement>) =>
                      e.currentTarget.blur(),
                  }}
                  type="text"
                  placeholder="–"
                  onBlur={props.onBlur}
                  onChange={(e) => {
                    clearErrors()
                    // Extract digits.
                    e.target.value =
                      e.target.value.match(/\d+/)?.[0] || ''
                    const valueAsNumber = Math.min(
                      11, // Limit to max 11.
                      parseInt(e.target.value),
                    )
                    const cm = convertFtInToCm(
                      parseInt(values.feet) || 0,
                      valueAsNumber,
                    )
                    const isValid = !!cm && isFinite(cm)
                    const inches = isValid ? valueAsNumber : ''

                    setValues({
                      cm: String(cm),
                      feet: values.feet,
                      inches: String(inches),
                    })
                    onChange(isValid ? cm : '')
                  }}
                  value={
                    isValidNumber(values.inches) ? values.inches : ''
                  }
                  fullWidth
                  disabled={props.disabled}
                  endAdornment={
                    <InputAdornment position="end" sx={{ mr: -1.5 }}>
                      <UnitSelectMenu<HeightField['unit']>
                        value={unit}
                        anchorEl={menuAnchorEl}
                        onAnchorElChange={setMenuAnchorEl}
                        onChange={(value) => {
                          clearErrors()
                          setUnit(value)
                        }}
                        options={Object.values(HeightUnit)}
                        renderLabel={(unit) =>
                          unit === 'ft/in' ? 'in' : unit
                        }
                      />
                    </InputAdornment>
                  }
                  required={required}
                  error={!!fieldState.error || !!error}
                />
              )}
            />
          </>
        )}
        {unit === 'cm' && (
          <Controller
            name={name}
            control={control}
            defaultValue={isValidNumber(values.cm) ? values.cm : ''}
            rules={{
              max: {
                message: getMaxErrorMessage('cm'),
                value: maxValue.cm,
              },
              min: minValue.cm,
              required,
            }}
            render={({ field, fieldState }) => (
              <OutlinedInput
                {...omit(field, 'ref')}
                inputRef={inputRef}
                color="secondary"
                inputProps={{
                  inputMode: 'numeric',
                  onWheel: (e: WheelEvent<HTMLInputElement>) =>
                    e.currentTarget.blur(),
                }}
                type="text"
                placeholder={placeholder}
                onBlur={props.onBlur}
                onChange={(e) => {
                  clearErrors()
                  const value = parseNumberString(e.target.value)
                  const cm = parseFloat(value)
                  const isValid =
                    !!cm && isFinite(cm) && !/\.$/.test(String(cm))
                  const { feet, inches } = convertCmToFtIn(cm)

                  setValues({
                    cm: value,
                    feet: String(feet),
                    inches: String(inches),
                  })
                  onChange(isValid ? cm : '')
                }}
                value={isValidNumber(values.cm) ? values.cm : ''}
                fullWidth
                disabled={props.disabled}
                endAdornment={
                  <InputAdornment position="end" sx={{ mr: -1.5 }}>
                    <UnitSelectMenu<HeightField['unit']>
                      value={unit}
                      anchorEl={menuAnchorEl}
                      onAnchorElChange={setMenuAnchorEl}
                      onChange={(value) => {
                        clearErrors()
                        setUnit(value)
                      }}
                      options={Object.values(HeightUnit)}
                    />
                  </InputAdornment>
                }
                error={!!fieldState.error || !!error}
              />
            )}
          />
        )}
      </Stack>
      {helperText && (
        <FormControl fullWidth error={!!error}>
          <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
      )}
    </Stack>
  )
}

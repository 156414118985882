import { StoryData } from 'components/StoryPlayer'
import { BaseModuleConfig } from 'components/StoryPlayer/Modules'
import moduleStory from 'components/StoryPlayer/Modules/FeatureTest/module.story.json'

export type FeatureTestConfig = BaseModuleConfig & {
  type: typeof FeatureTestModule.type
}

export class FeatureTestModule {
  static type = 'FeatureTest' as const

  static getStory() {
    return moduleStory as StoryData
  }
}

import React, { ReactNode } from 'react'
import {
  FormControl,
  FormHelperText,
  FormLabel,
  OutlinedInput,
  OutlinedInputProps,
  lighten,
} from '@mui/material'

type SignatureFieldProps = OutlinedInputProps & {
  helperText?: ReactNode
}

export default function SignatureField(
  props: SignatureFieldProps,
): JSX.Element {
  const error = !!props.error
  const { helperText, ...inputProps } = props

  return (
    <FormControl error={error}>
      <FormLabel sx={{ typography: 'body2' }}>
        Signature / Firma*
      </FormLabel>
      <OutlinedInput
        {...inputProps}
        error={error}
        placeholder="Your name / Tu nombre"
        sx={{
          '& .MuiOutlinedInput-input': {
            fontFamily: "'Oooh Baby', cursive",
            position: 'relative',
            textIndent: 8, // Avoid clipping.
            zIndex: 1,
          },
          '& .MuiOutlinedInput-input.Mui-disabled': {
            WebkitTextFillColor: 'rgba(0,0,0,.87)',
            color: 'rgba(0,0,0,.87)',
          },
          '&.MuiOutlinedInput-root.Mui-disabled:before': {
            borderBottomColor: ({ palette }) =>
              lighten(palette.secondary.light, 0.66),
          },
          '&.MuiOutlinedInput-root:before': {
            borderBottom: '1px solid',
            borderBottomColor: 'secondary.light',
            bottom: {
              sm: 28,
              xs: 24,
            },
            content: '""',
            display: 'block',
            left: 16,
            position: 'absolute',
            right: 16,
          },
          bgcolor: 'white',
          borderColor: error ? 'error.main' : 'divider',
          borderRadius: 1,
          borderWidth: 1,
          color: 'rgba(0,0,0,.87)',
          fontSize: {
            sm: '2.0rem',
            xs: '1.5rem',
          },
          overflow: 'hidden',
          pb: 0.5,
          position: 'relative',
          pt: 1,
          ...props.sx,
        }}
      />
      {helperText && (
        <FormHelperText sx={{ mx: 0 }}>{helperText}</FormHelperText>
      )}
    </FormControl>
  )
}

import {
  SelectOption,
  useOrderableTestOptionsLazyQuery,
} from '__generated__/graphql'
import { StoryPlayerContext } from 'components/StoryPlayerContext'
import useInterpolate from 'hooks/useInterpolate'
import { useEffect, useMemo, useState } from 'react'

export type SelectOptionsQueryName = keyof typeof LazyQueryMap
export type SelectOptionsQueryConfig = {
  [QueryName in SelectOptionsQueryName]: {
    name: QueryName
    variables: Variables<QueryName>
  }
}[SelectOptionsQueryName]

type Variables<T extends SelectOptionsQueryName> = Parameters<
  (typeof LazyQueryMap)[T]
>[0]['variables']

type FetchMap = {
  [QueryName in SelectOptionsQueryName]: (
    variables: Variables<QueryName>,
  ) => () => Promise<SelectOption[]>
}

// Add supported lazy queries to be used for story player form pages.
const LazyQueryMap = {
  OrderableTestOptions: useOrderableTestOptionsLazyQuery,
}

export function useSelectOptionsQuery(
  config: SelectOptionsQueryConfig,
) {
  const interpolate = useInterpolate(StoryPlayerContext)
  const name = config?.name
  const variables = config?.variables
    ? // Interpolate query variables with latest context data.
      JSON.parse(interpolate(JSON.stringify(config.variables)))
    : null
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState<SelectOption[]>([])
  const fetchMap: FetchMap = useMemo(
    () => ({
      OrderableTestOptions: (
        variables: Variables<'OrderableTestOptions'>,
      ) => {
        const [fetch] = LazyQueryMap.OrderableTestOptions({
          variables,
        })
        return async () =>
          // Pick "options" data from the response.
          (await fetch())?.data?.patientApp?.orderableTestOptions
      },
    }),
    [],
  )
  const fetch = fetchMap[name]?.(variables)

  useEffect(() => {
    if (fetch) {
      const fetchOptions = async () => {
        setLoading(true)
        const options = await fetch()
        setLoading(false)
        setOptions(options)
      }
      fetchOptions()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { loading, options }
}

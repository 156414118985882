import { useMemo } from 'react'

export type SessionStorage = {
  kiosk: {
    url: string | null
    storyId: string | null
  }
}

export default function useSessionStorage() {
  const set = (data: Partial<SessionStorage>): void =>
    Object.keys(data).forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ([null, undefined, false].includes(data[key] as any)) {
        sessionStorage.removeItem(key)
      } else {
        sessionStorage.setItem(key, JSON.stringify(data[key]))
      }
    })
  const result = useMemo(() => {
    return [
      {
        get kiosk() {
          try {
            const kiosk = sessionStorage.getItem('kiosk')
            if (kiosk) {
              return JSON.parse(kiosk) as SessionStorage['kiosk']
            }
          } catch {
            console.error('Invalid kiosk data')
          }
          return null
        },
      },
      set,
    ] as const
  }, [])

  return result
}

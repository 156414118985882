import React, { Ref } from 'react'
import { Box, BoxProps, Checkbox, TextField } from '@mui/material'
import ReactMarkdown from 'react-markdown'
import { ReactMarkdownOptions } from 'react-markdown/lib/react-markdown'
import remarkGfm from 'remark-gfm'
import remarkDirective from 'remark-directive'
import { commonRemarkDirectives } from 'utils/MarkdownUtil'

const MarkdownContent = React.forwardRef(function MarkdownContent(
  props: Omit<ReactMarkdownOptions, 'children'> & {
    component?: BoxProps['component']
    sx?: BoxProps['sx']
    onClick?: BoxProps['onClick']
    value: string
  },
  ref: Ref<HTMLDivElement>,
) {
  const { sx, onClick, ...markdownOptions } = props
  return (
    <Box
      ref={ref}
      component={props.component}
      onClick={onClick}
      sx={{
        '& .MuiTextField-root.signature': {
          '& .MuiInputBase-input': {
            '&.Mui-disabled': {
              WebkitTextFillColor: ({ palette }) =>
                palette.text.primary,
              color: 'text.primary',
            },
            fontFamily: "'Oooh Baby', cursive",
            fontSize: '2.5em',
            py: 1,
            textIndent: 8, // Avoid clipping.
          },
          width: 1,
        },
        '& .divided + .divided': {
          borderTop: '1px solid',
          borderTopColor: 'divider',
          mt: 1,
          pt: 1,
        },
        '& .fieldGroup > *': { ml: 7 },
        '& .fieldGroup > *:first-of-type': { ml: 0 },
        '& .indentedBlock': { ml: 7 },
        '& .note': {
          bgcolor: ({ palette }) => palette.action.hover,
          color: 'rgba(0,0,0,.66)',
          fontSize: '.875rem',
          mb: 1,
          p: 2,
        },
        '& .pageHeader': {
          '& .accountLogo': {
            maxHeight: 48,
            maxWidth: 240,
            mb: 1,
            objectFit: 'contain',
            objectPosition: 'right center',
          },
          '& .secondary': {
            color: 'text.secondary',
          },
          '& .subtitle': {
            fontSize: '1.25em',
            fontWeight: 700,
            m: 0,
          },
          '& .title': {
            fontSize: '1.66em',
            fontWeight: 300,
            m: 0,
          },
          '&.divider': {
            borderBottom: '1px solid',
            borderBottomColor: 'divider',
          },
          '&.gutterBottom': {
            mb: 5,
          },
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          py: 0.5,
        },
        '& .row': {
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        },
        '& label': {
          display: 'block',
          fontWeight: 500,
        },
        '& p:first-of-type': { mt: 0 },
        '& p:last-of-type': { mb: 0 },
        '& ul.contains-task-list': {
          '& .task-list-item': {
            '& .MuiCheckbox-root': {
              ml: -5.5,
              my: -0.2,
              py: 0,
            },
            paddingInlineStart: 5,
          },
          '&:has(.task-list-item)': {
            listStyleType: 'none',
            paddingInlineStart: 0,
          },
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        },
        hr: {
          borderBottom: '1px solid',
          borderColor: 'divider',
          borderTop: 0,
          my: 4,
        },
        table: {
          borderCollapse: 'collapse',
          fontSize: '.875em',
          tableLayout: 'fixed',
          width: '100%',
        },
        'tbody tr:nth-of-type(even)': {
          color: 'text.primary',
        },
        'tbody tr:nth-of-type(odd)': {
          bgcolor: 'action.hover',
          color: 'text.primary',
        },
        'th, td': {
          border: '1px solid',
          borderColor: 'divider',
          p: 1,
          textAlign: 'left',
        },
        thead: {
          bgcolor: 'transparent',
          color: 'text.primary',
        },
        ...sx,
      }}
    >
      <ReactMarkdown
        remarkPlugins={[
          remarkGfm,
          remarkDirective,
          commonRemarkDirectives,
        ]}
        linkTarget={(href = '') =>
          // Open relative links in same tab and external links in new tab.
          href.match(/^http|mailto|tel/) ? '_blank' : '_self'
        }
        components={{
          input: ({ checked, type, children, className }) => {
            return type === 'checkbox' ? (
              <Checkbox
                className={className}
                checked={checked}
                color="default"
                disabled
              />
            ) : (
              <TextField
                className={className}
                disabled
                value={children}
              />
            )
          },
        }}
        {...markdownOptions}
      >
        {props.value}
      </ReactMarkdown>
    </Box>
  )
})

export default MarkdownContent

import useAxios from 'axios-hooks'

const errorArticle = {
  content: 'The article you requested was not found.',
  title: '',
}

export default function useArticleQuery() {
  const [{ data, error, loading }, fetch] = useAxios(
    { method: 'GET' },
    { manual: true },
  )
  const dataLines: string[] = data?.trim().split('\n') ?? []
  const article = error
    ? errorArticle
    : ({
        content: dataLines.slice(1).join('\n').trim() ?? '',
        title: dataLines[0]?.replace(/^#+/, '').trim() ?? '',
      } as const)

  return [
    article,
    {
      error,
      fetch: (url) => fetch({ url }),
      loading,
    },
  ] as const
}

import { Texture } from 'components/DynamicBackground'

// eslint-disable-next-line import/no-anonymous-default-export
const texture: Texture = {
  data: (fill: string) => `
<svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4">
  <path fill="${fill}" d="M1 3h1v1H1V3zm2-2h1v1H3V1z"></path>
</svg>`,
  direction: ['x', 'y', 'xy'],
  height: 4,
  width: 4,
}
export default texture

// @refresh reset
import React, { useEffect, useState } from 'react'
import { Stack } from '@mui/material'
import { useRive } from '@rive-app/react-canvas'
import { omit } from 'lodash'
import { BaseBlockData } from 'components/StoryPlayer/PageBlock'

export type AnimationBlockData = BaseBlockData & {
  type: 'animation'
  src: string
  width?: string | number
  height?: string | number
  fullBleed?: boolean
}

export type AnimationBlockProps = Omit<AnimationBlockData, 'type'>

export default function AnimationBlock(props: AnimationBlockProps) {
  const {
    width = 200,
    height = 200,
    src,
    disableMotion,
    fullBleed,
    ...stackProps
  } = props
  const [loaded, setLoaded] = useState(!!disableMotion)
  const { rive, RiveComponent } = useRive({
    autoplay: true,
    onLoad: () => setLoaded(true),
    src,
  })

  // Scrub to last frame for disableMotion.
  useEffect(() => {
    if (rive && disableMotion && loaded) {
      rive.play('static')
    }
  }, [rive, disableMotion, loaded])

  return (
    <Stack
      {...omit(stackProps, 'type')}
      className={[
        'AnimationBlock',
        props.className,
        fullBleed && 'fullBleed',
      ]
        .filter(Boolean)
        .join(' ')}
      sx={{
        '> div': {
          height: `${height}px !important`,
          maxHeight: 1,
          width: `${width}px !important`,
        },
        alignItems: 'center',
        height,
        justifyContent: 'center',
        width,
        ...props.sx,
      }}
    >
      <RiveComponent width={width} height={height} />
    </Stack>
  )
}

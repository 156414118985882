import { useIntervalWhen } from 'rooks'
import useElapsedTime from 'hooks/useElapsedTime'
import { useCallback, useContext, useMemo } from 'react'
import { StoryPlayerContext } from 'components/StoryPlayerContext'

type useStoryPlayerProgressProps = {
  getValue?: (elapsedTime: number) => number
  playing?: boolean
  duration?: number // in ms.
  onNext?: () => void
}

export const useStoryPlayerProgress = (
  props?: useStoryPlayerProgressProps,
) => {
  const { getValue, duration, onNext } = props ?? {}
  const playing = !!duration && !!props?.playing
  const { getElapsedTime, resetElapsedTime } = useElapsedTime(playing)
  const { storyPlayerProgress, setStoryPlayerProgress } = useContext(
    StoryPlayerContext,
  )
  const resetProgress = useCallback(() => {
    return setStoryPlayerProgress(0)
  }, [setStoryPlayerProgress])
  const result = useMemo(
    () => ({
      resetElapsedTime,
      resetProgress,
      storyPlayerProgress,
    }),
    [storyPlayerProgress, resetProgress, resetElapsedTime],
  )

  useIntervalWhen(
    () => {
      const elapsedTime = getElapsedTime()
      let progress = 0

      if (getValue) {
        progress = getValue(elapsedTime)
      } else if (duration) {
        progress = Math.min(elapsedTime / duration, 1)
      }

      if (progress === 1) {
        onNext?.()
      }
      setStoryPlayerProgress(progress)
    },
    33, // 30fps
    playing,
  )

  return result
}

import { useLoginActions } from '@frontegg/react'
import useSessionStorage from 'hooks/useSessionStorage'
import { useMemo } from 'react'

export default function useKiosk() {
  const { logout } = useLoginActions()
  const [{ kiosk }, setSessionStorage] = useSessionStorage()
  const result = useMemo(() => {
    return {
      enabled: !!kiosk,
      resetStoryId() {
        setSessionStorage({
          kiosk: { storyId: null, url: kiosk.url },
        })
      },
      async sessionEnd() {
        return logout()
      },
      sessionStart(kioskArgs: { url: string; storyId: string }) {
        setSessionStorage({
          kiosk: kioskArgs,
        })
      },
      storyId: kiosk?.storyId,
      url: kiosk?.url,
    }
  }, [kiosk, logout, setSessionStorage])
  return result
}

import React from 'react'
import useInterpolate from 'hooks/useInterpolate'
import { StoryPlayerContext } from 'components/StoryPlayerContext'
import Link from 'next/link'
import { omit } from 'lodash'
import ButtonBlock from 'components/blocks/ButtonBlock'
import { ButtonProps } from '@mui/material'
import { BaseBlockData } from 'components/StoryPlayer/PageBlock'
import { InfoIcon } from 'components/blocks/InfoBannerBlock'
import { isArticleUrl } from 'components/blocks/TextBlock'

export type ButtonLinkBlockData = BaseBlockData & {
  type: 'button-link'
  label: string
  title?: string
  href?: string
  target?: string
  onClick?: () => void
  variant?: ButtonProps['variant']
  color?: ButtonProps['color']
}

export type ButtonLinkBlockProps = Omit<ButtonProps, 'type'> &
  Omit<ButtonLinkBlockData, 'type'>

export default function ButtonLinkBlock(props: ButtonLinkBlockProps) {
  const { ...buttonProps } = props
  const interpolate = useInterpolate(StoryPlayerContext)

  return (
    <ButtonBlock<'a' | 'button'>
      {...omit(buttonProps, ['type', 'disableMotion'])}
      className={['ButtonLinkBlock', props.className]
        .filter(Boolean)
        .join(' ')}
      sx={buttonProps.sx}
      LinkComponent={Link}
      title={interpolate(props.title)}
      target={props.target ?? '_blank'}
      endIcon={
        // Automatically add article info icon for article links consistency in UX.
        isArticleUrl(buttonProps.href) ? (
          <InfoIcon sx={{ color: 'text.secondary' }} />
        ) : (
          buttonProps.endIcon
        )
      }
    >
      {interpolate(props.label)}
    </ButtonBlock>
  )
}

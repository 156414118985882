import React from 'react'
import AnimationBlock, {
  AnimationBlockData,
} from 'components/blocks/AnimationBlock'
import ImageBlock, {
  ImageBlockData,
} from 'components/blocks/ImageBlock'
import TextBlock, { TextBlockData } from 'components/blocks/TextBlock'
import InfoBannerBlock, {
  InfoBannerBlockData,
} from 'components/blocks/InfoBannerBlock'
import ButtonLinkBlock, {
  ButtonLinkBlockData,
} from 'components/blocks/ButtonLinkBlock'
import ChartBlock, {
  ChartBlockData,
} from 'components/blocks/ChartBlock'
import ObjectBlock, {
  ObjectBlockData,
} from 'components/blocks/ObjectBlock'
import SvgBlock, { SvgBlockData } from 'components/blocks/SvgBlock'
import InfoLinkBlock, {
  InfoLinkBlockData,
} from 'components/blocks/InfoLinkBlock'
import OutlinedTextBlock, {
  OutlinedTextBlockData,
} from 'components/blocks/OutlinedTextBlock'
import { StorySxProps } from 'components/StoryPlayer'

export default function PageBlock(props: BlockData) {
  switch (props.type) {
    case 'text': {
      return <TextBlock {...props} />
    }
    case 'outlined-text': {
      return <OutlinedTextBlock {...props} />
    }
    case 'info-banner': {
      return <InfoBannerBlock {...props} />
    }
    case 'image': {
      return <ImageBlock {...props} />
    }
    case 'svg': {
      return <SvgBlock {...props} />
    }
    case 'object': {
      return <ObjectBlock {...props} />
    }
    case 'animation': {
      return <AnimationBlock {...props} />
    }
    case 'button-link': {
      return <ButtonLinkBlock {...props} />
    }
    case 'chart': {
      return <ChartBlock {...props} />
    }
    case 'info-link': {
      return <InfoLinkBlock {...props} />
    }
  }
}

export interface BaseBlockData {
  sx?: StorySxProps
  className?: string
  disableMotion?: boolean
}

export type BlockData =
  | TextBlockData
  | InfoBannerBlockData
  | ButtonLinkBlockData
  | ImageBlockData
  | SvgBlockData
  | ObjectBlockData
  | AnimationBlockData
  | ChartBlockData
  | InfoLinkBlockData
  | OutlinedTextBlockData

export type BlocksAlign = 'top' | 'center' | 'bottom'

export function getBlocksAlignStyle(blocksAlign: BlocksAlign) {
  return {
    justifyContent: (
      {
        bottom: 'flex-end',
        center: 'center',
        top: 'flex-start',
      } as const
    )[blocksAlign],
  }
}

import React from 'react'
import {
  Box,
  Button,
  Stack,
  ThemeProvider,
  Typography,
} from '@mui/material'
import ForbiddenImage from 'assets/images/forbidden.svg'
import ProblemImage from 'assets/images/problem.svg'
import { getTheme } from 'components/NestMuiTheme'
import { useRouter } from 'next/router'

export type CustomErrorPageProps = {
  type: 'error' | 'forbidden'
  message?: string
}

export default function CustomErrorPage({
  type,
  message,
}: CustomErrorPageProps) {
  const router = useRouter()

  const handleReset = () => {
    router.reload()
  }

  const handleGoHome = () => {
    router.push('/').then(() => {
      router.reload()
    })
  }

  return (
    <ThemeProvider theme={getTheme()}>
      <Stack
        sx={{
          alignItems: 'center',
          color: 'text.primary',
          gap: 3,
          justifyContent: 'center',
          maxWidth: 480,
          minHeight: '70vh',
          mx: 'auto',
          my: 3,
          textAlign: 'center',
        }}
      >
        {type === 'forbidden' && (
          <>
            <Box sx={{ height: 200 }}>
              <ForbiddenImage />
            </Box>
            <Box>
              <Typography
                component="div"
                color="inherit"
                variant="h3"
              >
                Access Forbidden
              </Typography>
              <Typography
                component="div"
                color="inherit"
                variant="body2"
              >
                {message}
              </Typography>
            </Box>
          </>
        )}

        {type === 'error' && (
          <>
            <Box sx={{ height: 200 }}>
              <ProblemImage />
            </Box>
            <Stack>
              <Typography
                component="div"
                color="inherit"
                variant="h3"
                sx={{ mb: 4 }}
              >
                Something went wrong
              </Typography>
              <Button
                onClick={handleReset}
                variant="contained"
                color="primary"
                sx={{ mb: 1 }}
              >
                Try Again
              </Button>
              <Button onClick={handleGoHome}>Go Home</Button>
            </Stack>
          </>
        )}
      </Stack>
    </ThemeProvider>
  )
}

import React, { useContext, useState } from 'react'
import Link from 'next/link'
import {
  AppBar,
  Badge,
  ButtonBase,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material'
import { useRouter } from 'next/router'
import MainMenuDrawer from 'components/MainMenuDrawer'
import AppIcon from 'components/AppIcon'
import NestLogo from 'components/NestLogo'
import { mixins } from 'components/NestMuiTheme'
import { AppContext } from './AppContext'

export const NavBarHeight = 64

export type MainNavBarProps = {
  hasBack?: boolean
  showLogo?: boolean
}

export default function MainNavBar(props: MainNavBarProps) {
  const router = useRouter()
  const [menuOpen, setMenuOpen] = useState(false)
  const { featureFlags, documentTitle } = useContext(AppContext)
  const { patientAppCarePlan } = featureFlags

  return (
    <>
      <AppBar
        variant="elevation"
        elevation={0}
        position="sticky"
        color="transparent"
        sx={{
          boxShadow: 0,
          height: NavBarHeight,
          top: 0,
          width: '100vw',
          zIndex: 1101,
        }}
      >
        <Toolbar
          sx={{
            ...mixins.bgBlur,
            height: 1,
            px: { md: 4, xs: 2 },
          }}
        >
          {props.showLogo && (
            <ButtonBase disableRipple sx={{ position: 'absolute' }}>
              <Link href="/">
                <>
                  <NestLogo
                    sx={{
                      height: 24,
                      width: 'auto',
                    }}
                  />
                  {/* <img
                    src="img/logo-nest-dana-farber.png"
                    height={40}
                    alt="Nest / Dana Farber logo"
                  /> */}
                </>
              </Link>
            </ButtonBase>
          )}
          {router.route !== '/' && (
            <Link href="/">
              <IconButton edge="start" color="inherit" sx={{ mx: 0 }}>
                <AppIcon.Back />
              </IconButton>
            </Link>
          )}
          <Stack
            sx={{
              alignItems: 'center',
              height: 1,
              justifyContent: 'center',
              left: 0,
              pointerEvents: 'none',
              position: 'absolute',
              top: 0,
              width: 1,
              zIndex: 1,
            }}
          >
            <Typography variant="h4" textAlign="center">
              {documentTitle}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            gap={0.25}
            sx={{
              alignItems: 'center',
              flexGrow: 1,
              justifyContent: 'flex-end',
              mr: -1,
            }}
          >
            {/* {router.route === '/' && (
              <Link href="/notifications">
                <IconButton color="inherit">
                  <Badge
                    // badgeContent={2}
                    color="primary"
                    overlap="circular"
                    variant="dot"
                  >
                    <SvgIcon
                      fontSize="small"
                      component={ChatIcon}
                    />
                  </Badge>
                </IconButton>
              </Link>
            )} */}
            {router.route === '/' && patientAppCarePlan && (
              <Link href="/care-plan">
                <IconButton
                  color="inherit"
                  data-testid="nav-bar.care-plans"
                >
                  <Badge
                    color="primary"
                    overlap="circular"
                    variant="dot"
                    invisible
                    sx={{
                      '& .MuiBadge-badge': {
                        marginRight: -0.5,
                      },
                    }}
                  >
                    <AppIcon.CarePlan fontSize="medium" />
                  </Badge>
                </IconButton>
              </Link>
            )}
            <IconButton
              color="inherit"
              onClick={() => setMenuOpen(true)}
              data-testid="nav-bar.menu"
            >
              <AppIcon.Menu fontSize="large" />
            </IconButton>
          </Stack>
        </Toolbar>
      </AppBar>
      <MainMenuDrawer
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
      />
    </>
  )
}

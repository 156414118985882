import React, { ElementType } from 'react'
import {
  Box,
  Button,
  ButtonProps,
  CircularProgress,
  Grow,
  alpha,
} from '@mui/material'

export default function ButtonBlock<T extends ElementType = 'button'>(
  props: ButtonProps<T> & {
    loading?: boolean
  },
) {
  const {
    color = 'secondary',
    variant = 'outlined',
    loading,
    children,
    ...buttonProps
  } = props
  const isThemeColor = ['primary', 'secondary'].includes(color)

  return (
    <Button
      variant={variant}
      color={color}
      {...buttonProps}
      className={['ButtonBlock', props.className]
        .filter(Boolean)
        .join(' ')}
      sx={{
        '&.Mui-disabled': {
          transition: 'none',
        },
        alignSelf: 'center',
        bgcolor: variant === 'outlined' ? 'background.default' : null,
        borderRadius: 1,
        boxShadow: 0,
        justifySelf: 'center',
        pointerEvents: loading ? 'none' : null,
        width: 1,
        ...props.sx,
        '&:hover': {
          backgroundColor:
            variant === 'contained' && isThemeColor
              ? `${color}.main`
              : variant === 'outlined'
                ? 'background.default'
                : null,
          boxShadow: ({ palette }) =>
            isThemeColor
              ? `0 0 0 0.2rem ${alpha(palette[color].main, 0.33)}`
              : `0 0 0 0.2rem ${palette.action.selected}`,
          color: ({ palette }) =>
            variant === 'contained' && isThemeColor
              ? palette.getContrastText(palette[color].main)
              : variant === 'outlined'
                ? palette[color].main
                : 'inherit',
          ...props.sx?.['&:hover'],
        },
      }}
    >
      <Box
        component="span"
        sx={{ visibility: loading ? 'hidden' : null }}
      >
        {children}
      </Box>
      <Grow in={!!loading} mountOnEnter unmountOnExit>
        <CircularProgress
          color="inherit"
          sx={{ position: 'absolute' }}
          size={16}
          thickness={5}
        />
      </Grow>
    </Button>
  )
}

import React, { MouseEvent, ReactNode, useRef, useState } from 'react'
import {
  Box,
  IconButton,
  IconButtonProps,
  Menu,
  MenuItem,
  MenuItemProps,
  MenuList,
  Paper,
  Popper,
  Stack,
  SvgIconProps,
  Tooltip,
} from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import {
  PopupState,
  bindHover,
  usePopupState,
} from 'material-ui-popup-state/hooks'
import { noop } from 'lodash'

export type MenuIconButtonItemProps = MenuItemProps & {
  label?: string
  nestedItems?: NestedMenuOption[]
}

export type NestedMenuOption = {
  label: ReactNode
  disabled?: boolean
  onClick?: (e: MouseEvent) => void
}

type MenuIconButtonProps = {
  menuItems: MenuIconButtonItemProps[]
  IconButtonProps?: IconButtonProps<'a'>
  IconComponent?: React.ElementType<SvgIconProps>
  IconProps?: SvgIconProps
  disabled?: boolean
  size?: 'small' | 'medium' | 'large'
}

export default function MenuIconButton(props: MenuIconButtonProps) {
  const { disabled, size, IconProps } = props
  const [menuOpen, setMenuOpen] = useState(false)
  const menuButton = useRef<HTMLAnchorElement>(null)
  const IconComponent = props.IconComponent ?? MoreVertIcon
  return (
    <>
      <IconButton
        ref={menuButton}
        color="secondary"
        {...props.IconButtonProps}
        onClick={(e) => {
          e.stopPropagation()
          setMenuOpen(true)
        }}
        // Explicitly set the href to fix type issue.
        href={props.IconButtonProps?.href}
        size={size}
        disabled={disabled}
      >
        <IconComponent fontSize="inherit" {...IconProps} />
      </IconButton>
      <Menu
        data-testid="care-plan-menu"
        anchorEl={menuButton.current}
        open={menuOpen}
        onClose={(e: MouseEvent) => {
          e.stopPropagation()
          setMenuOpen(false)
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
      >
        {props.menuItems.map((menuItemProps, index) => (
          <Tooltip
            key={`menuItem${index}`}
            title={menuItemProps.title ?? ''}
          >
            <div>
              {menuItemProps.nestedItems ? (
                <NestedMenu
                  disabled={menuItemProps.disabled}
                  label={menuItemProps.label}
                  options={
                    menuItemProps.nestedItems.length
                      ? menuItemProps.nestedItems
                      : [
                          {
                            disabled: true,
                            label: (
                              <Box
                                sx={{
                                  color: 'text.secondary',
                                  fontStyle: 'italic',
                                }}
                              >
                                None
                              </Box>
                            ),
                            onClick: noop,
                          },
                        ]
                  }
                  setMenuOpen={setMenuOpen}
                />
              ) : (
                <MenuItem
                  {...menuItemProps}
                  onClick={(e) => {
                    e.stopPropagation()
                    setMenuOpen(false)
                    menuItemProps.onClick?.(e)
                  }}
                >
                  {menuItemProps.children || menuItemProps.label}
                </MenuItem>
              )}
            </div>
          </Tooltip>
        ))}
      </Menu>
    </>
  )
}

type NestedOptionMenuProps = {
  options: NestedMenuOption[]
  popupState: PopupState
  setMenuOpen: (value: boolean) => void
}

type NestedMenuProps = {
  label: string
  options: NestedMenuOption[]
  disabled?: boolean
  setMenuOpen: (value: boolean) => void
}

function NestedMenu(props: NestedMenuProps) {
  const { label, options, setMenuOpen, disabled } = props
  const popupState = usePopupState({
    popupId: label,
    variant: 'popper',
  })
  return (
    <div {...bindHover(popupState)}>
      <MenuItem
        disabled={disabled}
        onClick={(e) => e.stopPropagation()}
      >
        <Stack
          direction="row"
          sx={{
            justifyContent: 'space-between',
            width: 1,
          }}
        >
          <Box>{label}</Box>
          <ArrowRightIcon
            className="NestedSelectOption-icon"
            fontSize="small"
            sx={{ ml: 1, mr: -1 }}
          />
        </Stack>
      </MenuItem>
      {!disabled && (
        <NestedSubMenu
          options={options}
          popupState={popupState}
          setMenuOpen={setMenuOpen}
        />
      )}
    </div>
  )
}

function NestedSubMenu(props: NestedOptionMenuProps) {
  const { options, popupState, setMenuOpen } = props
  return (
    <Popper
      anchorEl={popupState.anchorEl}
      open={popupState.isOpen}
      placement="right-start"
      sx={{ zIndex: 1301 }}
      onClick={(e) => e.stopPropagation()}
    >
      <Paper
        sx={{ maxHeight: `calc(60vh)`, my: -1, overflowY: 'auto' }}
      >
        <MenuList>
          {options.map((option, index) => (
            <MenuItem
              disabled={option.disabled}
              key={`nestedOption.${index}`}
              onClick={(e) => {
                e.stopPropagation()
                setMenuOpen(false)
                option.onClick?.(e)
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </MenuList>
      </Paper>
    </Popper>
  )
}

import {
  Engine,
  EngineOptions,
  RuleProperties,
} from 'json-rules-engine'
import { inRange, isArray, isNumber } from 'lodash'

// Default operators.
// https://github.com/CacheControl/json-rules-engine/blob/master/src/engine-default-operators.js
//   equal
//   notEqual
//   in
//   notIn
//   contains
//   doesNotContain
//   lessThan
//   lessThanInclusive
//   greaterThan
//   greaterThanInclusive

// Extended operators.
//   exists
//   notExists
//   emptyArray
//   notEmptyArray
//   includes
//   notIncludes

export default class RuleEngine extends Engine {
  constructor(rules?: RuleProperties[], options?: EngineOptions) {
    super(rules, {
      allowUndefinedFacts: true,
      ...options,
    })

    this.addOperator('exists', (factValue) => factValue !== undefined)
    this.addOperator(
      'notExists',
      (factValue) => factValue === undefined,
    )
    this.addOperator('emptyArray', (factValue: Array<unknown>) => {
      return (
        isArray(factValue) &&
        (factValue.length === 0 ||
          factValue.every((item) => item === null))
      )
    })
    this.addOperator('notEmptyArray', (factValue: Array<unknown>) => {
      return (
        isArray(factValue) &&
        factValue.length !== 0 &&
        factValue.some((item) => item !== null)
      )
    })
    this.addOperator(
      'includes',
      (factValue: unknown, compareToValue: Array<unknown>) => {
        if (!isArray(compareToValue)) {
          console.error(
            `Invalid 'includes' operation: Value to compare is not an array.`,
            { compareToValue, factValue },
          )
          return null
        }
        return compareToValue.includes(factValue ?? null)
      },
    )
    this.addOperator(
      'notIncludes',
      (factValue: unknown, compareToValue: Array<unknown>) => {
        if (!isArray(compareToValue)) {
          console.error(
            `Invalid 'notIncludes' operation: Value to compare is not an array.`,
            { compareToValue, factValue },
          )
          return null
        }
        return !compareToValue.includes(factValue)
      },
    )
    this.addOperator(
      'inBetween',
      (factValue: unknown, compareToValue: [number, number]) => {
        if (factValue === undefined) {
          return false
        }

        if (!isNumber(factValue)) {
          console.error(
            `Invalid 'inBetween' operation: Fact value is not a number.`,
            { compareToValue, factValue },
          )
          return null
        }

        if (!isArray(compareToValue)) {
          console.error(
            `Invalid 'inBetween' operation: Value to compare is not [number, number].`,
            { compareToValue, factValue },
          )
          return null
        }

        // Add +1 to include the end number to the range.
        return inRange(
          factValue,
          compareToValue[0],
          compareToValue[1] + 1,
        )
      },
    )
  }
}

import React from 'react'
import StoryProgressModel from 'models/StoryProgress'
import {
  PatientAppPatientData,
  QuestionnaireResponseItemInput,
} from '__generated__/graphql'
import PatientModel from 'models/Patient'
import { noop } from 'lodash'
import { StoryPageData } from 'components/StoryPlayer/StoryPage'
import { StoryData } from 'components/StoryPlayer'

export type StoryPlayerContextProps = {
  enableAudio: boolean
  hiddenControls: boolean
  refetchPatientData: () => Promise<void>
  page: StoryPageData
  pageId: string
  patient: PatientModel
  patientData: PatientAppPatientData[]
  questionnaireResponses: Record<string, unknown>
  questionnaireResponseItems: QuestionnaireResponseItemInput[]
  setEnableAudio: (value: boolean) => void
  setHiddenControls: (value: boolean) => void
  setQuestionnaireResponseItems: (
    value: QuestionnaireResponseItemInput[],
  ) => void
  setStoryAudioGuideCompleted: (value: boolean) => void
  setStoryAudioGuideOpen: (value: boolean) => void
  setStoryPlayerMuted: (value: boolean) => void
  setStoryPlayerOpen: (value: boolean) => void
  setStoryPlayerProgress: (value: number) => void
  storyAudioGuideCompleted: boolean
  storyAudioGuideOpen: boolean
  storyId: string
  story: StoryData
  storyPlayerMuted: boolean
  storyPlayerOpen: boolean
  storyPlayerProgress: number
  storyProgress: StoryProgressModel
  storyPages: StoryPageData[]
  setStory: (story: StoryData) => void
  setStoryPages: (pages: StoryPageData[]) => void
  templatePages: StoryPageData[]
  setTemplatePages: (pages: StoryPageData[]) => void
}

export const StoryPlayerContext =
  React.createContext<StoryPlayerContextProps>({
    enableAudio: false,
    hiddenControls: true,
    page: null,
    pageId: '',
    patient: null as PatientModel,
    patientData: [],
    questionnaireResponseItems: [],
    questionnaireResponses: {},
    refetchPatientData: async () => noop(),
    setEnableAudio: noop,
    setHiddenControls: noop,
    setQuestionnaireResponseItems: noop,
    setStory: noop,
    setStoryAudioGuideCompleted: noop,
    setStoryAudioGuideOpen: noop,
    setStoryPages: noop,
    setStoryPlayerMuted: noop,
    setStoryPlayerOpen: noop,
    setStoryPlayerProgress: noop,
    setTemplatePages: noop,
    story: null,
    storyAudioGuideCompleted: false,
    storyAudioGuideOpen: false,
    storyId: '',
    storyPages: [],
    storyPlayerMuted: false,
    storyPlayerOpen: false,
    storyPlayerProgress: 0,
    storyProgress: null,
    templatePages: [],
  })

import React, { Fragment, ReactNode, createContext } from 'react'
import { User as AuthUser } from '@frontegg/redux-store'
import Tracker from '@openreplay/tracker'
import trackerGraphQL from '@openreplay/tracker-graphql'
import trackerFetch from '@openreplay/tracker-fetch'
import { useReducer } from 'react'
import { noop } from 'lodash'
import { useAuth } from '@frontegg/react'

interface ITrackerState {
  tracker: Tracker | null
}

interface ITrackerAction {
  type: 'init'
  payload: {
    getUser: () => AuthUser
  }
}

const OPENREPLAY_INGEST_POINT =
  process.env.NEXT_PUBLIC_OPENREPLAY_INGEST_POINT
const OPENREPLAY_PROJECT_KEY =
  process.env.NEXT_PUBLIC_OPENREPLAY_PROJECT_KEY
const OPENREPLAY_ENABLED = !!(
  OPENREPLAY_INGEST_POINT && OPENREPLAY_PROJECT_KEY
)

export let tracker: Tracker | null = null
export let recordGraphQL
export let fetchPlugin

function reducer(state: ITrackerState, action: ITrackerAction) {
  if (action.type == 'init') {
    if (!state.tracker) {
      state.tracker = newTracker()
    }
    const user = action.payload.getUser()
    state.tracker.start({
      metadata: {
        email: user?.email,
        name: user?.name,
        tenantId: user?.tenantId,
      },
      userID: user?.id,
    })
  }
  return state
}

function newTracker() {
  const trackerConfig = {
    ingestPoint: OPENREPLAY_INGEST_POINT,
    projectKey: OPENREPLAY_PROJECT_KEY,
  }
  tracker = new Tracker(trackerConfig)
  recordGraphQL = tracker.use(trackerGraphQL())
  fetchPlugin = tracker.use(
    trackerFetch({
      overrideGlobal: true,
    }),
  )

  import('@openreplay/tracker-assist')
    .then((mod) => mod.default)
    .then((trackerAssist) => {
      tracker.use(trackerAssist())
    })
  return tracker
}

export const TrackerContext = createContext({
  initTracker: noop,
})

export default function TrackerProvider(props: {
  children: ReactNode
}) {
  const [, dispatch] = useReducer(reducer, {
    tracker: null,
  })
  const { user } = useAuth()
  const payload = {
    getUser: () => user,
  }
  const value = {
    initTracker: () =>
      OPENREPLAY_ENABLED
        ? dispatch({
            payload,
            type: 'init',
          })
        : noop,
  }

  return OPENREPLAY_ENABLED ? (
    <TrackerContext.Provider value={value}>
      {props.children}
    </TrackerContext.Provider>
  ) : (
    <Fragment>{props.children}</Fragment>
  )
}

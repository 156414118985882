import { OrderableTestOptionsQuery } from '__generated__/graphql'
import {
  OrderConfig,
  OrderModulePageId,
} from 'components/StoryPlayer/Modules/Order'
import { BlockData } from 'components/StoryPlayer/PageBlock'
import { StoryPageData } from 'components/StoryPlayer/StoryPage'
import { PageTransformer } from 'components/StoryPlayer/pageTransformer'
import { generateId } from 'utils/SeedUtil'
import {
  PaymentCurrency,
  PaymentResult,
  paymentCalculator,
} from 'components/StoryPlayer/Modules/Order/paymentCalculator'
import { formatTel } from '__shared__/utils/TelUtil'
import { RegionCode } from 'google-libphonenumber'

const getPageId = (value: string) => generateId('orderTests' + value)

const orderTests: PageTransformer<{
  contactPhoneNumber: string
  currency: OrderConfig['currency']
  labId: string
  orderPaymentMethod: OrderConfig['orderPaymentMethod']
  patientPayArticleLinkUrl: string
  patientPayArticleLinkLabel: string
}> = ({ returnToPageId, data, options }) => {
  const { questionnaireResponses, interpolationData, interpolate } =
    options
  const contactPhoneNumber = interpolate(options.contactPhoneNumber)
  const currency = interpolate(options.currency) as PaymentCurrency
  const labId = interpolate(options.labId)
  const orderPaymentMethod = interpolate(options.orderPaymentMethod)
  const patientPayArticleLinkUrl = interpolate(
    options.patientPayArticleLinkUrl,
  )
  const patientPayArticleLinkLabel = interpolate(
    options.patientPayArticleLinkLabel,
  )
  const phoneCountryCode = interpolationData.account?.settings
    ?.fieldFormatting?.phoneCountryCode as RegionCode
  const formattedContactPhone = formatTel(
    contactPhoneNumber,
    phoneCountryCode,
  )
  const isPatientPayMethod = orderPaymentMethod === 'patient-pay'
  const orderableTestOptions =
    (data as OrderableTestOptionsQuery)?.patientApp
      ?.orderableTestOptions ?? []

  // Exit questionnaire when no qualifying tests.
  if (!orderableTestOptions.length) {
    return [
      {
        get blocks() {
          const blocks: BlockData[] = [
            {
              content: `### You do not qualify for any of the offered tests.`,
              type: 'text',
            },
          ]

          if (formattedContactPhone) {
            blocks.push({
              content: `For any questions you can contact us at [${formattedContactPhone}](tel:${contactPhoneNumber}).`,
              type: 'text',
            })
          }
          return blocks
        },
        defaultConnectionPageId:
          OrderModulePageId.questionnaireFinished,
        id: getPageId('notQualified'),
        type: 'custom',
      },
    ]
  }

  const pages: StoryPageData[] = [
    {
      connectionRules: [
        {
          conditions: {
            all: [
              {
                fact: 'questionnaireResponses',
                operator: 'emptyArray',
                path: '$.selectedTestOrderIds',
                value: null,
              },
            ],
          },
          event: {
            params: {
              pageId: getPageId('noTestSelection'),
            },
            type: 'StoryNavigation',
          },
        },
      ],
      defaultConnectionPageId: isPatientPayMethod
        ? getPageId('estimatedCost')
        : getPageId('qualifyingQuestionsIntro'),
      fields: [
        {
          helperBlocks: isPatientPayMethod &&
            patientPayArticleLinkUrl && [
              {
                href: patientPayArticleLinkUrl,
                label:
                  patientPayArticleLinkLabel ||
                  `View test catalog & pricing`,
                type: 'info-link',
              },
            ],
          label: `Which tests would you like to order?`,
          name: 'selectedTestOrderIds',
          options: orderableTestOptions,
          type: 'checkbox',
        },
      ],
      id: getPageId('selectTests'),
      overrideHeaderTitle: 'Test Selection',
      submitLabelOnEmpty: 'None of these',
      type: 'form',
    },
    isPatientPayMethod && {
      connectionRules: [
        {
          conditions: {
            all: [
              {
                fact: 'questionnaireResponses',
                operator: 'equal',
                path: '$.continueWithEstimatedCost',
                value: 'backToTestSelection',
              },
            ],
          },
          event: {
            params: {
              // Avoid circular navigation issues for calculating story progress.
              excludeFromPath: true,
              pageId: getPageId('selectTests'),
            },
            type: 'StoryNavigation',
          },
        },
      ],
      defaultConnectionPageId: getPageId('qualifyingQuestionsIntro'),
      enableActionIconButton: true,
      fields: [
        {
          beforeBlock: {
            get content() {
              // Must reference `questionnaireResponses` ref to avoid stale closure.
              const selectedTestIds = (questionnaireResponses.current
                .selectedTestOrderIds ?? []) as string[]
              const paymentResult: PaymentResult = paymentCalculator(
                labId,
                selectedTestIds,
                currency,
              )
              const paymentResultLabel = paymentResult.label
              const selectedTestList = selectedTestIds
                .map(
                  (testId) =>
                    orderableTestOptions.find(
                      ({ value }) => value === testId,
                    )?.label,
                )
                .filter(Boolean)
                .map((label) => `- ${label}`)
                .join('\n')
              return `### Estimated cost of tests \n **Selected tests**  \n ${selectedTestList}${paymentResultLabel ? `\n\n **Estimated total**  \n${paymentResultLabel}` : ''}`
            },
            type: 'text',
            variant: 'body1',
          },
          label: ``,
          name: 'continueWithEstimatedCost',
          options: [
            {
              label: 'Continue',
              value: 'continue',
            },
            {
              label: 'Back to test selection',
              value: 'backToTestSelection',
            },
          ],
          selectionHidden: true,
          type: 'button',
        },
      ],
      id: getPageId('estimatedCost'),
      type: 'form',
    },
    {
      blocks: [
        {
          color: 'text.secondary',
          content: `Next Step`,
          type: 'text',
          variant: 'h5',
        },
        {
          content: `Test Qualification`,
          type: 'text',
          variant: 'h3',
        },
        {
          content: `We need to ask a few questions to make sure you qualify for the tests you selected. Please answer to the best of your ability.`,
          type: 'text',
        },
      ],
      connectionRules: [
        {
          conditions: {
            all: [],
          },
          event: {
            params: {
              get options() {
                // Must reference `questionnaireResponses` ref to avoid stale closure.
                const selectedTestIds = (questionnaireResponses
                  .current.selectedTestOrderIds ?? []) as string[]

                return {
                  contactPhoneNumber,
                  currency,
                  labId,
                  orderPaymentMethod,
                  orderableTestOptions,
                  selectedTestIds,
                  testSelectionPageId: getPageId('selectTests'),
                }
              },
              pageTransformer: 'testQualificationQuestions',
              queries: [
                {
                  name: 'OrderableTestDisqualifyingQuestionsSchemas',
                  variables: {
                    get orderableTestIds() {
                      // Must reference `questionnaireResponses` ref to avoid stale closure.
                      return (questionnaireResponses.current
                        .selectedTestOrderIds ?? []) as string[]
                    },
                  },
                },
              ],
              returnToPageId: getPageId('intakeQuestionsIntro'),
            },
            type: 'DynamicChapterNavigation',
          },
        },
      ],
      enableActionIconButton: true,
      id: getPageId('qualifyingQuestionsIntro'),
      type: 'custom',
    },
    {
      blocks: [
        {
          color: 'text.secondary',
          content: `Next Step`,
          type: 'text',
          variant: 'h5',
        },
        {
          content: `Intake Questionnaire`,
          type: 'text',
          variant: 'h3',
        },
        {
          content: `We need to ask a few additional questions to help determine your risk. Please answer to the best of your ability.`,
          type: 'text',
        },
      ],
      connectionRules: [
        {
          conditions: {
            all: [],
          },
          event: {
            params: {
              get options() {
                // Must reference `questionnaireResponses` ref to avoid stale closure.
                const selectedTestIds = (questionnaireResponses
                  .current.selectedTestOrderIds ?? []) as string[]

                return { orderableTestOptions, selectedTestIds }
              },
              pageTransformer: 'testIntakeQuestions',
              queries: [
                {
                  name: 'OrderableTestIntakeQuestions',
                  variables: {
                    get orderableTestIds() {
                      // Must reference `questionnaireResponses` ref to avoid stale closure.
                      return (questionnaireResponses.current
                        .selectedTestOrderIds ?? []) as string[]
                    },
                  },
                },
              ],
              returnToPageId,
            },
            type: 'DynamicChapterNavigation',
          },
        },
      ],
      enableActionIconButton: true,
      id: getPageId('intakeQuestionsIntro'),
      type: 'custom',
    },
    {
      get blocks() {
        const blocks: BlockData[] = [
          {
            content: `You chose not to order any tests`,
            type: 'text',
            variant: 'h3',
          },
        ]

        if (formattedContactPhone) {
          blocks.push({
            content: `For any questions you can contact us at [${formattedContactPhone}](tel:${contactPhoneNumber}).`,
            type: 'text',
          })
        }
        return blocks
      },
      defaultConnectionPageId:
        OrderModulePageId.questionnaireFinished,
      enableActionIconButton: true,
      id: getPageId('noTestSelection'),
      type: 'custom',
    },
  ]

  return pages.filter(Boolean)
}

export default orderTests

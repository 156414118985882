import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
} from 'react'
import Drawer, { DrawerProps } from '@mui/material/Drawer'
import {
  IconButton,
  Stack,
  StackProps,
  Typography,
  alpha,
  useTheme,
} from '@mui/material'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { useWindowEventListener } from 'rooks'

export type EnhancedDrawerProps = DrawerProps & {
  title?: ReactNode
  actions?: ReactNode
  dismiss?: boolean
  disableFullHeight?: boolean
  ContentStackProps?: StackProps
}

export default function EnhancedDrawer(
  props: EnhancedDrawerProps,
): JSX.Element {
  const {
    open,
    onClose,
    title,
    actions,
    dismiss,
    disableFullHeight,
    ContentStackProps,
    ...drawerProps
  } = props
  const drawer = useRef<HTMLDivElement>(null)
  const header = useRef<HTMLDivElement>(null)
  const footer = useRef<HTMLDivElement>(null)
  const { breakpoints, palette } = useTheme()
  const addScrollHint = useCallback(
    (scrollTop = 0) => {
      if (drawer.current) {
        const paper = drawer.current.querySelector('.MuiPaper-root')
        const scrollEnd =
          paper.scrollHeight - paper.clientHeight - scrollTop > 0

        if (header.current) {
          header.current.style.boxShadow = scrollTop
            ? `0 1px 0 ${alpha(palette.divider, 0.05)}`
            : 'none'
        }
        if (footer.current) {
          footer.current.style.boxShadow = scrollEnd
            ? `0 -1px 0 ${alpha(palette.divider, 0.05)}`
            : 'none'
        }
      }
    },
    [palette.divider],
  )
  const onScroll = useCallback(
    (e) => addScrollHint(e.target?.scrollTop),
    [addScrollHint],
  )

  useWindowEventListener('resize', () => addScrollHint())
  useEffect(() => addScrollHint(), [addScrollHint])

  return (
    <Drawer
      open={open}
      anchor="bottom"
      {...drawerProps}
      ref={drawer}
      sx={{
        flexShrink: 0,
        height: '100vh',
        width: '100vw',
        ...props.sx,
      }}
      PaperProps={{
        elevation: 0,
        onScroll,
        sx: {
          '@media (min-height: 1067px)': {
            maxHeight: (16 / 9) * breakpoints.values.sm,
          },
          backgroundImage: 'none',
          borderBottomLeftRadius: { sm: 12, xs: 0 },
          borderBottomRightRadius: { sm: 12, xs: 0 },
          borderRadius: 2,
          justifyContent: 'flex-start',
          m: {
            sm: 'auto',
          },
          maxHeight: '100vh',
          maxWidth: {
            sm: breakpoints.values.sm,
            xs: 'auto',
          },
          minHeight: '33vh',
          pt: title ? 0 : 3,
          px: {
            sm: 3,
            xs: 2,
          },
          top: {
            sm: 0,
          },
          willChange: 'transform',
          ...props.PaperProps?.sx,
        },
        variant: 'elevation',
      }}
      disableScrollLock={props.disableScrollLock}
      componentsProps={{
        backdrop: {
          style: {
            backgroundColor: 'rgba(0,0,0,0.8)',
          },
        },
      }}
      disableRestoreFocus
      onClose={onClose}
    >
      <Stack
        {...ContentStackProps}
        ref={(el) => {
          if (disableFullHeight && el && el.parentElement) {
            el.parentElement.style.maxHeight = `${el?.clientHeight}px`
          }
        }}
        sx={{
          ...ContentStackProps?.sx,
          gap: 3,
          minHeight: {
            xs: props.PaperProps?.sx['minHeight'],
          },
        }}
      >
        {title && (
          <Stack
            ref={header}
            direction="row"
            sx={{
              alignItems: 'center',
              backdropFilter: 'blur(5px) grayscale(.5)',
              bgcolor: alpha(palette.background.paper, 0.9),
              justifyContent: 'space-between',
              minHeight: 56,
              mx: {
                sm: -3,
                xs: -2,
              },
              p: 3,
              pb: 1,
              position: 'sticky',
              pt: 1,
              top: 0,
              zIndex: 1,
            }}
          >
            <Typography variant="h4">{title}</Typography>
            {dismiss && (
              <IconButton
                onClick={(e) => onClose(e, 'backdropClick')}
                sx={{ mx: -1.75 }}
              >
                <CloseRoundedIcon />
              </IconButton>
            )}
          </Stack>
        )}
        {props.children}
        {actions && (
          <Stack
            ref={footer}
            sx={{
              '@media all and (display-mode: standalone)': {
                pb: 6,
              },
              backdropFilter: 'blur(5px) grayscale(.5)',
              bgcolor: alpha(palette.background.paper, 0.9),
              bottom: 0,
              flexGrow: 1,
              justifyContent: 'flex-end',
              mx: {
                md: -3,
                xs: -2,
              },
              position: 'sticky',
              px: {
                md: 3,
                xs: 2,
              },
              py: 2,
            }}
          >
            {actions}
          </Stack>
        )}
      </Stack>
    </Drawer>
  )
}

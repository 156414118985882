import { useRouter } from 'next/router'
import { useEffect, useMemo, useRef, useState } from 'react'
import SettingsStore from 'stores/SettingsStore'

export enum PatientTestCase {
  'BRCA2' = 'brca2',
  'BRCA2 (male)' = 'brca2-male',
  'Cancer' = 'cancer',
  'Cardio' = 'cardio',
  'BRCA1' = 'brca1',
  'MUTYH' = 'mutyh',
}

export default function usePatientTestCase() {
  const router = useRouter()
  const [patientTestCasesOpen, setPatientTestCasesOpen] =
    useState(false)
  const [patientTestCase, setPatientTestCase] =
    useState<PatientTestCase>(null)
  const storeTimeout = useRef(-1)
  const overridePatientTestCase = useMemo<PatientTestCase>(() => {
    const isValid = Object.values(PatientTestCase).includes(
      router.query.overridePatientTestCase as PatientTestCase,
    )
    return isValid
      ? (router.query.overridePatientTestCase as PatientTestCase)
      : null
  }, [router.query.overridePatientTestCase])
  const result = useMemo(
    () => ({
      patientTestCase,
      patientTestCasesOpen,
      setPatientTestCase,
      setPatientTestCasesOpen,
    }),
    [
      patientTestCase,
      setPatientTestCase,
      patientTestCasesOpen,
      setPatientTestCasesOpen,
    ],
  )

  useEffect(() => {
    setPatientTestCasesOpen(
      SettingsStore.get('settings.patientTestCasesOpen'),
    )
  }, [])

  useEffect(() => {
    SettingsStore.set(
      'settings.patientTestCasesOpen',
      !!patientTestCasesOpen,
    )
  }, [patientTestCasesOpen])

  useEffect(() => {
    window.clearTimeout(storeTimeout.current)
    storeTimeout.current = window.setTimeout(() => {
      setPatientTestCase(
        overridePatientTestCase ??
          SettingsStore.get('settings.patientTestCase') ??
          PatientTestCase.BRCA2,
      )
    }, 100)
  }, [overridePatientTestCase])

  return result
}

import { lowerCase, startCase } from 'lodash'
import {
  FrequencyValue,
  getFrequencyLabel,
} from 'components/FrequencyField'
import { getDueLabel } from 'utils/CareTemplateUtil'
import {
  MedicationValue,
  getMedicationLabel,
} from 'components/MedicationField'
import { DurationValue } from 'components/DurationField'
import {
  PatientAppCareStep,
  StepCategory,
  StepType,
} from '__generated__/graphql'

const getLabelFromValue = (value: string) =>
  startCase(lowerCase(value))

// Determine if frequency field should be shown for a step.
export function isFrequencyVisible(stepType: StepType) {
  return [StepType.LIFESTYLE, StepType.SURVEILLANCE].includes(
    stepType,
  )
}

// Determine if due field should be shown for a step.
export function isDueVisible(stepType: StepType) {
  return stepType === StepType.CONSULT
}

// Determine if medication field should be shown for a step.
export function isMedicationVisible(stepType: StepType) {
  return stepType === StepType.MEDICATION
}

function getLabelForModifiers(
  stepType: StepType,
  data: Record<string, unknown>,
) {
  if (!data) {
    return null
  }
  const label = Object.keys(data)
    .filter((key) => key !== 'priority')
    .map((key) => {
      switch (key) {
        case 'frequency':
          return (
            isFrequencyVisible(stepType) &&
            getFrequencyLabel(data[key] as FrequencyValue)
          )
        case 'due':
          return (
            isDueVisible(stepType) &&
            getDueLabel(data[key] as DurationValue)
          )
        case 'medication':
          return (
            isMedicationVisible(stepType) &&
            getMedicationLabel(data[key] as MedicationValue)
          )
      }
      return ''
    })
    .filter(Boolean)
    .join(', ')
  return label
}

export const stepTypeOptions = (
  Object.keys(StepType) as Array<keyof typeof StepType>
).map((key) => ({
  label: getLabelFromValue(key),
  value: StepType[key],
}))

export const stepCategoryOptions = (
  Object.keys(StepCategory) as Array<keyof typeof StepCategory>
).map((key) => ({
  label: getLabelFromValue(key),
  value: StepCategory[key],
}))

export default class CareStepModel extends PatientAppCareStep {
  constructor(data: PatientAppCareStep) {
    super()
    Object.assign(this, data)
  }

  get modifiersLabel() {
    return getLabelForModifiers(this.type, this.data)
  }
}

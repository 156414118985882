import { useRouter } from 'next/router'
import { useEffect } from 'react'

export type UseUrlParamsProps = {
  onHashChange: () => void
  disabled?: boolean
}

export default function useHashParams(props: UseUrlParamsProps) {
  const router = useRouter()
  const { onHashChange, disabled } = props

  useEffect(() => {
    const removeHandlers = () => {
      router.events.off('routeChangeComplete', onHashChange)
      router.events.off('hashChangeComplete', onHashChange)
      window.removeEventListener('hashchange', onHashChange)
    }

    // Toggle hash change handlers.
    if (!disabled) {
      // Both route and hash changes can update the url params.
      router.events.on('routeChangeComplete', onHashChange)
      router.events.on('hashChangeComplete', onHashChange)
      window.addEventListener('hashchange', onHashChange)

      // Trigger hash change on initial mount.
      onHashChange()
    } else {
      removeHandlers()
    }

    return removeHandlers
  }, [router.events, onHashChange, disabled])
}

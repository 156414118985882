import { Currency } from '__shared__/constants'
import { StoryData } from 'components/StoryPlayer'
import { BaseModuleConfig } from 'components/StoryPlayer/Modules'
import moduleStory from 'components/StoryPlayer/Modules/Order/module.story.json'
import { InterpolationData } from 'hooks/useInterpolate'
import { interpolateData } from 'utils/ObjectUtil'

export type OrderConfig = BaseModuleConfig & {
  type: typeof OrderModule.type
  labId: string
  contactPhoneNumber: string
  orderPaymentMethod:
    | 'patient-pay'
    | 'institution-pay'
    | 'insurance-billing'
    | '{{accountStorySettings.orderPaymentMethod}}'
  patientPayArticleLinkUrl: string
  patientPayArticleLinkLabel?: string
  currency: Currency | '{{account.settings.fieldFormatting.currency}}'
  disablePayment: boolean
  consentId: string
}

// Pages to support different ending cases in the ordering flow.
export const OrderModulePageId = {
  consentAndPayment: '099408eb',
  consentOnly: 'f7ed9fb1',
  patientAddressCollection: '939b1a51',
  questionnaireFinished: '6590d6d0',
}

export class OrderModule {
  static type = 'Order' as const
  static getStory(
    config: OrderConfig,
    interpolationData?: InterpolationData,
  ) {
    try {
      config = JSON.parse(
        interpolateData(JSON.stringify(config), interpolationData),
      )
    } catch (e) {
      console.error('Error parsing OrderModule config', e)
    }
    // Dynamically set 'consentPageId' for consent vs consent & payment flows.
    // Payment flow is only for 'patient-pay' and when payment is not disabled.
    const consentPageId =
      config['disablePayment'] ||
      config['orderPaymentMethod'] !== 'patient-pay'
        ? OrderModulePageId.consentOnly
        : OrderModulePageId.consentAndPayment

    // Interpolate variables in module story.
    const data = {
      ...config,
      OrderModulePageId,
      consentPageId,
      returnToPageId: OrderModulePageId.patientAddressCollection,
    }

    try {
      const storyData = JSON.parse(
        // Interpolate with story player interpolation data.
        interpolateData(
          // Interpolate with config data first.
          interpolateData(JSON.stringify(moduleStory), data),
          interpolationData ?? {},
        ),
      ) as StoryData

      // Set page connections for end pages.
      storyData.pages.forEach((page) => {
        if (!page.defaultConnectionPageId && !page.connectionRules) {
          page.defaultConnectionPageId =
            config.defaultConnectionPageId
          page.connectionRules = config.connectionRules
        }
      })
      return storyData
    } catch (e) {
      console.error('Error parsing OrderModule story', e)
    }
  }
  // Orderable test ids from the ordering flow.
  static orderableTestIds: string[] = []
}

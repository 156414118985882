import { StoryData } from 'components/StoryPlayer'
import { isArray, isString, nth, sortBy } from 'lodash'
import { getCurrentIsoDate } from '__shared__/utils/DateUtil'
import StoryProgressStore from 'stores/StoryProgressStore'
import mitt from 'mitt'

export type ProgressEvents = {
  complete: {
    storyId: string
    storyProgress: ProgressData
  }
}

type ProgressData = {
  dateCreated: string
  dateModified: string
  activeIndex: number
  pageCount: number
  completed: boolean
  history: string[] // Records list of story page ids the user has visited. Used for back button.
}

export type StoryProgressState = {
  dateCreated: string
  dateModified: string
  progress: {
    [storyId: string]: ProgressData
  }
}

export default class StoryProgressModel {
  static persistTimeout = -1

  state: StoryProgressState = {
    dateCreated: getCurrentIsoDate(),
    dateModified: getCurrentIsoDate(),
    progress: {},
  }
  emitter = mitt<ProgressEvents>()
  on = this.emitter.on
  off = this.emitter.off

  constructor(state?: StoryProgressState) {
    this.initialize(state)
  }

  initialize(state?: StoryProgressState) {
    this.state.dateCreated = state?.dateCreated ?? getCurrentIsoDate()
    this.state.dateModified =
      state?.dateModified ?? getCurrentIsoDate()
    this.state.progress = state?.progress ?? {}
  }

  isCompleted(storyId: string) {
    const storyProgress = this.state.progress?.[storyId]
    return !!storyProgress?.completed
  }

  getCompletedCount(storyIds: string[]) {
    return storyIds.reduce((acc, id) => {
      if (this.isCompleted(id)) {
        acc = acc + 1
      }
      return acc
    }, 0)
  }

  getStoryCompletedRate(storyId: string): number {
    const storyProgress = this.state.progress?.[storyId]
    let rate = 0

    if (storyProgress) {
      if (storyProgress.completed) {
        rate = 1
      } else {
        rate =
          (storyProgress.activeIndex + 1) / storyProgress.pageCount
      }
    }
    return rate
  }

  getCompletedRate(storyId: string | string[]): number {
    if (isString(storyId)) {
      return this.getStoryCompletedRate(storyId)
    }
    if (isArray(storyId)) {
      return (
        storyId.reduce((acc, id) => {
          return acc + this.getStoryCompletedRate(id)
        }, 0) / storyId.length
      )
    }

    return 0
  }

  shouldResetPageIndex(storyId: string): boolean {
    const storyProgress = this.state.progress?.[storyId]
    // Default to true if no progress is available.
    if (!storyProgress) {
      return true
    }
    const { activeIndex, completed, pageCount } = storyProgress
    const isLastPage = activeIndex + 1 >= pageCount

    // Reset page when completed and on last page.
    return completed && isLastPage
  }

  getActivePageIndex(storyId: string) {
    if (this.shouldResetPageIndex(storyId)) {
      return -1
    }
    return this.state.progress?.[storyId]?.activeIndex ?? -1
  }

  // Label used to display page status (e.g. "2/3").
  getPageStatus(storyId: string, pageCount?: number): string {
    const storyProgress = this.state.progress?.[storyId]
    pageCount ??= storyProgress?.pageCount ?? 0
    let num = 0

    if (storyProgress) {
      if (storyProgress.completed) {
        num = pageCount
      } else {
        num = Math.max(storyProgress.activeIndex, 0)
      }
    }
    return `${num}/${pageCount}`
  }

  getProgress(storyId: string) {
    const currentDate = getCurrentIsoDate()
    this.state.progress[storyId] ??= {
      activeIndex: -1,
      completed: false,
      dateCreated: currentDate,
      dateModified: currentDate,
      history: [],
      pageCount: 0,
    }
    this.state.progress[storyId].history ??= []
    return this.state.progress[storyId]
  }

  getHistory(storyId: string) {
    if (!storyId) {
      return []
    }
    return this.getProgress(storyId).history ?? []
  }

  pushHistory(storyId: string, pageId: string) {
    const storyProgress = this.getProgress(storyId)
    const history = storyProgress.history ?? []

    if (!history.length || nth(history, -1) !== pageId) {
      history.push(pageId)
      this.setHistory(storyId, history)
      this.save()
    }
    return history
  }

  popHistory(storyId: string) {
    const storyProgress = this.getProgress(storyId)
    const history = storyProgress.history ?? []

    history.pop()
    this.setHistory(storyId, history)
    this.save()
    return history
  }

  updateHistory(storyId: string, pageId: string) {
    const storyProgress = this.getProgress(storyId)
    const history = storyProgress.history ?? []
    const startIndex = history.indexOf(pageId)
    const resumedHistory = history.slice(0, startIndex + 1)
    this.setHistory(storyId, resumedHistory)
    this.save()
    return resumedHistory
  }

  setHistory(storyId: string, history: string[]) {
    const storyProgress = this.getProgress(storyId)
    storyProgress.history = history
    this.save()
    return storyProgress.history
  }

  clearHistory(storyId: string) {
    const storyProgress = this.getProgress(storyId)
    storyProgress.history = []
    this.save()
    return storyProgress.history
  }

  setCompleted(storyId: string) {
    const storyProgress = this.state.progress?.[storyId]

    if (storyProgress) {
      storyProgress.completed = true
      this.save()
      this.emitter.emit('complete', { storyId, storyProgress })
    }
  }

  getNextUpStory(stories: StoryData[], contentAccountId?: string) {
    return sortBy(
      stories
        .filter(
          (story) =>
            !contentAccountId ||
            story.contentAccountId === contentAccountId,
        )
        .filter((story) => !this.state.progress[story.id]?.completed),
      ['dateModified'],
    )[0]
  }

  private getSequentialStories(
    stories: StoryData[],
    contentAccountId: string,
  ) {
    return sortBy(
      stories.filter(
        (story) => story.contentAccountId === contentAccountId,
      ),
      ['dateModified'],
    )
  }

  getNextSequentialStory(
    stories: StoryData[],
    contentAccountId: string,
    currentStoryId: string,
  ) {
    const sortedStories = this.getSequentialStories(
      stories,
      contentAccountId,
    )
    const currentIndex = sortedStories.findIndex(
      (story) => story.id === currentStoryId,
    )

    return sortedStories[currentIndex + 1]
  }

  resetProgress() {
    this.initialize()
    return StoryProgressStore.reset()
  }

  save() {
    this.state = { ...this.state }
    window.clearTimeout(StoryProgressModel.persistTimeout)
    StoryProgressModel.persistTimeout = window.setTimeout(() => {
      StoryProgressStore.set(this.state)
    }, 2000)
  }
}

import { Box, BoxProps } from '@mui/material'
import React from 'react'

export type AnimatedSuccessCheckmarkProps = {
  sx?: BoxProps['sx']
}

export default function AnimatedSuccessCheckmark(
  props: AnimatedSuccessCheckmarkProps,
) {
  return (
    <Box
      component="svg"
      id="eomDjlHVAnG1"
      viewBox="0 0 400 400"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      sx={{
        color: '#2bd994',
        height: 80,
        width: 80,
        ...props.sx,
      }}
    >
      <Box
        component="g"
        sx={{
          '#eomDjlHVAnG4_ts': {
            animation:
              'eomDjlHVAnG4_ts__ts 1130ms linear 1 normal forwards',
          },
          '#eomDjlHVAnG6_ts': {
            animation:
              'eomDjlHVAnG6_ts__ts 1130ms linear 1 normal forwards',
          },
          '#eomDjlHVAnG8_ts': {
            animation:
              'eomDjlHVAnG8_ts__ts 1130ms linear 1 normal forwards',
          },
          '#eomDjlHVAnG9': {
            animation:
              'eomDjlHVAnG9_s_do 1130ms linear 1 normal forwards',
          },
          '@keyframes checkAnimation': {
            '0%': {
              transform: 'translate(199px,204.140015px) scale(1,1)',
            },
            '100%': {
              transform: 'translate(199px,204.140015px) scale(1,1)',
            },
            '58.40708%': {
              animationTimingFunction: 'cubic-bezier(0,0,0.58,1)',
              transform: 'translate(199px,204.140015px) scale(1,1)',
            },
            '76.99115%': {
              animationTimingFunction:
                'cubic-bezier(0.005,0.615,0.69,0.995)',
              transform:
                'translate(199px,204.140015px) scale(1.1,1.1)',
            },
          },
          '@keyframes eomDjlHVAnG4_ts__ts': {
            '0%': {
              animationTimingFunction: 'cubic-bezier(0.25,1,0.25,1)',
              transform: 'translate(256px,257.86px) scale(0,0)',
            },
            '100%': {
              transform: 'translate(256px,257.86px) scale(1.5,1.5)',
            },
            '88.495575%': {
              transform: 'translate(256px,257.86px) scale(1.5,1.5)',
            },
          },
          '@keyframes eomDjlHVAnG6_ts__ts': {
            '0%': {
              transform: 'translate(256px,257.86px) scale(0,0)',
            },
            '100%': {
              transform: 'translate(256px,257.86px) scale(1.5,1.5)',
            },
            '88.495575%': {
              transform: 'translate(256px,257.86px) scale(1.5,1.5)',
            },
            '9.734513%': {
              animationTimingFunction: 'cubic-bezier(0.25,1,0.25,1)',
              transform: 'translate(256px,257.86px) scale(0,0)',
            },
          },
          '@keyframes eomDjlHVAnG8_ts__ts': {
            '0%': {
              transform: 'translate(173.5px,260.681714px) scale(0,0)',
            },
            '100%': {
              transform: 'translate(173.5px,260.681714px) scale(1,1)',
            },
            '43.362832%': {
              animationTimingFunction: 'cubic-bezier(0.25,1,0.25,1)',
              transform: 'translate(173.5px,260.681714px) scale(0,0)',
            },
            '51.327434%': {
              transform: 'translate(173.5px,260.681714px) scale(1,1)',
            },
          },
          '@keyframes eomDjlHVAnG9_s_do': {
            '0%': {
              strokeDashoffset: 221.7,
            },
            '100%': {
              strokeDashoffset: 0,
            },
            '49.557522%': {
              animationTimingFunction: 'cubic-bezier(0.42,0,0.58,1)',
              strokeDashoffset: 221.7,
            },
            '74.336283%': {
              strokeDashoffset: 0,
            },
          },
          animation: 'checkAnimation 1130ms linear 1 normal forwards',
        }}
        transform="translate(199,204.140015) scale(1,1)"
      >
        <g
          transform="translate(-255,-262)"
          clipPath="url(#eomDjlHVAnG10)"
        >
          <g>
            <g
              id="eomDjlHVAnG4_ts"
              transform="translate(256,257.86) scale(0,0)"
            >
              <g transform="translate(0,0)">
                <path
                  d="M0,-118c65.124199,0,118,52.875801,118,118s-52.875801,118-118,118-118-52.875801-118-118s52.875801-118,118-118Z"
                  fill="currentColor"
                  fillOpacity="0.16"
                />
              </g>
            </g>
            <g
              id="eomDjlHVAnG6_ts"
              transform="translate(256,257.86) scale(0,0)"
            >
              <g transform="translate(0,0)">
                <path
                  d="M0,-118c65.124199,0,118,52.875801,118,118s-52.875801,118-118,118-118-52.875801-118-118s52.875801-118,118-118Z"
                  fill="currentColor"
                />
              </g>
            </g>
            <g
              id="eomDjlHVAnG8_ts"
              transform="translate(173.5,260.681714) scale(0,0)"
            >
              <g transform="translate(82.5,-4.681714)">
                <path
                  id="eomDjlHVAnG9"
                  d="M-82.5,4.5C-82.5,4.5,-31,55,-31,55s104-107.5,104-107.5"
                  fillOpacity="0"
                  stroke="#fff"
                  strokeWidth="30"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeDashoffset="221.7"
                  strokeDasharray="221.701918"
                />
              </g>
            </g>
          </g>
          <clipPath id="eomDjlHVAnG10">
            <rect
              width="400"
              height="400"
              rx="0"
              ry="0"
              transform="translate(56 57.859985)"
            />
          </clipPath>
        </g>
      </Box>
    </Box>
  )
}

import React, { useRef, useState } from 'react'
import { Box, Fade } from '@mui/material'
import { omit } from 'lodash'
import { BaseBlockData } from 'components/StoryPlayer/PageBlock'

export type ObjectBlockData = BaseBlockData & {
  type: 'object'
  data: string
  width?: string | number
  height?: string | number
  fullBleed?: boolean
}

export type ObjectBlockProps = Omit<ObjectBlockData, 'type'>

export default function ObjectBlock(props: ObjectBlockProps) {
  const { disableMotion, fullBleed, ...boxProps } = props
  const imageRef = useRef<HTMLObjectElement>(null)
  const [timeout, setTimeout] = useState(0)
  const startTime = useRef(new Date().getTime())
  const [loaded, setLoaded] = useState(false)
  return (
    <Fade in={loaded} timeout={timeout}>
      <Box
        {...omit(boxProps, 'type')}
        className={[
          'ObjectBlock',
          props.className,
          fullBleed && 'fullBleed',
        ]
          .filter(Boolean)
          .join(' ')}
        ref={(el: HTMLObjectElement) => {
          // Set data attribute to trigger render.
          if (el && !el.getAttribute('data')) {
            el.setAttribute('data', props.data)
          }
          if (el && props.width && props.height) {
            el.setAttribute('width', String(props.width))
            el.setAttribute('height', String(props.height))
            imageRef.current = el
          }
        }}
        component="object"
        onLoad={() => {
          const loadTime = new Date().getTime() - startTime.current
          setTimeout(!disableMotion && loadTime > 100 ? 300 : 0)
          setLoaded(true)
        }}
        sx={{
          colorScheme: 'none', // Prevent svg from having opaque background.
          display: 'block',
          height: props.height,
          maxHeight: 'min(100%, 30vh, 300px)',
          maxWidth: 1,
          mx: 'auto',
          objectFit: 'contain',
          width: props.width,
          ...props.sx,
        }}
      />
    </Fade>
  )
}

import React, { useState } from 'react'
import Script from 'next/script'
import { useAuth } from '@frontegg/react'
import { pickBy } from 'lodash'
import { Environment } from 'types/Global'
import { useEffectOnceWhen, useIntervalWhen } from 'rooks'
import { usePendoDataLazyQuery } from '__generated__/graphql'

type PendoPayload = {
  account: Record<string, unknown>
  visitor: Record<string, unknown>
}

declare global {
  interface Window {
    pendo: {
      initialize(data: PendoPayload): void
    }
  }
}

export default function PendoScript() {
  const { isAuthenticated } = useAuth()
  const enabled = [
    Environment.DEVELOPMENT,
    Environment.STAGING,
  ].includes(
    process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT as Environment,
  )
  const [load, { called, data }] = usePendoDataLazyQuery()
  const pendoData = data?.pendoData
  const [available, setAvailable] = useState(false)

  useIntervalWhen(
    () => {
      if (!called) {
        load()
      }
      // Skip initialization until global Pendo is available
      // on next interval cycle.
      if (window.pendo) {
        setAvailable(true)
      }
    },
    1000,
    enabled && isAuthenticated && !available,
    true,
  )

  useEffectOnceWhen(
    () => {
      const pendoPayload: PendoPayload = {
        account: pendoData.account,
        visitor: pickBy(pendoData.visitor, (value) => value !== null),
      }

      window.pendo.initialize(pendoPayload)
    },
    enabled && isAuthenticated && available && !!pendoData,
  )

  if (!enabled) {
    return null
  }

  return (
    <Script
      id="pendo-js"
      dangerouslySetInnerHTML={{
        __html: `(function(apiKey){
    (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
    v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
        o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
        y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
        z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
})('2a6ef330-721b-4799-4bdf-2760562faa61');`,
      }}
    />
  )
}

import { TodosQuery } from '__generated__/graphql'
import { StoryPageData } from 'components/StoryPlayer/StoryPage'
import { PageTransformer } from 'components/StoryPlayer/pageTransformer'
import { capitalize } from 'lodash'
import { generateId } from 'utils/SeedUtil'

const exampleReviewTodos: PageTransformer = ({
  returnToPageId,
  data,
}): StoryPageData[] => {
  const seedRoot = 'exampleReviewTodos'
  const todos = (data as TodosQuery)?.patientApp?.todos ?? []

  if (!todos.length) {
    return [
      {
        blocks: [
          {
            content: `### You don't have any todos.`,
            type: 'text',
          },
        ],
        defaultConnectionPageId: returnToPageId,
        id: generateId(seedRoot + 'empty'),
        overrideHeaderTitle: 'Orderable Tests',
        theme: {
          backgroundColor: '#fffaf1',
          color: '#e9a907',
          secondaryColor: 'rgba(39, 33, 38, 0.5)',
          variant: 'random',
        },
        type: 'custom',
      },
    ]
  }

  return todos.map((todo, index) => {
    const nextTodo = todos[index + 1]
    const nextTodoId = nextTodo
      ? generateId(seedRoot + nextTodo.id)
      : returnToPageId

    return {
      blocks: [
        {
          content: `Todo ${index + 1}/${todos.length}`,
          type: 'text',
          variant: 'h5',
        },
        {
          content: `### ${todo.title}\n${todo.description}`,
          type: 'text',
        },
        {
          content: `${capitalize(todo.status)}`,
          sx: {
            bgcolor:
              todo.status === 'COMPLETE' ? '#7f9b2e' : 'text.primary',
            borderRadius: 4,
            color: '#fff',
            display: 'inline-block',
            fontWeight: '500',
            justifySelf: 'flex-start',
            px: 1.5,
            py: 0.5,
            width: 'auto !important',
          },
          type: 'text',
        },
      ],
      defaultConnectionPageId: nextTodoId,
      id: generateId(seedRoot + todo.id),
      overrideHeaderTitle: 'Review Todos',
      theme: {
        backgroundColor: '#fffaf1',
        color: '#e9a907',
        secondaryColor: 'rgba(39, 33, 38, 0.5)',
        variant: 'random',
      },
      type: 'custom',
    }
  })
}

export default exampleReviewTodos

import { PatientTestCase } from 'hooks/usePatientTestCase'
import LocalStorageUtil from 'utils/LocalStorageUtil'

type SettingsStoreState = {
  'settings.patientTestCase': PatientTestCase
  'settings.patientTestCasesOpen': boolean
  'settings.onboardingCompleted': boolean
  'settings.onboardingStoryId': string
  'settings.guidedToursCompleted': {
    [key: string]: boolean
  }
}

export default class SettingsStore {
  static get<T extends keyof SettingsStoreState>(key: T) {
    const value: SettingsStoreState[T] = LocalStorageUtil.getItem(key)
    SettingsStore.log('get', key, value)
    return value
  }

  static set<T extends keyof SettingsStoreState>(
    key: T,
    value: SettingsStoreState[T],
  ) {
    SettingsStore.log('set', key, value)
    LocalStorageUtil.setItem(key, value)
  }

  static reset() {
    SettingsStore.log('reset')
    const keys: (keyof SettingsStoreState)[] = [
      'settings.patientTestCase',
      'settings.patientTestCasesOpen',
      'settings.onboardingCompleted',
      'settings.onboardingStoryId',
      'settings.guidedToursCompleted',
    ]
    // Remove all related local storage items.
    keys.forEach((key) => LocalStorageUtil.removeItem(key))
  }

  static log(...rest) {
    if (localStorage.getItem('debugEnabled')) {
      console.log(
        '%cSettingsStore',
        'border-radius:4px; padding:0 4px; color:white; background-color:#2480ec;',
        ...rest,
      )
    }
  }
}

import {
  useOrderableTestDisqualifyingQuestionsSchemasLazyQuery,
  useOrderableTestIntakeQuestionsLazyQuery,
  useOrderableTestOptionsLazyQuery,
  useTodosLazyQuery,
} from '__generated__/graphql'

export type DynamicChapterQueryName = keyof ReturnType<
  typeof useDynamicChapterQuery
>
export type DynamicChapterQueryConfig = {
  [QueryName in DynamicChapterQueryName]: {
    name: QueryName
    variables: Parameters<
      ReturnType<typeof useDynamicChapterQuery>[QueryName]
    >[0]['variables']
  }
}[DynamicChapterQueryName]

export function useDynamicChapterQuery() {
  // Add lazy queries that are needed for dynamic chapters/page transformers.
  const [OrderableTestDisqualifyingQuestionsSchemas] =
    useOrderableTestDisqualifyingQuestionsSchemasLazyQuery()
  const [OrderableTestIntakeQuestions] =
    useOrderableTestIntakeQuestionsLazyQuery()
  const [OrderableTestOptions] = useOrderableTestOptionsLazyQuery()
  const [Todos] = useTodosLazyQuery()
  return {
    OrderableTestDisqualifyingQuestionsSchemas,
    OrderableTestIntakeQuestions,
    OrderableTestOptions,
    Todos,
  }
}

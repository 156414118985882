import { InputAdornment, InputLabel, Stack } from '@mui/material'
import React, { ReactNode, Ref, useState } from 'react'
import TextField from './TextField'

export enum DosageUnit {
  'mg/kg/day' = 'mg/kg/day',
  'mg/day' = 'mg/day',
}

export type DosageValue = {
  amount: number
  unit: DosageUnit
}

function getDosageLabel(value: DosageValue) {
  return `${value.amount} ${value.unit}`
}

export type MedicationValue = {
  label: string
  dose?: DosageValue
}

export function getMedicationLabel(value: MedicationValue) {
  if (!value?.label) {
    return ''
  }

  if (!value?.dose?.amount) {
    return value.label
  }

  return `${value.label}, ${getDosageLabel(value.dose)}`
}

type MedicationFieldProps = {
  label: ReactNode
  name: string
  value: MedicationValue
  readOnly?: boolean
  disabled?: boolean
  error?: boolean
  onChange: (e: { name: string; value: MedicationValue }) => void
  onBlur?: () => void
}

const unitOptions = Object.values(DosageUnit).map((key) => ({
  label: key,
  value: key,
}))

const MedicationField = React.forwardRef(function MedicationField(
  props: MedicationFieldProps,
  ref: Ref<HTMLDivElement>,
) {
  const [value, setValue] = useState<MedicationValue>({
    dose: {
      amount: props.value?.dose?.amount,
      unit: props.value?.dose?.unit ?? DosageUnit['mg/kg/day'],
    },
    label: props.value?.label ?? '',
  })
  const { name, label, onBlur, onChange, readOnly, disabled, error } =
    props

  return (
    <Stack ref={ref}>
      <InputLabel disabled={disabled}>{label}</InputLabel>
      {readOnly ? (
        <TextField
          value={getMedicationLabel(value) || 'N/A'}
          asDisplay
        />
      ) : (
        <Stack direction="row" gap={1}>
          <TextField
            label=""
            placeholder="Name"
            value={value.label ?? ''}
            disabled={disabled}
            onChange={({ target }) => {
              const result: MedicationValue = {
                ...value,
                label: target.value,
              }
              setValue(result)
              onChange({ name, value: result })
            }}
            onBlur={onBlur}
            error={error}
            fullWidth
          />
          <TextField
            type="number"
            placeholder="0"
            value={value.dose?.amount ?? ''}
            disabled={disabled}
            onChange={({ target }) => {
              const result: MedicationValue = {
                ...value,
                dose: {
                  ...value.dose,
                  amount: target.value
                    ? parseInt(target.value)
                    : undefined,
                },
              }
              setValue(result)
              onChange({ name, value: result })
            }}
            onBlur={onBlur}
            inputProps={{ min: 1 }}
            sx={{ maxWidth: 200, minWidth: 200 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <TextField
                    variant="standard"
                    select
                    options={unitOptions}
                    value={
                      value.dose?.unit ?? DosageUnit['mg/kg/day']
                    }
                    disabled={disabled}
                    onChange={({ target }) => {
                      const result: MedicationValue = {
                        ...value,
                        dose: {
                          ...value?.dose,
                          unit: target.value as DosageUnit,
                        },
                      }
                      setValue(result)
                      onChange({ name, value: result })
                    }}
                    onBlur={onBlur}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </InputAdornment>
              ),
            }}
            error={error}
          />
        </Stack>
      )}
    </Stack>
  )
})

export default MedicationField

import { getStoryParamsFromPath } from 'components/StoryPlayer'
import { useCallback, useMemo, useState } from 'react'
import useHashParams from 'hooks/useHashParams'

export default function useStoryHashParams({
  disabled,
}: {
  disabled?: boolean
}) {
  const [pageId, setPageId] = useState('')
  const [storyId, setStoryId] = useState('')
  const result = useMemo(
    () => ({ pageId, storyId }),
    [pageId, storyId],
  )
  const onHashChange = useCallback(() => {
    const params = getStoryParamsFromPath(window.location.hash)
    setPageId(params.pageId)
    setStoryId(params.storyId)
  }, [])

  useHashParams({ disabled, onHashChange })

  return result
}

import AnimatedSuccessCheckmark from 'components/AnimatedSuccessCheckmark'
import MarkdownContent from 'components/MarkdownContent'
import React, { useContext } from 'react'
import { Stack, Typography, darken } from '@mui/material'
import {
  BasePageProps,
  PageData,
} from 'components/StoryPlayer/StoryPage'
import {
  MotionElement,
  MotionTrail,
} from 'components/MotionComponents'
import StoryFeedback from 'components/StoryFeedback'
import { StoryPlayerContext } from 'components/StoryPlayerContext'
import ButtonLinkBlock, {
  ButtonLinkBlockProps,
} from 'components/blocks/ButtonLinkBlock'
import useInterpolate from 'hooks/useInterpolate'
import useKiosk from 'hooks/useKiosk'
import SvgBlock, { SvgBlockProps } from 'components/blocks/SvgBlock'

export interface PageStoryCompleteData extends PageData {
  type: 'story-complete'
  menuItems?: StoryCompleteMenuItem[]
  disableImage?: boolean
  disableFeedback?: boolean
  disableRestartButton?: boolean
  title?: string
  subtitle?: string
  image?: SvgBlockProps
  submitLabel?: never
  submitLabelOnEmpty?: never
  enableClose?: never
}

export type StoryCompleteMenuItem = {
  href: string
  label: string
  target?: '_blank' | '_self'
}

export type PageStoryCompleteProps = Pick<
  BasePageProps,
  'disableMotion' | 'sx'
> & {
  menuItems?: PageStoryCompleteData['menuItems']
  disableImage?: PageStoryCompleteData['disableImage']
  disableRestartButton?: PageStoryCompleteData['disableRestartButton']
  disableFeedback?: PageStoryCompleteData['disableFeedback']
  title?: PageStoryCompleteData['title']
  subtitle?: PageStoryCompleteData['subtitle']
  image?: PageStoryCompleteData['image']
}

export default function PageStoryComplete(
  props: PageStoryCompleteProps,
): JSX.Element {
  const {
    disableMotion,
    disableImage,
    disableRestartButton,
    disableFeedback,
    title = `You've finished the story`,
    subtitle = 'Nice work!',
    image,
    sx,
  } = props
  const menuItems: ButtonLinkBlockProps[] = props.menuItems ?? []
  const { storyId, storyProgress } = useContext(StoryPlayerContext)
  const interpolate = useInterpolate(StoryPlayerContext)
  const kiosk = useKiosk()

  return (
    <>
      <MotionElement
        disableMotion={disableMotion}
        sx={{
          alignItems: 'stretch',
          alignSelf: 'stretch',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          justifyContent: 'center',
          px: 3,
          py: 10,
          ...sx,
        }}
      >
        <MotionElement
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            justifyContent: 'center',
          }}
        >
          <Stack
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              textAlign: 'center',
              width: 1,
            }}
          >
            {!disableImage && (
              <>
                {image && (
                  <MotionElement
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <SvgBlock {...image} />
                  </MotionElement>
                )}
                {!image && (
                  <AnimatedSuccessCheckmark
                    sx={{
                      color: 'primary.light',
                      mb: 1,
                    }}
                  />
                )}
              </>
            )}
            <MotionElement sx={{ width: 1 }}>
              <Typography
                key="storyCompleted.title"
                variant="h4"
                className="title"
                sx={{
                  '&.MuiTypography-h4': { fontWeight: 500, my: 0 },
                }}
              >
                <MarkdownContent value={interpolate(title)} />
              </Typography>
              <Typography
                key="story.subtitle"
                variant="h4"
                className="subtitle"
                sx={{
                  '& strong': { fontWeight: 500 },
                  '&.MuiTypography-h4': { my: 0 },
                  color: 'text.secondary',
                }}
              >
                <MarkdownContent value={interpolate(subtitle)} />
              </Typography>
            </MotionElement>
          </Stack>
          <Stack gap={1} sx={{ py: 1, width: 1 }}>
            <MotionTrail
              id="storyCompleted"
              delay={disableMotion ? 0 : 1200}
              disableMotion={disableMotion}
              items={menuItems
                .concat(
                  !disableRestartButton && {
                    href: `#/story/${storyId}`,
                    label: 'Restart Story',
                    onClick: () =>
                      storyProgress.clearHistory(storyId),
                    target: '_self',
                  },
                  kiosk.enabled && {
                    label: 'Back to kiosk',
                    onClick: kiosk.sessionEnd,
                    target: '_self',
                  },
                )
                .filter(Boolean)
                .map((item, index) => (
                  <ButtonLinkBlock
                    key={`buttonLink.${index}`}
                    variant="contained"
                    color="inherit"
                    label={item.label}
                    href={interpolate(item.href)}
                    target={item.target ?? '_self'}
                    onClick={item.onClick}
                    sx={{
                      '&:hover': {
                        bgcolor: ({ palette }) =>
                          darken(palette.grey['300'], 0.05),
                      },
                      bgcolor: 'grey.300',
                      py: 1.5,
                    }}
                  />
                ))
                .concat(<StoryFeedback disabled={disableFeedback} />)}
            />
          </Stack>
        </MotionElement>
      </MotionElement>
    </>
  )
}

import React, { ReactNode } from 'react'
import { ApolloError } from '@apollo/client'
import CustomErrorPage from 'components/boundaries/CustomErrorPage'

export default class ForbiddenErrorBoundary extends React.Component<{
  children: ReactNode
}> {
  state = {
    hasError: false,
    message: 'You have insufficient privileges.',
  }

  static getDerivedStateFromError(error: ApolloError) {
    if (
      error.graphQLErrors?.some(
        (error) => error.extensions?.code === 'FORBIDDEN',
      )
    ) {
      return {
        hasError: true,
        message: error.message,
      }
    }

    throw error // Propagate other errors to the parent boundary
  }

  render() {
    if (this.state.hasError) {
      return (
        <CustomErrorPage
          type="forbidden"
          message={this.state.message ?? ''}
        />
      )
    }
    return this.props.children
  }
}

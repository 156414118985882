import React from 'react'
import useInterpolate from 'hooks/useInterpolate'
import { StoryPlayerContext } from 'components/StoryPlayerContext'
import OutlinedText from 'components/OutlinedText'
import { BaseBlockData } from 'components/StoryPlayer/PageBlock'
import { omit } from 'lodash'
import { useTheme } from '@mui/material'
import { StorySxProps } from 'components/StoryPlayer'
import { TextColor } from 'components/NestMuiTheme'

const fontSizes = {
  large: 48,
  medium: 32,
  small: 24,
}

export type OutlinedTextBlockData = BaseBlockData & {
  type: 'outlined-text'
  color?: string
  strokeColor?: string
  content: string
  className?: string
  fontSize?: 'small' | 'medium' | 'large'
  textAlign?: 'left' | 'center' | 'right'
  sx?: StorySxProps
}

export type OutlinedTextBlockProps = Omit<
  OutlinedTextBlockData,
  'type'
>

export default function OutlinedTextBlock(
  props: OutlinedTextBlockProps,
) {
  const { palette } = useTheme()
  const {
    content,
    color = TextColor.dark.primary,
    strokeColor = palette.secondary.light,
    fontSize = 'medium',
    textAlign = 'center',
    ...outlinedTextProps
  } = props
  const interpolate = useInterpolate(StoryPlayerContext)
  const interpolatedContent = interpolate(content)

  return (
    <OutlinedText
      className={['OutlinedTextBlock', props.className]
        .filter(Boolean)
        .join(' ')}
      {...omit(outlinedTextProps, 'disableMotion')}
      sx={{
        color,
        fontSize: fontSizes[fontSize],
        lineHeight: 1.4,
        textAlign,
        ...outlinedTextProps.sx,
      }}
      color={color}
      content={interpolatedContent}
      strokeColor={strokeColor}
      strokeWidth={fontSizes[fontSize] / 4}
    />
  )
}

import { StoryData } from 'components/StoryPlayer'
import breastCancerScreeningModuleChapter from 'components/StoryPlayer/Modules/HistoryCollection/chapters/breast-cancer-screening.story.json'
import breastCancerScreeningWithMammoModuleChapter from 'components/StoryPlayer/Modules/HistoryCollection/chapters/breast-cancer-screening-with-mammo.story.json'
import pancreaticCancerScreeningModuleChapter from 'components/StoryPlayer/Modules/HistoryCollection/chapters/pancreatic-cancer-screening.story.json'
import colonoscopiesModuleChapter from 'components/StoryPlayer/Modules/HistoryCollection/chapters/colonoscopies.story.json'
import colonoscopiesWithOccurrenceModuleChapter from 'components/StoryPlayer/Modules/HistoryCollection/chapters/colonoscopies-with-occurrence.story.json'
import otherScreeningHistoryModuleChapter from 'components/StoryPlayer/Modules/HistoryCollection/chapters/other-screening-history.story.json'
import familyCancerHistoryModuleChapter from 'components/StoryPlayer/Modules/HistoryCollection/chapters/family-cancer-history.story.json'
import familyCancerHistoryWithoutSizeModuleChapter from 'components/StoryPlayer/Modules/HistoryCollection/chapters/family-cancer-history-without-size.story.json'
import personalGeneticTestingModuleChapter from 'components/StoryPlayer/Modules/HistoryCollection/chapters/personal-genetic-testing.story.json'
import personalCancerHistoryModuleChapter from 'components/StoryPlayer/Modules/HistoryCollection/chapters/personal-cancer-history.story.json'
import personalInformationModuleChapter from 'components/StoryPlayer/Modules/HistoryCollection/chapters/personal-information.story.json'
import personalInformationWithNameDobModuleChapter from 'components/StoryPlayer/Modules/HistoryCollection/chapters/personal-information-with-name-dob.story.json'
import reproductiveHealthHistoryModuleChapter from 'components/StoryPlayer/Modules/HistoryCollection/chapters/reproductive-health-history.story.json'
import breastCancerScreeningModuleChapterEs from 'components/StoryPlayer/Modules/HistoryCollection/chapters/breast-cancer-screening-es.story.json'
import pancreaticCancerScreeningModuleChapterEs from 'components/StoryPlayer/Modules/HistoryCollection/chapters/pancreatic-cancer-screening-es.story.json'
import colonoscopiesModuleChapterEs from 'components/StoryPlayer/Modules/HistoryCollection/chapters/colonoscopies-es.story.json'
import familyCancerHistoryModuleChapterEs from 'components/StoryPlayer/Modules/HistoryCollection/chapters/family-cancer-history-es.story.json'
import personalGeneticTestingModuleChapterEs from 'components/StoryPlayer/Modules/HistoryCollection/chapters/personal-genetic-testing-es.story.json'
import personalCancerHistoryModuleChapterEs from 'components/StoryPlayer/Modules/HistoryCollection/chapters/personal-cancer-history-es.story.json'
import personalInformationModuleChapterEs from 'components/StoryPlayer/Modules/HistoryCollection/chapters/personal-information-es.story.json'
import reproductiveHealthHistoryModuleChapterEs from 'components/StoryPlayer/Modules/HistoryCollection/chapters/reproductive-health-history-es.story.json'
import { ChapterModuleConfig } from 'components/StoryPlayer/Modules/Chapters'
import { getStoryFromChapters } from 'components/StoryPlayer/Modules/Chapters/chaptersUtil'
import { BaseModuleConfig } from 'components/StoryPlayer/Modules'

const HistoryCollectionModuleChapters = [
  breastCancerScreeningModuleChapter,
  breastCancerScreeningWithMammoModuleChapter,
  pancreaticCancerScreeningModuleChapter,
  colonoscopiesModuleChapter,
  colonoscopiesWithOccurrenceModuleChapter,
  otherScreeningHistoryModuleChapter,
  familyCancerHistoryModuleChapter,
  familyCancerHistoryWithoutSizeModuleChapter,
  personalGeneticTestingModuleChapter,
  personalCancerHistoryModuleChapter,
  personalInformationModuleChapter,
  personalInformationWithNameDobModuleChapter,
  reproductiveHealthHistoryModuleChapter,
  breastCancerScreeningModuleChapterEs,
  pancreaticCancerScreeningModuleChapterEs,
  colonoscopiesModuleChapterEs,
  familyCancerHistoryModuleChapterEs,
  personalGeneticTestingModuleChapterEs,
  personalCancerHistoryModuleChapterEs,
  personalInformationModuleChapterEs,
  reproductiveHealthHistoryModuleChapterEs,
] as StoryData[]

export enum HistoryCollectionChapter {
  'breast-cancer-screening' = 'breast-cancer-screening',
  'breast-cancer-screening-with-mammo' = 'breast-cancer-screening-with-mammo',
  'pancreatic-cancer-screening' = 'pancreatic-cancer-screening',
  'colonoscopies' = 'colonoscopies',
  'colonoscopies-with-occurrence' = 'colonoscopies-with-occurrence',
  'other-screening-history' = 'other-screening-history',
  'family-cancer-history' = 'family-cancer-history',
  'family-cancer-history-without-size' = 'family-cancer-history-without-size',
  'personal-genetic-testing' = 'personal-genetic-testing',
  'personal-cancer-history' = 'personal-cancer-history',
  'personal-information' = 'personal-information',
  'personal-information-with-name-dob' = 'personal-information-with-name-dob',
  'reproductive-health-history' = 'reproductive-health-history',
  'breast-cancer-screening-es' = 'breast-cancer-screening-es',
  'pancreatic-cancer-screening-es' = 'pancreatic-cancer-screening-es',
  'colonoscopies-es' = 'colonoscopies-es',
  'family-cancer-history-es' = 'family-cancer-history-es',
  'personal-genetic-testing-es' = 'personal-genetic-testing-es',
  'personal-cancer-history-es' = 'personal-cancer-history-es',
  'personal-information-es' = 'personal-information-es',
  'reproductive-health-history-es' = 'reproductive-health-history-es',
}

export type HistoryCollectionConfig = BaseModuleConfig &
  ChapterModuleConfig<
    typeof HistoryCollectionModule.type,
    keyof typeof HistoryCollectionChapter
  >

export class HistoryCollectionModule {
  static type = 'HistoryCollection' as const

  static getStory(config: HistoryCollectionConfig) {
    const { chapters = [] } = config
    return getStoryFromChapters(
      chapters,
      HistoryCollectionModuleChapters,
    )
  }
}

import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { StoryData } from 'components/StoryPlayer'
import {
  FormField,
  ObjectField,
} from 'components/StoryPlayer/pages/PageForm'
import {
  cloneDeep,
  get,
  isArray,
  isEqual,
  omit,
  set,
  times,
  uniq,
  upperCase,
} from 'lodash'
import {
  QuestionnaireResponseItemInput,
  QuestionnaireResponseItemType,
  QuestionnaireResponseQuery,
} from '__generated__/graphql'
import { StoryPageData } from 'components/StoryPlayer/StoryPage'
import {
  StoryModuleConfig,
  getModuleStory,
} from 'components/StoryPlayer/Modules'
import LocalStorageUtil from 'utils/LocalStorageUtil'
import { StoryPlayerContext } from 'components/StoryPlayerContext'
import { InterpolationData } from 'hooks/useInterpolate'

export function flattenQuestionnaireResponse(
  items: QuestionnaireResponseItemInput[],
) {
  // Flatten questionnaire response items into object.
  return items.reduce(
    (acc, item) => set(acc, item.name, item.answer),
    {},
  )
}

export function getAnswerLabel(
  name: string,
  value: unknown,
  fields: FormField[] = [],
) {
  const getField = (name: string) =>
    fields.find((field) => field.name === name) as FormField
  const field = getField(name)
  const hasOptions =
    field?.type === 'select' ||
    field?.type === 'checkbox' ||
    field?.type === 'button'

  if (hasOptions) {
    const getOptionLabel = (value: unknown): string =>
      (
        (field?.options ?? []) as {
          label: string
          value: unknown
        }[]
      ).find((option) => option.value === value)?.label ??
      String(value)
    const label = isArray(value)
      ? value.map((item) => getOptionLabel(item)).join(', ')
      : getOptionLabel(value)
    return label
  }

  if (field?.type === 'object' || field?.type === 'address') {
    return ''
  }

  return String(value)
}

class LocalQuestionnaireResponse {
  static get items(): QuestionnaireResponseItemInput[] {
    return (
      LocalStorageUtil.getItem(
        'storyPlayer.questionnaireResponseItems',
      ) ?? []
    )
  }

  static setItems(items: QuestionnaireResponseItemInput[]) {
    LocalStorageUtil.setItem(
      'storyPlayer.questionnaireResponseItems',
      items,
    )
  }
}

export function useLocalQuestionnaireResponse() {
  const [questionnaireResponseItems, setQuestionnaireResponseItems] =
    useState<QuestionnaireResponseItemInput[]>(
      LocalQuestionnaireResponse.items,
    )
  const persistQuestionnaireResponseItems = useCallback(
    (items: QuestionnaireResponseItemInput[]) => {
      LocalQuestionnaireResponse.setItems(items)
      setQuestionnaireResponseItems(items)
    },
    [setQuestionnaireResponseItems],
  )
  const fetchQuestionnaireResponse = useCallback<
    () => Promise<{ data: QuestionnaireResponseQuery }>
  >(
    () =>
      Promise.resolve({
        data: {
          patientApp: {
            questionnaireResponse: {
              createdAt: null,
              id: null,
              items: questionnaireResponseItems,
              name: 'temp',
              title: null,
              updatedAt: null,
            },
          },
        } as QuestionnaireResponseQuery,
      }),
    [questionnaireResponseItems],
  )
  const onStoryPlayerSubmit = useCallback(
    (data: Record<string, unknown>, fields: FormField[]) => {
      const getField = (name: string) =>
        fields.find((field) => field.name === name) as FormField
      const items = fields.map(
        ({ name }): QuestionnaireResponseItemInput => {
          const answer = get(data, name)
          const field = getField(name)
          return {
            answer: get(data, name),
            answerLabel: getAnswerLabel(name, answer, fields),
            label: field.label ?? '',
            metadata: {
              fields:
                field.type === 'object' ? field.fields : undefined,
            },
            name: name,
            type: QuestionnaireResponseItemType[
              upperCase(field.type)
            ],
          }
        },
      )
      const result = questionnaireResponseItems
        .filter(
          ({ name }) => !items.map(({ name }) => name).includes(name),
        )
        .concat(items)

      LocalQuestionnaireResponse.setItems(result)
      setQuestionnaireResponseItems(result)
      return result
    },
    [questionnaireResponseItems],
  )
  const result = useMemo(
    () => ({
      fetchQuestionnaireResponse,
      onStoryPlayerSubmit,
      persistQuestionnaireResponseItems,
      questionnaireResponseItems,
    }),
    [
      fetchQuestionnaireResponse,
      onStoryPlayerSubmit,
      persistQuestionnaireResponseItems,
      questionnaireResponseItems,
    ],
  )

  return result
}

interface LongestPathNode {
  id: string
  outgoingIds: string[]
}

interface LongestPathResult {
  length: number
  nodes: string[]
}

function findLongestPath(
  nodes: LongestPathNode[],
  startNodeId?: string,
): LongestPathResult {
  // Create a memoization table to store the longest path for each node.
  const memo: Record<string, number> = {}

  // Define a helper function to recursively find the longest path for a node.
  function getLongestPath(node: LongestPathNode): LongestPathResult {
    if (!node) {
      return { length: 0, nodes: [] }
    }
    // If we have already calculated the longest path for this node, return it.
    if (memo[node.id]) {
      return { length: memo[node.id], nodes: [] }
    }

    // If this node has no outgoing edges, its longest path is 0.
    if (node.outgoingIds.length === 0) {
      memo[node.id] = 0
      return { length: 0, nodes: [node.id] }
    }

    // Otherwise, iterate through the outgoing edges and find the longest path
    // for each node.
    let longestPath: LongestPathResult = {
      length: 0,
      nodes: [],
    }
    for (const outgoingId of node.outgoingIds) {
      const outgoingNode = nodes.find((n) => n.id === outgoingId)
      const path = getLongestPath(outgoingNode)
      const pathLength = path.length + 1
      if (pathLength > longestPath.length) {
        longestPath = {
          length: pathLength,
          nodes: [node.id, ...path.nodes],
        }
      }
    }

    // Store the longest path for this node in the memoization table and return it.
    memo[node.id] = longestPath.length
    return longestPath
  }

  // Find the longest path for each node in the graph.
  let maxPath: LongestPathResult = { length: 0, nodes: [] }

  if (startNodeId) {
    const node = nodes.find((n) => n.id === startNodeId)
    const path = getLongestPath(node)
    if (path.length > maxPath.length) {
      maxPath = path
    }
  } else {
    for (const node of nodes) {
      const path = getLongestPath(node)
      if (path.length > maxPath.length) {
        maxPath = path
      }
    }
  }

  return maxPath
}

export function findLongestPathFromPages(
  pages: StoryPageData[],
  startNodeId?: string,
) {
  if (!pages) {
    return 0
  }
  try {
    return findLongestPath(
      pages.map(
        (page): LongestPathNode => ({
          id: page.id,
          outgoingIds: [
            page.defaultConnectionPageId,
            ...(page.connectionRules?.map(
              (rule) =>
                !rule.event?.params?.excludeFromPath &&
                (rule.event?.params?.pageId ||
                  rule.event?.params?.returnToPageId),
            ) ?? []),
          ].filter(Boolean),
        }),
      ),
      startNodeId,
    ).length
  } catch (e) {
    console.error(e)
    return 0
  }
}

export function useModuleConfigsForStory(story: StoryData) {
  const moduleConfigs = useMemo(
    () =>
      (story?.pages ?? [])
        .reduce<StoryModuleConfig[]>((acc, page) => {
          page.connectionRules?.forEach((rule) => {
            acc.push(rule.event?.params?.module)
          })
          return acc
        }, [])
        .filter(Boolean),
    [story?.pages],
  )
  return moduleConfigs
}

export function useModulePages(
  story: StoryData,
  interpolationData: InterpolationData,
) {
  const moduleConfigs = useModuleConfigsForStory(story)
  const modulePages = useMemo(
    () =>
      updateStoryPagesWithConnectionsToTemplatePages(
        getModulePages(moduleConfigs, interpolationData),
      ),
    // Only recompute module pages when `accountStorySettings` change to avoid
    // unnecessary rerenders than cause the story progress bar to jump.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [interpolationData?.accountStorySettings, moduleConfigs],
  )

  return modulePages
}

const updateStoryPagesWithConnectionsToTemplatePages = (
  pages: StoryPageData[],
) => {
  const clonedPages = cloneDeep(pages)
  const templatePageIds = clonedPages
    .filter((page) => !!page.templateDataKey)
    .map((page) => page.id)

  clonedPages
    // Collect non-template pages that connect to template pages.
    .filter((page) => !page.templateDataKey)
    .forEach((page) => {
      // Update connection page ids to map to first instance of templated pages.
      if (templatePageIds.includes(page.defaultConnectionPageId)) {
        page.defaultConnectionPageId = `${page.defaultConnectionPageId}.0`
      }
      page.connectionRules?.forEach((rule) => {
        const pageId = rule.event?.params?.pageId
        if (pageId && templatePageIds.includes(pageId)) {
          rule.event.params.pageId = `${pageId}.0`
        }
      })
    })
  return clonedPages
}

const getModulePages = (
  moduleConfigs: StoryModuleConfig[],
  interpolationData: InterpolationData,
) => {
  const storyModulePages: StoryPageData[] = []
  Promise.all(
    moduleConfigs.map((moduleConfig) => {
      const moduleStory = getModuleStory(
        moduleConfig,
        interpolationData,
      )

      if (!moduleStory) {
        throw new Error(`Module story "${moduleConfig}" not found.`)
      }
      // TODO support multiple module instances???

      // Clone and modify module pages before adding to story.
      const modulePages = moduleStory.pages.map((modulePage) => {
        const page = cloneDeep(modulePage)
        const hasDefaultConnectionPageId =
          !!page.defaultConnectionPageId
        const hasConnectionRulePageIds = !!page.connectionRules
          ?.filter(({ event }) =>
            ['StoryNavigation', 'DynamicChapterNavigation'].includes(
              event?.type,
            ),
          )
          .map(
            ({ event }) =>
              event?.params?.pageId || event?.params?.returnToPageId,
          )
          .some(Boolean)

        // Determine module end if page has no default connection.
        // and add configured module connections to resume back to parent story.
        if (!hasDefaultConnectionPageId) {
          // Add default connection to end pages.
          page.defaultConnectionPageId =
            moduleConfig.defaultConnectionPageId
        }

        // Determine module end pages if there are no valid connections.
        if (!hasConnectionRulePageIds) {
          // Add connections from module config to resume back to parent story.
          page.connectionRules = moduleConfig.connectionRules
        }

        return page
      })
      storyModulePages.push(...modulePages)

      // Clone and add nested module pages to story.
      const nestedModulePages = cloneDeep(moduleStory.pages)
        .map((modulePage) => {
          const nestedModuleConfigs = (
            modulePage.connectionRules ?? []
          )
            .map((rule) => rule.event?.params?.module)
            .filter(Boolean)
          return getModulePages(
            nestedModuleConfigs,
            interpolationData,
          )
        })
        .flat()
      storyModulePages.push(...nestedModulePages)
    }),
  )
  return storyModulePages.reduce<StoryPageData[]>((acc, page) => {
    // Remove duplicate pages from multiple module instances.
    if (!acc.find((p) => p.id === page.id)) {
      acc.push(page)
    }
    return acc
  }, [])
}

const createTemplatedPages = (
  templateDataKey: string,
  index: number,
  templatePages: StoryPageData[],
) => {
  const matchedTemplatePages = getTemplatePages(
    templateDataKey.replace(/\[\d+\]/g, '[]'),
    templatePages,
  )
  const indexes = (templateDataKey.match(/\[(\d+)?\]/g) ?? [])
    .map((i) => parseInt(i.match(/\d+/)?.[0] ?? '0'))
    .concat([index])
  const result = cloneDeep(matchedTemplatePages).map((page) => {
    // Calculate nested levels from the template data key
    // and add 1 for the current level.
    const levelCount =
      (page.templateDataKey.match(/\[\]/g)?.length ?? 0) + 1
    const pageIndexes = [...indexes].concat([0]).slice(0, levelCount)
    // Update page id to include nested indexes from template data key.
    page.id = `${page.id}.${pageIndexes.join('.')}`
    const isTemplateConnectionPageId = templatePages.some(
      ({ id }) => id === page.defaultConnectionPageId,
    )

    if (isTemplateConnectionPageId) {
      const connectedTemplatePage = templatePages.find(
        ({ id }) => id === page.defaultConnectionPageId,
      )
      // Calculate nested levels from the "connected" template data key.
      const templateLevelCount =
        connectedTemplatePage.templateDataKey.match(/\[\]/g)
          ?.length ?? 0
      // Add 1 for the current level.
      const connectedLevelCount =
        templateLevelCount + 1 - indexes.length
      // Templates determine nested level from template data key.
      const connectionIndexes = [
        ...indexes,
        // Add initial indexes for levels not accounted for in page's template data key.
        ...new Array(connectedLevelCount).fill(0),
      ]
      // Update default connection page id using nested indexes.
      page.defaultConnectionPageId = `${
        page.defaultConnectionPageId
      }.${connectionIndexes.join('.')}`
    }

    return interpolatePageData(page, (stringifiedPage) =>
      pageIndexes.reduce(
        (acc, itemIndex, index) =>
          acc.replaceAll(`{{indexes.${index}}}`, `${itemIndex}`),
        stringifiedPage,
      ),
    )
  })
  return result
}

function interpolatePageData(
  page: StoryPageData,
  replacer: (stringifiedPage: string) => string,
) {
  try {
    // Hack to interpolate indexes of nested template item data for the page
    // while preserving mustache templates.
    const stringifiedPage = JSON.stringify(page)
    const interpolatedStringifiedPage = replacer(stringifiedPage)
    const interpolatedPage = JSON.parse(
      interpolatedStringifiedPage,
    ) as StoryPageData
    return interpolatedPage
  } catch {
    console.error(
      `Error interpolating template page with page id "${page.id}"`,
    )
    return page
  }
}

export const useStoryPages = (
  story: StoryData,
  interpolationData: InterpolationData,
) => {
  const {
    storyPages,
    setStoryPages,
    setStory,
    templatePages,
    setTemplatePages,
  } = useContext(StoryPlayerContext)
  const questionnaireResponses =
    interpolationData?.questionnaireResponses
  const templateInstances = useRef<Record<string, number>>({})
  const templateDataKeys = useMemo(
    () => uniq(templatePages.map((page) => page.templateDataKey)),
    [templatePages],
  )
  const modulePages = useModulePages(story, interpolationData)

  // Remove story specific template instance data when player closes and story is cleared.
  useEffect(() => {
    if (!story) {
      templateInstances.current = {}
    }
    setStory(story)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [story])

  useEffect(() => {
    if (story?.pages?.length) {
      const pages = [...story.pages, ...modulePages].filter(
        (page) => !page.templateDataKey,
      )
      const templatePages = [...story.pages, ...modulePages].filter(
        (page) => page.templateDataKey,
      )

      setStoryPages(getUniqueStoryPages(pages))
      setTemplatePages(templatePages)
    }
  }, [story?.pages, modulePages, setStoryPages, setTemplatePages])

  // Sync templated pages according to questionnaire responses.
  useEffect(() => {
    const instances = templateDataKeys.reduce(
      (acc, templateDataKey) => {
        const data = get(
          questionnaireResponses,
          templateDataKey,
        ) as unknown[]

        // Add instances for nested data.
        if (templateDataKey.includes('[]')) {
          // Match the root data key including nested objects.
          // e.g. objectKey, objectKey.nestedKey
          const objectDataKey = templateDataKey.match(/^[\w.]+/)[0]
          const objectDataCount = acc[objectDataKey] ?? 0
          times(objectDataCount, (index) => {
            // Create unique instance key per array index.
            // e.g. rootKey[0].field, rootKey[1].field, etc
            const dataKey = templateDataKey.replace(
              '[]',
              `[${index}]`,
            )
            const data = get(
              questionnaireResponses,
              dataKey,
            ) as unknown[]

            // Use the template item data length so each item has pages available in the story.
            // Adding 1 accommodates the initial or next item to support resuming on incomplete items.
            acc[dataKey] = (isArray(data) ? data.length : 0) + 1
          })
        } else {
          // Use the template item data length so each item has pages available in the story.
          // Adding 1 accommodates the initial or next item to support resuming on incomplete items.
          acc[templateDataKey] = (isArray(data) ? data.length : 0) + 1
        }
        return acc
      },
      {} as Record<string, number>,
    )

    if (!isEqual(templateInstances.current, instances)) {
      let templatedPages: StoryPageData[] = []

      // Create initial templated pages according to questionnaire response data.
      for (const [key, count] of Object.entries(instances)) {
        times(count, (index) => {
          templatedPages.push(
            ...createTemplatedPages(key, index, templatePages),
          )
        })
      }
      templatedPages = templatedPages.filter(
        // Remove existing templated pages.
        ({ id }) => !storyPages.find((page) => page.id === id),
      )
      setStoryPages(
        getUniqueStoryPages([...storyPages, ...templatedPages]),
      )
      templateInstances.current = instances
    }
  }, [
    questionnaireResponses,
    setStoryPages,
    storyPages,
    templateDataKeys,
    templatePages,
  ])

  return storyPages
}

export function useTemplatePages() {
  const { storyPages, setStoryPages, templatePages } = useContext(
    StoryPlayerContext,
  )
  const result = useMemo(
    () => ({
      createTemplatedPages(templateDataKey: string, index: number) {
        const templatedPages = createTemplatedPages(
          templateDataKey,
          index,
          templatePages,
        )
        setStoryPages(
          getUniqueStoryPages([...storyPages, ...templatedPages]),
        )
        return templatedPages[0].id
      },
      getTemplatedPageId(templateDataKey: string, index: number) {
        const pages = getTemplatePages(
          templateDataKey.replace(/\[\d+\]/, '[]'),
          templatePages,
        )
        const templateDataIndexes = (
          templateDataKey.match(/\[\d+\]/g) ?? []
        ).map((i) => parseInt(i.replace(/\D/g, '') ?? '0'))
        const indexes = [...templateDataIndexes, index]
        return `${pages[0].id}.${indexes.join('.')}`
      },
    }),
    [setStoryPages, storyPages, templatePages],
  )
  return result
}

export const getUniqueStoryPages = (pages: StoryPageData[]) =>
  pages.reduce((acc, page) => {
    if (!acc.some((p) => p.id === page.id)) {
      acc.push(page)
    }
    return acc
  }, [] as StoryPageData[])

export const getTemplatePages = (
  templateDataKey: string,
  pages: StoryPageData[],
) =>
  pages.filter(
    (page) =>
      // Include exact template match.
      page.templateDataKey === templateDataKey ||
      // Include related templates matching nested key.
      !!`${page.templateDataKey}[]`.match(templateDataKey),
  )

export function formatFormFields(
  fields: FormField[],
  questionnaireResponseItems: QuestionnaireResponseItemInput[] = [],
) {
  // Format fields for persisting as questionnaire response items.
  return fields.reduce<FormField[]>((acc, field) => {
    const isObject = !!field.name.match(/^\w+\./)
    const isObjectArray = !!field.name.match(/^\w+(?:\[\d\])/)

    // Determine object type data and structure metadata accordingly.
    if (isObject || isObjectArray) {
      const name = field.name.match(/^\w+/)[0]
      const currentFieldName = field.name
        // Remove object name from field name.
        .replace(isObjectArray ? /^\w+(?:\[\d\]\.)/ : /^\w+\./, '')
        // Replace array index with empty brackets.
        .replace(/\[\d+\]/g, '[]')
      const fields: FormField[] = (
        (questionnaireResponseItems.find((item) => item.name === name)
          ?.metadata?.fields as FormField[]) ?? []
      )
        .filter((item) => item.name !== currentFieldName)
        .concat([
          {
            ...omit(field, [
              'beforeBlock',
              'afterBlock',
              'displayConditions',
            ]),
            name: currentFieldName,
          } as FormField,
        ])
      const objectField: ObjectField = {
        fields,
        multiple: isObjectArray,
        name,
        type: 'object',
      }
      acc.push(objectField)
    } else {
      acc.push(field)
    }
    return acc
  }, [])
}

import useHashParams from 'hooks/useHashParams'
import { useCallback, useState } from 'react'

export const getAppointmentParamsFromPath = (url = '') => {
  const matches = url?.match?.(
    /#\/appointment\/?\?patientTodoId=([\w-]+)/,
  )
  return {
    patientTodoId: matches?.[1],
  }
}

export default function useAppointmentHashParams({
  disabled,
}: {
  disabled?: boolean
}) {
  const [patientTodoId, setPatientTodoId] = useState('')
  const onHashChange = useCallback(() => {
    setPatientTodoId(
      getAppointmentParamsFromPath(window.location.hash)
        .patientTodoId,
    )
  }, [])

  useHashParams({ disabled, onHashChange })
  return { patientTodoId }
}

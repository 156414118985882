import chroma from 'chroma-js'

export const isDarkColor = (color?: string) => {
  if (color) {
    return chroma(color).luminance() < 0.5
  }
  return undefined
}

export const isLightColor = (color?: string) => {
  if (color) {
    return chroma(color).luminance() > 0.5
  }
  return undefined
}

export function getCssGradientBackgroundImage(
  bgcolor?: string,
  multiplier = 1,
) {
  if (!bgcolor) return null

  const gradientColorStart = chroma(bgcolor)
    .set('hsl.s', `+${0.1 * multiplier}`)
    .set('hsl.l', `-${0.025 * multiplier}`)
    .css()
  const gradientColorEnd = chroma(bgcolor)
    .set('hsl.s', `+${0.3 * multiplier}`)
    .set('hsl.l', `+${0.1 * multiplier}`)
    .css()

  return `linear-gradient(90deg, ${gradientColorStart} 0%, ${gradientColorEnd} 100%)`
}

export const getIconLinearGradient = (
  color: string,
  hueOffset = '-0',
) => {
  if (!color) {
    return ''
  }
  const start = chroma(color).set('hsl.h', hueOffset).css('hsl')
  const end = chroma(start)
    .set('hsl.h', '-40')
    .set('hsl.s', '+0.2')
    .css('hsl')
  return `linear-gradient(135deg, ${start}, ${end})`
}

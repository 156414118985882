import React from 'react'
import { Box, useTheme } from '@mui/material'
import DynamicBackground from 'components/DynamicBackground'
import * as textures from 'assets/images/textures'
import { PageTheme } from 'components/StoryPlayer/StoryPage'

export type PageBackgroundProps = {
  id: string
  theme: PageTheme
  disableMotion?: boolean
}

export default function PageBackground(
  props: PageBackgroundProps,
): JSX.Element {
  const { palette } = useTheme()
  const {
    theme: {
      variant,
      texture,
      textureColor,
      background,
      backgroundColor = palette.background.default,
    },
  } = props

  return (
    <Box
      sx={{
        background,
        backgroundColor,
        borderRadius: 2,
        height: 1,
        left: 0,
        overflow: 'hidden',
        pointerEvents: 'none',
        position: 'fixed',
        top: 0,
        width: 1,
        zIndex: -1,
      }}
    >
      <Box
        sx={{
          '& > svg': {
            display: 'block',
            height: 1,
            left: 0,
            position: 'absolute',
            top: 0,
            width: 1,
          },
          height: 1,
          left: 0,
          position: 'absolute',
          top: 0,
          width: 1,
        }}
      >
        {(texture || variant) && (
          <DynamicBackground
            id={props.id}
            variant={variant}
            texture={textures[texture]}
            textureColor={textureColor}
            backgroundColor={backgroundColor}
            textureAnimated={!props.disableMotion}
          />
        )}
      </Box>
    </Box>
  )
}

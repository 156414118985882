import { useContext, useEffect } from 'react'
import { AppContext } from 'components/AppContext'

export default function useDocumentTitle(
  title = '',
  options?: { excludeNestInTitle?: boolean },
) {
  const { excludeNestInTitle } = options ?? {}
  const { documentTitle, setDocumentTitle } = useContext(AppContext)
  useEffect(() => {
    if (documentTitle !== title) {
      const composedTitle = excludeNestInTitle
        ? title
        : `${title} / Nest`
      document.title = composedTitle
      setDocumentTitle(title)
    }
  }, [documentTitle, setDocumentTitle, title, excludeNestInTitle])
}

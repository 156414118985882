import { useRouter } from 'next/router'

const NON_AUTH_ROUTE_REGEX = [
  /\/invite\/.+/,
  /\/login(\/.+)?/,
  /\/kiosk(\/.+)?/,
  /\/account\/.+/,
  /\/sso\/.+/,
  /\/sign-up(\/.+)?/,
]

const UPLOADS_ROUTE_REGEX = [/\/uploads\/.+/]

export default function useRouteCheck(): {
  isAuthRequired: boolean
  isUploadedContent: boolean
} {
  const router = useRouter()
  const isAuthRequired = !NON_AUTH_ROUTE_REGEX.some((regex) =>
    router.asPath.match(regex),
  )
  const isUploadedContent = !!UPLOADS_ROUTE_REGEX.some((regex) =>
    router.asPath.match(regex),
  )
  return { isAuthRequired, isUploadedContent }
}

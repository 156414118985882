import { InputBaseProps } from '@mui/material'
import React, { RefObject } from 'react'
import { IMask, IMaskInput } from 'react-imask'

const blocks = {
  DD: {
    from: 1,
    mask: IMask.MaskedRange,
    maxLength: 2,
    to: 31,
  },
  MM: {
    from: 1,
    mask: IMask.MaskedRange,
    maxLength: 2,
    to: 12,
  },
  YYYY: {
    from: 1900,
    mask: IMask.MaskedRange,
    // For some reason `to` is 9999 only 3 digits are accepted.
    // Setting to 99999 allows 4 digits to be accepted.
    to: 99999,
  },
}

/**
 * Parses date string into DD, MM, YYYY keys.
 * @param value date formatted in given pattern
 * @param pattern format using MM, DD, YYYY.
 * @returns object with DD, MM, YYYY keys.
 * @example
 * parseDateFormat('01/01/1900', 'MM/DD/YYYY')
 * // { DD: '01', MM: '01', YYYY: '1900' }
 */
function parseDateFormat(value: string, pattern: string) {
  if (!value || !pattern) {
    return null
  }
  const index = {
    DD: pattern.indexOf('DD'),
    MM: pattern.indexOf('MM'),
    YYYY: pattern.indexOf('YYYY'),
  }
  const range = {
    DD: [index.DD, index.DD + 2],
    MM: [index.MM, index.MM + 2],
    YYYY: [index.YYYY, index.YYYY + 4],
  }
  return {
    DD: value.slice(...range.DD),
    MM: value.slice(...range.MM),
    YYYY: value.slice(...range.YYYY),
  }
}

/**
 * Formats Date into MM-DD-YYYY.
 * @param date {Date}
 * @returns {string} Date formatted in MM-DD-YYYY
 */
const formatDate = (date: Date) => {
  return [
    `${date.getMonth() + 1}`.padStart(2, '0'),
    `${date.getDate()}`.padStart(2, '0'),
    date.getFullYear(),
  ].join('-')
}

/**
 * Parses string into a valid Date.
 * @param value {string} date formatted in given pattern.
 * @param pattern {string} format using MM, DD, YYYY.
 * @returns {Date}
 * @example
 * parseDate('01/01/1900', 'MM/DD/YYYY')
 * // new Date(1900, 0, 1)
 */
const parseDate = (value: string, pattern: string): Date => {
  const parsedDate = parseDateFormat(value, pattern)
  if (!parsedDate) {
    return new Date()
  }
  const { DD, MM, YYYY } = parsedDate
  return new Date(parseInt(YYYY), parseInt(MM) - 1, parseInt(DD))
}

/**
 * Transforms formatted date into ISO date.
 * @param maskedValue date formatted in given pattern
 * @param pattern {string} format using MM, DD, YYYY.
 * @returns {string} date formatted in YYYY-MM-DD (ISO8601 format)
 * @example
 * toISO8601Value('01/01/1900', 'MM/DD/YYYY')
 * // '1900-01-01'
 */
function toISO8601Value(maskedValue: string, pattern: string) {
  const parsedDate = parseDateFormat(maskedValue, pattern)
  if (!parsedDate) {
    return ''
  }
  return [parsedDate.YYYY, parsedDate.MM, parsedDate.DD].join('-')
}

/**
 * Transforms ISO date into formatted date.
 * @param iso8601Value date formatted in YYYY-MM-DD (ISO8601 format)
 * @param pattern date formatted in YYYY-MM-DD (ISO8601 format)
 * @returns {string} date formatted in MM-DD-YYYY
 */
function toMaskedValue(iso8601Value: string, pattern: string) {
  if (!iso8601Value) {
    return ''
  }
  const [YYYY, MM, DD] = iso8601Value.split('-')
  return pattern
    .replace('MM', MM)
    .replace('DD', DD)
    .replace('YYYY', YYYY)
    .replace(/\D+$/g, '')
    .replace(/^\D+/g, '')
}

type DateMaskInputProps = InputBaseProps['inputProps'] & {
  pattern?: string
}

const DateMaskInput = React.forwardRef<
  HTMLElement,
  DateMaskInputProps
>(function DateMaskInput(props, ref: RefObject<HTMLInputElement>) {
  const {
    onChange,
    pattern = 'MM/DD/YYYY',
    ...maskInputProps
  } = props
  const value = (maskInputProps.value ?? '').substring(0, 10)

  return (
    <IMaskInput
      {...maskInputProps}
      value={toMaskedValue(value, pattern)}
      mask={Date}
      pattern={pattern}
      inputRef={ref}
      onAccept={(value: string) =>
        onChange({
          target: {
            name: props.name,
            value: toISO8601Value(value, pattern),
          },
        } as unknown as React.FormEvent<HTMLInputElement>)
      }
      blocks={blocks}
      format={formatDate}
      parse={(value) => parseDate(value, pattern)}
      autofix="pad"
      lazy
    />
  )
})

export default DateMaskInput

import React from 'react'
import { Stack, StackProps } from '@mui/material'

export default function BlocksContainer(props: StackProps) {
  return (
    <Stack
      {...props}
      className={['BlocksContainer', props.className]
        .filter(Boolean)
        .join(' ')}
      sx={{
        '& .MuiBox-root:has(> .fullBleed)': {
          mx: -3,
        },
        '& .MuiTypography-root': {
          width: 1,
        },
        alignItems: 'stretch',
        alignSelf: 'stretch',
        flexGrow: 1,
        gap: 1.5,
        justifyContent: 'center',
        pb: 5,
        px: 3,
        width: 1,
        ...props.sx,
      }}
    />
  )
}

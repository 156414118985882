import { Texture } from 'components/DynamicBackground'

// eslint-disable-next-line import/no-anonymous-default-export
const texture: Texture = {
  data: (fill: string) => `
<svg xmlns="http://www.w3.org/2000/svg" width="36" height="72" viewBox="0 0 36 72">
  <g fill-rule="evenodd">
    <g fill="${fill}">
      <path d="M2 6h12L8 18 2 6zm18 36h12l-6 12-6-12z" />
    </g>
  </g>
</svg>`,
  direction: ['x', 'y', 'xy'],
  height: 72,
  scale: 0.5,
  width: 36,
}
export default texture

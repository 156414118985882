import StoryProgressStore from 'stores/StoryProgressStore'
import { useEffect, useState } from 'react'
import StoryProgressModel from 'models/StoryProgress'

let globalStoryProgress: StoryProgressModel = null

export default function useStoryProgress(): StoryProgressModel {
  const [storyProgress, setStoryProgress] =
    useState<StoryProgressModel>(globalStoryProgress)
  useEffect(() => {
    if (!globalStoryProgress) {
      globalStoryProgress = new StoryProgressModel(
        StoryProgressStore.get(),
      )
      setStoryProgress(globalStoryProgress)
    }
  }, [])

  return storyProgress
}

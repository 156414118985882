import { capitalize } from 'lodash'
import { PatientAppCareTeamMember } from '__generated__/graphql'

export default class CareTeamMemberModel extends PatientAppCareTeamMember {
  constructor(data: PatientAppCareTeamMember) {
    super()
    Object.assign(this, data)
  }

  get fullName() {
    return [
      this.namePrefix ? `${capitalize(this.namePrefix)}.` : '',
      this.firstName,
      this.lastName,
    ]
      .filter(Boolean)
      .join(' ')
  }
}

import React from 'react'
import { Grow, Stack, StackProps, alpha } from '@mui/material'
import BlocksContainer from 'components/blocks/BlocksContainer'
import PageBlock, {
  BlockData,
  BlocksAlign,
  getBlocksAlignStyle,
} from 'components/StoryPlayer/PageBlock'

export default function OverlayBlocksContainer(
  props: StackProps & {
    blocks?: BlockData[]
    blocksAlign?: BlocksAlign
    variant?: 'contained' | 'transparent'
    in?: boolean
    disableMotion?: boolean
  },
) {
  const {
    blocks = [],
    blocksAlign = 'center',
    variant = 'contained',
    in: transitionIn,
    disableMotion,
    ...blockContainerProps
  } = props

  if (!blocks.length) {
    return null
  }

  return (
    <Stack
      className={[
        'OverlayBlocksContainer',
        blockContainerProps.className,
      ]
        .filter(Boolean)
        .join(' ')}
      sx={{
        height: 1,
        left: 0,
        overflowY: 'auto',
        // Allow clicks to pass through transparent overlay (e.g. replay video button).
        pointerEvents: 'none',
        position: 'sticky',
        top: 0,
        width: 1,
        zIndex: 1,
      }}
    >
      <BlocksContainer
        {...blockContainerProps}
        sx={{
          ...getBlocksAlignStyle(blocksAlign),
          '& > *': {
            // Allow block content to be interative.
            pointerEvents: 'auto',
          },
          height: 1,
          overflowY: 'auto',
          px: 2,
          py: 15,
          width: 1,
          zIndex: 1,
          ...blockContainerProps.sx,
        }}
      >
        <Grow in={transitionIn}>
          <Stack
            sx={[
              variant === 'contained' && {
                backdropFilter: 'blur(10px)',
                bgcolor: ({ palette }) =>
                  alpha(palette.background.default, 0.66),
                borderRadius: 1,
                color: ({ palette }) =>
                  palette.getContrastText(palette.background.default),
              },
              {
                gap: 1.5,
                p: 2,
              },
            ]}
          >
            {blocks.map((block, index) => (
              <PageBlock
                key={`overlayBlocksContainer.block.${index}`}
                data-id={`overlayBlocksContainer.block.${index}`}
                disableMotion={disableMotion ?? true}
                {...block}
              />
            ))}
          </Stack>
        </Grow>
      </BlocksContainer>
    </Stack>
  )
}

import React, { useEffect, useState } from 'react'
import { Box, Stack } from '@mui/material'
import { useStoryPlayerProgress } from 'hooks/useStoryPlayerProgress'
import {
  BasePageProps,
  PageData,
} from 'components/StoryPlayer/StoryPage'
import PageFooter from 'components/StoryPlayer/PageFooter'
import ActionButtonBlock from 'components/blocks/ActionButtonBlock'
import {
  BlockData,
  BlocksAlign,
} from 'components/StoryPlayer/PageBlock'
import OverlayBlocksContainer from 'components/blocks/OverlayBlocksContainer'

export interface PageImageData extends PageData {
  blocks?: BlockData[]
  blocksAlign?: BlocksAlign
  blocksVariant?: 'contained' | 'transparent'
  type: 'image'
  src: string
  duration?: number
}

export type PageImageProps = Pick<
  BasePageProps,
  | 'onNext'
  | 'disableMotion'
  | 'enableActionIconButton'
  | 'submitLabel'
> &
  Pick<
    PageImageData,
    'blocks' | 'blocksAlign' | 'blocksVariant' | 'src' | 'duration'
  > & {
    canPlay: boolean
    hidden?: boolean
  }

export default function PageImage(
  props: PageImageProps,
): JSX.Element {
  const {
    blocks = [],
    blocksAlign = 'center',
    blocksVariant = 'contained',
    duration,
    onNext,
    hidden,
    canPlay,
    disableMotion,
    enableActionIconButton,
    submitLabel = 'Next',
  } = props
  const { resetProgress, resetElapsedTime } = useStoryPlayerProgress({
    duration,
    onNext,
    playing: canPlay && !hidden,
  })
  const [imageLoaded, setImageLoaded] = useState(false)

  useEffect(() => {
    if (!hidden) {
      resetProgress()
    }
    return () => resetElapsedTime()
  }, [hidden, resetProgress, resetElapsedTime])

  return (
    <Stack
      sx={{
        flexGrow: 1,
        justifyContent: 'flex-end',
      }}
    >
      <OverlayBlocksContainer
        in={imageLoaded}
        blocks={blocks}
        blocksAlign={blocksAlign}
        variant={blocksVariant}
        disableMotion={disableMotion}
      />
      <Box
        component="img"
        src={props.src}
        sx={[
          {
            alignItems: 'center',
            bottom: 0,
            height: 1,
            justifyContent: 'center',
            left: 0,
            objectFit: 'cover',
            objectPosition: 'center',
            position: 'fixed',
            right: 0,
            top: 0,
            width: 1,
          },
        ]}
        onLoad={() => setImageLoaded(true)}
      />
      <PageFooter>
        <ActionButtonBlock
          variant={enableActionIconButton ? 'icon' : 'label'}
          label={submitLabel}
          onClick={props.onNext}
        />
      </PageFooter>
    </Stack>
  )
}

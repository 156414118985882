const LocalStorageUtil = {
  getItem: <T>(key: string): T => {
    try {
      return JSON.parse(localStorage.getItem(key))
    } catch (e) {
      console.error(e)
    }
  },
  removeItem: (key: string) => {
    localStorage.removeItem(key)
  },
  setItem: (key: string, value: unknown) => {
    localStorage.setItem(key, JSON.stringify(value))
  },
}

export default LocalStorageUtil

import {
  AccountSettings,
  ContentAccountData,
} from '__shared__/constants'
import useSessionStorage from 'hooks/useSessionStorage'
import { useMemo } from 'react'
import { isNil } from 'lodash'

export default function useFeatureFlags(settings: AccountSettings) {
  // Kiosk will override `enablePatientOnboardingAlwaysOnStart` and `onboardingStoryId`.
  const [{ kiosk }] = useSessionStorage()
  const result = useMemo(() => {
    const getValue = <T>(
      name: keyof AccountSettings,
      defaultValue: T,
      valid: (value) => boolean,
    ): T => {
      const value = settings?.[name]

      if (isNil(value)) {
        return defaultValue
      }

      if (!valid(value)) {
        console.error(`Invalid ${name} value "${value}"`)
      }
      return value as T
    }

    return {
      get clinicalSupportEmail() {
        return getValue('clinicalSupportEmail', '', (value) =>
          value.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          ),
        )
      },
      get customLearnAccounts() {
        return getValue<ContentAccountData[]>(
          'customLearnAccounts',
          [],
          (value) => (value as ContentAccountData[]) !== undefined,
        )
      },
      get demoAccount() {
        return getValue(
          'demoAccount',
          false,
          (value) => typeof value === 'boolean',
        )
      },
      get enablePatientOnboardingAlwaysOnStart() {
        // Always enable onboarding for kiosk mode.
        if (kiosk) {
          return true
        }
        return getValue(
          'enablePatientOnboardingAlwaysOnStart',
          false,
          (value) => typeof value === 'boolean',
        )
      },
      get onboardingStoryId() {
        // Use kiosk story if configured.
        if (kiosk?.storyId) {
          return kiosk.storyId
        }
        return getValue(
          'onboardingStoryId',
          undefined,
          (value) => typeof value === 'string',
        )
      },
      get patientAppCarePlan() {
        return getValue(
          'patientAppCarePlan',
          true,
          (value) => typeof value === 'boolean',
        )
      },
      get patientAppCareTeam() {
        return getValue(
          'patientAppCareTeam',
          true,
          (value) => typeof value === 'boolean',
        )
      },
    }
  }, [kiosk, settings])
  return result
}

import { GuidelineStatement } from '__generated__/graphql'

export enum StepPriority {
  CONSIDERATION = 'CONSIDERATION',
  RECOMMENDATION = 'RECOMMENDATION',
}

export const stepPriorityOptions = [
  {
    label: 'Consideration',
    value: StepPriority.CONSIDERATION,
  },
  {
    label: 'Recommendation',
    value: StepPriority.RECOMMENDATION,
  },
]

export const getHighestPriorityIndex = (
  guidelineStatements: GuidelineStatement[],
) => {
  const stepPriorityKeys = Object.keys(StepPriority)
  return guidelineStatements.reduce((acc, guidelineStatement) => {
    const index = stepPriorityKeys.findIndex(
      (key) => key === guidelineStatement.data.priority,
    )
    return index > acc ? index : acc
  }, 0)
}

export const getHighestPriorityLabel = (
  guidelineStatements: GuidelineStatement[],
) => {
  const index = getHighestPriorityIndex(guidelineStatements)
  return stepPriorityOptions[index]?.label
}

export function getNewGuidelineStatement(): GuidelineStatement {
  return {
    _count: undefined,
    data: {
      priority: StepPriority.RECOMMENDATION,
    },
    description: '',
    id: undefined,
    metadata: {},
    rules: {},
  }
}

export default class GuidelineStatementModel extends GuidelineStatement {
  constructor(data?: Partial<GuidelineStatement>) {
    super()
    Object.assign(this, data ?? getNewGuidelineStatement())
  }
}

import { useAuthUser } from '@frontegg/react'

export enum Role {
  Patient = 'Patient',
  Admin = 'Admin',
  Portal = 'Portal',
}

type AuthUser = ReturnType<typeof useAuthUser>

function hasRole(user: AuthUser, role: keyof typeof Role) {
  return !!user?.roles.some(({ key }) => key === role)
}

export function hasPatientRole(user: AuthUser) {
  return hasRole(user, 'Patient')
}

export function hasAdminRole(user: AuthUser) {
  return hasRole(user, 'Admin')
}

export function hasPortalRole(user: AuthUser) {
  return hasRole(user, 'Portal')
}

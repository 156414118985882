import React from 'react'
import {
  Palette,
  Stack,
  Theme,
  Typography,
  alpha,
} from '@mui/material'
import useInterpolate from 'hooks/useInterpolate'
import MarkdownContent from 'components/MarkdownContent'
import BlockIcon, { BlockIconProps } from 'components/BlockIcon'
import { StoryPlayerContext } from 'components/StoryPlayerContext'
import { MotionTrail } from 'components/MotionComponents'
import { BaseBlockData } from 'components/StoryPlayer/PageBlock'
import chroma from 'chroma-js'
import { SystemStyleObject } from '@mui/system/styleFunctionSx'

export const articleUrlPrefix = `/assets/articles/`

export const isArticleUrl = (url = '') =>
  url.startsWith(articleUrlPrefix)

export const articleLinkStyle: SystemStyleObject<Theme> = {
  '&::after': {
    backgroundImage: ({ palette }) =>
      // Embed svg info icon in data url for ease of reuse.
      `url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="${palette.primary.light}" viewBox="0 0 24 24"%3E%3Cpath d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2m0 15c-.55 0-1-.45-1-1v-4c0-.55.45-1 1-1s1 .45 1 1v4c0 .55-.45 1-1 1m1-8h-2V7h2z"%3E%3C/path%3E%3C/svg%3E')`,
    content: '""',
    display: 'inline-block',
    height: '1em',
    ml: '0.16em',
    verticalAlign: 'middle',
    width: '1em',
  },
  '&:hover': {
    color: 'primary.main',
    textDecoration: 'underline',
    textDecorationColor: 'primary.main',
  },
  color: 'primary.light',
  lineHeight: 1.5,
  position: 'relative',
  textDecoration: 'underline',
  textDecorationColor: 'primary.light',
  whiteSpace: 'nowrap',
}

const getBlockquoteBackgroundColor = (palette: Palette) =>
  chroma(palette.secondary.light).set('hsl.l', 0.92).hex()
const getBlockquoteTextColor = (palette: Palette) =>
  palette.getContrastText(getBlockquoteBackgroundColor(palette))

export const textBlockStyles: SystemStyleObject<Theme> = {
  '& .MuiBox-root > ul': {
    '& > li': {
      mb: 0.5,
    },
    my: 0.5,
    paddingInlineStart: 3,
  },
  '& .MuiSvgIcon-root': { minHeight: 30 },
  // Match specific url patterns to style as article/tooltip links.
  '& a[href^="/assets/articles/"]': articleLinkStyle,
  '& a[href^="tel:"]': { textWrap: 'nowrap' },
  '& blockquote': {
    '& > *': { mt: 0 },
    '& > ul': { mt: 0.5 },
    '& a': {
      '&:hover': {
        color: ({ palette }) =>
          alpha(getBlockquoteTextColor(palette), 1),
      },
      color: ({ palette }) =>
        alpha(getBlockquoteTextColor(palette), 0.74),
    },
    '& h1, h2, h3, h4, h5, h6, strong, b': {
      '&:last-child': { mb: 0 },
      fontWeight: 500,
    },
    '& ul': {
      '&:last-child': { mb: 0 },
      paddingInlineStart: '2em',
    },
    bgcolor: ({ palette }) => getBlockquoteBackgroundColor(palette),
    borderLeftColor: ({ palette }) => palette.secondary.light,
    borderLeftStyle: 'solid',
    borderLeftWidth: 3,
    color: ({ palette }) => getBlockquoteTextColor(palette),
    mx: 0,
    my: '1em',
    px: '1.5em',
    py: '1em',
    typography: 'body2',
  },
  '& code': {
    bgcolor: 'rgba(0,0,0,.0875)',
    opacity: 0.84,
    padding: '.0875em .125em',
  },
  '& h1': { fontSize: ({ typography }) => typography.h1.fontSize },
  '& h1, & h2, & h3, & h4, & h5, & h6, &.MuiTypography-h1, &.MuiTypography-h2, &.MuiTypography-h3, &.MuiTypography-h4, &.MuiTypography-h5, &.MuiTypography-h6':
    {
      '& strong, & b': { color: 'primary.main' },
      '&:first-child': { mt: 0 },
      fontWeight: 500,
      my: '.25em',
    },
  '& h2': { fontSize: ({ typography }) => typography.h2.fontSize },
  '& h3': { fontSize: ({ typography }) => typography.h3.fontSize },
  '& h4': { fontSize: ({ typography }) => typography.h4.fontSize },
  '& h5': { fontSize: ({ typography }) => typography.h5.fontSize },
  '& h6': { fontSize: ({ typography }) => typography.h6.fontSize },
  '& p:first-child': { mt: 0 },
  '& p:last-child': { mb: 0 },
  '& p:not(:first-child,:last-child)': { my: 1.5 },
  '&.MuiTypography-subtitle1, &.MuiTypography-subtitle2': {
    fontWeight: 600,
  },
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  textWrap: 'pretty',
}

export type TextBlockData = BaseBlockData & {
  type: 'text'
  variant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'caption'
  color?: string
  content: string
  startIcon?: BlockIconProps
  textTransition?: 'word-by-word'
}

export type TextBlockProps = Omit<TextBlockData, 'type'>

export default function TextBlock(props: TextBlockProps) {
  const {
    startIcon,
    content,
    textTransition,
    disableMotion,
    ...typographyProps
  } = props
  const interpolate = useInterpolate(StoryPlayerContext)
  const contentResult = interpolate(content)

  return (
    <Typography
      className={['TextBlock', props.className]
        .filter(Boolean)
        .join(' ')}
      {...typographyProps}
      component="div"
      variant={props.variant}
      sx={{
        ...textBlockStyles,
        ...props.sx,
      }}
      color={props.color ?? 'text.primary'}
    >
      <BlockIcon {...startIcon} />
      {textTransition === 'word-by-word' && (
        <MotionTrail
          id="lineBreakBlock"
          itemComponent="span"
          disableMotion={disableMotion}
          items={contentResult.split(/ /).map((line, index, arr) => (
            <MarkdownContent
              key={`text.${index}`}
              component="span"
              value={
                // Add non-breaking space at end of each word except the last.
                line + (index === arr.length - 1 ? '' : '&nbsp;')
              }
              components={{
                p: React.Fragment,
              }}
            />
          ))}
        />
      )}
      {!textTransition && (
        <Stack gap={2} sx={{ width: 1 }}>
          <MotionTrail
            id="textBlock"
            itemComponent="div"
            items={contentResult.split(/\n\n/).map((line, index) => {
              const { lines } = line
                // Temporarily replace table line breaks with a placeholder.
                .replaceAll('|\n|', '|\\n|')
                // Matches a line break that is not preceded by two spaces.
                // Avoids using negative lookbehind, which is not supported in Safari 16.3.
                .split(/(?:(?!\s{2})\n)/)
                .reduce(
                  (acc, line) => {
                    // Restore table line breaks.
                    line = line.replaceAll('|\\n|', '\n')
                    if (!line.match(/ {2}$/)) {
                      if (acc.temp) {
                        acc.lines.push(acc.temp)
                        acc.temp = ''
                      }
                      acc.lines.push(line)
                    } else {
                      acc.temp = `${acc.temp}${line}`
                    }
                    return acc
                  },
                  { lines: [], temp: '' },
                )

              return lines.length === 1 ? (
                <MarkdownContent
                  key={`text.${index}`}
                  component="span"
                  value={line}
                />
              ) : (
                <MotionTrail
                  key={`text.${index}`}
                  id="lineBreakBlock"
                  itemComponent="span"
                  items={lines.map((line, lineBreakIndex) => (
                    <MarkdownContent
                      key={`text.${index}.${lineBreakIndex}`}
                      component="span"
                      value={line}
                    />
                  ))}
                  disableMotion={disableMotion}
                />
              )
            })}
            disableMotion={disableMotion}
          />
        </Stack>
      )}
    </Typography>
  )
}

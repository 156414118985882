import LocalStorageUtil from 'utils/LocalStorageUtil'

type RootStoreState = {
  lastActivity: string
  redirectAfterLogin: string
  scheduledLogout: string
  recentPaymentUrl: string
}

export default class RootStore {
  static get<T extends keyof RootStoreState>(key: T) {
    const value: RootStoreState[T] = LocalStorageUtil.getItem(key)
    RootStore.log('get', key, value)
    return value
  }

  static set<T extends keyof RootStoreState>(
    key: T,
    value: RootStoreState[T],
  ) {
    RootStore.log('set', key, value)
    LocalStorageUtil.setItem(key, value)
  }

  static remove<T extends keyof RootStoreState>(key: T) {
    RootStore.log('remove', key)
    LocalStorageUtil.removeItem(key)
  }

  static log(...rest) {
    if (localStorage.getItem('debugEnabled')) {
      console.log(
        '%RootStore',
        'border-radius:4px; padding:0 4px; color:white; background-color:#434461;',
        ...rest,
      )
    }
  }
}

import { Texture } from 'components/DynamicBackground'

// eslint-disable-next-line import/no-anonymous-default-export
const texture: Texture = {
  data: (fill: string) => `
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="12" viewBox="0 0 20 12">
  <g fill-rule="evenodd">
    <g fill="${fill}">
      <path
        d="M9.8 12L0 2.2V.8l10 10 10-10v1.4L10.2 12h-.4zm-4 0L0 6.2V4.8L7.2 12H5.8zm8.4 0L20 6.2V4.8L12.8 12h1.4zM9.8 0l.2.2.2-.2h-.4zm-4 0L10 4.2 14.2 0h-1.4L10 2.8 7.2 0H5.8z" />
    </g>
  </g>
</svg>`,
  direction: ['x', 'y', 'xy'],
  height: 12,
  width: 20,
}
export default texture

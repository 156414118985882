import {
  OrderableTestIntakeQuestionsQuery,
  SelectOption,
} from '__generated__/graphql'
import { Section } from '__shared__/FormSchema'
import { PageTransformer } from 'components/StoryPlayer/pageTransformer'
import { getFormPagesFromSections } from 'components/StoryPlayer/pageTransformer/pageTransformerUtil'
import { generateId } from 'utils/SeedUtil'

const getPageId = (value: string) =>
  generateId('testIntakeQuestions' + value)

const testIntakeQuestions: PageTransformer<{
  orderableTestOptions: SelectOption[]
  selectedTestIds: string[]
}> = ({ returnToPageId, data, options }) => {
  const sections: Section[] =
    (data as OrderableTestIntakeQuestionsQuery)?.patientApp
      ?.orderableTestIntakeQuestions ?? []
  const { orderableTestOptions = [], selectedTestIds = [] } = options
  const formPages = getFormPagesFromSections({
    formName: 'patientData',
    getPageId,
    returnToPageId,
    sections,
  })

  // Page for handling case for no intake questions.
  if (!formPages.length) {
    const selectedTestNames = orderableTestOptions
      .filter(({ value }) => selectedTestIds.includes(value))
      .map(({ label }) => label)
    return [
      {
        blocks: [
          {
            content: `Intake Questionnaire Summary`,
            type: 'text',
            variant: 'h4',
          },
          {
            content: `There are no intake questions for the tests:`,
            type: 'text',
          },
          {
            content: selectedTestNames
              .map((testName) => `- ${testName} \n`)
              .join(''),
            type: 'text',
          },
          {
            content: `*Let's continue to the next step.*`,
            type: 'text',
          },
        ],
        defaultConnectionPageId: returnToPageId,
        enableActionIconButton: true,
        id: getPageId('noQualifyingQuestions'),
        type: 'custom',
      },
    ]
  }

  return formPages
}

export default testIntakeQuestions

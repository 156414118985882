import React from 'react'
import { Avatar, AvatarProps, SvgIcon } from '@mui/material'
import { getIconLinearGradient } from 'utils/ColorUtil'
import StepConsultIcon from 'assets/icons/step-consult.svg'
import StepLifestyleIcon from 'assets/icons/step-lifestyle.svg'
import StepMedicalDeviceIcon from 'assets/icons/step-medical-device.svg'
import StepMedicationIcon from 'assets/icons/step-medication.svg'
import StepResearchIcon from 'assets/icons/step-research.svg'
import StepSurgeryIcon from 'assets/icons/step-surgery.svg'
import StepSurveillanceIcon from 'assets/icons/step-surveillance.svg'
import { StepType } from '__generated__/graphql'

const StepLabel = {
  [StepType.CONSULT]: 'Consult',
  [StepType.LIFESTYLE]: 'Lifestyle',
  [StepType.MEDICATION]: 'Medication',
  [StepType.MEDICAL_DEVICE]: 'Medical Device',
  [StepType.RESEARCH]: 'Research',
  [StepType.SURGERY]: 'Surgery',
  [StepType.SURVEILLANCE]: 'Surveillance',
}

const StepColor = {
  [StepType.CONSULT]: 'hsla(190, 50%, 54%, 0.33)',
  [StepType.LIFESTYLE]: 'hsla(108, 47%, 58%, 0.33)',
  [StepType.MEDICATION]: 'hsla(30, 77%, 58%, 0.33)',
  [StepType.MEDICAL_DEVICE]: 'hsla(160, 77%, 58%, 0.33)',
  [StepType.RESEARCH]: 'hsla(122, 23%, 60%, 0.33)',
  [StepType.SURGERY]: 'hsla(258, 23%,60%, 0.33)',
  [StepType.SURVEILLANCE]: 'hsla(58, 74%, 71%, 0.33)',
}

const StepIcon = {
  [StepType.CONSULT]: StepConsultIcon,
  [StepType.LIFESTYLE]: StepLifestyleIcon,
  [StepType.MEDICATION]: StepMedicationIcon,
  [StepType.MEDICAL_DEVICE]: StepMedicalDeviceIcon,
  [StepType.RESEARCH]: StepResearchIcon,
  [StepType.SURGERY]: StepSurgeryIcon,
  [StepType.SURVEILLANCE]: StepSurveillanceIcon,
}
const getIconGradient = (type: keyof typeof StepType) => {
  return getIconLinearGradient(StepColor[type])
}

type StepTypeIconProps = {
  variant: keyof typeof StepType
  sx?: AvatarProps['sx']
}

export default function StepTypeIcon(props: StepTypeIconProps) {
  const { variant, sx } = props
  return (
    <Avatar
      sx={{
        backgroundImage: getIconGradient(variant),
        bgcolor: StepColor[variant],
        color: 'inherit',
        ...sx,
      }}
      title={StepLabel[variant]}
    >
      <SvgIcon
        color="inherit"
        fontSize="small"
        {...props}
        component={StepIcon[variant]}
      />
    </Avatar>
  )
}

import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'
import { SxProps } from '@mui/material'

export default function NestLogo(props: { sx?: SxProps }) {
  return (
    <SvgIcon
      sx={props.sx}
      viewBox="0 0 372 100"
      width="372"
      height="100"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.1857 96.389C54.7813 96.3193 54.3784 96.5199 54.1897 96.8843C54.0012 97.2488 54.0702 97.6932 54.3605 97.9833C54.3567 97.9873 54.3567 97.9873 54.3569 97.9875C56.9569 100.585 60.7174 100.608 63.1798 98.4027C66.4935 95.435 69.98 91.9638 73.2072 88.1868C73.236 86.4163 73.1103 81.7162 72.9696 79.8729C72.6936 81.3817 71.9341 82.2267 70.9284 83.6464C68.9047 86.5034 67.0486 88.2451 61.5931 93.5899C59.4717 95.6683 57.3568 96.808 55.1857 96.389Z"
        fill="url(#paint0_linear_242_4581)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.6943 75.8602C72.4676 72.2254 72.263 69.7003 71.7141 66.7933C71.7141 66.7933 71.8103 68.6098 70.254 70.9234C66.9294 75.8656 61.6478 81.7964 48.7609 93.8585C46.4815 95.9919 44.5982 96.9105 42.49 96.1092C42.0849 95.9611 41.6315 96.1145 41.3995 96.4783C41.1677 96.8419 41.22 97.3178 41.525 97.6226C41.5239 97.624 41.5241 97.6242 41.5243 97.6244C44.1243 100.222 47.8294 100.194 50.3472 98.0396C61.9723 88.0923 64.9662 85.2521 72.6943 75.8602Z"
        fill="url(#paint1_linear_242_4581)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.3439 92.502C26.1106 92.2624 25.757 92.1845 25.4446 92.3037C25.132 92.4232 24.9205 92.7169 24.906 93.051C24.8148 94.8896 25.4808 96.7588 26.8996 98.1468C29.5265 100.717 33.3757 100.168 36.316 98.0436C50.3894 87.8763 61.288 76.2629 71.1399 62.7886C71.0792 61.5374 70.2967 57.2339 69.7712 54.8843C69.8062 56.0648 69.5909 57.3856 68.8294 58.3776C59.4481 70.5983 48.2908 82.9186 35.9355 92.6021C32.2645 95.4794 30.0699 96.3224 26.3444 92.5023C26.3442 92.5023 26.3442 92.502 26.3439 92.502Z"
        fill="url(#paint2_linear_242_4581)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M88.1638 76.4618C87.8574 76.7347 87.7628 77.1745 87.9302 77.5492C88.0975 77.9239 88.4881 78.1473 88.8959 78.101C88.8968 78.1064 88.8968 78.1064 88.897 78.1064C92.5488 77.6932 94.9118 74.7675 94.7226 71.4674C94.4685 67.0262 93.9287 62.1361 92.9876 57.258C91.6213 56.1316 88.4669 53.7255 86.938 52.6862C86.938 52.6862 87.4367 53.6197 87.6346 54.203C88.8814 57.8813 89.3162 61.4543 89.9708 69.707C90.2055 72.6676 89.8452 75.0256 88.1638 76.4618Z"
        fill="url(#paint3_linear_242_4581)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M83.0305 49.9734C80.0474 47.8845 77.9457 46.47 75.3308 45.0867C75.3308 45.0867 76.811 46.1439 77.6495 48.8032C79.4406 54.4839 80.7841 62.3112 82.1797 79.907C82.4264 83.0193 81.9704 85.0645 80.0295 86.213C79.6613 86.4375 79.4984 86.8877 79.6382 87.2957C79.7778 87.7037 80.1825 87.9596 80.611 87.9112C80.6115 87.913 80.6117 87.913 80.6119 87.913C84.2637 87.4996 86.5521 84.5854 86.4378 81.2738C85.909 65.9828 85.555 61.8712 83.0305 49.9734Z"
        fill="url(#paint4_linear_242_4581)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.1424 96.5875C66.8095 96.6206 66.5283 96.8484 66.4267 97.167C66.3251 97.4857 66.4227 97.8343 66.675 98.0541C68.0556 99.2716 69.9322 99.9164 71.9019 99.6725C75.5492 99.2212 77.5198 95.8693 77.6925 92.2461C78.5183 74.9042 76.2337 59.1422 71.8418 43.0386C70.8257 42.3059 66.9734 40.2346 64.8085 39.1805C65.7536 39.8891 66.6518 40.8808 66.9526 42.0946C70.6575 57.049 73.3336 73.4535 73.201 89.1511C73.1614 93.8152 72.4524 96.0567 67.1429 96.5873C67.1426 96.5873 67.1424 96.5875 67.1424 96.5875Z"
        fill="url(#paint5_linear_242_4581)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.1462 38.2541C93.1686 38.6637 93.4535 39.0121 93.8508 39.1148C94.2481 39.2175 94.6662 39.0514 94.8844 38.7037C94.889 38.7066 94.8893 38.7063 94.8893 38.7063C96.8431 35.5934 96.0289 31.922 93.3309 30.0121C89.7003 27.4419 85.5405 24.815 81.1399 22.5091C79.4074 22.8749 75.5595 23.841 73.7937 24.3884C73.7937 24.3884 74.8344 24.5805 75.4137 24.7895C79.0668 26.1082 82.1315 27.996 88.9917 32.6299C91.453 34.2921 93.0719 36.0441 93.1462 38.2541Z"
        fill="url(#paint6_linear_242_4581)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.2363 25.752C65.7433 26.7818 63.327 27.5431 60.6149 28.7249C60.6149 28.7249 62.3647 28.2269 64.9664 29.2294C70.5245 31.3711 77.4816 35.2008 92.1089 45.0806C94.6959 46.8282 96.0106 48.46 95.6984 50.6937C95.6441 51.1215 95.8947 51.5293 96.3009 51.6745C96.7069 51.8197 97.1593 51.6629 97.3887 51.2977C97.3902 51.2984 97.3903 51.2982 97.3905 51.298C99.3443 48.1853 98.4926 44.5791 95.8321 42.604C83.5478 33.4835 80.1124 31.1966 69.2363 25.752Z"
        fill="url(#paint7_linear_242_4581)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95.7741 67.2375C95.5925 67.5184 95.5952 67.8804 95.781 68.1584C95.9668 68.4367 96.3002 68.5776 96.6294 68.5173C98.4421 68.1971 100.116 67.1321 101.154 65.4399C103.075 62.3069 101.683 58.6764 98.958 56.2823C85.9143 44.8241 72.167 36.7826 56.8385 30.1758C55.6321 30.5135 51.6105 32.2338 49.4367 33.2689C50.5799 32.9721 51.9156 32.8881 53.0521 33.4098C67.0538 39.837 81.5477 47.9731 93.7376 57.8641C97.3597 60.8031 98.6699 62.7551 95.7743 67.2372C95.7743 67.2372 95.7743 67.2375 95.7741 67.2375Z"
        fill="url(#paint8_linear_242_4581)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66.3809 10.5361C66.7152 10.7744 67.1652 10.7686 67.4933 10.5221C67.8213 10.2755 67.952 9.84516 67.8162 9.45769C67.8214 9.45591 67.8214 9.45569 67.8214 9.45546C66.6058 5.98725 63.2278 4.33466 60.0524 5.25319C55.7793 6.48928 51.1317 8.10346 46.5853 10.1062C45.7912 11.6889 44.1473 15.2996 43.4742 17.0214C43.4742 17.0214 44.2732 16.3276 44.7978 16.0051C48.1066 13.9711 51.4933 12.7522 59.3936 10.2777C62.2278 9.39003 64.6066 9.21651 66.3809 10.5361Z"
        fill="url(#paint9_linear_242_4581)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.6988 21.4345C40.326 24.8076 39.4146 27.1715 38.6477 30.0287C38.6477 30.0287 39.3492 28.3504 41.7553 26.9412C46.8951 23.931 54.227 20.8796 71.0714 15.6038C74.0507 14.6707 76.146 14.6602 77.6978 16.2972C77.9984 16.6063 78.4737 16.6646 78.8404 16.4377C79.207 16.2105 79.3665 15.7592 79.224 15.3523C79.2256 15.3514 79.2254 15.3512 79.2254 15.351C78.01 11.8825 74.6595 10.3001 71.4566 11.1485C56.6666 15.0662 52.7368 16.3262 41.6988 21.4345Z"
        fill="url(#paint10_linear_242_4581)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.6794 26.5529C90.7859 26.87 91.0704 27.0935 91.4038 27.1217C91.737 27.1498 92.0553 26.9772 92.2132 26.6822C93.0933 25.0653 93.3043 23.0923 92.6283 21.2262C91.3766 17.7707 87.6703 16.5951 84.0996 17.2331C67.0085 20.2866 52.1501 26.0209 37.4275 33.8859C36.9391 35.0396 35.7767 39.2564 35.2307 41.6013C35.7115 40.5224 36.4784 39.4259 37.595 38.8625C51.3499 31.9231 66.7478 25.664 82.0814 22.3008C86.6375 21.3014 88.9803 21.4941 90.6794 26.5524C90.6794 26.5526 90.6794 26.5526 90.6794 26.5529Z"
        fill="url(#paint11_linear_242_4581)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.0224 14.1801C28.417 14.0673 28.6931 13.7118 28.7049 13.3017C28.7167 12.8915 28.4615 12.5208 28.074 12.3854C28.0758 12.3803 28.0756 12.3803 28.0756 12.3801C24.606 11.1679 21.2077 12.7785 19.9462 15.8338C18.2482 19.9454 16.6126 24.5854 15.3437 29.3886C16.0858 30.9963 17.8838 34.5329 18.8103 36.1328C18.8103 36.1328 18.7661 35.0753 18.8409 34.4641C19.3137 30.6091 20.4723 27.2012 23.4637 19.4818C24.5368 16.7126 25.8843 14.7444 28.0224 14.1801Z"
        fill="url(#paint12_linear_242_4581)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.1537 40.2723C22.9349 43.4486 24.2148 45.6352 25.9703 48.016C25.9703 48.016 25.0955 46.4213 25.4942 43.6614C26.3453 37.7661 28.5311 30.1314 34.9088 13.6724C36.0368 10.7614 37.3352 9.11688 39.5823 8.92415C40.0115 8.88172 40.3534 8.54673 40.4046 8.1184C40.4557 7.69029 40.2023 7.28406 39.7951 7.1418C39.7956 7.14002 39.7954 7.14002 39.7951 7.14002C36.3256 5.9276 32.9994 7.56031 31.6657 10.5935C25.5071 24.5994 24.0419 28.4576 21.1537 40.2723Z"
        fill="url(#paint13_linear_242_4581)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.6947 5.1692C56.0089 5.28377 56.3613 5.20069 56.5911 4.95749C56.8209 4.71452 56.8841 4.35809 56.7521 4.0508C56.0366 2.35443 54.6256 0.959333 52.745 0.324425C49.2632 -0.851596 46.0332 1.3133 44.3058 4.50258C36.0368 19.7687 31.2559 34.9608 28.2252 51.375C28.8228 52.4762 31.3948 56.0141 32.8878 57.903C32.344 56.8545 31.9648 55.5713 32.2207 54.347C35.3713 39.2664 40.0786 23.3254 47.0094 9.24016C49.0687 5.05508 50.6802 3.34353 55.6942 5.16898C55.6944 5.1692 55.6944 5.1692 55.6947 5.1692Z"
        fill="url(#paint14_linear_242_4581)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.95477 46.4416C7.11243 46.0628 7.0068 45.6254 6.69348 45.3605C6.38016 45.0954 5.93128 45.0639 5.58401 45.2823C5.58088 45.2779 5.58088 45.2779 5.58066 45.2779C2.46976 47.2346 1.61019 50.8956 3.21209 53.7872C5.36806 57.6781 7.97603 61.8498 10.94 65.8368C12.6598 66.2589 16.5458 67.0582 18.3742 67.3313C18.3742 67.3313 17.52 66.7066 17.0887 66.2669C14.3696 63.4937 12.4275 60.4632 8.25741 53.3115C6.76137 50.7459 6.06281 48.4651 6.95477 46.4416Z"
        fill="url(#paint15_linear_242_4581)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.0718 68.0801C26.6657 68.6681 29.173 69.0308 32.1291 69.1426C32.1291 69.1426 30.3369 68.8322 28.4278 66.8C24.3494 62.4588 19.7432 55.9896 10.8518 40.7413C9.27912 38.0442 8.80277 36.0037 10.0532 34.1267C10.2877 33.7649 10.239 33.2885 9.93592 32.9814C9.63309 32.6746 9.15741 32.6194 8.7925 32.849C8.79139 32.8477 8.79116 32.8479 8.79094 32.8479C5.67982 34.8047 4.88256 38.4232 6.42237 41.3572C13.5325 54.9047 15.6354 58.4557 23.0718 68.0801Z"
        fill="url(#paint16_linear_242_4581)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1633 19.1886C17.449 19.0144 17.6035 18.687 17.5568 18.3558C17.5102 18.0246 17.271 17.7528 16.9483 17.6644C15.176 17.1664 13.2056 17.3995 11.5365 18.4739C8.44635 20.4631 8.12499 24.3377 9.54131 27.6771C16.3212 43.6603 25.2177 56.8704 36.1612 69.4741C37.3946 69.6934 41.7644 69.8885 44.1721 69.899C43.0132 69.6703 41.7736 69.1668 40.9759 68.2036C31.1499 56.3373 21.6218 42.7179 14.931 28.5172C12.943 24.2977 12.6095 21.9705 17.1631 19.1888L17.1633 19.1886Z"
        fill="url(#paint17_linear_242_4581)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0422 83.028C18.8443 82.6684 18.4365 82.4784 18.0339 82.5581C17.6314 82.6378 17.3268 82.969 17.281 83.3768C17.2755 83.3764 17.2755 83.3764 17.2755 83.3766C16.8657 87.0288 19.192 89.9836 22.4514 90.5339C26.8377 91.2744 31.7254 91.8365 36.6905 92.0049C38.0928 90.9236 41.1407 88.3837 42.494 87.1244C42.494 87.1244 41.4732 87.4031 40.8604 87.4659C36.9969 87.8627 33.4168 87.4916 25.2253 86.2928C22.2866 85.8629 20.0679 84.9868 19.0422 83.028Z"
        fill="url(#paint18_linear_242_4581)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.0085 83.9188C48.7091 81.4754 50.5558 79.7413 52.4866 77.4998C52.4866 77.4998 51.1261 78.7076 48.3471 78.9331C42.4103 79.4148 34.4803 78.9827 17.0151 76.427C13.9258 75.975 12.0336 75.0752 11.3458 72.9271C11.2091 72.5182 10.8062 72.2593 10.3774 72.3047C9.94867 72.3502 9.60899 72.6877 9.56076 73.1162C9.55919 73.1162 9.55897 73.1164 9.55897 73.1167C9.14917 76.7689 11.4809 79.6487 14.7349 80.274C29.7599 83.1618 33.8474 83.7319 46.0085 83.9188Z"
        fill="url(#paint19_linear_242_4581)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.10004 58.0542C4.1418 57.7223 3.98235 57.3974 3.69426 57.2274C3.40617 57.0575 3.04461 57.0751 2.77417 57.2721C1.27969 58.3472 0.233641 60.0333 0.0328731 62.0079C-0.338737 65.6641 2.49032 68.3313 5.98422 69.3059C22.7076 73.9709 38.5825 75.2519 55.2596 74.5542C56.2003 73.7266 59.0771 70.4319 60.5866 68.5562C59.6852 69.3195 58.5186 69.9747 57.2682 69.9977C41.8645 70.2814 25.2756 69.2391 10.0014 65.6159C5.46321 64.5395 3.43565 63.3492 4.10004 58.0546C4.10004 58.0546 4.10004 58.0544 4.10004 58.0542Z"
        fill="url(#paint20_linear_242_4581)"
      />
      <path
        d="M138.188 91.2183C136.984 91.2183 135.993 90.8288 135.214 90.0498C134.435 89.2707 134.045 88.2792 134.045 87.0752V12.3936C134.045 11.1896 134.435 10.1981 135.214 9.41905C135.993 8.64001 136.984 8.25049 138.188 8.25049C139.463 8.25049 140.525 8.74624 141.375 9.73775L192.155 76.452V12.3936C192.155 11.1896 192.544 10.1981 193.323 9.41905C194.102 8.64001 195.094 8.25049 196.298 8.25049C197.431 8.25049 198.387 8.64001 199.166 9.41905C200.016 10.1981 200.441 11.1896 200.441 12.3936V87.0752C200.441 88.2792 200.016 89.2707 199.166 90.0498C198.387 90.8288 197.431 91.2183 196.298 91.2183C195.731 91.2183 195.165 91.1121 194.598 90.8996C194.031 90.6872 193.571 90.333 193.217 89.8373L142.331 23.1231V87.0752C142.331 88.2792 141.906 89.2707 141.057 90.0498C140.278 90.8288 139.321 91.2183 138.188 91.2183Z"
        fill="#E75732"
      />
      <path
        d="M246.478 91.7495C240.742 91.7495 235.643 90.5101 231.181 88.0313C226.79 85.4818 223.32 81.9761 220.77 77.5143C218.291 73.0525 217.052 67.9533 217.052 62.2168C217.052 56.4094 218.22 51.3102 220.557 46.9192C222.965 42.4575 226.259 38.9518 230.437 36.4022C234.616 33.8526 239.432 32.5778 244.885 32.5778C250.267 32.5778 254.942 33.8172 258.908 36.296C262.944 38.7039 266.061 42.0679 268.256 46.3881C270.452 50.7082 271.549 55.6303 271.549 61.1544C271.549 62.2876 271.195 63.2083 270.487 63.9165C269.779 64.5539 268.858 64.8726 267.725 64.8726H222.576V58.0737H268.575L264.007 61.3669C264.078 57.2592 263.334 53.5765 261.776 50.3187C260.218 47.0609 257.987 44.5113 255.083 42.6699C252.25 40.8286 248.851 39.9079 244.885 39.9079C240.848 39.9079 237.307 40.864 234.262 42.7762C231.216 44.6883 228.844 47.3442 227.144 50.7436C225.515 54.0722 224.701 57.8966 224.701 62.2168C224.701 66.5369 225.621 70.3613 227.463 73.6899C229.375 77.0185 231.96 79.6389 235.218 81.5511C238.475 83.4633 242.229 84.4194 246.478 84.4194C249.028 84.4194 251.577 83.9945 254.127 83.1446C256.747 82.2239 258.837 81.0908 260.395 79.7452C261.174 79.1078 262.059 78.7891 263.051 78.7891C264.042 78.7183 264.892 78.9661 265.6 79.5327C266.521 80.3826 266.981 81.3033 266.981 82.2948C267.052 83.2863 266.663 84.1361 265.813 84.8444C263.476 86.8274 260.501 88.4917 256.889 89.8373C253.277 91.1121 249.807 91.7495 246.478 91.7495Z"
        fill="#E75732"
      />
      <path
        d="M305.164 91.7495C300.986 91.7495 296.843 91.0413 292.735 89.6248C288.627 88.2084 285.299 86.0837 282.749 83.2509C281.97 82.401 281.651 81.4449 281.793 80.3826C281.935 79.3202 282.466 78.3996 283.386 77.6205C284.378 76.9123 285.405 76.629 286.467 76.7707C287.53 76.9123 288.415 77.3726 289.123 78.1517C290.823 80.1347 293.054 81.6574 295.816 82.7197C298.649 83.782 301.765 84.3132 305.164 84.3132C310.405 84.3132 314.194 83.4279 316.531 81.6574C318.868 79.816 320.072 77.6205 320.143 75.0709C320.143 72.5213 318.939 70.4321 316.531 68.8032C314.123 67.1035 310.157 65.7933 304.633 64.8726C297.48 63.7394 292.239 61.7564 288.911 58.9235C285.582 56.0907 283.918 52.7266 283.918 48.8314C283.918 45.2195 284.874 42.2096 286.786 39.8016C288.698 37.3937 291.248 35.5877 294.435 34.3838C297.622 33.1798 301.127 32.5778 304.952 32.5778C309.697 32.5778 313.769 33.3923 317.168 35.0212C320.639 36.6501 323.436 38.8455 325.561 41.6076C326.269 42.5283 326.552 43.4844 326.411 44.4759C326.269 45.4674 325.703 46.2818 324.711 46.9192C323.861 47.415 322.87 47.592 321.736 47.4504C320.674 47.2379 319.753 46.7068 318.974 45.8569C317.204 43.8031 315.15 42.3158 312.813 41.3951C310.476 40.4036 307.785 39.9079 304.739 39.9079C300.844 39.9079 297.728 40.7223 295.391 42.3512C293.054 43.9093 291.885 45.8923 291.885 48.3003C291.885 49.9292 292.31 51.3456 293.16 52.5496C294.081 53.7535 295.603 54.8159 297.728 55.7366C299.923 56.6572 302.969 57.4363 306.864 58.0737C312.176 58.9235 316.354 60.1983 319.399 61.8981C322.516 63.527 324.711 65.4746 325.986 67.7409C327.331 69.9363 328.004 72.3443 328.004 74.9647C328.004 78.2933 327.013 81.2324 325.03 83.782C323.118 86.2608 320.426 88.2084 316.956 89.6248C313.557 91.0413 309.626 91.7495 305.164 91.7495Z"
        fill="#E75732"
      />
      <path
        d="M364.36 91.2183C360.607 91.2183 357.243 90.333 354.268 88.5625C351.365 86.792 349.063 84.384 347.363 81.3387C345.663 78.2225 344.814 74.6814 344.814 70.7154V18.7675C344.814 17.5636 345.168 16.5721 345.876 15.793C346.655 15.014 347.646 14.6245 348.85 14.6245C350.054 14.6245 351.046 15.014 351.825 15.793C352.604 16.5721 352.994 17.5636 352.994 18.7675V70.7154C352.994 74.3273 354.056 77.3018 356.181 79.6389C358.305 81.9052 361.032 83.0384 364.36 83.0384H367.229C368.362 83.0384 369.283 83.4279 369.991 84.207C370.699 84.986 371.053 85.9775 371.053 87.1815C371.053 88.3854 370.628 89.3769 369.778 90.156C368.928 90.8642 367.866 91.2183 366.591 91.2183H364.36ZM337.908 42.3512C336.846 42.3512 335.961 42.0325 335.253 41.3951C334.544 40.6869 334.19 39.837 334.19 38.8455C334.19 37.7832 334.544 36.9333 335.253 36.296C335.961 35.5877 336.846 35.2336 337.908 35.2336H364.998C366.06 35.2336 366.945 35.5877 367.654 36.296C368.362 36.9333 368.716 37.7832 368.716 38.8455C368.716 39.837 368.362 40.6869 367.654 41.3951C366.945 42.0325 366.06 42.3512 364.998 42.3512H337.908Z"
        fill="#E75732"
      />
      <defs>
        <linearGradient
          id="paint0_linear_242_4581"
          x1="57.3665"
          y1="100.122"
          x2="73.294"
          y2="84.9102"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E75732" />
          <stop offset="0.67" stopColor="#E45430" />
          <stop offset="1" stopColor="#D34022" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_242_4581"
          x1="44.6743"
          y1="100.122"
          x2="72.4372"
          y2="71.7794"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E75732" />
          <stop offset="0.67" stopColor="#E35430" />
          <stop offset="1" stopColor="#CA3D21" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_242_4581"
          x1="28.5941"
          y1="100.122"
          x2="71.0331"
          y2="61.2425"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E75732" />
          <stop offset="0.58" stopColor="#E55631" />
          <stop offset="0.87" stopColor="#D94D2B" />
          <stop offset="1" stopColor="#C53E20" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_242_4581"
          x1="92.4425"
          y1="77.0845"
          x2="90.4801"
          y2="55.1472"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E75732" />
          <stop offset="0.67" stopColor="#E45430" />
          <stop offset="1" stopColor="#D34022" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_242_4581"
          x1="84.5288"
          y1="87.0076"
          x2="79.6798"
          y2="47.6303"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E75732" />
          <stop offset="0.67" stopColor="#E35430" />
          <stop offset="1" stopColor="#CA3D21" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_242_4581"
          x1="74.5029"
          y1="99.5794"
          x2="70.5664"
          y2="42.158"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E75732" />
          <stop offset="0.58" stopColor="#E55631" />
          <stop offset="0.87" stopColor="#D94D2B" />
          <stop offset="1" stopColor="#C53E20" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_242_4581"
          x1="96.3009"
          y1="35.2973"
          x2="77.9263"
          y2="23.1537"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E75732" />
          <stop offset="0.67" stopColor="#E45430" />
          <stop offset="1" stopColor="#D34022" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_242_4581"
          x1="99.1248"
          y1="47.6712"
          x2="65.3152"
          y2="26.9106"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E75732" />
          <stop offset="0.67" stopColor="#E35430" />
          <stop offset="1" stopColor="#CA3D21" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_242_4581"
          x1="102.703"
          y1="63.3483"
          x2="55.3548"
          y2="30.624"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E75732" />
          <stop offset="0.58" stopColor="#E55631" />
          <stop offset="0.87" stopColor="#D94D2B" />
          <stop offset="1" stopColor="#C53E20" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_242_4581"
          x1="66.0361"
          y1="6.22641"
          x2="45.0854"
          y2="13.0206"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E75732" />
          <stop offset="0.67" stopColor="#E45430" />
          <stop offset="1" stopColor="#D34022" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_242_4581"
          x1="77.4712"
          y1="11.7337"
          x2="40.1598"
          y2="25.2226"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E75732" />
          <stop offset="0.67" stopColor="#E35430" />
          <stop offset="1" stopColor="#CA3D21" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_242_4581"
          x1="91.9586"
          y1="18.711"
          x2="36.8527"
          y2="35.3252"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E75732" />
          <stop offset="0.58" stopColor="#E55631" />
          <stop offset="0.87" stopColor="#D94D2B" />
          <stop offset="1" stopColor="#C53E20" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_242_4581"
          x1="24.4379"
          y1="11.7625"
          x2="16.687"
          y2="32.3784"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E75732" />
          <stop offset="0.67" stopColor="#E45430" />
          <stop offset="1" stopColor="#D34022" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_242_4581"
          x1="35.8734"
          y1="6.25597"
          x2="23.1559"
          y2="43.8373"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E75732" />
          <stop offset="0.67" stopColor="#E35430" />
          <stop offset="1" stopColor="#CA3D21" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_242_4581"
          x1="50.3613"
          y1="-0.720296"
          x2="28.9922"
          y2="52.7219"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E75732" />
          <stop offset="0.58" stopColor="#E55631" />
          <stop offset="0.87" stopColor="#D94D2B" />
          <stop offset="1" stopColor="#C53E20" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_242_4581"
          x1="2.82967"
          y1="47.7369"
          x2="14.1151"
          y2="66.6507"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E75732" />
          <stop offset="0.67" stopColor="#E45430" />
          <stop offset="1" stopColor="#D34022" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_242_4581"
          x1="5.65456"
          y1="35.363"
          x2="27.1073"
          y2="68.7377"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E75732" />
          <stop offset="0.67" stopColor="#E35430" />
          <stop offset="1" stopColor="#CA3D21" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_242_4581"
          x1="9.23348"
          y1="19.6863"
          x2="37.6924"
          y2="69.7142"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E75732" />
          <stop offset="0.58" stopColor="#E55631" />
          <stop offset="0.87" stopColor="#D94D2B" />
          <stop offset="1" stopColor="#C53E20" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_242_4581"
          x1="17.4827"
          y1="87.0605"
          x2="39.3064"
          y2="90.03"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E75732" />
          <stop offset="0.67" stopColor="#E45430" />
          <stop offset="1" stopColor="#D34022" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_242_4581"
          x1="9.56982"
          y1="77.1369"
          x2="49.0387"
          y2="81.1737"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E75732" />
          <stop offset="0.67" stopColor="#E35430" />
          <stop offset="1" stopColor="#CA3D21" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_242_4581"
          x1="-0.455166"
          y1="64.5645"
          x2="56.4018"
          y2="73.5069"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E75732" />
          <stop offset="0.58" stopColor="#E55631" />
          <stop offset="0.87" stopColor="#D94D2B" />
          <stop offset="1" stopColor="#C53E20" />
        </linearGradient>
      </defs>
    </SvgIcon>
  )
}

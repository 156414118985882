import ConfirmationDialog from 'components/ConfirmationDialog'
import React from 'react'

export type SessionInactiveDialogProps = {
  onConfirm: () => void
  onClose: () => void
  open: boolean
}

export default function SessionInactiveDialog({
  onConfirm,
  open,
  onClose,
}: SessionInactiveDialogProps) {
  return (
    <ConfirmationDialog
      open={open}
      title="Your session will expire"
      message="For your security, you will be automatically logged out due to inactivity."
      cancelLabel="Stay logged in"
      confirmLabel="Log out now"
      onConfirm={onConfirm}
      onClose={onClose}
      slotProps={{
        backdrop: { sx: { backdropFilter: 'blur(5px)' } },
      }}
    />
  )
}

export type PaymentResult = {
  cost: number
  label: string
  currency: 'USD' | 'AUS'
  paymentUrl: string
}
export type PaymentCurrency = 'USD' | 'AUS'

const calculators = {
  genetype(
    testIds: string[] = [],
    currency: PaymentCurrency,
  ): PaymentResult {
    // Default to USD.
    currency = currency || 'USD'
    switch (currency) {
      case 'USD': {
        const testCount = testIds.length
        const isHboc = testIds.includes(
          // Hardcoded value for specific HBOC test.
          'comprehensive-hboc-13-gene-genetype-test',
        )
        let cost = 0
        let paymentUrl = ''

        if (testCount === 1 && !isHboc) {
          paymentUrl = 'https://buy.stripe.com/5kA2bMeP23hf2XebIR'
          cost = 249
        }
        if (testCount > 1 && !isHboc) {
          paymentUrl = 'https://buy.stripe.com/9AQ6s28qEbNL2Xe00a'
          cost = 349
        }
        if (testCount === 1 && isHboc) {
          paymentUrl = 'https://buy.stripe.com/14k9EegXadVT1Ta28j'
          cost = 449
        }
        if (testCount === 2 && isHboc) {
          paymentUrl = 'https://buy.stripe.com/7sI6s236k053eFWfZa'
          cost = 698
        }
        if (testCount > 2 && isHboc) {
          paymentUrl = 'https://buy.stripe.com/00g8Aa36k197apG28l'
          cost = 798
        }

        const label = `$${cost} ${currency}`
        return { cost, currency, label, paymentUrl }
      }
      case 'AUS': {
        const cost = 250
        const label = `$${cost} ${currency}`
        const paymentUrl = 'https://pay.airwallex.com/hkgov02166g0'
        return { cost, currency, label, paymentUrl }
      }
    }
  },
}

function unsupportedLabCalculator(labId: string) {
  return (
    testIds: string[] = [],
    currency: PaymentCurrency,
  ): PaymentResult => {
    console.error('Unsupported payment calculator', {
      currency,
      labId,
      testIds,
    })
    return {
      cost: 0,
      currency: 'USD',
      label: 'N/A',
      paymentUrl: '',
    }
  }
}

export function paymentCalculator(
  labId: string,
  testIds: string[] = [],
  currency: PaymentCurrency,
) {
  const calculator =
    calculators[labId] ?? unsupportedLabCalculator(labId)
  return calculator(testIds, currency)
}

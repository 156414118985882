import {
  PaletteMode,
  PaletteOptions,
  ThemeOptions,
  alpha,
  createTheme,
} from '@mui/material'
import { green, grey } from '@mui/material/colors'
import chroma from 'chroma-js'
import { backdropClasses } from '@mui/material/Backdrop'

export const FontSize = {
  Default: 16,
  Large: 18,
}
export const pwaStatusBarHeight = 48
export const defaultPrimaryColor = '#E75732'
const errorColor = '#EF5350'
const successColor = green['A400']

export const BackgroundColor: {
  dark: PaletteOptions['background']
  light: PaletteOptions['background']
} = Object.freeze({
  dark: Object.freeze({
    default: 'rgb(31, 31, 35)',
    paper: 'rgb(42, 42, 46)',
  }),
  light: Object.freeze({
    default: '#f9f8f6',
    paper: '#fff',
  }),
})

export const TextColor: {
  dark: PaletteOptions['text']
  light: PaletteOptions['text']
} = Object.freeze({
  dark: Object.freeze({
    disabled: 'rgba(255,255,255,0.33)',
    primary: 'rgba(255, 255, 255, 0.87)',
    secondary: 'rgba(255,255,255,0.5)',
  }),
  light: Object.freeze({
    disabled: 'rgba(20, 16, 19, 0.33)', // rgba(0,0,0,0.33)
    primary: 'rgba(20, 16, 19, .86)', // rgba(0, 0, 0, 0.72)
    secondary: 'rgba(20, 16, 19, 0.5)', // rgba(0,0,0,0.5)
  }),
})

export const getPalettes = (
  primaryColor?: string,
  secondaryColor?: string,
  options?: {
    contrastThreshold?: number
  },
): {
  light: PaletteOptions
  dark: PaletteOptions
} => {
  primaryColor = primaryColor ?? defaultPrimaryColor
  secondaryColor = secondaryColor ?? grey[500]
  const contrastThreshold = options?.contrastThreshold
  const { augmentColor } = createTheme({
    palette: { contrastThreshold },
  }).palette
  const primary = augmentColor({ color: { main: primaryColor } })
  const secondary = augmentColor({ color: { main: secondaryColor } })
  const success = augmentColor({ color: { main: successColor } })
  const error = augmentColor({ color: { main: errorColor } })
  const lightTheme = createTheme({
    palette: {
      background: { ...BackgroundColor.light },
      contrastThreshold,
      error,
      mode: 'light',
      primary,
      secondary,
      success,
      text: TextColor.light,
    },
  })
  const darkTheme = createTheme({
    palette: {
      background: { ...BackgroundColor.dark },
      contrastThreshold,
      error,
      mode: 'dark',
      primary,
      secondary,
      success,
      text: TextColor.dark,
    },
  })

  return {
    dark: darkTheme.palette,
    light: lightTheme.palette,
  }
}

export function getTheme(options?: {
  mode?: PaletteMode
  primaryColor?: string
  secondaryColor?: string
  overrideComponents?: ThemeOptions['components']
  fontSize?: number
  background?: string
  contrastThreshold?: number
}) {
  const mode = options?.mode ?? 'light'
  const primaryColor = options?.primaryColor ?? defaultPrimaryColor
  const overrideComponents = options?.overrideComponents ?? {}
  const palettes = getPalettes(
    primaryColor,
    options?.secondaryColor,
    { contrastThreshold: options?.contrastThreshold },
  )
  const palette: PaletteOptions = palettes[mode]
  palette.background.default =
    options?.background ?? palette.background.default

  return createTheme({
    components: {
      MuiAutocomplete: {
        styleOverrides: {
          option: {
            // Hide option with empty label.
            '&:empty': { display: 'none' },
            fontSize: '0.875rem',
          },
        },
      },
      MuiButton: {
        defaultProps: {
          disableElevation: true,
        },
        styleOverrides: {
          root: {
            borderRadius: 8,
          },
        },
        variants: [
          {
            props: {
              variant: 'tertiary',
            },
            style: {
              '&:hover': {
                backgroundColor: chroma(primaryColor)
                  .alpha(0.15)
                  .css(),
              },
              backgroundColor: chroma(primaryColor).alpha(0.1).css(),
              color: primaryColor,
              textTransform: 'none',
            },
          },
        ],
      },
      MuiChip: {
        styleOverrides: {
          iconSmaller: {
            fontSize: '1.125rem',
          },
          label: {
            fontWeight: 500,
          },
          labelSmaller: {
            paddingLeft: 10,
            paddingRight: 10,
          },
        },
        variants: [
          {
            props: { size: 'smaller' },
            style: {
              '& > .MuiChip-label': {
                paddingLeft: 8,
                paddingRight: 8,
              },
              fontSize: '0.6875rem',
              height: 20,
            },
          },
        ],
      },
      MuiCssBaseline: {
        styleOverrides: {
          a: {
            color: primaryColor,
          },
          body: {
            '& a': {
              color: 'inherit',
            },
            '&.no-scroll': {
              overflow: 'hidden',
            },
            backgroundColor: palette.background.default,
            overflowX: 'hidden',
            overscrollBehaviorX: 'none',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            '&::-webkit-outer-spin-button,::-webkit-inner-spin-button':
              {
                appearance: 'none',
                margin: 0,
              },
            '&:autofill': {
              // Override Safari's yellow autofill text color.
              WebkitBoxShadow: 'inherit !important',
              WebkitTextFillColor: 'inherit !important',
              caretColor: 'inherit !important',
              color: 'inherit !important',
            },
            '&[type=number]': {
              appearance: 'textfield',
            },
          },
          root: {
            fontSize: 16,
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize: '.875em',
            fontWeight: 500,
            marginBottom: 4,
            textWrap: 'pretty',
          },
          shrink: {
            position: 'relative',
            transform: 'none',
          },
        },
      },
      MuiListSubheader: {
        styleOverrides: {
          root: {
            // borderBottom: `solid 1px ${palette.divider}`,
            color: palette.grey[700],
            fontSize: '.875em',
            fontWeight: 600,
            textTransform: 'uppercase',
          },
        },
      },
      // Fix Modal backdrop issue (workaround)
      // ref: https://github.com/mui/material-ui/issues/32286#issuecomment-1820685334
      MuiModal: {
        styleOverrides: {
          root: {
            [`&:has(> div.${backdropClasses.root}[style*="opacity: 0"])`]:
              {
                pointerEvents: 'none',
              },
          },
        },
      },
      MuiPaper: {
        defaultProps: {
          variant: 'outlined',
        },
        styleOverrides: {
          outlined: {
            borderColor:
              mode === 'light'
                ? alpha(palette.divider, 0.08)
                : alpha(palette.divider, 0.05),
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            minHeight: 64,
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: '.875rem',
          },
        },
      },
      ...overrideComponents,
    },
    palette,
    shape: { borderRadius: 6 },
    typography: {
      body1: {
        fontSize: '1.0rem',
      },
      body2: {
        fontSize: '.875rem',
      },
      button: {
        textTransform: 'none',
      },
      fontFamily: [
        'Inter',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
      ].join(','),
      fontWeightBold: 600,
      h1: {
        fontSize: '2.0rem',
        fontWeight: 400,
        lineHeight: 1.5,
      },
      h2: {
        fontSize: '1.75rem',
        fontWeight: 400,
        lineHeight: 1.5,
      },
      h3: {
        fontSize: '1.5rem',
        fontWeight: 400,
        lineHeight: 1.5,
      },
      h4: {
        fontSize: '1.25rem',
        fontWeight: 400,
        lineHeight: 1.5,
      },
      h5: {
        fontSize: '1.0rem',
        fontWeight: 500,
        lineHeight: 1.5,
      },
      h6: {
        fontSize: '.875rem',
        fontWeight: 500,
        lineHeight: 1.5,
      },
      htmlFontSize: 16, // Default is 16
      subtitle1: {
        fontSize: '1.0rem',
      },
      subtitle2: {
        fontSize: '.875rem',
      },
    },
  })
}

export const mixins = {
  bgBlur: {
    backdropFilter: 'blur(4px)',
    bgcolor: ({ palette }) => alpha(palette.background.default, 0.95),
  },
  elevationHover: {
    '&:hover, &:focus-within': {
      boxShadow: ({ palette }) => `0 0 0 2px ${palette.divider}`,
    },
    transition: '300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  responsiveWidth: {
    maxWidth: () => 720,
    mx: 'auto',
    width: 1,
  },
  scrollHorizontal: {
    '&::-webkit-scrollbar': {
      display: 'none',
    } as const,
    MsOverflowStyle: 'none',
    overflowX: 'auto',
    overflowY: 'hidden',
    scrollbarWidth: 'none',
  },
  scrollVertical: {
    '&::-webkit-scrollbar': {
      display: 'none',
    } as const,
    MsOverflowStyle: 'none',
    overflowX: 'hidden',
    overflowY: 'auto',
    scrollbarWidth: 'none',
  },
  textOverflowEllipsis: {
    '> *': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  textOverflowEllipsisMultiline: {
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '2',
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}

export function getBackgroundColorForMode(
  bgcolor: string,
  mode: 'light' | 'dark',
) {
  const lightness = mode === 'light' ? 0.9925 : 0.15

  return chroma(bgcolor).set('hsl.l', lightness).css()
}

export function getPreferredThemeMode() {
  return window?.matchMedia('(prefers-color-scheme: dark)').matches
    ? 'dark'
    : 'light'
}

// Adding custom typography variants.
// https://mui.com/customization/typography/#adding-amp-disabling-variants
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    overline: false
  }
}
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    tertiary: true
  }
}
declare module '@mui/material/Chip' {
  interface ChipPropsSizeOverrides {
    smaller: true
  }
  interface ChipClasses {
    iconSmaller: true
    labelSmaller: true
  }
}

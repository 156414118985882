import React, {
  ChangeEvent,
  MouseEvent,
  ReactNode,
  RefObject,
  useContext,
  useEffect,
  useState,
} from 'react'
import {
  Badge,
  Box,
  Collapse,
  FormControlLabel,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Radio,
  RadioGroup,
  Stack,
  SwipeableDrawer,
  Toolbar,
} from '@mui/material'
import { AppContext } from 'components/AppContext'
import Switch from '@mui/material/Switch'
import { FontSize, pwaStatusBarHeight } from 'components/NestMuiTheme'
import usePatientTestCase, {
  PatientTestCase,
} from 'hooks/usePatientTestCase'
import SettingsStore from 'stores/SettingsStore'
import UserStore from 'stores/UserStore'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import AppIcon from 'components/AppIcon'
import { useRouter } from 'next/router'
import { useLoginActions } from '@frontegg/react'
import Link from 'next/link'
import { grey } from '@mui/material/colors'
import { StoryPlayerContext } from 'components/StoryPlayerContext'

type MainMenuDrawerProps = {
  open?: boolean
  onClose: () => void
}

export default function MainMenuDrawer(
  props: MainMenuDrawerProps,
): JSX.Element {
  const router = useRouter()
  const {
    themeMode,
    setThemeMode,
    fontSize,
    setFontSize,
    featureFlags,
  } = useContext(AppContext)
  const { storyProgress } = useContext(StoryPlayerContext)
  const { logout } = useLoginActions()
  const {
    patientTestCase,
    setPatientTestCase,
    patientTestCasesOpen,
    setPatientTestCasesOpen,
  } = usePatientTestCase()
  const [debugToolsOpen, setDebugToolsOpen] = useState(false)
  const hasLargeText = fontSize === FontSize.Large
  const { demoAccount, patientAppCarePlan, patientAppCareTeam } =
    featureFlags

  useEffect(() => {
    UserStore.set('user.fontSize', fontSize || FontSize.Default)
  }, [fontSize])

  useEffect(() => {
    UserStore.set('user.themeMode', themeMode)
  }, [themeMode])

  return (
    <SwipeableDrawer
      disableDiscovery
      disableSwipeToOpen
      disableScrollLock
      disableRestoreFocus
      anchor="right"
      open={props.open}
      ModalProps={{ keepMounted: true }}
      PaperProps={{
        sx: {
          '@media all and (display-mode: standalone)': {
            pt: `${pwaStatusBarHeight}px`,
          },
          backgroundImage: 'none',
          minWidth: 240,
          width: '25%',
        },
        variant: 'elevation',
      }}
      sx={{
        '& .MuiListItemIcon-root': {
          color: 'inherit',
        },
        '& .MuiSvgIcon-root': {
          color: 'text.secondary',
        },
        '& .MuiSwitch-root': {
          ml: -0.5,
          mr: 0.5,
        },
      }}
      onOpen={() => null}
      onClose={props.onClose}
    >
      <Stack sx={{ '& a': { textDecoration: 'none' }, pb: 8 }}>
        <Toolbar sx={{ justifyContent: 'flex-end' }}>
          <IconButton onClick={props.onClose} edge="end">
            <AppIcon.Close fontSize="large" />
          </IconButton>
        </Toolbar>
        <List sx={{ mb: 1 }}>
          {/* <Link href="/notifications">
            <DrawerListItemButton
              onClick={props.onClose}
              icon={
                <Badge
                  // badgeContent={2}
                  color="primary"
                  overlap="circular"
                  variant="dot"
                >
                  <SvgIcon
                    fontSize="small"
                    component={NotificationsIcon}
                  />
                </Badge>
              }
            >
              Notifications
            </DrawerListItemButton>
          </Link> */}
          <Link href="/">
            <DrawerListItemButton
              onClick={props.onClose}
              icon={<AppIcon.Todos />}
            >
              To-dos
            </DrawerListItemButton>
          </Link>
          <Link href="/profile">
            <DrawerListItemButton
              onClick={props.onClose}
              icon={<AppIcon.User />}
            >
              Profile
            </DrawerListItemButton>
          </Link>
          {patientAppCarePlan && (
            <Link href="/care-plan">
              <DrawerListItemButton
                onClick={props.onClose}
                icon={
                  <Badge
                    badgeContent={0}
                    color="primary"
                    overlap="circular"
                    variant="dot"
                  >
                    <AppIcon.CarePlan />
                  </Badge>
                }
              >
                Care Plan
              </DrawerListItemButton>
            </Link>
          )}
          {demoAccount && (
            <Link href="/journey">
              <DrawerListItemButton
                onClick={props.onClose}
                icon={
                  <Badge
                    badgeContent={0}
                    color="primary"
                    overlap="circular"
                    variant="dot"
                  >
                    <AppIcon.Journey />
                  </Badge>
                }
              >
                Journey
              </DrawerListItemButton>
            </Link>
          )}
          {patientAppCareTeam && (
            <Link href="/care-team">
              <DrawerListItemButton
                onClick={props.onClose}
                icon={<AppIcon.CareTeam />}
              >
                Care Team
              </DrawerListItemButton>
            </Link>
          )}
          {demoAccount && (
            <Link href="/share">
              <DrawerListItemButton
                onClick={props.onClose}
                icon={<AppIcon.Share />}
              >
                Share
              </DrawerListItemButton>
            </Link>
          )}

          <Link href="/learn">
            <DrawerListItemButton
              onClick={props.onClose}
              icon={<AppIcon.Learn />}
            >
              Learn
            </DrawerListItemButton>
          </Link>
          <Link href="/support">
            <DrawerListItemButton
              onClick={props.onClose}
              icon={<AppIcon.Support />}
            >
              Support
            </DrawerListItemButton>
          </Link>
          <Link href="/login">
            <DrawerListItemButton
              onClick={() => logout()}
              icon={<AppIcon.Logout />}
            >
              Log out
            </DrawerListItemButton>
          </Link>
        </List>
        <List
          subheader={<ListSubheader>Preferences</ListSubheader>}
          sx={{ mb: 1 }}
        >
          <DrawerListItemButton
            onClick={() =>
              setThemeMode(themeMode === 'dark' ? 'light' : 'dark')
            }
            icon={<AppIcon.DarkMode />}
          >
            <Stack
              direction="row"
              sx={{ justifyContent: 'space-between' }}
            >
              <Box>Dark mode</Box>
              <Switch
                edge="end"
                checked={themeMode === 'dark'}
                inputProps={{
                  'aria-labelledby': 'switch-label-theme-mode',
                }}
                size="small"
              />
            </Stack>
          </DrawerListItemButton>
          <DrawerListItemButton
            onClick={() =>
              setFontSize(
                hasLargeText ? FontSize.Default : FontSize.Large,
              )
            }
            icon={<AppIcon.LargeText />}
          >
            <Stack
              direction="row"
              sx={{ justifyContent: 'space-between' }}
            >
              <Box>Large text</Box>
              <Switch
                edge="end"
                checked={hasLargeText}
                size="small"
              />
            </Stack>
          </DrawerListItemButton>
          {/* <Link href="/help">
            <DrawerListItemButton onClick={props.onClose}>
              Help & Feedback
            </DrawerListItemButton>
          </Link> */}
        </List>
        {demoAccount && (
          <List
            subheader={
              <ListSubheader
                onClick={() => setDebugToolsOpen(!debugToolsOpen)}
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                Debug Tools
                {debugToolsOpen ? <ExpandLess /> : <ExpandMore />}
              </ListSubheader>
            }
            sx={{ mb: 1 }}
          >
            <Collapse in={debugToolsOpen}>
              <>
                <ListItemButton
                  onClick={() => {
                    storyProgress.resetProgress()
                    SettingsStore.reset()
                    window.location.reload()
                  }}
                >
                  <ListItemText primary="Reset Data" />
                </ListItemButton>
                <ListItemButton
                  onClick={() => {
                    storyProgress.resetProgress()
                    window.location.reload()
                  }}
                >
                  <ListItemText primary="Reset Progress" />
                </ListItemButton>
                <ListItemButton
                  onClick={() => {
                    setPatientTestCasesOpen(!patientTestCasesOpen)
                  }}
                >
                  <ListItemText primary="Patient test case" />
                  {patientTestCasesOpen ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </ListItemButton>
                <Collapse
                  in={patientTestCasesOpen}
                  timeout="auto"
                  unmountOnExit
                >
                  {patientTestCase && (
                    <RadioGroup
                      value={patientTestCase}
                      name="patientTestCase"
                      onChange={({
                        target,
                      }: ChangeEvent<HTMLInputElement>) => {
                        const value = target.value as PatientTestCase

                        setPatientTestCase(value)
                        storyProgress.resetProgress()
                        SettingsStore.set(
                          'settings.patientTestCase',
                          value,
                        )
                        // Remove override URL param and reload using
                        // selected patient case
                        const path = router.asPath.replace(
                          /overridePatientTestCase=\w+&?/,
                          '',
                        )
                        router.replace(path)
                        window.setTimeout(() => router.reload(), 1000)
                      }}
                    >
                      <List component="div">
                        {Object.keys(PatientTestCase).map((key) => (
                          <ListItemButton key={`testCase${key}`}>
                            <FormControlLabel
                              value={PatientTestCase[key]}
                              control={<Radio sx={{ py: 0.25 }} />}
                              label={key}
                              sx={{ px: 0.5, width: 1 }}
                            />
                          </ListItemButton>
                        ))}
                      </List>
                    </RadioGroup>
                  )}
                </Collapse>
              </>
            </Collapse>
          </List>
        )}
        <Stack
          sx={{
            '& a': {
              '&:hover': {
                textDecoration: 'underline',
              },
              color: 'primary.main',
              textDecoration: 'none',
            },
            gap: 1,
            p: 2,
            typography: 'body2',
          }}
        >
          <a
            href="https://www.nestgenomics.com/tos"
            target="_blank"
            rel="noreferrer"
          >
            Terms of Service
          </a>
          <a
            href="https://www.nestgenomics.com/privacy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
        </Stack>
      </Stack>
    </SwipeableDrawer>
  )
}

type DrawerListItemButtonProps = {
  onClick: (e: MouseEvent<HTMLButtonElement>) => void
  icon?: ReactNode
  children: ReactNode
}

const DrawerListItemButton = React.forwardRef(
  function DrawerListItemButton(
    props: DrawerListItemButtonProps,
    ref: RefObject<HTMLButtonElement>,
  ) {
    return (
      <ListItemButton
        component="button"
        onClick={props.onClick}
        sx={{ width: 1 }}
        ref={ref}
      >
        {props.icon && (
          <ListItemIcon
            sx={{
              '&.MuiListItemIcon-root .MuiSvgIcon-root': {
                color: grey[500],
              },
              minWidth: 40,
              px: 0.5,
            }}
          >
            {props.icon}
          </ListItemIcon>
        )}
        <ListItemText primary={props.children} />
      </ListItemButton>
    )
  },
)

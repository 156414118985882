import { StoryConnectionRule } from 'components/StoryPlayer'
import {
  FeatureTestConfig,
  FeatureTestModule,
} from 'components/StoryPlayer/Modules/FeatureTest'
import {
  HistoryCollectionConfig,
  HistoryCollectionModule,
} from 'components/StoryPlayer/Modules/HistoryCollection'
import {
  ChaptersConfig,
  ChaptersModule,
} from 'components/StoryPlayer/Modules/Chapters'
import {
  OrderConfig,
  OrderModule,
} from 'components/StoryPlayer/Modules/Order'
import { InterpolationData } from 'hooks/useInterpolate'

export type StoryModuleType =
  | typeof FeatureTestModule.type
  | typeof HistoryCollectionModule.type
  | typeof ChaptersModule.type
  | typeof OrderModule.type

export type BaseModuleConfig =
  | {
      connectionRules: StoryConnectionRule[]
      defaultConnectionPageId: string
    }
  | {
      connectionRules: StoryConnectionRule[]
      defaultConnectionPageId?: never
    }
  | {
      connectionRules?: never
      defaultConnectionPageId: string
    }

export type StoryModuleConfig =
  | FeatureTestConfig
  | HistoryCollectionConfig
  | ChaptersConfig
  | OrderConfig

export function getModuleStory(
  config: StoryModuleConfig,
  interpolationData?: InterpolationData,
) {
  switch (config?.type) {
    case 'FeatureTest': {
      return FeatureTestModule.getStory()
    }
    case 'HistoryCollection': {
      return HistoryCollectionModule.getStory(config)
    }
    case 'Chapters': {
      return ChaptersModule.getStory(config, interpolationData)
    }
    case 'Order': {
      return OrderModule.getStory(config, interpolationData)
    }
    default:
      return null
  }
}

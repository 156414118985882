import React, {
  MouseEvent,
  Ref,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  Box,
  IconButton,
  InputAdornment,
  Paper,
  Popover,
  Stack,
} from '@mui/material'
import TextField, { TextFieldProps } from 'components/TextField'
import DropDownIcon from '@mui/icons-material/ArrowDropDown'
import ClearIcon from '@mui/icons-material/ClearRounded'
import DurationField, {
  DurationValue,
  getDurationLabel,
} from 'components/DurationField'
import { times as _times, omit } from 'lodash'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import {
  bindToggle,
  usePopupState,
} from 'material-ui-popup-state/hooks'

export type FrequencyValue = `${string},${DurationValue}` | ''

export function getFrequencyLabel(value: FrequencyValue) {
  if (!value) return ''
  const [times, duration] = value.split(',')
  const timesLabel = frequencyTimesOptions.find(
    ({ value }) => value === times,
  )?.label

  if (!timesLabel || !duration) {
    return ''
  }

  if (times === '1') {
    const label = {
      P1D: 'Daily',
      P1M: 'Monthly',
      P1W: 'Weekly',
      P1Y: 'Annually',
    }[duration]
    if (label) {
      return label
    }
  }

  return `${timesLabel} every ${getDurationLabel(
    duration as DurationValue,
  )}`
}

const frequencyTimesOptions = [
  {
    label: 'N/A',
    value: '',
  },
  {
    label: 'Once',
    value: '1',
  },
  {
    label: 'Twice',
    value: '2',
  },
  ..._times(4, (n) => ({
    label: `${n + 3} times`,
    value: String(n + 3),
  })),
]

function getDurationFromFrequency(value: FrequencyValue) {
  const times = getTimesFromFrequency(value)
  // Empty if times is invalid.
  return (times ? value.replace(/^(\d+)?,/, '') : '') as DurationValue
}

function getTimesFromFrequency(value: FrequencyValue) {
  const times = value.match(/(\d+),(\w+)?/)?.[1]
  const option = frequencyTimesOptions.find(
    (item) => item.value === times,
  )
  return option ? times : ''
}

type FrequencyValues = {
  times: string
  duration: DurationValue
}

type FrequencyFieldProps = {
  name?: TextFieldProps['name']
  disabled?: TextFieldProps['disabled']
  label?: TextFieldProps['label']
  required?: TextFieldProps['required']
  value: FrequencyValue
  onChange: (e: { name: string; value: FrequencyValue }) => void
  onBlur: TextFieldProps['onBlur']
  error?: boolean
  asDisplay?: TextFieldProps['asDisplay']
  sx: TextFieldProps['sx']
}

const FrequencyField = React.forwardRef(function FrequencyField(
  props: FrequencyFieldProps,
  ref: Ref<HTMLDivElement>,
) {
  const { onChange, onBlur, name, ...textFieldProps } = props
  const formMethods = useForm<FrequencyValues>({
    defaultValues: {
      duration: getDurationFromFrequency(props.value),
      times: getTimesFromFrequency(props.value),
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const { control, handleSubmit, reset, watch } = formMethods
  const times = watch('times')
  const duration = watch('duration')
  const [isHovered, setIsHovered] = useState(false)
  const { disabled } = props
  const popupState = usePopupState({
    popupId: 'frequencyField',
    variant: 'popper',
  })
  const { isOpen } = popupState
  const handleClear = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      e.stopPropagation()
      reset({
        duration: '',
        times: '',
      })
      onChange({ name, value: '' })
    },
    [onChange, name, reset],
  )
  const value = useMemo(() => {
    if (!times || !duration) {
      return ''
    }
    return `${times},${duration}` as FrequencyValue
  }, [duration, times])
  const valueAsLabel = useMemo(
    () => getFrequencyLabel(`${times},${duration}`) || 'N/A',
    [times, duration],
  )

  useEffect(() => {
    reset({
      duration: getDurationFromFrequency(props.value),
      times: getTimesFromFrequency(props.value),
    })
  }, [reset, props.value])

  if (props.asDisplay) {
    return (
      <TextField
        label={props.label}
        value={valueAsLabel}
        sx={props.sx}
        error={props.error}
        asDisplay
      />
    )
  }

  return (
    <FormProvider {...formMethods}>
      <TextField
        ref={ref}
        {...textFieldProps}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        value={valueAsLabel}
        {...bindToggle(popupState)}
        sx={{
          ...props.sx,
          '& .MuiOutlinedInput-root, &.MuiTextField-root .MuiOutlinedInput-input':
            {
              cursor: 'pointer',
            },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment
              position="end"
              sx={{
                '& .MuiButton-root': { mr: -0.25 },
              }}
            >
              <IconButton
                sx={{
                  p: 0.5,
                  position: 'relative',
                  visibility:
                    isHovered || isOpen ? 'visible' : 'hidden',
                  zIndex: 1301,
                }}
                onClick={handleClear}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
              <DropDownIcon
                sx={{
                  transform: isOpen ? 'scaleY(-1)' : null,
                }}
              />
            </InputAdornment>
          ),
          readOnly: true,
        }}
      />
      <Popover
        open={isOpen}
        anchorEl={popupState.anchorEl}
        disablePortal
        onClose={handleSubmit(() => {
          onChange({ name, value })
          popupState.close()
          props.onBlur?.(null)
        })}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
      >
        <Paper
          sx={{
            alignItems: 'flex-start',
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            maxWidth: 400,
            p: 2,
          }}
        >
          <Stack direction="row" gap={1}>
            <Stack
              direction="row"
              gap={1}
              sx={{ alignItems: 'center' }}
            >
              <Controller
                name="times"
                control={control}
                rules={{ required: props.required }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...omit(field, ['ref'])}
                    select
                    options={frequencyTimesOptions}
                    disabled={disabled}
                    onChange={({ target }) => {
                      field.onChange(target.value)
                    }}
                    onBlur={onBlur}
                    sx={{ minWidth: 102 }}
                    error={!!fieldState.error}
                  />
                )}
              />
              <Box
                color={disabled ? 'text.disabled' : 'text.primary'}
              >
                every
              </Box>
              <Controller
                name="duration"
                control={control}
                rules={{ required: props.required }}
                render={({ field, fieldState }) => (
                  <DurationField
                    {...omit(field, ['ref'])}
                    disabled={disabled}
                    onChange={({ value }) => {
                      field.onChange(value)
                    }}
                    sx={{ maxWidth: 300 }}
                    onBlur={onBlur}
                    error={!!fieldState.error}
                  />
                )}
              />
            </Stack>
          </Stack>
        </Paper>
      </Popover>
    </FormProvider>
  )
})

export default FrequencyField

import React from 'react'
import { Chip, Stack } from '@mui/material'
import {
  CareTemplate,
  CareTemplateVersionStatus,
} from '__generated__/graphql'
import {
  DurationValue,
  getDurationLabel,
} from 'components/DurationField'

const { DRAFT } = CareTemplateVersionStatus

export const PREDRAFT_VERSION_ID = 'PREDRAFT_VERSION_ID'

export function getVersionOptions(
  careTemplate: CareTemplate,
  hasPreDraft: boolean,
) {
  const options = careTemplate.CareTemplateVersions.map((item) => ({
    id: item.id,
    revision: item.revision,
    status: item.status,
  }))

  // Add temporary version option when creating a new draft version.
  if (hasPreDraft) {
    options.unshift({
      id: PREDRAFT_VERSION_ID,
      revision: options.length + 1,
      status: DRAFT,
    })
  }

  return options.map(({ revision, id, status }) => ({
    label: (
      <Stack
        direction="row"
        sx={{ alignItems: 'center', gap: 1 }}
        title={id}
      >
        {revision}
        <Stack direction="row" sx={{ gap: 0.5 }}>
          {status === DRAFT && (
            <Chip
              label="Draft"
              size="smaller"
              color="warning"
              variant="outlined"
            />
          )}
          {id === careTemplate.currentVersionId && (
            <Chip
              label="Current"
              size="smaller"
              color="success"
              variant="outlined"
            />
          )}
        </Stack>
      </Stack>
    ),
    value: id,
  }))
}

export const nestStandardAccountId = 'nest-standard-care-plans'

export function getDueLabel(value: DurationValue) {
  return `Due in ${getDurationLabel(value)}`
}

import { DynamicChapterNavigationEvent } from 'components/StoryPlayer/StoryEvents'
import pageTransformer, {
  PageTransformer,
} from 'components/StoryPlayer/pageTransformer'
import {
  StoryPlayerContext,
  StoryPlayerContextProps,
} from 'components/StoryPlayerContext'
import { useDynamicChapterQuery } from 'hooks/useDynamicChapterQuery'
import useInterpolate, {
  useInterpolationData,
} from 'hooks/useInterpolate'
import { MutableRefObject, useCallback, useRef } from 'react'

export function useDynamicChapter() {
  const dynamicChapterQuery = useDynamicChapterQuery()
  const interpolationData = useInterpolationData(StoryPlayerContext)
  const interpolate = useInterpolate(StoryPlayerContext)
  const transformerData = useRef({})
  const getPages = useCallback(
    async (
      params: DynamicChapterNavigationEvent['params'],
      questionnaireResponses: MutableRefObject<
        StoryPlayerContextProps['questionnaireResponses']
      >,
    ) => {
      await Promise.all(
        (params.queries ?? []).map(async (query) => {
          const variables = query.variables
            ? JSON.parse(interpolate(JSON.stringify(query.variables)))
            : null
          const { data } = await dynamicChapterQuery[query.name]({
            variables,
          })
          transformerData.current = {
            ...transformerData.current,
            ...data,
          }
        }),
      )

      const transformer: PageTransformer<Record<string, unknown>> =
        pageTransformer[params.pageTransformer]
      const pages = transformer?.({
        data: transformerData.current,
        options: {
          interpolate,
          interpolationData,
          questionnaireResponses,
          ...params.options,
        },
        returnToPageId: params.returnToPageId,
      })
      return pages
    },
    [dynamicChapterQuery, interpolate, interpolationData],
  )

  return {
    getPages,
  }
}

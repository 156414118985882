import { isPlainObject } from 'lodash'
import { StoryProgressState } from 'models/StoryProgress'
import { getCurrentIsoDate } from '__shared__/utils/DateUtil'
import LocalStorageUtil from 'utils/LocalStorageUtil'

export default class StoryProgressStore {
  static key = 'storyProgress'

  static get() {
    const progress = LocalStorageUtil.getItem<StoryProgressState>(
      StoryProgressStore.key,
    )
    const value: StoryProgressState = isPlainObject(progress)
      ? progress
      : {
          dateCreated: getCurrentIsoDate(),
          dateModified: getCurrentIsoDate(),
          progress: {},
        }
    StoryProgressStore.log('get', value)
    return value
  }

  static set(value: StoryProgressState) {
    StoryProgressStore.log('set', value)
    LocalStorageUtil.setItem(StoryProgressStore.key, value)
  }

  static reset() {
    StoryProgressStore.log('reset')
    LocalStorageUtil.removeItem(StoryProgressStore.key)
  }

  static log(...rest) {
    if (localStorage.getItem('debugEnabled')) {
      console.log(
        '%cStoryProgressStore',
        'border-radius:4px; padding:0 4px; color:white; background-color:#4829ef;',
        ...rest,
      )
    }
  }
}

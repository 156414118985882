import React, { ElementType } from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'
import JourneyIcon from 'assets/icons/road-straight.svg'
import UserIcon from 'assets/icons/user.svg'
import CarePlanIcon from 'assets/icons/task-list-approve.svg'
import TodosIcon from 'assets/icons/todos.svg'
import ShareIcon from 'assets/icons/task-list-share.svg'
import LearnIcon from 'assets/icons/read-human.svg'
import SupportIcon from 'assets/icons/help-wheel.svg'
import AppointmentIcon from 'assets/icons/appointments.svg'
import QuestionnaireIcon from 'assets/icons/task-list-edit.svg'
import ConsentDocumentIcon from 'assets/icons/task-list-check.svg'
import TestResultIcon from 'assets/icons/test-result.svg'
import StepIcon from 'assets/icons/step.svg'
import GeneIcon from 'assets/icons/gene.svg'
import BookPlayIcon from 'assets/icons/book-play.svg'
import ButtonPlayIcon from 'assets/icons/button-play.svg'
import ControlsPlayIcon from 'assets/icons/controls-play.svg'
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded'
import CareTeamIcon from 'assets/icons/care-team.svg'
import LogoutIcon from 'assets/icons/logout.svg'
import DarkModeIcon from 'assets/icons/dark-mode.svg'
import LargeTextIcon from 'assets/icons/large-text.svg'
import QuestionIcon from 'assets/icons/question.svg'
import EmptyTasksImage from 'assets/images/empty-tasks.svg'
import CleanImage from 'assets/images/clean.svg'
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import MenuIcon from '@mui/icons-material/MenuRounded'
import BackIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import ChevronRightIcon from '@mui/icons-material/ArrowForwardIosRounded'
import CloseIcon from '@mui/icons-material/CloseRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded'

export default function AppIcon(): JSX.Element {
  return null
}

function getAppIcon(IconType: ElementType) {
  return function AppIcon(props: SvgIconProps<'svg', unknown>) {
    return (
      <SvgIcon
        color="inherit"
        fontSize="small"
        {...props}
        component={IconType}
      />
    )
  }
}

AppIcon.Appointment = getAppIcon(AppointmentIcon as ElementType)
AppIcon.ArrowForward = getAppIcon(
  ArrowForwardRoundedIcon as ElementType,
)
AppIcon.ArrowBack = getAppIcon(ArrowBackRoundedIcon as ElementType)
AppIcon.Back = getAppIcon(BackIcon as ElementType)
AppIcon.BookPlay = getAppIcon(BookPlayIcon as ElementType)
AppIcon.ButtonPlay = getAppIcon(ButtonPlayIcon as ElementType)
AppIcon.CarePlan = getAppIcon(CarePlanIcon as ElementType)
AppIcon.CareTeam = getAppIcon(CareTeamIcon as ElementType)
AppIcon.Check = getAppIcon(CheckCircleRoundedIcon as ElementType)
AppIcon.ChevronRight = getAppIcon(ChevronRightIcon as ElementType)
AppIcon.Clean = getAppIcon(CleanImage as ElementType)
AppIcon.Close = getAppIcon(CloseIcon as ElementType)
AppIcon.ConsentDocument = getAppIcon(
  ConsentDocumentIcon as ElementType,
)
AppIcon.ControlsPlay = getAppIcon(ControlsPlayIcon as ElementType)
AppIcon.DarkMode = getAppIcon(DarkModeIcon as ElementType)
AppIcon.EmptyTasks = getAppIcon(EmptyTasksImage as ElementType)
AppIcon.Gene = getAppIcon(GeneIcon as ElementType)
AppIcon.Journey = getAppIcon(JourneyIcon as ElementType)
AppIcon.LargeText = getAppIcon(LargeTextIcon as ElementType)
AppIcon.Learn = getAppIcon(LearnIcon as ElementType)
AppIcon.Logout = getAppIcon(LogoutIcon as ElementType)
AppIcon.Menu = getAppIcon(MenuIcon as ElementType)
AppIcon.Questionnaire = getAppIcon(QuestionnaireIcon as ElementType)
AppIcon.Share = getAppIcon(ShareIcon as ElementType)
AppIcon.Step = getAppIcon(StepIcon as ElementType)
AppIcon.Support = getAppIcon(SupportIcon as ElementType)
AppIcon.TestResult = getAppIcon(TestResultIcon as ElementType)
AppIcon.Todos = getAppIcon(TodosIcon as ElementType)
AppIcon.User = getAppIcon(UserIcon as ElementType)
AppIcon.Question = getAppIcon(QuestionIcon as ElementType)
AppIcon.Play = getAppIcon(PlayArrowRoundedIcon as ElementType)
AppIcon.OpenInNew = getAppIcon(OpenInNewRoundedIcon as ElementType)

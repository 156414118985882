import { getTheme } from 'components/NestMuiTheme'
import { useMemo } from 'react'
import { isLightColor } from 'utils/ColorUtil'
import { backdropClasses } from '@mui/material/Backdrop'

export default function usePageTheme(options: {
  color: string
  secondaryColor: string
  backgroundColor: string
  contrastThreshold?: number
}) {
  const {
    color,
    contrastThreshold,
    secondaryColor,
    backgroundColor,
  } = options
  const theme = useMemo(() => {
    const mode = isLightColor(backgroundColor) ? 'light' : 'dark'

    return getTheme({
      background: backgroundColor,
      contrastThreshold,
      mode,
      overrideComponents: {
        MuiFormLabel: {
          styleOverrides: {
            root: {
              marginBottom: 16,
            },
          },
        },
        MuiInputLabel: {
          styleOverrides: {
            root: {
              textWrap: 'pretty',
            },
          },
        },
        // Fix Modal backdrop issue (workaround)
        // ref: https://github.com/mui/material-ui/issues/32286#issuecomment-1820685334
        MuiModal: {
          styleOverrides: {
            root: {
              [`&:has(> div.${backdropClasses.root}[style*="opacity: 0"])`]:
                {
                  pointerEvents: 'none',
                },
            },
          },
        },
        MuiTypography: {
          styleOverrides: {
            h1: { lineHeight: 1.4 },
            h2: { lineHeight: 1.4 },
            h3: { lineHeight: 1.4 },
            h4: { lineHeight: 1.4 },
            h5: { lineHeight: 1.4 },
          },
        },
      },
      primaryColor: color,
      secondaryColor,
    })
  }, [backgroundColor, color, secondaryColor, contrastThreshold])

  return theme
}

import React, { ReactNode } from 'react'
import FormLabel, { FormLabelProps } from '@mui/material/FormLabel'
import useInterpolate from 'hooks/useInterpolate'
import { StoryPlayerContext } from 'components/StoryPlayerContext'
import MarkdownContent from 'components/MarkdownContent'

export type StoryFieldLabelProps = {
  label: string
  required?: boolean
  children?: ReactNode
  sx?: FormLabelProps['sx']
}

export default function StoryFieldLabel(props: StoryFieldLabelProps) {
  const { required } = props
  const interpolate = useInterpolate(StoryPlayerContext)
  const label =
    interpolate(props.label) + (required ? '&thinsp;*' : '')

  return (
    <FormLabel
      hidden={!label}
      sx={{
        '&.Mui-focused': {
          color: 'text.primary',
        },
        color: 'text.primary',
        fontSize: '1.25rem',
        mb: 1,
        ...props.sx,
      }}
    >
      <MarkdownContent value={label} />
      {props.children}
    </FormLabel>
  )
}

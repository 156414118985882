export function copyToClipboard(text: string, type = 'text/plain') {
  const blob = new Blob([text], { type })
  const data = [new ClipboardItem({ [type]: blob })]
  return navigator.clipboard.write(data)
}

// Emoji regex resource.
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Regular_expressions/Unicode_character_class_escape
const emojiRegex = /\p{Emoji_Presentation}/gu

export function isSingleEmoji(text = '') {
  const normalizeEmoji = text
    .replaceAll(emojiRegex, 'x')
    .replaceAll(/x[^x]/gu, 'x')

  // Emoji with skin tone can be 2 characters long.
  return emojiRegex.test(text) && normalizeEmoji.length <= 2
}

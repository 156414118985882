import { Texture } from 'components/DynamicBackground'

// eslint-disable-next-line import/no-anonymous-default-export
const texture: Texture = {
  data: (fill: string) => `
<svg width="52" height="26" viewBox="0 0 52 26" xmlns="http://www.w3.org/2000/svg">
  <g fill="none" fill-rule="evenodd">
    <g fill="${fill}">
      <path d="M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z" />
    </g>
  </g>
</svg>`,
  direction: ['x', 'xy'],
  height: 26,
  width: 52,
}
export default texture

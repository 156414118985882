import {
  StoryConnectionRule,
  StoryData,
} from 'components/StoryPlayer'
import { ChapterModuleConfig } from 'components/StoryPlayer/Modules/Chapters'
import { StoryPageData } from 'components/StoryPlayer/StoryPage'
import { cloneDeep } from 'lodash'

const CHAPTER_END_PAGE_ID = 'CHAPTER_END_PAGE_ID'

// Determines if a connection rule references a chapter end page.
function checkConnectionRuleForChapterEndPage(
  rule: StoryConnectionRule,
) {
  const type = rule.event?.type
  const pageId = rule.event?.params.pageId
  return type === 'StoryNavigation' && pageId === CHAPTER_END_PAGE_ID
}

// Determines if a page connects to a chapter end page.
function checkChapterEndPage(page: StoryPageData) {
  return !!(
    page.defaultConnectionPageId === CHAPTER_END_PAGE_ID ||
    page.connectionRules?.some(checkConnectionRuleForChapterEndPage)
  )
}

// Determine the next initial page for given chapter stories.
function getNextInitialPageId(
  chapterStories: StoryData[],
  chapterStartIndex: number,
) {
  return chapterStories
    .slice(chapterStartIndex)
    .reduce((acc, story) => {
      if (!acc && story.initialPageId) {
        return story.initialPageId
      }
      return acc
    }, '')
}

// Determine the next initial connection rules for given chapter stories.
function getNextInitialConnectionRules(
  chapterStories: StoryData[],
  chapterStartIndex: number,
) {
  return chapterStories
    .slice(chapterStartIndex)
    .reduce((acc, story) => {
      if (!acc.length && story.initialConnectionRules?.length) {
        return story.initialConnectionRules
      }
      return acc
    }, [] as StoryConnectionRule[])
}

// Create a single story connecting the given chapters.
export const getStoryFromChapters = <
  T extends string,
  K extends string,
>(
  moduleConfigChapters: ChapterModuleConfig<T, K>['chapters'],
  moduleChapterStories: StoryData[],
) => {
  const chapterIds = moduleConfigChapters.map(
    (chapter) => chapter.storyId,
  )
  const chapterStories = chapterIds
    .map((chapterId) => {
      const chapterStory = moduleChapterStories.find(
        (story) => story.id === chapterId,
      )

      if (!chapterStory) {
        throw new Error(
          `Story not found for chapter ID: ${chapterId}`,
        )
      }

      return cloneDeep(chapterStory)
    })
    .filter(Boolean)
    .map((story, index, stories) => {
      const nextIndex = index + 1
      const nextInitialPageId = getNextInitialPageId(
        stories,
        nextIndex,
      )
      const nextInitialConnectionRules =
        getNextInitialConnectionRules(stories, nextIndex)

      // Dynamically connect chapter stories.
      story.pages = story.pages.map((page) => {
        const isChapterEndPage = checkChapterEndPage(page)

        // Replace CHAPTER_END_PAGE_ID with actual default connection.
        if (page.defaultConnectionPageId === CHAPTER_END_PAGE_ID) {
          page.defaultConnectionPageId = nextInitialPageId
        }

        // Replace CHAPTER_END_PAGE_ID within connection rules.
        page.connectionRules = page.connectionRules?.map((rule) => {
          if (checkConnectionRuleForChapterEndPage(rule)) {
            rule.event.params.pageId = nextInitialPageId
          }
          return rule
        })

        // Include additional connection rules to conditionally opt-in to next chapters.
        if (isChapterEndPage) {
          page.connectionRules = [
            ...(page.connectionRules ?? []),
            ...nextInitialConnectionRules,
          ]
        }

        return page
      })

      return story
    })
  const story: StoryData = {
    // Use first configured chapter as base story.
    ...chapterStories[0],
    pages: chapterStories.map((story) => story.pages).flat(),
  }

  return story
}

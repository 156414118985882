import React, { ElementType } from 'react'
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import InfoRoundedIcon from '@mui/icons-material/InfoRounded'
import { SvgIconProps } from '@mui/material'

export type BlockIconProps = {
  name:
    | 'circle-outline'
    | 'circle-outline-checked'
    | 'play'
    | 'check'
    | 'info'
}

export default function BlockIcon(
  props: SvgIconProps & BlockIconProps,
) {
  const { name, sx, ...svgIconProps } = props
  const Icon = {
    check: CheckRoundedIcon,
    'circle-outline': RadioButtonUncheckedRoundedIcon,
    'circle-outline-checked': CheckCircleOutlineRoundedIcon,
    info: InfoRoundedIcon,
    play: PlayArrowRoundedIcon,
  }[name] as ElementType<SvgIconProps>
  if (!Icon) return null
  return <Icon sx={sx} {...svgIconProps} />
}

import { StoryData } from 'components/StoryPlayer'

// Debug stories
import ModuleDebugger from './module-debugger.story.json'
import ExamplePageTemplates from './example-page-templates.story.json'

// Import stories
import beginngsPreQuestionnaireEs from './beginngs-pre-questionnaire-es.story.json'
import beginngsNicuReadyToConsentEs from './beginngs-nicu-ready-to-consent-es.story.json'
import beginngsNicuEs from './beginngs-nicu-es.story.json'
import mciVusResults from './mci-vus-results.story.json'
import mciNegativeResults from './mci-negative-results.story.json'
import mciResults from './mci-results.story.json'
import beginngsObOneMonthReminder from './beginngs-ob-one-month-reminder.story.json'
import beginngsObFull from './beginngs-ob-full.story.json'
import uicEdPostTriageEducationEs from './uic-ed-post-triage-education-es.story.json'
import mciStoryEs from './mci-story-es.story.json'
import mciStartEs from './mci-start-es.story.json'
import mciEnglishStart from './mci-english-start.story.json'
import moffittAyaLearnOverview from './moffitt-aya-learn-overview.story.json'
import moffittGiGeneticTestingEs from './moffitt-gi-genetic-testing-es.story.json'
import carrierScreening from './carrier-screening.story.json'
import mciStory from './mci-story.story.json'
import hcmaDigitalSurvey from './hcma-digital-survey.story.json'
import beginngsObFinishConsent from './beginngs-ob-finish-consent.story.json'
import dfciLearnOverview from './dfci-learn-overview.story.json'
import dfciSharingResults from './dfci-sharing-results.story.json'
import dfciReviewCarePlan from './dfci-review-care-plan.story.json'
import resultsOverviewCourseCHEK2I157T from './results-overview-course-chek2-i157t.story.json'
import danaFarberOverview from './dana-farber-overview.story.json'
import beginngsNicuNotInterestedReady from './beginngs-nicu-not-interested-ready.story.json'
import beginngsNicuReadyToConsent from './beginngs-nicu-ready-to-consent.story.json'
import resultsOverviewCourseAPC from './results-overview-course-apc.story.json'
import learnLibraryOverview from './learn-library-overview.story.json'
import resultsOverviewCourseLZTR1Schwannomatosis from './results-overview-course-lztr1-schwannomatosis.story.json'
import resultsOverviewCourseVHL from './results-overview-course-vhl.story.json'
import resultsOverviewCourseTSC from './results-overview-course-tsc.story.json'
import resultsOverviewCourseSTK11 from './results-overview-course-stk11.story.json'
import resultsOverviewCourseSMAD4 from './results-overview-course-smad4.story.json'
import resultsOverviewCourseRPS20 from './results-overview-course-rps20.story.json'
import resultsOverviewCourseRNF43 from './results-overview-course-rnf43.story.json'
import resultsOverviewCourseRETMEN2 from './results-overview-course-ret-men2.story.json'
import resultsOverviewCourseRECQL4Carrier from './results-overview-course-recql4-carrier.story.json'
import resultsOverviewCourseNTHL1Biallelic from './results-overview-course-nthl1-biallelic.story.json'
import resultsOverviewCourseNTHL1Carrier from './results-overview-course-nthl1-carrier.story.json'
import resultsOverviewCourseNBNCarrier from './results-overview-course-nbn-carrier.story.json'
import resultsOverviewCourseMUTYHBiallelic from './results-overview-course-mutyh-biallelic.story.json'
import resultsOverviewCourseMUTYHCarrier from './results-overview-course-mutyh-carrier.story.json'
import resultsOverviewCourseMSH3Biallelic from './results-overview-course-msh3-biallelic.story.json'
import resultsOverviewCourseMSH3CARRIER from './results-overview-course-msh3-carrier.story.json'
import beginngsResults from './beginngs-results.story.json'
import genetypeTestOrdering from './genetype-test-ordering.story.json'
import foreConsent from './fore-consent.story.json'
import ACSGeneticTesting from './acs-genetic-testing.story.json'
import ACSOnboarding from './acs-onboarding.story.json'
import ACSScreening38FFamilial from './acs-screening-38ffamilial.story.json'
import ACSScreening44F from './acs-screening-44f.story.json'
import ACSLifestyle60M from './acs-lifestyle-60m.story.json'
import ACSScreening47MLynch from './acs-screening-47mlynch.story.json'
import moffittCrcGeneticTesting from './moffitt-crc-genetic-testing.story.json'
import ACSScreening49F from './acs-screening-49f.story.json'
import ACSScreeningQ from './acs-screening-q.story.json'
import ACSLifestyleQ from './acs-lifestyle-q.story.json'
import ACSLifestyle55F from './acs-lifestyle-55f.story.json'
import ACSCancerHealthHistory from './acs-cancer-health-history-questionnaire.story.json'
import ACSCancerLifeStyle from './acs-cancer-lifestyle-questionnaire.story.json'
import galleriDemo from './galleri-demo.story.json'
import beginngsCaFormInputs from './beginngs-ca-form-inputs.story.json'
import cardioRORNegative from './cardio-ror-negative.story.json'
import cardioRORSchedule from './cardio-ror-schedule.story.json'
import medstarPreEducationSurvey from './medstar-pre-education-survey.story.json'
import medstarPostEducationSurvey from './medstar-post-education-survey.story.json'
import medstarCrc from './medstar-crc.story.json'
import geisingerCarePlanCancerScreeningPrevention from './geisinger-care-plan-cancer-screening-prevention.story.json'
import beginngsNicu from './beginngs-nicu.story.json'
import geisingerBehaviorQuestionnaire from './geisinger-behavior-questionnaire.story.json'
import geisingerCareHistoryQuestionnaire from './geisinger-care-history-questionnaire.story.json'
import geisingerOnboarding from './geisinger-onboarding.story.json'
import openDnaCardioPrs from './open-dna-cardio-prs.story.json'
import beginngsClinicianAssessment from './beginngs-clinician-assessment.story.json'
import beginngsPostQuestionnaire from './beginngs-post-questionnaire.story.json'
import beginngsPreQuestionnaire from './beginngs-pre-questionnaire.story.json'
import myriadRecapture from './myriad-recapture.story.json'
import resultsOverviewCoursePTEN from './results-overview-course-pten.story.json'
import resultsOverviewCoursePOLE from './results-overview-course-pole.story.json'
import resultsOverviewCoursePOLD1 from './results-overview-course-pold1.story.json'
import resultsOverviewCourseMITF from './results-overview-course-mitf.story.json'
import resultsOverviewCourseMEN1 from './results-overview-course-men1.story.json'
import resultsOverviewCourseHOXB13 from './results-overview-course-hoxb13.story.json'
import resultsOverviewCourseGREM1 from './results-overview-course-grem1.story.json'
import resultsOverviewCourseGALNT12 from './results-overview-course-galnt12.story.json'
import resultsOverviewCourseCDK4 from './results-overview-course-cdk4.story.json'
import resultsOverviewCourseCDH1 from './results-overview-course-cdh1.story.json'
import resultsOverviewCourseBMPR1A from './results-overview-course-bmpr1a.story.json'
import resultsOverviewCourseBAP1 from './results-overview-course-bap1.story.json'
import resultsOverviewCourseAXIN2 from './results-overview-course-axin2.story.json'
import beginngsOb from './beginngs-ob.story.json'
import moffittGiPgx from './moffitt-gi-pgx.story.json'
import genetypeDocumentDemo from './genetype-document-demo.story.json'
import AnnualQuestionnaireBrca2 from './annual-questionnaire-brca2.story.json'
import CancerRiskQuestionnaire from './cancer-risk-questionnaire.story.json'
import CancerRiskQuestionnaireDemo from './cancer-risk-questionnaire-demo.story.json'
import AspirinForLynch from './aspirin-for-lynch.story.json'
import AspirinForLynchPms2 from './aspirin-for-lynch-pms2.story.json'
import BetaBlockerTherapy from './beta-blocker-therapy.story.json'
import BrainMri from './brain-mri.story.json'
import Brca2CancerRisks from './brca2-cancer-risks.story.json'
import BreastAwareness from './breast-awareness.story.json'
import BreastCancerChemoprevention from './breast-cancer-chemoprevention.story.json'
import BreastMri from './breast-mri.story.json'
import BreastUltrasound from './breast-ultrasound.story.json'
import ComprehensiveEyeExam from './comprehensive-eye-exam.story.json'
import MRIGeneral from './mri-general.story.json'
import BreastSelfExamMales from './breast-self-exam-males.story.json'
import ClinicalBreastExam from './clinical-breast-exam.story.json'
import Colonoscopy from './colonoscopy.story.json'
import DiabetesScreening from './diabetes-screening.story.json'
import DnaGenesHereditarydiseases from './dna-genes-hereditarydiseases.story.json'
import EndometrialCancerScreening from './endometrial-cancer-screening.story.json'
import EndoscopicUltrasound from './endoscopic-ultrasound.story.json'
import FamilyPlanningAndPartnerTesting from './family-planning-and-partner-testing.story.json'
import FamilyPlanningConsiderations from './family-planning-considerations.story.json'
import FamilyPlanningConsiderationsCancer from './family-planning-considerations-cancer.story.json'
import GeneticCounseling from './genetic-counseling.story.json'
import GeneticTestingForInheritedCancerRisk from './genetic-testing-for-inherited-cancer-risk.story.json'
import MakeMammogramAppointment from './make-mammogram-appointment.story.json'
import MakeMammogramAppointmentNonIntegrated from './make-mammogram-appointment-non-integrated.story.json'
import Mammogram from './mammogram.story.json'
import Metanephrines from './metanephrines.story.json'
import EndometrialBiopsy from './endometrial-biopsy.story.json'
import CA125 from './ca-125.story.json'
import TransvaginalUltrasound from './transvaginal-ultrasound.story.json'
import PSATest from './psa-test.story.json'
import DigitalRectalExam from './digital-rectal-exam.story.json'
import AtypicalHyperplasia from './atypical-hyperplasia.story.json'
import Myh7_001 from './myh7-001.story.json'
import Myh7_002 from './myh7-002.story.json'
import Myh7_003 from './myh7-003.story.json'
import Onboarding from './onboarding.story.json'
import OnboardingCardio from './onboarding-cardio.story.json'
import OnboardingConsent from './onboarding-consent.story.json'
import Welcome from './welcome.story.json'
import OnwardPrecisionMedicineProgramInformedConsent from './onward-precision-medicine-program-informed-consent.story.json'
import OvarianCancerScreening from './ovarian-cancer-screening.story.json'
import PancreasMriMrcp from './pancreas-mri-mrcp.story.json'
import ProstateCancerScreening from './prostate-cancer-screening.story.json'
import IncreasedRiskBreastCancer from './increased-risk-breast-cancer.story.json'
import IncreasedRiskColorectalCancer from './increased-risk-colorectal-cancer.story.json'
import IncreasedRiskPancreaticCancer from './increased-risk-pancreatic-cancer.story.json'
import ResultsOverviewCourseAtm from './results-overview-course-atm.story.json'
import ResultsOverviewCourseBard1 from './results-overview-course-bard1.story.json'
import ResultsOverviewCourseBrca1 from './results-overview-course-brca1.story.json'
import ResultsOverviewCourseBrca2 from './results-overview-course-brca2.story.json'
import ResultsOverviewCourseBrip1 from './results-overview-course-brip1.story.json'
import ResultsOverviewCourseCdkn2a from './results-overview-course-cdkn2a.story.json'
import ResultsOverviewCourseRet from './results-overview-course-ret.story.json'
import ResultsOverviewCourseChek2 from './results-overview-course-chek2.story.json'
import ResultsOverviewCourseEpcam from './results-overview-course-epcam.story.json'
import ResultsOverviewCourseMlh1 from './results-overview-course-mlh1.story.json'
import ResultsOverviewCourseMsh2 from './results-overview-course-msh2.story.json'
import ResultsOverviewCourseMsh6 from './results-overview-course-msh6.story.json'
import ResultsOverviewCourseMyh7 from './results-overview-course-myh7.story.json'
import ResultsOverviewCourseNf1 from './results-overview-course-nf1.story.json'
import ResultsOverviewCoursePalb2 from './results-overview-course-palb2.story.json'
import ResultsOverviewCoursePms2 from './results-overview-course-pms2.story.json'
import ResultsOverviewCourseCftr from './results-overview-course-cftr.story.json'
import ResultsOverviewCourseRad51c from './results-overview-course-rad51c.story.json'
import ResultsOverviewCourseRad51d from './results-overview-course-rad51d.story.json'
import ResultsOverviewCourseSdha from './results-overview-course-sdha.story.json'
import ResultsOverviewCourseSdhb from './results-overview-course-sdhb.story.json'
import ResultsOverviewCourseSdhc from './results-overview-course-sdhc.story.json'
import ResultsOverviewCourseSdhd from './results-overview-course-sdhd.story.json'
import ResultsOverviewCourseTp53 from './results-overview-course-tp53.story.json'
import ResultsOverviewCourseRho from './results-overview-course-rho.story.json'
import ResultsOverviewCourse2Q112 from './results-overview-course-22q112.story.json'
import ResultsOverviewCourseAPOB from './results-overview-course-apob.story.json'
import ResultsOverviewCourseLDLR from './results-overview-course-ldlr.story.json'
import ResultsOverviewCoursePCSK9 from './results-overview-course-pcsk9.story.json'
import ResultsOverviewCourseKCNQ1 from './results-overview-course-kcnq1.story.json'
import ResultsOverviewCourseMYBPC3 from './results-overview-course-mybpc3.story.json'
import RiskReducingMastectomy from './risk-reducing-mastectomy.story.json'
import RiskReducingSalpingoOophorectomy from './risk-reducing-salpingo-oophorectomy.story.json'
import SexGenderIdentityDiseaseRisk from './sex-gender-identity-disease-risk.story.json'
import SharingResults from './sharing-results.story.json'
import SharingResultsCardio from './sharing-results-cardio.story.json'
import TotalHysterectomyLynch from './total-hysterectomy-lynch.story.json'
import TotalHysterectomyBrca1 from './total-hysterectomy-brca1.story.json'
import UpperEndoscopy from './upper-endoscopy.story.json'
import UsingBirthControlForCancerPrevention from './using-birth-control-for-cancer-prevention.story.json'
import WholeBodyMri from './whole-body-mri.story.json'
import SerumCalcitonin from './serum-calcitonin.story.json'
import CarcinoembryonicAntigenCea from './carcinoembryonic-antigen-cea.story.json'
import NeckUltrasound from './neck-ultrasound.story.json'
import SestamibiScan from './sestamibi-scan.story.json'
import CTScan from './ct-scan.story.json'
import TotalThyroidectomy from './total-thyroidectomy.story.json'
import AudiologyExam from './audiology-exam.story.json'
import SerumCalcium from './serum-calcium.story.json'
import ParathyroidHormoneTest from './parathyroid-hormone-test.story.json'
import ChatGeneticTesting from './chat-genetic-testing.story.json'
import ChatFamilySharing from './chat-family-sharing.story.json'
import CloverHealthDemoConsent from './clover-health-demo-consent.story.json'
import PretestHCEducation from './pretest-hc-education.story.json'
import CarePlanOverview from './care-plan-overview.story.json'
import CarePlanOverviewCancerScreeningPrevention from './care-plan-cancer-screening-prevention.story.json'
import Nf1ResultsOverviewChild from './nf1-results-overview-child.story.json'
import NfComprehensiveExam from './nf-comprehensive-exam.story.json'
import NfDevelopmentatlQuestionnaire from './nf-developmental-questionnaire.story.json'
import Hypermobility5Q from './hypermobility-5q.story.json'
import CTDIntake from './ctd-intake.story.json'
import hEDSPatientStory from './heds-patient-story.story.json'
import StgDemoConsent from './stg-demo-consent.story.json'
import ScheduleHcTestResultsCall from './schedule-hc-test-results-call.story.json'
import ScheduleHcTestResultsVus from './schedule-hc-test-results-vus.story.json'
import ScheduleHcTestResultsNeg from './schedule-hc-test-results-neg.story.json'

// Customer specific stories
import UicHealthFairQuestionnaire from './uic-health-fair-questionnaire.story.json'
import UicCancerHealthHistoryQuestionnaire from './uic-cancer-health-history-questionnaire.story.json'
import UicCancerHealthHistoryQuestionnaireEs from './uic-cancer-health-history-questionnaire-es.story.json'
import UicPreApptCancerHealthHistoryQuestionnaire from './uic-pre-appt-cancer-health-history-questionnaire.story.json'
import UicPreApptCancerHealthHistoryQuestionnaireEs from './uic-pre-appt-cancer-health-history-questionnaire-es.story.json'
import UicImagingCancerHealthHistoryQuestionnaire from './uic-mammo-cancer-health-history-questionnaire.story.json'
import UicImagingCancerHealthHistoryQuestionnaireEs from './uic-mammo-cancer-health-history-questionnaire-es.story.json'
import UicEdCancerHealthHistoryQuestionnaire from './uic-ed-cancer-health-history-questionnaire.story.json'
import UicEdCancerHealthHistoryQuestionnaireSendEn from './uic-ed-cancer-health-history-questionnaire-send-en.story.json'
import UicEdCancerHealthHistoryQuestionnaireSendEs from './uic-ed-cancer-health-history-questionnaire-send-es.story.json'
import UicEdPostTriageEducation from './uic-ed-post-triage-education.story.json'
import UicPostTriageEducation from './uic-post-triage-education.story.json'
import UicPostTriageEducationEs from './uic-post-triage-education-es.story.json'
import UpmcCancerHealthHistoryQuestionnaire from './upmc-cancer-health-history-questionnaire.story.json'
import MsMedicineCancerHealthHistoryQuestionnaire from './msmedicine-cancer-health-history-questionnaire.story.json'
import HuntsmanRedcapSurvey from './huntsman-redcap-survey.story.json'
import FirststepsBeginnings from './firststeps-beginnings.story.json'
import FirststepsBeginningsConsent from './firststeps-beginnings-consent.story.json'
import BeginningsNewbornConsent from './beginnings-newborn-consent.story.json'
import BeginningsRWGSConsent from './beginnings-rwgs-consent.story.json'
import MyriadTestOrdering from './myriad-test-ordering.story.json'
import MyriadCancerHealthHistoryQuestionnaire from './myriad-cancer-health-history-questionnaire.story.json'
import GeneTypePreVisit from './genetype-previsit.story.json'
import HcmaCardiacMri from './hcma-cardiac-mri.story.json'
import HcmaEchocardiogram from './hcma-echocardiogram.story.json'
import HcmaStressEcho from './hcma-stress-echo.story.json'
import HcmaEcgEkg from './hcma-ecg-ekg.story.json'
import HcmaGeneticTesting from './hcma-genetic-testing.story.json'
import HcmaCpet from './hcma-cpet.story.json'
import HcmaCoe from './hcma-coe.story.json'
import HcmaPatientDiscussionGroups from './hcma-patient-discussion-groups.story.json'
import HcmaBigHeartedWarrior from './hcma-big-hearted-warrior.story.json'
import HcmaEducationAndSupport from './hcma-education-and-support.story.json'
import HcmaHolterEventMonitor from './hcma-holter-event-monitor.story.json'
import HcmaOnboarding from './hcma-onboarding.story.json'
import HcmaProcedureHistory from './hcma-procedure-history.story.json'
import HcmaServices from './hcma-services.story.json'
import HcmaCenterOfExcellence from './hcma-center-of-excellence.story.json'
import HcmaGeneticTestingHistory from './hcma-genetic-testing-history.story.json'
import HcmaPatientJourneyConsent from './hcma-patient-journey-consent.story.json'
import HcmaNavigatingHcm from './hcma-navigating-hcm.story.json'
import HcmaNewlyDxd from './hcma-newly-dxd.story.json'
import HcmaNewlyDxdPt from './hcma-newly-dxd-pt.story.json'
import HcmaShareWithFamily from './hcma-share-with-family.story.json'
import Hcma2ndOpinion from './hcma-2nd-opinion.story.json'
import HcmaInsurance from './hcma-insurance.story.json'
import HcmaIcd from './hcma-icd.story.json'
import HcmaSrt from './hcma-srt.story.json'
import HcmaTransplant from './hcma-transplant.story.json'
import HcmaAfterALoss from './hcma-after-a-loss.story.json'
import MoffittOnboarding from './moffitt-onboarding.story.json'
import MoffittOnboardingEs from './moffitt-onboarding-es.story.json'
import MoffittCRCOnboarding from './moffitt-crc-onboarding.story.json'
import MoffittCRCOnboardingEs from './moffitt-crc-onboarding-es.story.json'
import MoffittCRCPretestEducation from './moffitt-crc-pretest-education.story.json'
import MoffittCRCProviderSurvey from './moffitt-crc-provider-survey.story.json'
import MoffittCRCPatientHealthHistory from './moffitt-crc-patient-health-history.story.json'
import MoffittCRCPatientDemographics from './moffitt-crc-patient-demographics.story.json'
import MoffittCRCPretestEducationSpanish from './moffitt-crc-pretest-education-es.story.json'
import MoffittCRCPatientHealthHistorySpanish from './moffitt-crc-patient-health-history-es.story.json'
import MoffittCRCPatientDemographicsSpanish from './moffitt-crc-patient-demographics-es.story.json'
import MoffittGiOnboarding from './moffitt-gi-onboarding.story.json'
import MoffittGiftdHealthQuestionnaire from './moffitt-giftd-health-questionnaire.story.json'
import MoffittGiGeneticTesting from './moffitt-gi-genetic-testing.story.json'
import MoffittGiHereditaryCancer from './moffitt-gi-hereditary-cancer.story.json'
import MoffittGiGeneticTestResults from './moffitt-gi-genetic-test-results.story.json'
import MoffittGiFamilyRisk from './moffitt-gi-family-risk.story.json'
import MoffittGiGina from './moffitt-gi-gina.story.json'
import MoffittGiGermlineVsSomatic from './moffitt-gi-germline-vs-somatic.story.json'
import MoffittGiTargetedTreatmentOptions from './moffitt-gi-targeted-treatment-options.story.json'
import MoffittGiHowDoYouFeelNow from './moffitt-gi-how-do-you-feel-now.story.json'
import MoffittGiGeneticTestingWithConsent from './moffitt-gi-genetic-testing-with-consent.story.json'
import MedstarOnboarding from './medstar-onboarding.story.json'
import MedstarPrestestEducation from './medstar-pretest-education.story.json'
import MedstarGeneticCounseling from './medstar-genetic-counseling.story.json'
import MedstarSharingResults from './medstar-sharing-results.story.json'
import MyriadOutreach from './myriad-outreach.story.json'
import GeneticCounselingSpanish from './genetic-counseling-es.story.json'
import SharingResultsSpanish from './sharing-results-es.story.json'
import Nyu_001 from './nyu-001.story.json'
import Upenn_001 from './upenn-001.story.json'
import GeisingerLynch from './geisinger-lynch.story.json'
import MoffittAyaIntroQuestionnaire from './moffitt-aya-intro-questionnaire.story.json'
import MoffittAyaPreservingFertility from './moffitt-aya-preserving-fertility.story.json'
import MoffittAyaDecisionSupportQuestionnaireFemale from './moffitt-aya-decision-support-questionnaire-female.story.json'
import MoffittAyaDecisionSupportQuestionnaireMale from './moffitt-aya-decision-support-questionnaire-male.story.json'
import MoffittAyaGeneticTesting from './moffitt-aya-genetic-testing.story.json'
import MoffittAyaGeneticCounseling from './moffitt-aya-genetic-counseling.story.json'
import MoffittAyaPGT from './moffitt-aya-pgt.story.json'
import MoffittAyaFemaleFertilityPreservation from './moffitt-aya-female-fertility-preservation.story.json'
import MoffittAyaMaleFertilityPreservation from './moffitt-aya-male-fertility-preservation.story.json'
import MoffittAYAFemaleCancerImpact from './moffitt-aya-female-cancer-impact.story.json'
import MoffittAYAMaleCancerImpact from './moffitt-aya-male-cancer-impact.story.json'
import MoffittAYAPayingForFertilityTx from './moffitt-aya-paying-for-fertilitytx.story.json'
import MoffittAyaMentalHealthResources from './moffitt-aya-mental-health-resources.story.json'
import MoffittAyaBenefitsOfGeneticCounseling from './moffitt-aya-benefits-of-genetic-counseling.story.json'
import MoffittAyaCancerRiskForFutureChildren from './moffitt-aya-cancer-risk-for-future-children.story.json'
import MoffittAyaCostOfGeneticCounselingAndTesting from './moffitt-aya-cost-of-genetic-counseling-and-testing.story.json'
import MoffittAyaGinaLaw from './moffitt-aya-gina-law.story.json'
import MoffittAyaGeneticTestingProcess from './moffitt-aya-genetic-testing-process.story.json'
import MoffittAyaValueOfGeneticTesting from './moffitt-aya-value-of-genetic-testing.story.json'
import MoffittAyaImpactOfGeneticTestResults from './moffitt-aya-impact-of-genetic-test-results.story.json'
import MoffittAyaWhatIsGeneticCounseling from './moffitt-aya-what-is-genetic-counseling.story.json'
import MoffittAyaWhoShouldHaveGeneticCounseling from './moffitt-aya-who-should-have-genetic-counseling.story.json'
import MoffittAyaCostOfIvf from './moffitt-aya-cost-of-ivf.story.json'
import MoffittAyaIvfExperience1 from './moffitt-aya-ivf-experience-1.story.json'
import MoffittAyaSpermBankingProcess from './moffitt-aya-sperm-banking-process.story.json'
import MoffittAyaIvfExperience2 from './moffitt-aya-ivf-experience-2.story.json'
import MoffittAyaGeneticCounselingRachell from './moffitt-aya-genetic-counseling-rachell.story.json'
import MoffittAyaImpactOfFertilityPreservationOnCancer from './moffitt-aya-impact-of-fertility-preservation-on-cancer.story.json'
import MoffittAyaWhatIsIvfRei from './moffitt-aya-what-is-ivf-rei.story.json'
import MoffittAyaWhatIsPgtEndocrinologist from './moffitt-aya-what-is-pgt-endocrinologist.story.json'
import MoffittAyaWhatIsFertilityPreservationOncologist from './moffitt-aya-what-is-fertility-preservation-oncologist.story.json'
import MoffittAyaUnderstandingHereditaryCancer from './moffitt-aya-understanding-hereditary-cancer.story.json'
import MoffittAyaImpactOfDiagnosisOnFertility from './moffitt-aya-impact-of-diagnosis-on-fertility.story.json'
import MoffittAyaImportanceOfAskingAboutFertilityPreservation from './moffitt-aya-importance-of-asking-about-fertility-preservation.story.json'
import MoffittAyaImpactOfTreatmentOnFertilityOncologist from './moffitt-aya-impact-of-treatment-on-fertility-oncologist.story.json'
import MoffittAyaWhatIsFertilityPreservationPatientNavigator from './moffitt-aya-what-is-fertility-preservation-patient-navigator.story.json'
import MoffittAyaImpactOfTreatmentOnFertilityPatientNavigator from './moffitt-aya-impact-of-treatment-on-fertility-patient-navigator.story.json'
import MoffittAyaWhatIsPgtGeneticCounselor from './moffitt-aya-what-is-pgt-genetic-counselor.story.json'
import MoffittAyaWhatIsIvfRachell from './moffitt-aya-what-is-ivf-rachell.story.json'
import OverviewMoffittAYAFemaleFertilityPreservation from './moffitt-aya-overview-female-fertility-preservation.story.json'
import OverviewMoffittAYAMaleFertilityPreservation from './moffitt-aya-overview-male-fertility-preservation.story.json'
import OverviewMoffittAYAGeneticTesting from './moffitt-aya-overview-genetic-testing.story.json'
import OverviewMoffittAYAMaleCancerImpact from './moffitt-aya-overview-male-cancer-impact.story.json'
import OverviewMoffittAYAFemaleCancerImpact from './moffitt-aya-overview-female-cancer-impact.story.json'
import EggEmbryoFreezing from './eggembryo-freezing.story.json'
import OvarianTissueFreezing from './ovarian-tissue-freezing.story.json'
import OvarianSuppression from './ovarian-suppression.story.json'
import OvarianTransposition from './ovarian-transposition.story.json'
import SpermBanking from './sperm-banking.story.json'
import TesticularShielding from './testicular-shielding.story.json'
import NerveSparingSurgery from './nerve-sparing-surgery.story.json'
import ChemotherapyMale from './chemotherapy-male.story.json'
import RadiationMale from './radiation-male.story.json'
import SurgeryMale from './surgery-male.story.json'
import HormoneTxMale from './hormone-tx-male.story.json'
import TargetedTxMale from './targeted-tx-male.story.json'
import ChemotherapyFemale from './chemotherapy-female.story.json'
import RadiationFemale from './radiation-female.story.json'
import SurgeryFemale from './surgery-female.story.json'
import HormoneTxFemale from './hormone-tx-female.story.json'
import TargetedTxFemale from './targeted-tx-female.story.json'
import Immunotherapy from './immunotherapy.story.json'
import RadyChildrensOnboarding from './rady-childrens-onboarding.story.json'
import DevelopmentalQuestionnaire from './developmental-questionnaire.story.json'

// Keep a "frozen" copy of these stories until the DFCI study is complete
import ResultsOverviewCourseAtmDfci from './results-overview-course-atm-dfci.story.json'
import ResultsOverviewCourseBrca1Dfci from './results-overview-course-brca1-dfci.story.json'
import ResultsOverviewCourseBrca2Dfci from './results-overview-course-brca2-dfci.story.json'
import ResultsOverviewCourseChek2Dfci from './results-overview-course-chek2-dfci.story.json'
import ResultsOverviewCourseEpcamDfci from './results-overview-course-epcam-dfci.story.json'
import ResultsOverviewCourseMlh1Dfci from './results-overview-course-mlh1-dfci.story.json'
import ResultsOverviewCourseMsh2Dfci from './results-overview-course-msh2-dfci.story.json'
import ResultsOverviewCourseMsh6Dfci from './results-overview-course-msh6-dfci.story.json'
import ResultsOverviewCoursePms2Dfci from './results-overview-course-pms2-dfci.story.json'
import ResultsOverviewCourseSdhaDfci from './results-overview-course-sdha-dfci.story.json'
import ResultsOverviewCourseSdhbDfci from './results-overview-course-sdhb-dfci.story.json'
import ResultsOverviewCourseSdhcDfci from './results-overview-course-sdhc-dfci.story.json'
import ResultsOverviewCourseSdhdDfci from './results-overview-course-sdhd-dfci.story.json'
import ResultsOverviewCourseTp53Dfci from './results-overview-course-tp53-dfci.story.json'
import DnaGenesHereditarydiseasesDfci from './dna-genes-hereditarydiseases-dfci.story.json'
import SharingResultsDfci from './sharing-results-dfci.story.json'
import SexGenderIdentityDiseaseRiskDfci from './sex-gender-identity-disease-risk-dfci.story.json'
import FamilyPlanningAndPartnerTestingDfci from './family-planning-and-partner-testing-dfci.story.json'
import FamilyPlanningConsiderationsDfci from './family-planning-considerations-dfci.story.json'
import AspirinForLynchDfci from './aspirin-for-lynch-dfci.story.json'
import AspirinForLynchPms2Dfci from './aspirin-for-lynch-pms2-dfci.story.json'
import BreastAwarenessDfci from './breast-awareness-dfci.story.json'
import ClinicalBreastExamDfci from './clinical-breast-exam-dfci.story.json'
import MammogramDfci from './mammogram-dfci.story.json'
import MetanephrineTestingDfci from './metanephrine-testing-dfci.story.json'
import OvarianCancerScreeningDfci from './ovarian-cancer-screening-dfci.story.json'
import RiskReducingMastectomyDfci from './risk-reducing-mastectomy-dfci.story.json'
import RiskReducingSalpingoOophorectomyDfci from './risk-reducing-salpingo-oophorectomy-dfci.story.json'
import TotalHysterectomyDfci from './total-hysterectomy-dfci.story.json'
import TotalHysterectomyBrca1Dfci from './total-hysterectomy-brca1-dfci.story.json'
import ColonoscopyDfci from './colonoscopy-dfci.story.json'
import UsingBirthControlForCancerPreventionDfci from './using-birth-control-for-cancer-prevention-dfci.story.json'
import BreastCancerChemopreventionDfci from './breast-cancer-chemoprevention-dfci.story.json'
import Brca2CancerRisksDfci from './brca2-cancer-risks-dfci.story.json'
import BreastMriDfci from './breast-mri-dfci.story.json'
import BreastSelfExamMalesDfci from './breast-self-exam-males-dfci.story.json'

// Old stories to be removed?
import Brca1_001 from './brca1-001.story.json'
import Brca1_002 from './brca1-002.story.json'
import Brca1_004 from './brca1-004.story.json'
import Brca2_004 from './brca2-004.story.json'
import C001 from './c001.story.json'
import C002 from './c002.story.json'
import C003 from './c003.story.json'
import C004 from './c004.story.json'
import C005 from './c005.story.json'
import G001 from './g001.story.json'
import G005 from './g005.story.json'
import G006 from './g006.story.json'
import G007 from './g007.story.json'
import G008 from './g008.story.json'
import G4m001 from './g4m001.story.json'
import G4m005 from './g4m005.story.json'
import G4m006 from './g4m006.story.json'
import G4m007 from './g4m007.story.json'
import G4m008 from './g4m008.story.json'
import L001 from './l001.story.json'
import L002 from './l002.story.json'
import L003 from './l003.story.json'
import L004 from './l004.story.json'
import L005 from './l005.story.json'
import L006 from './l006.story.json'
import L007 from './l007.story.json'
import L008 from './l008.story.json'
import L009 from './l009.story.json'
import L010 from './l010.story.json'
import L011 from './l011.story.json'

const DFCIStories = [
  ResultsOverviewCourseAtmDfci,
  ResultsOverviewCourseBrca1Dfci,
  ResultsOverviewCourseBrca2Dfci,
  ResultsOverviewCourseChek2Dfci,
  ResultsOverviewCourseEpcamDfci,
  ResultsOverviewCourseMlh1Dfci,
  ResultsOverviewCourseMsh2Dfci,
  ResultsOverviewCourseMsh6Dfci,
  ResultsOverviewCoursePms2Dfci,
  ResultsOverviewCourseSdhaDfci,
  ResultsOverviewCourseSdhbDfci,
  ResultsOverviewCourseSdhcDfci,
  ResultsOverviewCourseSdhdDfci,
  ResultsOverviewCourseTp53Dfci,
  DnaGenesHereditarydiseasesDfci,
  SharingResultsDfci,
  SexGenderIdentityDiseaseRiskDfci,
  FamilyPlanningAndPartnerTestingDfci,
  FamilyPlanningConsiderationsDfci,
  AspirinForLynchDfci,
  AspirinForLynchPms2Dfci,
  BreastAwarenessDfci,
  ClinicalBreastExamDfci,
  MammogramDfci,
  MetanephrineTestingDfci,
  OvarianCancerScreeningDfci,
  RiskReducingMastectomyDfci,
  RiskReducingSalpingoOophorectomyDfci,
  TotalHysterectomyDfci,
  TotalHysterectomyBrca1Dfci,
  ColonoscopyDfci,
  UsingBirthControlForCancerPreventionDfci,
  BreastCancerChemopreventionDfci,
  Brca2CancerRisksDfci,
  BreastMriDfci,
  BreastSelfExamMalesDfci,
] as StoryData[]

const stories = [
  beginngsPreQuestionnaireEs,
  beginngsNicuReadyToConsentEs,
  beginngsNicuEs,
  mciVusResults,
  mciNegativeResults,
  mciResults,
  beginngsObOneMonthReminder,
  beginngsObFull,
  uicEdPostTriageEducationEs,
  mciStoryEs,
  mciStartEs,
  mciEnglishStart,
  moffittAyaLearnOverview,
  moffittGiGeneticTestingEs,
  carrierScreening,
  mciStory,
  hcmaDigitalSurvey,
  beginngsObFinishConsent,
  dfciLearnOverview,
  dfciSharingResults,
  dfciReviewCarePlan,
  resultsOverviewCourseCHEK2I157T,
  danaFarberOverview,
  beginngsNicuNotInterestedReady,
  beginngsNicuReadyToConsent,
  resultsOverviewCourseAPC,
  learnLibraryOverview,
  resultsOverviewCourseLZTR1Schwannomatosis,
  resultsOverviewCourseVHL,
  resultsOverviewCourseTSC,
  resultsOverviewCourseSTK11,
  resultsOverviewCourseSMAD4,
  resultsOverviewCourseRPS20,
  resultsOverviewCourseRNF43,
  resultsOverviewCourseRETMEN2,
  resultsOverviewCourseRECQL4Carrier,
  resultsOverviewCourseNTHL1Biallelic,
  resultsOverviewCourseNTHL1Carrier,
  resultsOverviewCourseNBNCarrier,
  resultsOverviewCourseMUTYHBiallelic,
  resultsOverviewCourseMUTYHCarrier,
  resultsOverviewCourseMSH3Biallelic,
  resultsOverviewCourseMSH3CARRIER,
  beginngsResults,
  genetypeTestOrdering,
  foreConsent,
  ACSGeneticTesting,
  ACSOnboarding,
  ACSScreening38FFamilial,
  ACSScreening44F,
  ACSLifestyle60M,
  ACSScreening47MLynch,
  moffittCrcGeneticTesting,
  ACSScreening49F,
  ACSScreeningQ,
  ACSLifestyleQ,
  ACSLifestyle55F,
  ACSCancerHealthHistory,
  ACSCancerLifeStyle,
  cardioRORSchedule,
  galleriDemo,
  beginngsCaFormInputs,
  cardioRORNegative,
  cardioRORSchedule,
  medstarPreEducationSurvey,
  medstarPostEducationSurvey,
  medstarCrc,
  geisingerCarePlanCancerScreeningPrevention,
  beginngsNicu,
  geisingerBehaviorQuestionnaire,
  geisingerCareHistoryQuestionnaire,
  geisingerOnboarding,
  openDnaCardioPrs,
  beginngsClinicianAssessment,
  beginngsPostQuestionnaire,
  beginngsPreQuestionnaire,
  myriadRecapture,
  resultsOverviewCoursePTEN,
  resultsOverviewCoursePOLE,
  resultsOverviewCoursePOLD1,
  resultsOverviewCourseMITF,
  resultsOverviewCourseMEN1,
  resultsOverviewCourseHOXB13,
  resultsOverviewCourseGREM1,
  resultsOverviewCourseGALNT12,
  resultsOverviewCourseCDK4,
  resultsOverviewCourseCDH1,
  resultsOverviewCourseBMPR1A,
  resultsOverviewCourseBAP1,
  resultsOverviewCourseAXIN2,
  beginngsOb,
  moffittGiPgx,
  genetypeDocumentDemo,
  ModuleDebugger,
  AnnualQuestionnaireBrca2,
  CancerRiskQuestionnaire,
  CancerRiskQuestionnaireDemo,
  UicHealthFairQuestionnaire,
  UicCancerHealthHistoryQuestionnaire,
  UicCancerHealthHistoryQuestionnaireEs,
  UicPreApptCancerHealthHistoryQuestionnaire,
  UicPreApptCancerHealthHistoryQuestionnaireEs,
  UicImagingCancerHealthHistoryQuestionnaire,
  UicImagingCancerHealthHistoryQuestionnaireEs,
  UicEdCancerHealthHistoryQuestionnaire,
  UicEdCancerHealthHistoryQuestionnaireSendEn,
  UicEdCancerHealthHistoryQuestionnaireSendEs,
  UicEdPostTriageEducation,
  UicPostTriageEducation,
  UicPostTriageEducationEs,
  UpmcCancerHealthHistoryQuestionnaire,
  MsMedicineCancerHealthHistoryQuestionnaire,
  HuntsmanRedcapSurvey,
  FirststepsBeginnings,
  FirststepsBeginningsConsent,
  BeginningsNewbornConsent,
  BeginningsRWGSConsent,
  MyriadTestOrdering,
  MyriadCancerHealthHistoryQuestionnaire,
  GeneTypePreVisit,
  AspirinForLynch,
  AspirinForLynchPms2,
  BetaBlockerTherapy,
  BrainMri,
  Brca1_001,
  Brca1_002,
  Brca1_004,
  Brca2CancerRisks,
  Brca2_004,
  BreastAwareness,
  BreastCancerChemoprevention,
  BreastMri,
  BreastUltrasound,
  ComprehensiveEyeExam,
  MRIGeneral,
  BreastSelfExamMales,
  C001,
  C002,
  C003,
  C004,
  C005,
  ClinicalBreastExam,
  Colonoscopy,
  DiabetesScreening,
  DnaGenesHereditarydiseases,
  EndometrialCancerScreening,
  EndoscopicUltrasound,
  FamilyPlanningAndPartnerTesting,
  FamilyPlanningConsiderations,
  FamilyPlanningConsiderationsCancer,
  GeneticCounseling,
  G001,
  G005,
  G006,
  G007,
  G008,
  G4m001,
  G4m005,
  G4m006,
  G4m007,
  G4m008,
  GeneticTestingForInheritedCancerRisk,
  HcmaBigHeartedWarrior,
  HcmaCardiacMri,
  HcmaCenterOfExcellence,
  HcmaCoe,
  HcmaCpet,
  HcmaEcgEkg,
  HcmaEchocardiogram,
  HcmaEducationAndSupport,
  HcmaGeneticTesting,
  HcmaGeneticTestingHistory,
  HcmaPatientJourneyConsent,
  HcmaNavigatingHcm,
  HcmaNewlyDxd,
  HcmaNewlyDxdPt,
  HcmaShareWithFamily,
  Hcma2ndOpinion,
  HcmaInsurance,
  HcmaIcd,
  HcmaSrt,
  HcmaTransplant,
  HcmaAfterALoss,
  HcmaHolterEventMonitor,
  HcmaOnboarding,
  HcmaPatientDiscussionGroups,
  HcmaProcedureHistory,
  HcmaServices,
  HcmaStressEcho,
  L001,
  L002,
  L003,
  L004,
  L005,
  L006,
  L007,
  L008,
  L009,
  L010,
  L011,
  MakeMammogramAppointment,
  MakeMammogramAppointmentNonIntegrated,
  Mammogram,
  Metanephrines,
  EndometrialBiopsy,
  CA125,
  TransvaginalUltrasound,
  PSATest,
  DigitalRectalExam,
  AtypicalHyperplasia,
  MoffittOnboarding,
  MoffittOnboardingEs,
  MoffittCRCOnboarding,
  MoffittCRCOnboardingEs,
  MoffittCRCPretestEducation,
  MoffittCRCProviderSurvey,
  MoffittCRCPatientHealthHistory,
  MoffittCRCPatientDemographics,
  MoffittCRCPretestEducationSpanish,
  MoffittCRCPatientHealthHistorySpanish,
  MoffittCRCPatientDemographicsSpanish,
  MoffittGiOnboarding,
  MoffittGiftdHealthQuestionnaire,
  MoffittGiGeneticTesting,
  MoffittGiHereditaryCancer,
  MoffittGiGeneticTestResults,
  MoffittGiFamilyRisk,
  MoffittGiGina,
  MoffittGiGermlineVsSomatic,
  MoffittGiTargetedTreatmentOptions,
  MoffittGiHowDoYouFeelNow,
  MoffittGiGeneticTestingWithConsent,
  MedstarOnboarding,
  MedstarPrestestEducation,
  MedstarGeneticCounseling,
  MedstarSharingResults,
  MyriadOutreach,
  GeneticCounselingSpanish,
  SharingResultsSpanish,
  Myh7_001,
  Myh7_002,
  Myh7_003,
  Onboarding,
  OnboardingCardio,
  OnboardingConsent,
  Welcome,
  Nyu_001,
  OnwardPrecisionMedicineProgramInformedConsent,
  OvarianCancerScreening,
  PancreasMriMrcp,
  ProstateCancerScreening,
  IncreasedRiskBreastCancer,
  IncreasedRiskColorectalCancer,
  IncreasedRiskPancreaticCancer,
  ResultsOverviewCourseAtm,
  ResultsOverviewCourseBard1,
  ResultsOverviewCourseBrca1,
  ResultsOverviewCourseBrca2,
  ResultsOverviewCourseBrip1,
  ResultsOverviewCourseCdkn2a,
  ResultsOverviewCourseRet,
  ResultsOverviewCourseChek2,
  ResultsOverviewCourseEpcam,
  ResultsOverviewCourseMlh1,
  ResultsOverviewCourseMsh2,
  ResultsOverviewCourseMsh6,
  ResultsOverviewCourseMyh7,
  ResultsOverviewCourseNf1,
  ResultsOverviewCoursePalb2,
  ResultsOverviewCoursePms2,
  ResultsOverviewCourseCftr,
  ResultsOverviewCourseRad51c,
  ResultsOverviewCourseRad51d,
  ResultsOverviewCourseSdha,
  ResultsOverviewCourseSdhb,
  ResultsOverviewCourseSdhc,
  ResultsOverviewCourseSdhd,
  ResultsOverviewCourseTp53,
  ResultsOverviewCourseRho,
  ResultsOverviewCourse2Q112,
  ResultsOverviewCourseAPOB,
  ResultsOverviewCourseLDLR,
  ResultsOverviewCoursePCSK9,
  ResultsOverviewCourseKCNQ1,
  ResultsOverviewCourseMYBPC3,
  RiskReducingMastectomy,
  RiskReducingSalpingoOophorectomy,
  SexGenderIdentityDiseaseRisk,
  SharingResults,
  SharingResultsCardio,
  TotalHysterectomyLynch,
  TotalHysterectomyBrca1,
  Upenn_001,
  UpperEndoscopy,
  UsingBirthControlForCancerPrevention,
  WholeBodyMri,
  SerumCalcitonin,
  CarcinoembryonicAntigenCea,
  NeckUltrasound,
  SestamibiScan,
  CTScan,
  TotalThyroidectomy,
  AudiologyExam,
  SerumCalcium,
  ParathyroidHormoneTest,
  ChatGeneticTesting,
  ChatFamilySharing,
  CloverHealthDemoConsent,
  PretestHCEducation,
  CarePlanOverview,
  CarePlanOverviewCancerScreeningPrevention,
  Nf1ResultsOverviewChild,
  NfComprehensiveExam,
  NfDevelopmentatlQuestionnaire,
  Hypermobility5Q,
  CTDIntake,
  hEDSPatientStory,
  StgDemoConsent,
  ScheduleHcTestResultsCall,
  ScheduleHcTestResultsVus,
  ScheduleHcTestResultsNeg,
  GeisingerLynch,
  MoffittAyaIntroQuestionnaire,
  MoffittAyaPreservingFertility,
  MoffittAyaDecisionSupportQuestionnaireFemale,
  MoffittAyaDecisionSupportQuestionnaireMale,
  MoffittAyaGeneticTesting,
  MoffittAyaGeneticCounseling,
  MoffittAyaPGT,
  MoffittAyaFemaleFertilityPreservation,
  MoffittAyaMaleFertilityPreservation,
  MoffittAYAFemaleCancerImpact,
  MoffittAYAMaleCancerImpact,
  MoffittAYAPayingForFertilityTx,
  MoffittAyaMentalHealthResources,
  MoffittAyaBenefitsOfGeneticCounseling,
  MoffittAyaCancerRiskForFutureChildren,
  MoffittAyaCostOfGeneticCounselingAndTesting,
  MoffittAyaGinaLaw,
  MoffittAyaGeneticTestingProcess,
  MoffittAyaValueOfGeneticTesting,
  MoffittAyaImpactOfGeneticTestResults,
  MoffittAyaWhatIsGeneticCounseling,
  MoffittAyaWhoShouldHaveGeneticCounseling,
  MoffittAyaCostOfIvf,
  MoffittAyaIvfExperience1,
  MoffittAyaSpermBankingProcess,
  MoffittAyaIvfExperience2,
  MoffittAyaGeneticCounselingRachell,
  MoffittAyaImpactOfFertilityPreservationOnCancer,
  MoffittAyaWhatIsIvfRei,
  MoffittAyaWhatIsPgtEndocrinologist,
  MoffittAyaWhatIsFertilityPreservationOncologist,
  MoffittAyaUnderstandingHereditaryCancer,
  MoffittAyaImpactOfDiagnosisOnFertility,
  MoffittAyaImportanceOfAskingAboutFertilityPreservation,
  MoffittAyaImpactOfTreatmentOnFertilityOncologist,
  MoffittAyaWhatIsFertilityPreservationPatientNavigator,
  MoffittAyaImpactOfTreatmentOnFertilityPatientNavigator,
  MoffittAyaWhatIsPgtGeneticCounselor,
  MoffittAyaWhatIsIvfRachell,
  OverviewMoffittAYAFemaleFertilityPreservation,
  OverviewMoffittAYAGeneticTesting,
  OverviewMoffittAYAMaleFertilityPreservation,
  OverviewMoffittAYAMaleCancerImpact,
  OverviewMoffittAYAFemaleCancerImpact,
  EggEmbryoFreezing,
  OvarianTissueFreezing,
  OvarianSuppression,
  OvarianTransposition,
  SpermBanking,
  TesticularShielding,
  NerveSparingSurgery,
  ChemotherapyMale,
  RadiationMale,
  SurgeryMale,
  HormoneTxMale,
  TargetedTxMale,
  ChemotherapyFemale,
  RadiationFemale,
  SurgeryFemale,
  HormoneTxFemale,
  TargetedTxFemale,
  Immunotherapy,
  RadyChildrensOnboarding,
  DevelopmentalQuestionnaire,
  ExamplePageTemplates,
  ...DFCIStories,
] as StoryData[]

export default stories

import React, { Fragment } from 'react'
import useInterpolate from 'hooks/useInterpolate'
import { StoryPlayerContext } from 'components/StoryPlayerContext'
import useDialog from 'hooks/useDialog'
import MarkdownContent from 'components/MarkdownContent'
import { BaseBlockData } from 'components/StoryPlayer/PageBlock'
import ContentDrawer from 'components/ContentDrawer'
import { articleLinkStyle } from 'components/blocks/TextBlock'
import { Typography } from '@mui/material'

export type InfoLinkBlockData = BaseBlockData & {
  type: 'info-link'
  variant?: 'body1' | 'body2'
  label: string
} & (
    | {
        contentTitle?: string
        content: string
        href?: never
      }
    | {
        contentTitle?: never
        content?: never
        href: string
      }
  )

export type InfoLinkBlockProps = Omit<InfoLinkBlockData, 'type'>

export default function InfoLinkBlock(props: InfoLinkBlockProps) {
  const {
    label,
    contentTitle,
    content,
    href,
    variant = 'body1',
    sx,
  } = props
  const [dialogProps, { setOpen }] = useDialog('info-link')
  const interpolate = useInterpolate(StoryPlayerContext)
  const interpolatedLabel = interpolate(label)

  if (href) {
    return (
      <Typography
        component="a"
        variant={variant}
        href={href}
        target="_blank"
        sx={{ ...articleLinkStyle, ...sx }}
      >
        {label}
      </Typography>
    )
  }

  return (
    <>
      <MarkdownContent
        component="a"
        value={interpolatedLabel}
        sx={{
          cursor: 'pointer',
          display: 'inline-block',
          typography: variant,
          ...articleLinkStyle,
          ...sx,
        }}
        onClick={() => setOpen(true)}
        // Prevent p elements from being rendered inside the anchor tag.
        components={{ p: Fragment }}
      />
      <ContentDrawer
        {...dialogProps}
        title={interpolate(contentTitle)}
        content={interpolate(content)}
      />
    </>
  )
}
